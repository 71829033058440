.dlx-blockCaseStudies {
	//padding-top: $base6;
	//padding-bottom: $base3;
	padding-top: $base2;
	padding-bottom: $base2;
	position: relative;
	clear: both;

	@include media($smallLayout) {
		//padding-top: $base5;
		padding-bottom: $base4;
	}

	@include media($mediumLayout) {
		//padding-top: $base8;
		padding-bottom: $base6;
	}

	@include media($xLargeLayout) {
		//padding-top: $base10;
		padding-bottom: $base8;
	}
}
