.dlx-ugc {
	@include dlx-fadeUp();

	p {
		margin-bottom: $base2;

		&:last-of-type {
			margin-bottom: 0;
		}
	}

	p + ul {
		margin-top: $base2;
	}

	ul {
		list-style: none;
		padding-left: 0;

		li {
			position: relative;
			margin-bottom: $base;

			//switch off list anim
			//opacity: 0;
			//transform: translateY($base3);
			@include media($mediumLayout) {
				margin-bottom: $base + $base05;
			}
		}

		li::before {
			content: '\0025cf\0020';
			list-style: none;
			margin-right: $base;
			padding-left: 0;
			opacity: 0;
		}
	}

	@for $i from 0 through 20 {
		&.dlx-js-inside li:nth-child(#{$i})::before {
			@include ease(opacity, $durationShowAnim, $easeOutQuad, $durationDelayBetweenElements * $i);
			opacity: 1;
		}
	}

	//switch off list anim
	//@for $i from 0 through 20 {
	//	&.dlx-js-inside li:nth-child(#{$i}) {
	//		@include ease(opacity transform, $durationShowAnim, $easeOutQuad, $duration05 * $i);
	//		opacity: 1;
	//		transform: translateY(0);
	//	}
	//}

	&--checkmarklist {
		ul {
			li {
				padding-left: $base3;
			}

			li::before {
				background-image: url("/assets/img/check-mark.svg");
				background-repeat: no-repeat;
				background-size: ($base + $base05) ($base + $base05);
				background-position: left top;
				content: '';
				display: inline-block;
				height: 100%;
				left: 0;
				position: absolute;
				top: $base025;
				width: $base2;// + $base05;

				@include media($smallLayout) {
					top: $base05 + 0.1rem;
				}

				@include media($mediumLayout) {
					top: $base05 + 0.1rem;
				}

				@include media($xLargeLayout) {
					top: $base05 + $base025;
				}
			}
		}
	}

	a {
		border-bottom: 2px solid $colorFootnoteActive;
		background-color: transparent;
		@include ease(background-color, $durationBase, $easeBase);

		&:hover {
			background-color: $colorFootnote;
		}
	}


	h1,
	h2,
	h3 {
		@include font(contentheader);
		margin-bottom: $base;
		margin-top: $base2;

		@include media($mediumLayout) {
			margin-top: $base4;
			margin-bottom: $base2;
		}
	}

	blockquote {
		@include font(quote);
		//clear: both;
		color: $colorQuote;
		display: block;
		margin-bottom: $base2;
		margin-left: 0;
		margin-right: 0;
		margin-top: $base2;
		padding-left: $base2;
		padding-right: 0;
		padding-top: 0;
		padding-bottom: 0;
		position: relative;
		width: 100%;

		&:first-child {
			margin-top: 0;

			@include media($smallLayout) {
				margin-top: 0;
			}

			@include media($mediumLayout) {
				margin-top: 0;
			}

			@include media($xLargeLayout) {
				margin-top: 0;
			}
		}

		&:last-child {
			margin-bottom: 0;

			@include media($smallLayout) {
				margin-bottom: 0;
			}

			@include media($mediumLayout) {
				margin-bottom: 0;
			}

			@include media($xLargeLayout) {
				margin-bottom: 0;
			}
		}

		@include media($smallLayout) {
			margin-bottom: $base3;
			margin-top: $base3;
		}

		@include media($mediumLayout) {
			margin-bottom: $base4;
			padding-left: $base3;
			margin-top: $base4;
		}

		@include media($xLargeLayout) {
			margin-bottom: $base6;
			margin-top: $base6;
		}

		a {
			@include ease(color, $durationBase, $easeBase);
			@include font(subhead);
			background-color: transparent;
			border-bottom: none;
			color: $colorQuote;
			display: block;
			margin-top: $base + $base05;
			text-decoration: none;
			text-transform: uppercase;

			&:hover {
				color: $colorFootnoteActive;
				background-color: transparent;
			}

			@include media($mediumLayout) {
				margin-top: $base2;
			}

			@include media($xLargeLayout) {
				margin-top: $base3;
			}
		}

		&::before {
			background-color: $colorQuoteDash;
			content: '';
			display: block;
			width: 1px;
			height: 0;
			left: 0;
			bottom: 0;
			position: absolute;
		}
	}


	&.dlx-js-inside blockquote {
		&::before {
			@include ease(height, $durationBlockquote, $easeOutQuad, $durationAnim);
			height: calc(100% + #{$base05});
		}
	}
}
