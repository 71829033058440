.dlx-homepageLink {
	@include dlx-mainContent(true);
	position: relative;
	opacity: 0;
	margin-bottom: $base2;
	transform: translateY($baseYShift);

	&:nth-child(even) {
		@include dlx-mainContent(false, false, true);
	}

	//add some nice animations
	@for $i from 0 through 10 {
		&:nth-child(#{$i}) {
			@include ease(opacity transform, $durationVeryLongHome, $easeBase);
		}
	}

	.dlx-homepageSlide.dlx-js-active & {
		opacity: 1;
		transform: translateY(0);
		//add some nice animations
		@for $i from 0 through 10 {
			&:nth-child(#{$i}) {
				@include ease(opacity transform, $durationVeryLongHome, $easeBase, $durationBase * $i);
			}
		}
	}

	@include media($smallLayout) {
		margin-bottom: $base * 4;
	}

	@include media($xLargeLayout) {
		margin-bottom: $base * 4;
	}

	@include media('height#{$homepageMinHeight}') {
		margin-bottom: $base2;
	}

	&__anchor {
		display: block;
		padding-left: $mainPageDotSmall + $base;

		@include media($smallLayout) {
			padding-left: $mainPageDotMedium + $base2;
		}

		@include media($xLargeLayout) {
			padding-left: $mainPageDotLarge + $base2;
		}

		@include media('height#{$homepageMinHeight}') {
			padding-left: $mainPageDotSmall + $base;
		}
	}

	&__dot {
		height: $mainPageDotSmall;
		left: - $mainPageDotSmall - $base;
		opacity: 0;
		position: absolute;
		top: $base05;
		transform: scale(0);
		width: $mainPageDotSmall;

		//add some nice animations
		@for $i from 1 through 10 {
			.dlx-homepageLink:nth-child(#{$i}) & {
				@include ease(opacity transform, $durationVeryLongHome, $easeBase, $durationBase * $i);
			}
		}

		.dlx-homepageSlide.dlx-js-active & {
			opacity: 1;
			transform: scale(1);
		}

		@include media($smallLayout) {
			height: $mainPageDotMedium;
			left: - $mainPageDotMedium - $base2;
			top: 0;
			width: $mainPageDotMedium;
		}

		@include media('height#{$homepageMinHeight}') {
			height: $mainPageDotSmall;
			left: - $mainPageDotSmall - $base;
			top: $base05;
			width: $mainPageDotSmall;
		}


		@include media($xLargeLayout) {
			height: $mainPageDotLarge;
			left: - $mainPageDotLarge - $base2;
			top: -$base05;
			width: $mainPageDotLarge;
		}
	}

	&__dotShape {
		@include colorSet($colorsSetActive, $colorsSetLength, background-color, true, false, '.dlx-homepageSlide');
		@include ease(transform, $durationLong, $easeBase);
		border-radius: 50%;
		display: block;
		height: 100%;
		transform: scale(1);
		width: 100%;

		.dlx-body.dlx-js-idle & {
			@include media($mediumLayout) {
				transform: scale(0.5);
			}
		}

		.dlx-homepageLink:hover & {
			transform: scale(0.75);
		}
	}

	&__content {
		position: relative;

		margin-top: $base05;
	}


	&__subtitle {
		@include colorSet($colorsSetActive, $colorsSetLength, color, true, false, '.dlx-homepageSlide');
		@include font(subhead);
		text-transform: uppercase;
		@include fadeIn($durationLong, $easeBase);

		.dlx-body.dlx-js-idle & {
			@include media($mediumLayout) {
				@include fadeOut($durationLong, $easeBase);
			}
		}
	}

	&__title {
		position: relative;
		@include font(header);
		@include fadeIn($durationLong, $easeBase);

		@include media('height#{$homepageMinHeight}') {
			@include font(base);
		}

		.dlx-body.dlx-js-idle & {
			@include media($mediumLayout) {
				@include fadeOut($durationLong, $easeBase);
			}
		}
	}
}
