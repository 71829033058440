//used for team tiles grid
@mixin dlx-tilesContent() {
	margin-left: 0;
	width: 100%;

	@include media($smallLayout) {
		margin-left: 0;
		width: 100%;
	}

	@include media($mediumLayout) {
		margin-left: staticColumnPush(3, 12, $gutterMedium);
		width: staticColumnSize(8, 12, $gutterMedium);
	}

	@include media($xLargeLayout) {
		margin-left: staticColumnPush(3, 12, $gutterXLarge);
		width: staticColumnSize(8, 12, $gutterXLarge);
	}
}
