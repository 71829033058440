.dlx-homepageTimer {
	background-color: $colorOverlayTransparent;
	bottom: 0;
	height: $base05;
	left: 0;
	position: absolute;
	width: 100%;

	@include media($mediumLayout) {
		height: $base05 + $base025;
	}

	&.dlx-js-hidden {
		@include fadeOut($durationLong, $easeBase);
	}

	&__scroll {
		@include fadeIn($durationLong, $easeBase);
		bottom: $base5;
		position: absolute;
		right: $containerPaddingBase;
		display: none;

		svg {
			fill: $colorScrollIcon;
		}

		.dlx-body.dlx-js-idle & {
			@include media($mediumLayout) {
				@include fadeOut($durationLong, $easeBase);
			}
		}

		@include media($smallLayout) {
			right: $containerPaddingSmall;
		}

		@include media($mediumLayout) {
			display: block;
			right: $containerPaddingMedium;
		}

		@include media($xLargeLayout) {
			right: $containerPaddingXLarge;
		}
	}


	&__strip {
		background-color: $colorBaseInverted;
		bottom: 0;
		height: $base05;
		left: 0;
		position: absolute;
		width: 0;
	}
}
