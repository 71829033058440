@use "sass:math";

$base: 1rem;
$base0: $base * 0;
$base1: $base * 1;
$base2: $base * 2;
$base3: $base * 3;
$base4: $base * 4;
$base5: $base * 5;
$base6: $base * 6;
$base7: $base * 7;
$base8: $base * 8;
$base9: $base * 9;
$base10: $base * 10;
$base05: $base * 0.5;
$base025: $base * 0.25;

$baseHalf: $base05;

$videoRatio: math.div(16, 9);

$containerPaddingBase: $base;
$containerPaddingSmall: $base2;
$containerPaddingMedium: $base * 3.2;
$containerPaddingLarge: $base * 3.2;
$containerPaddingXLarge: $base2;

$gutterBase: $base2;
$gutterMedium: $base * 3.2;
$gutterXLarge: $base2;

$verticalMargin: $base6;
$verticalMargin2: $base6 * 2;
$verticalMarginHalf: $base3;

$baseIconSize: $base6;

$linkElementMinHeight: $base * 15;
$linkElementMinHeightM: $base * 20;
$linkElementMinHeightXL: $base * 24;

$mediumMargin: $base * 2.5;

$footnoteWidthXL: $base * 42;

$formElementsHeight: $base * 5.4;
$formElementsHeightXL: $base * 6;

$logoWidth: $base * 14.5;
$logoHeight: $base * 5;
$logoSmallWidth: $base * 10;
$logoSmallHeight: $base * 3.2;

$logoSpecialWidth: $base * 8.2;
$logoSpecialHeight: $base * 2.6;

$headerPaddingSmall: $base;
$headerPaddingBig: $base2;

$marginMenuWidth: $base * 22;

$headerHeightSmall: $logoSmallHeight + 2 * $headerPaddingSmall;
$headerHeightBig: $logoHeight + 2 * $headerPaddingBig;

$headerHeightSpecialBig: $logoSmallHeight + 2 * $headerPaddingBig;

$dotNavSize: 0.8rem;
$dotNavSizeActiv: 1.2rem;

$mainPageDotSmall: $base * 1.5;
$mainPageDotMedium: $base * 3.5;
$mainPageDotLarge: $base * 5;

$portraitHeight: 138%;

$caseHeightBase: $base * 40;
$caseWidthBase: 100%;
$caseHeightSmall: $base * 33;
$caseWidthSmall: $base * 50;
$caseHeightMedium: $base * 46;
$caseWidthMedium: $base * 55;
$caseHeightXLarge: $base * 70;
$caseWidthXLarge: $base * 100;
$caseImageWidth: 130vw;

$tableSpacing: $base + $base05;
$tableSpacingLarge: $base3;

$galleryRatio: 3 * 0.5;
$galleryNavigationSizeXSmall: $base * 0.8;
$galleryNavigationSpaceXSmall: $base * 0.5;
$galleryNavigationSizeSmall: $base * 1.3;
$galleryNavigationSpaceSmall: $base;
$galleryMaxItems: 10;

$inputPadding: $base05;
$inputNestedPadding: $base1;
$buttonMinWidth: $base10;
$buttonBorderSize: $base1;
$radioOuterSize: $base2;
$radioInnerScale: 0.5;
$inputBorderSize: 1px;
$labelNestedPadding: 1px;
$formFieldFullColumns: 8; // number of columns, 12 = full width

$ctaWidth: $base * 68;
$ctaHeight: $base * 45;

$sizeVariants: (
	logoSize: (
		width: $logoSmallWidth,
		height: $logoSmallHeight,
		variants: (
			$mediumLayout: (
				width: $logoWidth,
				height: $logoHeight,
			)
		)
	),
	logoSizeSpecial: (
		width: $logoSmallWidth,
		height: $logoSmallHeight,
		variants: (
			$mediumLayout: (
				width: $logoSmallWidth,
				height: $logoSmallHeight,
			)
		)
	),
	mainPaddingLeft: (
		padding-left: $containerPaddingBase,
		variants: (
			$smallLayout: (
				padding-left: $containerPaddingSmall,
			),
			$mediumLayout: (
				padding-left: $containerPaddingMedium,
			),
			$xLargeLayout: (
				padding-left: $containerPaddingXLarge,
			),
		)
	)
);
