.dlx-seo {
	@include clearfix();

	&__content {
		display: block;
	}

	&__figure {
		margin-top: $base2;
		display: block;
		width: 100px;

		img {
			width: 100px;
		}
	}

	&__highlight {
		color: $colorError;
	}

	&__label {
		@include font(subhead);
		float: left;
		width: 25%;
		margin-left: 0;
		margin-top: $base + $baseHalf;

		&--description {
			width: 70%;
			min-width: 70%;

			&::after {
				content: 'z';
				visibility: hidden;
				clear: both;
			}
		}
	}

	&__link {
		//
	}
}
