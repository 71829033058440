.dlx-blockLinkImage {
	position: relative;

	&__anchor {
		@include ease(background-color, $durationBase, $easeBase);
		display: block;
		height: 100%;
		position: relative;
		text-decoration: none;
		width: 100%;

		&:hover {
			background-color: $colorSecondInverted;
		}

		&::before {
			content: '';
			position: absolute;
			top: 0;
			left: -$gutterBase;
			height: 100%;
			width: $gutterBase;
			background-color: inherit;

			@include media($mediumLayout) {
				left: -$gutterMedium;
				width: $gutterMedium;
			}

			@include media($xLargeLayout) {
				left: -$gutterXLarge;
				width: $gutterXLarge;
			}
		}

		&::after,
		&:last-of-type::after {
			content: '';
			position: absolute;
			top: 0;
			left: auto;
			right: -30vw;
			height: 100%;
			width: 30vw;
			background-color: inherit;
			z-index: -1;
		}

		.dlx-blockLinks__item--highlight & {
			color: $colorBase;
			@include colorSet($colorsSet, $colorsSetLength, background-color, false, true, '.dlx-blockLink');

			&:hover {
				@include colorSet($colorsSetActive, $colorsSetLength, background-color, false, true, '.dlx-blockLink');
			}
		}
	}

	&__content {
		position: relative;

		margin-bottom: $base2;

		@include media($smallLayout) {
			margin-bottom: $base2;
		}

		@include media($xLargeLayout) {
			margin-bottom: $base3;
		}
	}

	&__columns {
		position: relative;
		min-height: $linkElementMinHeight;
		padding-top: $base2;
		padding-bottom: $base;

		@include media($smallLayout) {
			padding-top: $base3;
			padding-bottom: $base2;
			min-height: $linkElementMinHeightM;
		}

		@include media($mediumLayout) {
			@include staticColumns(0);
		}

		@include media($xLargeLayout) {
			padding-top: $base5;
			padding-bottom: $base5;
			min-height: $linkElementMinHeightXL;
		}
	}

	&__columnLeft {
		@include dlx-wideContentLeft();
		display: flex;
		flex-direction: column;
	}

	&__columnRight {
		@include dlx-wideContentRight();
	}

	&__description {
		flex-grow: 1;
		margin-bottom: $base2;

		@include media($smallLayout) {
			margin-bottom: $base2;
		}

		@include media($xLargeLayout) {
			margin-bottom: $base3;
		}
	}

	&__icon {
		color: $colorIcon;
		height: $baseIconSize;
		width: $baseIconSize;
		position: absolute;
		right: 0;
		top: 50%;
		transform: translateY(-50%);

		@include media($mediumLayout) {
			display: none;
		}
	}

	&__iconBottom {
		color: $colorIcon;
		height: $baseIconSize;
		width: $baseIconSize;
		display: none;
		position: relative;

		@include media($mediumLayout) {
			display: block;
		}
	}

	&__image {
		overflow: hidden;
		padding-bottom: 100%;
		position: relative;
		width: 100%;
	}

	&__img {
		@include objectFit(cover);
		display: block;
		height: 100%;
		width: 100%;
		position: absolute;
	}

	&__title {
		@include font(header);
		margin-top: $base;
		//margin-bottom: $base2;
		color: $colorSecond;
		margin-right: $base8;

		@include media($smallLayout) {
			//margin-bottom: $base2;
		}

		@include media($xLargeLayout) {
			//margin-bottom: $base3;
		}
	}

	&__subtitle {
		@include font(subheader);
	}
}
