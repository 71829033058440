.dlx-gallery {
	@include dlx-fadeUp();
	margin-bottom: $base4;
	margin-top: $base0;
	touch-action: pan-y;

	@include media($smallLayout) {
		margin-left: staticColumnPush(2, 12, $gutterBase);
		width: staticColumnSize(10, 12, $gutterBase);
	}

	@include media($mediumLayout) {
		margin-left: staticColumnPush(5, 12, $gutterMedium);
		width: staticColumnSize(7, 12, $gutterMedium);
	}

	@include media($xLargeLayout) {
		margin-left: staticColumnPush(5, 12, $gutterXLarge);
		width: staticColumnSize(7, 12, $gutterXLarge);
	}

	&__captions {
		margin-top: $base;

		@include media($smallLayout) {
			flex-grow: 1;
			margin-top: 0;
		}
	}

	&__nextPrev {
		display: none;
		@include media($mediumLayout) {
			//display: block;
		}
	}


	&__info {
		display: flex;
		flex-direction: column;
		margin-top: $base;

		@include media($smallLayout) {
			flex-direction: row;
		}
	}


	&__itemsContainter {
		position: relative;
	}


	&__navigation {
		@include media($smallLayout) {
			flex-grow: 0;
			margin-right: $base6;
		}
	}
}
