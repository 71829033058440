.dlx-blockConfiguratorLink {
	@include dlx-container();
	@include ease(background-color, $duration2, $easeBase);
	@include colorSet($colorsSet, $colorsSetLength, background-color);
	cursor: pointer;

	&:hover {
		@include colorSet($colorsSetActive, $colorsSetLength, background-color, false, true, '.dlx-blockConfiguratorLink');
	}

	&__header {
		//
	}

	&__anchor {
		display: block;
		position: relative;
		height: 100%;

		padding-top: $base3;
		padding-bottom: $base4;

		@include media($smallLayout) {
			padding-top: $base3;
			padding-bottom: $base5;
		}

		@include media($mediumLayout) {
			padding-top: $base3;
			padding-bottom: $base8;
		}

		@include media($xLargeLayout) {
			padding-top: $base5;
			padding-bottom: $base10;
		}
	}

	&__content {
		@include dlx-fadeUp();
		@include dlx-mainContent();
		//margin-bottom: $base2;
	}

	&__description {
		margin-top: $base2;

		@include media($smallLayout) {
			margin-top: $base3;
		}

		@include media($mediumLayout) {
			margin-top: $base3;
		}

		@include media($xLargeLayout) {
			margin-top: $base5;
		}
	}

	&__link {
		@include media($mediumLayout) {
			margin-top: -$base3;
		}

		@include media($xLargeLayout) {
			margin-top: -$base5;
		}
	}
}
