// element inside main-content, which extends all the way to the right edge
@mixin dlx-fullRightContent() {
	width: calc(100% + #{$containerPaddingBase});
	@include media($smallLayout) {
		width: calc(100% + #{staticColumnSize(1, 9, $gutterBase, $gutterBase)} + #{$containerPaddingSmall});
	}

	@include media($mediumLayout) {
		width: calc(100% + #{staticColumnSize(1, 6, $gutterMedium, $gutterMedium)} + #{$containerPaddingMedium});
	}

	@include media($xLargeLayout) {
		width: calc(100% + #{staticColumnSize(1, 6, $gutterXLarge, $gutterXLarge)} + #{$containerPaddingXLarge});
	}
}
