
@mixin dlx-mainContentWide() {
	width: 100%;
	margin-left: 0;

	@include media($smallLayout) {
		margin-left: staticColumnPush(2, 12, $gutterBase);
		width: staticColumnSize(10, 12, $gutterBase, $containerPaddingSmall);
	}

	@include media($mediumLayout) {
		margin-left: staticColumnPush(5, 12, $gutterMedium);
		width: staticColumnSize(7, 12, $gutterMedium, $containerPaddingMedium);
	}

	@include media($xLargeLayout) {
		margin-left: staticColumnPush(5, 12, $gutterXLarge);
		width: staticColumnSize(7, 12, $gutterXLarge, $containerPaddingXLarge);
	}
}
