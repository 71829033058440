.dlx-footnoteLink {
	@include ease(background-color, $duration3, $easeBase);
	background-color: $colorFootnote;
	cursor: pointer;
	display: inline;

	@include media($smallLayout) {
		position: relative;
	}

	&:hover,
	&.dlx-js-active {
		background-color: $colorFootnoteActive;
	}

	&__text {
		position: relative;
	}
}
