.dlx-homepage {
	display: none;
	background-color: $colorOverlayTransparentDark;

	&.dlx-js-initiated {
		display: block;
	}

	&__dotsNav {
		@include fadeIn($durationLong, $easeBase);
		position: absolute;
		right: $containerPaddingBase;
		top: 50%;
		transform: translateY(-50%);

		.dlx-body.dlx-js-idle & {
			@include media($mediumLayout) {
				@include fadeOut($durationLong, $easeBase);
			}
		}

		@include media($smallLayout) {
			right: $containerPaddingSmall;
		}

		@include media($mediumLayout) {
			right: $containerPaddingMedium;
		}

		@include media($xLargeLayout) {
			right: $containerPaddingXLarge;
		}
	}

	&__slides {
		//
	}

	&__slide {
		//
	}
}
