.dlx-contactCta {
	&__closeButton {
		position: absolute;
		@include font(smallbutton);
		text-transform: uppercase;
		right: $base2;
		top: $base;
		cursor: pointer;

		@include media($smallLayout) {
			top: $base2;
		}
	}

	&__content {
		display: block;
		z-index: 2;
		@include ease(transform, $durationVeryLong, $easeBase);
		background-color: $colorBgBase;
		border: 1px solid $colorSeparateLine;
		bottom: $base2;
		padding: $base3 $base2 $base2 $base2;
		position: fixed;
		right: 0;
		transform: translateX(100%);

		&.dlx-js-active {
			transform: translateX(0);
		}

		@include media($smallLayout) {
			padding: $base2;
		}
	}

	&__flag {
		bottom: $base2;
		left: 100%;
		position: fixed;
	}

	&__flagContent {
		@include ease(background-color, $durationBase, $easeBase);
		background-color: $nameColorDarkPeach;
		color: $colorBaseInverted;
		font-weight: normal;
		padding-left: $base05;
		padding-right: $base05;
		text-transform: uppercase;
		transform-origin: bottom left;
		transform: rotate(-90deg);
		cursor: pointer;

		.dlx-contactCta__flag:hover & {
			background-color: $nameColorBlush;
		}
	}

	&__headline {
		@include font(headermediumlight);
		margin-top: $base3;
		margin-bottom: $base2;
		flex-grow: 2;

		@include media($smallLayout) {
			margin-top: $base4;
		}
	}

	&__image {
		display: block;
		position: relative;
		width: 100%;
		height: 100%;
		min-height: $base * 27;
	}

	&__imageContainer {
		display: flex;
		flex-basis: 100%;
		overflow: hidden;
		position: relative;
	}

	&__img {
		@include objectFit(cover);
		display: block;
		height: 100%;
		left: 0;
		position: absolute;
		top: 0;
		width: 100%;
	}

	&__innerContent {
		display: flex;
		flex-direction: column;
		max-width: $ctaWidth;
		min-height: $ctaHeight;

		@include media($smallLayout) {
			flex-direction: row;
		}
	}

	&__label {
		padding: $base;
	}

	&__textContainer {
		flex-basis: 100%;
		display: flex;
		padding: 0;
		flex-direction: column;

		@include media($smallLayout) {
			padding: $base2 $base2 0 $base2;
		}
	}

	&__button {
		width: 100%;
		align-items: flex-end;
		align-self: flex-end;
		margin-top: $base;
		display: block;
	}
}
