.dlx-headerMargin {
	@include dlx-marginContent();
	@include dlx-fadeUp();
	margin-bottom: $base2;
	z-index: 2;
	clear: both;

	@include media($smallLayout) {
		margin-bottom: $base3;
	}

	@include media($mediumLayout) {
		float: left;
	}

	&__additional {
		margin-bottom: $base4;
		@include font(subhead);
		font-weight: normal;

		.title {
			display: block;
			text-transform: uppercase;
			color: $colorLabel;
			margin-top: $base;
			font-weight: bold;
			margin-bottom: -($base * 1.5);
		}
	}

	&__additionalHeader {
		font-weight: bold;
		margin-bottom: $base2;
		@include font(subhead);
	}

	&__image {
		width: 100%;
		height: auto;
		margin-bottom: $base;
	}

	&__img {
		width: 100%;
		height: auto;
	}

	&__subtitle {
		@include font(subheader);
	}

	&__title {
		@include font(header);
		margin-bottom: $base;

		@include media($smallLayout) {
			margin-bottom: $base2;
		}

		@include media($xLargeLayout) {
			margin-bottom: $base3;
		}
	}
}
