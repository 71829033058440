.dlx-blockKeywords {
	@include font(subhead);
	@include dlx-mainContent();
	// @include dlx-fadeUp(0s, '.dlx-pictureHeader');
	// color: $colorSecondInverted;
	margin-bottom: $base3;
	margin-top: $base3;
	position: relative;
	text-transform: uppercase;
	color: $colorKeywords;

	@include media($xLargeLayout) {
		margin-top: $base6;
		margin-bottom: $base6;
	}


	&__label {
		// @include colorSet($colorsSetActive, $colorsSetLength, color, true, false, '.dlx-blockKeywords');
		color: $colorKeywordsLabel;
	}
}
