.dlx-footerMenu {
	margin-top: $base;
	margin-bottom: $base2;
	@include font(footermenu);

	&--additional {
		margin-top: 0;
		margin-bottom: 0;
	}


	&__icon {
		@include ease(opacity, $durationBase, $easeBase);
		display: inline-block;
		margin-left: $base025;
		margin-top: 2px;
		opacity: 1;
		vertical-align: top;

		@include media($mediumLayout) {
			opacity: 0;
		}

		.dlx-footerMenu__link:hover & {
			opacity: 1;
		}

		svg {
			fill: $colorSecond;
			@include ease(transform, $durationBase, $easeBase);
		}

		.dlx-footerMenu__link.dlx-js-toggled & {
			svg {
				transform: scaleY(-1);
			}
		}
	}


	&__item {
		@include ease(height, $durationLong, $easeBase);
		overflow: hidden;
		position: relative;
		margin-bottom: $base;

		.dlx-footerMenu--additional & {
			margin-bottom: 0;
		}
	}

	&__items {
		display: flex;
		flex-direction: column;
		@include ease(opacity, $durationShort, $easeBase);
	}

	&__link {
		//
	}

	&__menuItems {
		@include ease(opacity visibility, $durationShort, $easeBase);
		padding-bottom: $base;

		&:focus {
			outline: 0;
		}

		&.dlx-js-collapsed {
			opacity: 0;
			position: absolute;
			visibility: hidden;
		}
	}

	&__menuItem {
		@include font(submenufooter);
		color: $colorSecond;
		height: auto;
		padding-top: $base;

		&:first-of-type {
			@include media($smallLayout) {
				padding-top: $base1;
			}
		}
	}

	&__menuLink {
		color: $colorSecond;
		position: relative;
		margin: 0;
		padding: 0;
		display: inline-block;
		vertical-align: top;

		&--active {
			font-weight: bold;
		}
	}

	&__section {
		//@include ease(height, $durationLong, $easeBase);
		flex-grow: 0;
		flex-shrink: 0;
		margin-bottom: 0;

		padding-bottom: $base;

		@include media($xLargeLayout) {
			padding-bottom: $base2;
		}

		width: staticColumnSize(1, 2, $gutterBase);

		@include media($smallLayout) {
			width: staticColumnSize(3, 9, $gutterBase);
		}

		@include media($mediumLayout) {
			width: staticColumnSize(2, 6, $gutterMedium);
		}

		@include media($xLargeLayout) {
			width: staticColumnSize(2, 6, $gutterXLarge);
		}

		&__section:last-of-type {
			margin-top: -$base2;
		}

		&--wide {
			width: 100%;
			display: block;
			padding-bottom: $base3;
			@include media($xLargeLayout) {
				padding-bottom: $base5;
			}
		}

		&--top {
//
		}
	}

	&__sections {
		vertical-align: top;
		padding-bottom: $base2;

		@include staticColumns(0);
	}

	&__title {
		margin-bottom: $base2;
		text-transform: uppercase;
		font-weight: normal;
	}
}
