.dlx-pagination {
	@include dlx-mainContent();
	margin-bottom: $base3;
	margin-top: $base3;
	position: relative;

	@include media($xLargeLayout) {
		margin-top: $base6;
		margin-bottom: $base6;
	}


	&__item {
		margin-right: $base;
		margin-bottom: $base;

		&:not(&--arrow) {
			display: none;

			@include media($smallLayout) {
				display: block;
			}
		}

		&--arrowPrev {
			left: -100%;
			//margin-left: -100%;
		}
	}


	&__itemContent {
		padding: $base $base2;
	}


	&__items {
		display: flex;
		flex-direction: row;
		margin-right: -$base2;
		flex-wrap: wrap;
	}
}
