@mixin colorSet($set, $length, $property: background-color, $isElementParent:false, $isElementModifier:false, $element:false) {
	@for $i from 1 through $length {
		@if ($isElementParent != false) {
			#{$element}--color#{$i} & {
				#{$property}: nth($set, $i);
			}
		}
		@else if ($isElementModifier != false) {
			@at-root #{$element}--color#{$i}#{&} {
				#{$property}: nth($set, $i);
			}
		}
		@else {
			&--color#{$i} {
				#{$property}: nth($set, $i);
			}
		}
	}
}
