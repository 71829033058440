.dlx-dotsNav {
	&__buttons {
		display: block;
		position: relative;
	}

	&__button {
		display: block;
		height: $dotNavSizeActiv;
		margin-bottom: $base;
		position: relative;
		width: $dotNavSizeActiv;
		cursor: pointer;
		background-color: transparent;
	}

	&__dot {
		@include ease(width height background-color, $durationBase, $easeBase);
		background-color: $colorDotNav;
		border-radius: 50%;
		display: block;
		height: $dotNavSize;
		left: 50%;
		position: absolute;
		top: 50%;
		transform: translate(-50%, -50%);
		width: $dotNavSize;

		.dlx-dotsNav__button.dlx-js-active & {
			@include colorSet($colorsSetActive, $colorsSetLength, background-color, false, true, '.dlx-dotsNav__button');
			height: $dotNavSizeActiv;
			width: $dotNavSizeActiv;
		}
	}
}
