.dlx-nextPrevNavigation {
	@include dlx-mainContent();

	&__button {
		@include font(subhead);
		cursor: pointer;
		display: inline-block;
		margin-right: $base2;
		text-transform: uppercase;
		vertical-align: top;

		&--next {
			float: right;
		}
	}

	&__content {
		padding-top: $base4;
		padding-bottom: $base4;
		display: block;
		position: relative;

		&::after {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			height: 1px;
			width: 100vw;
			background-color: $colorSeparateLine;
		}
	}
}
