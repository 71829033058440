.dlx-blockStageHeader {
	color: $colorBaseInverted;
	height: 100vh;

	padding-top: $base10;
	overflow: hidden;

	@include media($smallLayout) {
		padding-top: $base * 13;
	}

	@include media($xLargeLayout) {
		padding-top: $base * 17;
	}

	&__content {
		height: 100%;
		display: flex;
		flex-direction: column;

		@include media($smallLayout) {
			margin-left: staticColumnPush(2, 12, $gutterBase);
		}

		@include media($xLargeLayout) {
			margin-left: staticColumnPush(2, 12, $gutterXLarge);
		}
	}

	&__head {
		display: flex;
		flex-grow: 1;
		@include font(largeheadlight);
		@include ease(transform opacity, $durationVeryLong * 2);
		transform-origin: top left;

		.dlx-body.dlx-js-scrolledDown & {
			transform: scale(0.7);
			opacity: 0.5;
		}
	}

	&__subhead {
		padding-bottom: $base4;
		display: flex;
		@include font(headerlight);
		width: staticColumnPush(7, 10, $gutterXLarge);

		@include media($smallLayout) {
			padding-bottom: $base8;
		}

		@include media($xLargeLayout) {
			padding-bottom: $base10;
		}
	}

	&__image {
		display: block;
	}

	&__imageContainer {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: -1;
	}

	&__videoContainer {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: -1;
	}

	&__img {
		@include objectFit(cover);
		display: block;
		height: 100%;
		width: 100%;
		position: absolute;
	}
}
