
// the regular version is directly requested in the <head> in order to prefetch the file
@font-face {
	font-family: 'Roboto';
	src:
		url('../fonts/roboto-v29-latin-regular.woff2') format('woff2'),
		url('../fonts/roboto-v29-latin-regular.woff') format('woff');
	font-display: swap;
	font-weight: normal;
}
@font-face {
	font-family: 'Roboto';
	src:
		url('../fonts/roboto-v29-latin-italic.woff2') format('woff2'),
		url('../fonts/roboto-v29-latin-italic.woff') format('woff');
	font-display: swap;
	font-style: italic;
	font-weight: normal;
}
@font-face {
	font-family: 'Roboto';
	src:
		url('../fonts/roboto-v29-latin-regular.woff2') format('woff2'),
		url('../fonts/roboto-v29-latin-regular.woff') format('woff');
	font-display: swap;
	font-weight: bold;
}
@font-face {
	font-family: 'Roboto';
	src:
		url('../fonts/roboto-v29-latin-200.woff2') format('woff2'),
		url('../fonts/roboto-v29-latin-200.woff') format('woff');
	font-display: swap;
	font-weight: 200;
}
@font-face {
	font-family: 'Roboto';
	src:
		url('../fonts/roboto-v29-latin-300.woff2') format('woff2'),
		url('../fonts/roboto-v29-latin-300.woff') format('woff');
	font-display: swap;
	font-weight: 300;
}
@font-face {
	font-family: 'Roboto';
	src:
		url('../fonts/roboto-v29-latin-500.woff2') format('woff2'),
		url('../fonts/roboto-v29-latin-500.woff') format('woff');
	font-display: swap;
	font-weight: 500;
}
@font-face {
	font-family: 'Roboto';
	src:
		url('../fonts/roboto-v29-latin-700.woff2') format('woff2'),
		url('../fonts/roboto-v29-latin-700.woff') format('woff');
	font-display: swap;
	font-weight: 700;
}
