.dlx-blockInquiry {
	@include dlx-container();
	background-color: $colorInquiry;
	padding-bottom: $base;
	padding-top: $base2;

	@include media($smallLayout) {
		padding-top: $base3;
		padding-bottom: $base2;
	}

	@include media($xLargeLayout) {
		padding-top: $base5;
		padding-bottom: $base5;
	}

	&__content {
		@include dlx-fadeUp();
		@include dlx-mainContent();
	}
}
