$nameColorBeige: #ccc5b5;
$nameColorBlack: #323232;
$nameColorBlueGrey: #7695a3;
$nameColorLilia: #64556B;
$nameColorBlush: #eab29d;
$nameColorBrownishGrey: #6a665b;
$nameColorDarkPeach: #da7a61;
$nameColorGolden: #ebba00;
$nameColorKhakiGreen: #778d34;
$nameColorOffWhite: #f8f8f7;
$nameColorPale: #ece9e5;
$nameColorPaleSkyBlue: #c9dfe2;
$nameColorSicklyYellow: #d1d92b;
$nameColorTanGreen: #b1c077;
$nameColorWheat: #f3d580;
$nameColorWheatTwo: #e3e791;

$nameColorDarkGreen: #202508;
$nameColorDarkRed: #4D1A0A;
$nameColorDarkLime: #3C3C16;
$nameColorDarkYellow: #6E4E00;
$nameColorDarkBlue: #253335;
$nameColorDarkBeige: #4E4531;

$nameColorWhite: #ffffff;
$nameColorRealBlack: #000000;

$nameColorGrey: #cacac9;
$nameColorDarkGrey: #afafae;

$colorsSetLength: 6;
/// blue, yellow, green, orange, red, white
$colorsSet: $nameColorPaleSkyBlue, $nameColorWheatTwo, $nameColorTanGreen, $nameColorWheat, $nameColorBlush, $nameColorGrey;
$colorSetTeam: $nameColorBlueGrey, $nameColorGolden, $nameColorKhakiGreen, $nameColorDarkPeach, $nameColorLilia;
$colorSetTeamOpacity: rgba($nameColorBlueGrey, 0.9), rgba($nameColorGolden, 0.9), rgba($nameColorKhakiGreen, 0.9), rgba($nameColorDarkPeach, 0.9), rgba($nameColorLilia, 0.9);
$colorsSetActive: $nameColorBlueGrey, $nameColorSicklyYellow, $nameColorKhakiGreen, $nameColorGolden, $nameColorDarkPeach, $nameColorDarkGrey;
$colorsSetBright: $nameColorBlueGrey, $nameColorSicklyYellow, $nameColorKhakiGreen, $nameColorGolden, $nameColorDarkPeach, $nameColorDarkGrey;

$colorsSetDark: $nameColorDarkBlue, $nameColorDarkLime, $nameColorDarkGreen,  $nameColorDarkYellow, $nameColorDarkRed, $nameColorDarkBeige;
$nameColorDarkGreen: #202508;
$nameColorDarkRed: #4D1A0A;
$nameColorDarkLime: #3C3C16;
$nameColorDarkYellow: #6E4E00;

$nameColorDarkBeige: #4E4531;


$colorBase: $nameColorBlack;
$colorBgBase: $nameColorOffWhite;
$colorSecond: $nameColorBrownishGrey;

$colorBaseInverted: $nameColorWhite;
$colorSecondInverted: $nameColorPale;

$colorSubhead: $nameColorBrownishGrey;
$colorFooter: $nameColorBeige;

$colorQuoteDash: $nameColorBeige;
$colorQuote: $nameColorBrownishGrey;

$colorOverlayTransparent: transparentize($nameColorRealBlack, 0.8);
$colorOverlayTransparentDark: transparentize($nameColorRealBlack, 0.6);
$colorOverlayTransparentLight: transparentize($nameColorRealBlack, 0.8);

$colorSeparateLine: $nameColorBeige;
$colorLabel: $nameColorBeige;
$colorSeparateLineDark: $nameColorBrownishGrey;

$colorIcon: $nameColorBlueGrey;

$colorBgHighlight: $nameColorWheatTwo;

$colorFootnote: $nameColorPaleSkyBlue;
$colorFootnoteActive: $nameColorBlueGrey;

$colorSquareButton: $nameColorPale;
$colorSquareButtonHover: $colorBaseInverted;
$colorSquareButtonLightBgHover: $nameColorBeige;

$headerBackground: $colorBgBase;

$colorError: red;

$colorInput: $colorBgBase;

$colorLogoOverlayTransparent: transparentize($nameColorRealBlack, 0.9);
$colorGalleryNavigation: $nameColorBeige;
$colorGalleryNavigationCurrent: #7A7977;
$colorModalGalleryNav: $nameColorBrownishGrey;
$colorGalleryNavBg: transparentize($nameColorWhite, 0.2);
$colorModalGalleryNavBg: $nameColorWhite;
$colorModalGalleryBg: transparentize($nameColorOffWhite, 0);

$colorLoader: $nameColorBeige;

$colorConfiguratorLink: $nameColorPaleSkyBlue;
$colorInquiry: $nameColorPaleSkyBlue;

$colorRadioOff: $nameColorOffWhite;
$colorRadioOn: $nameColorBlueGrey;

$colorRadioOffAlt: $nameColorBeige;
$colorRadioOnAlt: $nameColorBrownishGrey;

$colorRadioOffAlt2: $nameColorPaleSkyBlue;
$colorRadioOnAlt2: $nameColorBlueGrey;

$colorDotNav: $nameColorBeige;
$colorDotNavActive: $nameColorWheat;

$colorScrollIcon: $nameColorBeige;

$colorTextHighlight: $nameColorTanGreen;

$colorCta: $nameColorKhakiGreen;

$colorSpecialHighlight: $nameColorGolden;


$colorKeywordsLabel: $nameColorBlack;
$colorKeywords: $nameColorBeige;

$colorBgInput: $nameColorBeige;
$colorInputBorder: $nameColorWhite;

$fullGalleryOpacity: 0.2;
$fullGalleryBg: transparentize($colorBase, $fullGalleryOpacity);
$fullGalleryBgInverted: transparentize($nameColorWhite, $fullGalleryOpacity);

$additionalMenuBg: transparentize($nameColorWhite, 0.1);
