.dlx-cursorEffect {
	display: none;
	pointer-events: none;
	position: fixed;
	z-index: 14;
	top: 0;
	left: 0;

	@include media($mediumLayout) {
		display: block;
	}

	&__dot {
		@include ease(width height opacity, $durationLong, $easeBase);
		background-color: $colorBaseInverted;
		display: block;
		position: absolute;
		height: 0;
		left: 0;
		top: 0;
		width: 0;
		border-radius: 50%;
		transform: translate(-50%, -50%);
		z-index: 6;
		opacity: 0;

		.dlx-cursorEffect.dlx-js-active & {
			@include ease(width height opacity, $durationVeryLong, $easeBase);
			width: $base10;
			height: $base10;
			opacity: 0.85;
		}
	}
}
