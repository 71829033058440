
@mixin dlx-mainContent($shifted: false, $narrow: false, $largeShift: false) {
	width: 100%;
	margin-left: 0;

	@include media($smallLayout) {
		margin-left: if($shifted, 0, staticColumnSize(2, 12, $gutterBase, $gutterBase));
		width: if($narrow, staticColumnSize(6, 12, $gutterBase), staticColumnSize(9, 12, $gutterBase));
	}

	@include media($mediumLayout) {
		margin-left: if($largeShift, staticColumnSize(2, 12, $gutterMedium, $gutterMedium), staticColumnSize(5, 12, $gutterMedium, $gutterMedium));
		width: if($narrow, staticColumnSize(4, 12, $gutterMedium), staticColumnSize(6, 12, $gutterMedium));
	}

	@include media($xLargeLayout) {
		margin-left: if($largeShift, staticColumnSize(2, 12, $gutterXLarge, $gutterXLarge), staticColumnSize(5, 12, $gutterXLarge, $gutterXLarge));
		width: if($narrow, staticColumnSize(3, 12, $gutterXLarge), staticColumnSize(6, 12, $gutterXLarge));
	}
}
