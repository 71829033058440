.dlx-blockTitleText {
	@include colorSet($colorsSet, $colorsSetLength, background-color);
	@include dlx-container();
	clear: both;
	padding-bottom: $base2;
	padding-top: $base2;
	position: relative;

	@include media($mediumLayout) {
		padding-bottom: $base4;
		padding-top: $base4;
	}

	@include media($xLargeLayout) {
		//padding-bottom: $base5;
		//padding-top: $base5;
	}

	&__text {
		@include dlx-mainContent();
	}

	&__content {
		//@include dlx-mainContent();
		@include ease(opacity, $durationAnim, $easeBase);
		padding-bottom: $base3;
		padding-top: $base3;

		&.dlx-js-collapsed {
			opacity: 0;
			position: absolute;
		}
	}

	&__header {
		@include font(subheader);
		color: $colorSecond;
		cursor: pointer;
		font-weight: bold;
		padding-bottom: $base3;
		padding-top: $base3;
		position: relative;
		text-transform: uppercase;
	}

	&__icon {
		display: inline-block;
		opacity: 1;
		position: absolute;
		right: 0;
		top: 50%;
		margin-top: $base025;
		transform: translateY(-50%);
		vertical-align: top;

		svg {
			fill: $colorSecond;
			@include ease(transform, $durationBase, $easeBase);
		}

		.dlx-blockTitleText__togglerLink.dlx-js-toggled & {
			svg {
				transform: scaleY(-1);
			}
		}
	}

	&__inner {
		@include dlx-mainContent();
		overflow: hidden;
		position: relative;
		@include ease(height, $durationAnim, $easeBase);

		@include media($mediumLayout) {
			padding-bottom: $base8;
			//padding-top: $base4;
		}

		@include media($xLargeLayout) {
			//padding-bottom: $base5;
			//padding-top: $base5;
		}
	}

	&__togglerLink {
		//@include dlx-mainContent();
		position: relative;
		width: 100%;
		color: $colorBase;
		text-decoration: none;
		margin-top: -1px;
		text-align: left;

		&::before {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			height: 1px;
			width: 100vw;
			background-color: $colorSeparateLine;
		}

		&:last-of-type::after {
			content: '';
			position: absolute;
			bottom: 0;
			left: 0;
			height: 1px;
			width: 100vw;
			background-color: $colorSeparateLine;
		}
	}
}
