.dlx-video {
	@include dlx-wideContent();
	margin-top: $base2;
	margin-bottom: $base2;

	@include media($mediumLayout) {
		margin-bottom: $base4;
	}

	&--alternate {
		@include dlx-mainContent();
	}


	&__caption {
		@include font(subheader);
		@include dlx-mainContent(false, true);
		margin-top: $base;
	}

	&__content {
		@include aspectRatio($videoRatio);
		position: relative;


		&::after {
			background-color: $colorBase;
			content: '';
			height: 100%;
			left: 0;
			opacity: $videoOverlayOpacity;
			position: absolute;
			top: 0;
			width: 100%;
			z-index: 2;
		}
	}


	&__icon {
		color: $colorBgBase;
		height: $base4;
		left: 50%;
		position: absolute;
		top: 50%;
		width: $base4;
		margin-left: -$base2;
		margin-top: -$base2;


		[data-type="svg"] {
			width: 100%;
			height: 100%;
		}
	}


	&__img {
		@include objectFit(cover);
		height: 100%;
		left: 0;
		position: absolute;
		top: 0;
		width: 100%;
		z-index: 1;
	}


	&__link {
		display: block;
		height: 100%;
		left: 0;
		position: absolute;
		top: 0;
		width: 100%;
		z-index: 3;
	}


	&__player {
		height: 100%;
		left: 0;
		position: absolute;
		top: 0;
		width: 100%;
		z-index: 4;
	}
}
