.dlx-blockCallToAction {
	margin-top: -1px;
	padding-top: $base4;
	padding-bottom: $base4;
	position: relative;

	&--simple {
		background-color: $colorBaseInverted;
		//@include dlx-fadeBg($colorBaseInverted);
		@include dlx-container();

		&::after {
			display: none;
		}
	}

	@include media($xLargeLayout) {
		//padding-top: $base5;
	}

	&__button {
		//margin-bottom: $base2;
	}

	&__content {
		@include dlx-fadeUp();
		@include dlx-mainContent();
		width: 100%;
		padding-top: $base;
		position: relative;

		@include media($mediumLayout) {
			padding-top: 0;
		}
	}

	&__columns {
		@include staticColumns(0);
	}

	&__column {
		width: 100%;
		width: staticColumnSize(2, 2, $gutterBase);

		&:first-of-type {
			@include media($smallLayout) {
				width: staticColumnSize(5, 9, $gutterBase);
			}

			@include media($mediumLayout) {
				width: staticColumnSize(6, 6, $gutterMedium);
			}

			@include media($largeLayout) {
				width: staticColumnSize(4, 6, $gutterMedium);
			}

			@include media($xLargeLayout) {
				width: staticColumnSize(3, 6, $gutterXLarge);
			}
		}

		&:last-of-type {
			@include media($smallLayout) {
				//margin-left: $gutterBase;
				width: staticColumnSize(4, 9, $gutterBase);
			}

			@include media($mediumLayout) {
				margin-left: 0;
				width: staticColumnSize(6, 6, $gutterMedium);
			}

			@include media($largeLayout) {
				//margin-left: $gutterMedium;
				width: staticColumnSize(2, 6, $gutterMedium);
			}

			@include media($xLargeLayout) {
				//margin-left: $gutterXLarge;
				width: staticColumnSize(3, 6, $gutterXLarge);
			}
		}
	}


	&__link {
		//display: inline-block;
		@include font(header);
		margin-bottom: $base2;
		display: block;
	}

	&__title {
		@include font(subhead);
		font-weight: normal;
		margin-bottom: $base;
		display: block;
	}
}
