.dlx-newsHeader {
	margin-top: $base2;
	margin-bottom: $base2;
	@include dlx-mainContent();

	@include media($mediumLayout) {
		margin-bottom: $base4;
	}

	&__image {
		width: 100%;
		margin-bottom: $base3;
	}

	&__img {
		width: 100%;
	}

	&__title {
		@include font(header);
		margin-top: $base2;
		margin-bottom: $base4;
	}

	&__video {
		width: 100%;
		margin-bottom: $base3;
	}

	&__vid {
		width: 100%;
	}
}
