.dlx-contactPerson {
	position: relative;
	padding-top: $base2;
	padding-bottom: $base;


	&::before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		height: 1px;
		width: 100vw;
		background-color: $colorSeparateLine;
	}

	&:last-of-type::after {
		content: '';
		position: absolute;
		bottom: 0;
		left: 0;
		height: 1px;
		width: 100vw;
		background-color: $colorSeparateLine;
	}


	@include media($smallLayout) {
		padding-top: $base3;
		padding-bottom: $base2;
	}

	@include media($xLargeLayout) {
		padding-top: $base5;
		padding-bottom: $base4;
	}

	&__name {
		@include font(header);
		color: $colorSecond;
		margin-bottom: $base + $base05;

		@include media($xLargeLayout) {
			margin-bottom: $base3;
		}
	}

	&__row {
		width: 100%;
		position: relative;
		@include staticColumns();
		margin-bottom: $base;
	}

	&__rowName {
		vertical-align: middle;
		@include font(subhead);
		color: $colorLabel;
		margin-top: $base05;

		width: staticColumnSize(2, 12, $gutterBase, $gutterBase);

		@include media($smallLayout) {
			margin-top: $base05 + $base025;
			width: staticColumnSize(2, 9, $gutterBase, $gutterBase);
		}
		@include media($mediumLayout) {
			margin-top: $base;
			width: staticColumnSize(1, 6, $gutterMedium, $gutterMedium);
		}
		@include media($xLargeLayout) {
			width: staticColumnSize(1, 6, $gutterXLarge, $gutterXLarge);
		}
	}

	&__rowValue {
		@include font(base);
		vertical-align: middle;

		width: staticColumnSize(10, 12, $gutterBase);

		@include media($smallLayout) {
			width: staticColumnSize(7, 9, $gutterBase);
		}
		@include media($mediumLayout) {
			width: staticColumnSize(5, 6, $gutterMedium);
		}
		@include media($xLargeLayout) {
			width: staticColumnSize(5, 6, $gutterXLarge);
		}
	}
}
