/*
	This module behaves differently on mobile, desktop and edge.
	There's a few tricky solutions.
	Please be very careful when making any changes.
	Please test carefully whenever changed.
	PLease also check case-study.js
*/

.dlx-caseStudyItem {
	@mixin imgSizing() {
		height: $caseHeightSmall;

		.dlx-caseStudyItem:nth-of-type(3n) & {
			@include media($smallLayout) {
				height: $caseHeightSmall;
				width: staticColumnSize(9, 12, $gutterBase);
				margin-left: staticColumnPush(1, 12, $gutterBase);
			}

			@include media($mediumLayout) {
				height: $caseHeightMedium;
				width: staticColumnSize(7, 12, $gutterMedium);
				margin-left: staticColumnPush(4, 12, $gutterBase);
			}

			@include media($xLargeLayout) {
				height: $caseHeightXLarge;
				width: staticColumnSize(7, 12, $gutterXLarge);
				margin-left: staticColumnPush(4, 12, $gutterXLarge);
			}
		}

		.dlx-caseStudyItem:nth-of-type(3n+1) & {
			@include media($smallLayout) {
				height: $caseHeightSmall;
				width: staticColumnSize(8, 12, $gutterBase);
				margin-left: staticColumnPush(1, 12, $gutterBase);
			}

			@include media($mediumLayout) {
				height: $caseHeightMedium;
				width: staticColumnSize(6, 12, $gutterMedium);
				margin-left: staticColumnPush(4, 12, $gutterBase);
			}

			@include media($xLargeLayout) {
				height: $caseHeightXLarge;
				width: staticColumnSize(6, 12, $gutterXLarge);
				margin-left: staticColumnPush(4, 12, $gutterXLarge);
			}
		}

		.dlx-caseStudyItem:nth-of-type(3n+2) & {
			@include media($smallLayout) {
				height: $caseHeightSmall;
				width: staticColumnSize(10, 12, $gutterBase);
				margin-left: 0;
			}

			@include media($mediumLayout) {
				height: $caseHeightMedium;
				width: staticColumnSize(7, 12, $gutterMedium);
				margin-left: staticColumnPush(3, 12, $gutterBase);
			}

			@include media($xLargeLayout) {
				height: $caseHeightXLarge;
				width: staticColumnSize(7, 12, $gutterXLarge);
				margin-left: staticColumnPush(3, 12, $gutterXLarge);
			}
		}
	}

	margin-top: $base4;
	position: relative;
	z-index: 1;

	&.dlx-js-front {
		z-index: 10;
	}

	&:first-of-type {
		margin-top: $base2;
		@include media($smallLayout) {
			margin-top: $base2;
		}

		@include media($mediumLayout) {
			margin-top: $base5;
		}

		@include media($xLargeLayout) {
			margin-top: $base5;
		}
	}


	@include media($smallLayout) {
		margin-top: $base6;
	}

	@include media($mediumLayout) {
		margin-top: $base * 8;
	}

	@include media($xLargeLayout) {
		margin-top: $base * 10;
	}


	&__imageEffect {
		@include imgSizing();
		@include ease(clip-path, $durationCaseStudies);
		position: relative;
		clip-path: inset(0 0 0 0);
		z-index: 1;

		height: $caseHeightBase;
		width: $caseWidthBase;

		.dlx-caseStudyItem.dlx-js-front & {
			z-index: 10;
			//opacity: 0.4;
		}

		.dlx-caseStudyItem.dlx-js-expanded & {
			clip-path: inset(calc(-#{$caseHeightMedium} * 2) calc(-#{$caseHeightMedium} * 2) calc(-#{$caseHeightMedium} * 2) calc(-#{$caseHeightMedium} * 2));
			//clip-path: inset(-100vh -100vw -100vh -100vw);
			@include media($xLargeLayout) {
				clip-path: inset(calc(-#{$caseHeightXLarge} * 2) calc(-#{$caseHeightXLarge} * 2) calc(-#{$caseHeightXLarge} * 2) calc(-#{$caseHeightXLarge} * 2));
			}
		}
	}


	&__image {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
	}


	&__imageClip {
		display: none;
		width: 100%;
		height: 100%;
		position: relative;
		z-index: 18;
		border: 1px solid $colorBgBase;

		@supports (clip-path: inset(0 0 0 0)) {
			display: block;
		}

		.dlx-caseStudyItem.dlx-js-front & {
			z-index: 18;
		}

		&::before {
			@include ease(transform, $durationCaseStudiesScale);
			content: '';
			position: absolute;
			pointer-events: none;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			z-index: 0;

			outline-width: 100vw;
			outline-color: $colorBgBase;
			outline-style: solid;
			outline-offset: 0;
			backface-visibility: hidden;

			.dlx-caseStudyItem.dlx-js-expanded & {
				transform: scale(5) translateZ(0);
			}
		}
	}


	&__imageContainer {

		@supports (clip-path: inset(0 0 0 0)) {
			position: fixed;
			left: 0;
			right: 0;
			top: 0;
			bottom: 0;
			z-index: 7;
			pointer-events: none;

			.dlx-body .dlx-caseStudyItem & {
				height: auto;
				width: auto;
				margin-left: 0;
			}
		}

		.dlx-caseStudyItem.dlx-js-front & {
			z-index: 17;
		}
	}


	&__img {
		@include objectFit(cover);
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
	}

	&__infoContainer {
		@include dlx-fadeUp();
		z-index: 17;
		position: relative;
		margin-left: - $base1 - $base05;
	}


	&__info {
		@include dlx-mainContent();
		@include ease(background-color, $durationLong, $easeOutQuad);
		margin-bottom: $base;
		padding: $base + $base05;
		position: relative;

		.dlx-caseStudyItem.dlx-js-expanded & {
			@include media($smallLayout) {
				background-color: $colorOverlayTransparent;
			}
		}
	}


	&__title {
		@include ease(color, $durationLong, $easeOutQuad);
		@include font(header);
		margin-bottom: $base1;

		.dlx-caseStudyItem.dlx-js-expanded & {
			@include media($smallLayout) {
				color: $colorBaseInverted;
			}
		}

		@include media($xLargeLayout) {
			margin-bottom: $base1 + $base05;
		}
	}


	&__subtitle {
		@include ease(color, $durationLong, $easeOutQuad);
		@include font(subhead);
		color: $colorSubhead;

		.dlx-caseStudyItem.dlx-js-expanded & {
			@include media($smallLayout) {
				color: $colorBaseInverted;
			}
		}
	}
}
