.dlx-blockEvent {
	padding-bottom: $base2;
	padding-top: $base2;

	@include media($mediumLayout) {
		padding-bottom: $base4;
		padding-top: $base4;
	}

	&__content {
		@include dlx-fadeUp();
		@include dlx-mainContent();
	}

	&__contentHead {
		@include font(subheader);
		margin-bottom: $base05;
	}

	&__contentInfo {
		@include font(header);
		margin-bottom: $base3;
		color: $colorSecond;
	}

	&__contentText {
		@include font(base);
	}

	&__header {
		//
	}

	&__section {
		position: relative;
		margin-top: $base6;

		&:first-of-type {
			margin-top: 0;
		}
	}
}
