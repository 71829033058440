
@mixin dlx-wideContentRight() {
	width: 100%;
	margin-left: 0;

	@include media($smallLayout) {
		width: staticColumnSize(7, 10, $gutterBase);
	}

	@include media($mediumLayout) {
		width: staticColumnSize(3, 7, $gutterMedium);
	}

	@include media($xLargeLayout) {
		width: staticColumnSize(3, 7, $gutterXLarge);
	}
}
