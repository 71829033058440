.dlx-radioButton {
	background-color: $colorRadioOff;
	cursor: pointer;
	display: inline-block;
	height: $formElementsHeight;
	padding-left: $base2;
	padding-right: $base2;
	position: relative;
	margin-bottom: $base2;
	margin-right: $gutterBase - $base05;

	&--alternativeColor {
		background-color: $colorRadioOffAlt;
	}

	&--alternativeColor2 {
		background-color: $colorRadioOffAlt2;
	}

	@include media($mediumLayout) {
		margin-right: $gutterMedium - $base05;
	}

	@include media($xLargeLayout) {
		margin-right: $gutterXLarge - $base05;
	}


	&__input {
		display: none;
	}

	@include media($xLargeLayout) {
		height: $formElementsHeightXL;
	}

	&__label {
		display: inline-block;
		line-height: $formElementsHeight;

		&::before {
			@include ease(opacity, $durationBase, $easeBase);
			position: absolute;
			content: '';
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			opacity: 0;
			background-color: $colorRadioOn;

			.dlx-radioButton--alternativeColor & {
				background-color: $colorRadioOnAlt;
			}

			.dlx-radioButton--alternativeColor2 & {
				background-color: $colorRadioOnAlt2;
			}
		}

		.dlx-radioButton__input:checked ~ &::before {
			opacity: 1;
		}

		@include media($xLargeLayout) {
			line-height: $formElementsHeightXL;
		}
	}

	&__labelText {
		@include ease(opacity, $durationBase, $easeBase);
		@include font(subhead);
		color: $colorSecond;
		position: relative;
		text-transform: uppercase;
		width: auto;
		vertical-align: middle;
		margin-top: -1px; //just to align nicer

		.dlx-radioButton__input:checked ~ .dlx-radioButton__label & {
			color: $colorBaseInverted;
		}
	}
}
