.dlx-linkIcon {
	display: block;

	&__label {
		display: inline-block;
		vertical-align: top;
		// /margin-top: 4px;// $baseHalf;
		margin-right: $base;
	}

	&__icon {
		display: inline-block;
		vertical-align: top;
		height: $base4;
		margin-top: -$base;

		svg {
			height: $base4;
		}

		&--small {
			margin-top: -$base * 1.3;
			margin-left: -$base;
			width: $base4;
			height: $base4;

			svg {
				width: $base4;
				height: $base4;
			}
		}
	}
}
