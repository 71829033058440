.dlx-homeHeadline {
	@include dlx-container();
	display: block;
	left: 0;
	position: absolute;
	top: 0;
	z-index: 11;

	&__text {
		@include dlx-mainContent(true);
		@include font(largehead);
		font-weight: bold;
		color: $colorBaseInverted;

		padding-top: $base6;

		@include media($smallLayout) {
			padding-top: $base7;
		}

		@include media($mediumLayout) {
			padding-top: $base * 12;
		}

		@include media($xLargeLayout) {
			padding-top: $base * 18;
		}

		@include media('height#{$homepageMinHeight}') {
			padding-top: $base7;
			@include font(head);
		}
	}

	&__word {
		display: inline-block;
		overflow: hidden;
	}

	&__wordText {
		display: block;

		@for $i from 1 through 20 {
			.dlx-homeHeadline__word--delay#{$i} & {
				@include ease(transform opacity, $durationHomeHeadline, $easeOutQuad, $duration05 * $i);
			}
		}

		.dlx-homeHeadline.dlx-js-up & {
			transform: translateY(-120%);
			opacity: 0;
			will-change: transform, opacity;
		}

		.dlx-homeHeadline.dlx-js-down & {
			transform: translateY(120%);
			opacity: 0;
			will-change: transform, opacity;
		}

		.dlx-homeHeadline.dlx-js-hide.dlx-js-up & {
			transform: translateY(120%);
			opacity: 0;
			will-change: transform, opacity;
		}

		.dlx-homeHeadline.dlx-js-hide.dlx-js-down & {
			transform: translateY(-120%);
			opacity: 0;
			will-change: transform, opacity;
		}

		.dlx-homeHeadline.dlx-js-animate & {
			transform: translateY(0);
			opacity: 1;
		}
	}
}
