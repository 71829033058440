.dlx-interactiveGraphicPopup {
	background-color: $colorBgBase;
	border: 1px solid $colorSeparateLine;
	box-shadow: 0 5px 110px 20px rgba(210, 210, 210, 0.5);
	display: none;
	padding: $base3 $base2 $base2 $base2;
	right: 0;
	z-index: 2;

	&.dlx-js-active {
		display: block;
	}

	@include media($smallLayout) {
		padding: $base2;
	}

	&__close {
		position: absolute;
		@include font(smallbutton);
		text-transform: uppercase;
		right: $base2;
		top: $base;
		cursor: pointer;

		@include media($smallLayout) {
			top: $base2;
		}
	}

	&__content {
		//
	}

	&__copy {
		@include font(intro);
	}

	&__head {
		margin-top: $base2;
		margin-bottom: $base3;
	}

	&__item {
		display: none;

		&.dlx-js-active {
			display: block;
		}
	}

	&__link {
		@include font(subhead);
		color: $colorSecond;
		display: inline-block;
		text-transform: uppercase;
		margin-top: $base3;
	}
}
