
@mixin dlx-wideContentLeft() {
	width: 100%;
	margin-left: 0;

	@include media($smallLayout) {
		padding-right: $containerPaddingSmall;
		width: staticColumnSize(9, 10, $gutterBase);
	}

	@include media($mediumLayout) {
		padding-right: $gutterMedium;
		width: staticColumnSize(4, 7, $gutterMedium, $gutterMedium);
	}

	@include media($xLargeLayout) {
		padding-right: $gutterXLarge;
		width: staticColumnSize(4, 7, $gutterXLarge, $gutterXLarge);
	}
}
