.dlx-newsletterForm {
	.button {
		@include ease(background-color, $durationBase, $easeBase);
		@include font(subhead);
		background-color: $colorSquareButton;
		border: none;
		color: $colorSecond;
		cursor: pointer;
		display: inline-block;
		height: $formElementsHeight;
		line-height: $formElementsHeight;
		margin-top: $base3;
		min-width: $base * 11;
		overflow: hidden;
		padding-left: $base2;
		padding-right: $base2;
		position: relative;
		text-align: center;
		text-transform: uppercase;
		vertical-align: middle;
		width: 100%;
		z-index: 1;

		@include media($xLargeLayout) {
			height: $formElementsHeightXL;
		}

		@include media($xLargeLayout) {
			line-height: $formElementsHeightXL;
		}

		&::after {
			@include ease(opacity width height, $durationBase, $easeBase);
			background-color: $colorBaseInverted;
			border-radius: 50%;
			content: '';
			filter: blur(10px);
			height: 10px;
			left: 50%;
			opacity: 0.4;
			position: absolute;
			top: 50%;
			transform: translate(-50%, -50%);
			width: 10px;
		}

		&:hover::after {
			opacity: 1;
			width: 100px;
			height: 100px;
		}

		&:hover {
			background-color: $colorBaseInverted;
		}


		&__label {
			vertical-align: middle;
			@include font(subhead);
			color: $colorSecond;
			display: inline-block;
			width: auto;
			position: relative;
			text-transform: uppercase;
			margin-top: -1px; //just to align nicer
			z-index: 1;
		}
	}

	h2 {
		@include font(header);
	}

	.indicates-required {
		@include font(subheader);
		margin-top: $base2;
		margin-bottom: $base2;
	}

	.mc-field-group {
		margin-bottom: $base;

		input {
			@include font(base);
			border: 1px solid $colorSeparateLine;
			background-color: $colorBaseInverted;
			border: none;
			color: $colorBase;
			height: $formElementsHeight;
			padding-left: $base2;
			padding-right: $base2;
			vertical-align: top;
			width: 100%;

			@include media($xLargeLayout) {
				height: $formElementsHeightXL;
			}

			&:focus {
				border: 1px solid $colorSeparateLine;
				background-color: $colorBaseInverted;
			}

			&::placeholder {
				color: $colorSecond;
				opacity: 0.5;
			}
		}
	}

	label {
		color: $colorSecond;
		@include font(subhead);
		margin-bottom: $base;
		text-transform: uppercase;
		display: block;
	}
}
