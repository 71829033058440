.dlx-animatedHeadline {

	&__word {
		display: inline-block;
		overflow: hidden;
	}

	&__wordText {
		display: block;

		transform: translateY(120%);
		opacity: 0;

		@for $i from 1 through 20 {
			.dlx-animatedHeadline__word--delay#{$i} & {
				@include ease(transform opacity, $durationHomeHeadline, $easeOutQuad, $duration05 * $i);
			}
		}

		.dlx-animatedHeadline.dlx-js-animate & {
			transform: translateY(0);
			opacity: 1;
		}
	}
}
