.dlx-headerSmall {
	@include dlx-mainContent();

	&__title {
		@include font(base);
		font-weight: bold;

		margin-top: $base2;
		margin-bottom: $base;

		@include media($smallLayout) {
			margin-top: $base2;
			margin-bottom: $base2;
		}

		@include media($xLargeLayout) {
			margin-top: $base3;
			margin-bottom: $base3;
		}
	}
}
