.dlx-news {
	@include dlx-mediumContent();
	margin-bottom: $base10;

	&__elements {
		margin-top: $base3;

		@include media($smallLayout) {
			@include dynamicColumns($gutterBase, 0);
		}

		@include media($mediumLayout) {
			@include dynamicColumns($gutterMedium, 0);
		}

		@include media($xLargeLayout) {
			@include dynamicColumns($gutterXLarge, 0);
		}
	}

	&__showmore {
		margin-top: $base4;
	}
}
