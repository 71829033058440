.dlx-textHeader {
	&--search {
		@include dlx-fadeUp();
	}

	&__container {
		@include dlx-container();
		color: $colorBase;
		margin-bottom: $base6;
		padding-top: $base6;

		@include media($smallLayout) {
			margin-bottom: $base8;
			padding-top: $base7;
		}

		@include media($mediumLayout) {
			margin-bottom: $base10;
			padding-top: $base * 12;
		}
	}


	&__content {
		@include dlx-mainContent(true);
	}


	&__searchQuery {
		font-style: italic;
	}


	&__textarea {
		@include font(header);
		@include dlx-fadeUp(0s, '.dlx-textHeader');
	}


	&__title {
		@include font(head);
		margin-bottom: $base4;

		@include media($mediumLayout) {
			margin-bottom: $base6;
		}

		@include media($xLargeLayout) {
			margin-bottom: $base8;
		}

		.dlx-textHeader--search & {
			@include font(header);
		}
	}
}
