//
@mixin dlx-mediumContent($noSmallLeftMargin: false) {
	width: 100%;

	@include media($smallLayout) {
		//margin-left: if($noSmallLeftMargin, -$containerPaddingSmall, 0);
		//width: if($noSmallLeftMargin, calc(100% + 2 * #{$containerPaddingSmall}), calc(100% + #{$containerPaddingSmall}));
	}

	@include media($mediumLayout) {
		margin-left: staticColumnPush(2, 12, $gutterMedium);
		width: staticColumnSize(9, 12, $gutterMedium);
	}

	@include media($xLargeLayout) {
		margin-left: staticColumnPush(2, 12, $gutterXLarge);
		width: staticColumnSize(9, 12, $gutterXLarge);
	}
}
