@use "sass:math";

.dlx-galleryItems {
	position: relative;
	touch-action: pan-y;

	&--modal {
		display: none;
		height: 100%;
		width: 100%;

		.dlx-modalGallery &.dlx-js-active {
			display: block;
		}
	}


	&--normal::before {
		content: '';
		display: block;
		padding-bottom: math.div(100%, $galleryRatio);
	}



	&__image {
		position: absolute;
		height: 100%;
		left: 0;
		top: 0;
		width: 100%;
	}


	&__imageContainer {
		height: 100%;
		position: relative;

		.dlx-galleryItems--normal & {
			margin-left: -$containerPaddingBase;
			margin-right: -$containerPaddingBase;

			@include media($smallLayout) {
				margin-left: 0;
				margin-right: -$containerPaddingSmall;
			}

			@include media($mediumLayout) {
				margin-left: 0;
				margin-right: -$containerPaddingMedium;
			}

			@include media($xLargeLayout) {
				margin-left: 0;
				margin-right: -$containerPaddingXLarge;
			}
		}
	}


	&__img {
		@include objectFit(cover);
		height: 100%;
		position: relative;
		width: 100%;

		.dlx-galleryItems--modal & {
			@include objectFit(contain);
		}
	}


	&__item {
		@include storeValuesForJs((allowZoom: false));
		@include ease(opacity visibility transform, $durationLong, $easeBase);
		@include fadeOut();
		height: 100%;
		left: 0;
		position: absolute;
		top: 0;
		width: 100%;
		z-index: 1;

		&.dlx-js-current {
			@include fadeIn();
			z-index: 3;

			.dlx-galleryItems--normal & {
				@include media($smallLayout) {
					@include storeValuesForJs((allowZoom: true));
					cursor: pointer;
				}
			}

			.dlx-gallery__itemsContainter:hover & {
				@include media($mediumLayout) {
					transform: translateX(#{staticColumnSize(-1, 7, $gutterMedium)});
				}

				@include media($xLargeLayout) {
					transform: translateX(#{staticColumnSize(-1, 7, $gutterXLarge)});
				}
			}
		}

		&.dlx-js-next {
			cursor: pointer;
		}

		.dlx-gallery__itemsContainter:hover .dlx-galleryItems--normal &.dlx-js-next {
			@include media($mediumLayout) {
				z-index: 2;
				@include fadeIn(0s);
			}
		}
	}


	&__nextIcon {
		bottom: $base2;
		color: $colorModalGalleryNav;
		display: none;
		height: $base6;
		position: absolute;
		right: $base2;
		width: $base6;

		.dlx-galleryItems__item.dlx-js-next & {
			@include media($mediumLayout) {
				display: block;
			}
		}
	}
}
