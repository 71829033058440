.dlx-pageHeaderSpecial {
	@include dlx-container();
	left: 0;
	position: absolute;
	top: 0;

	&__anchorMenu {
		display: inline-block;
	}

	&__container {
		top: 0;

		.dlx-body.dlx-js-invertedHeader .dlx-pageHeader:not(.dlx-js-scrolledDown) &,
		.dlx-body.dlx-js-fullyInvertedHeader .dlx-pageHeader:not(.dlx-js-scrolledDown) & {
			color: $colorBaseInverted;
		}
	}

	&__title {
		@include font(subhead);
		display: inline-block;
		font-weight: normal;
		vertical-align: center;
	}

	&__togglerContainer {
		vertical-align: top;
		display: inline-block;
		margin-left: staticColumnPush(3, 12, $gutterBase);
		margin-top: $base * 1.5;

		@include media($smallLayout) {
			float: none;
			margin-left: staticColumnPush(2, 12, $gutterBase);
			margin-top: $base * 1.2;
		}

		@include media($mediumLayout) {
			float: none;
			margin-left: staticColumnPush(2, 12, $gutterMedium);
			margin-top: $base * 2;
		}

		@include media($xLargeLayout) {
			float: none;
			margin-top: $base * 2;
			margin-left: staticColumnPush(2, 12, $gutterXLarge);
		}
	}

	&__togglerLink {
		white-space: nowrap;
		display: block;

		.dlx-mainMenu__menuSections--additional & {
			text-transform: uppercase;
		}

		@include media($mediumLayout) {
			pointer-events: none;
		}
	}


	&__icon {
		display: inline-block;
		margin-left: $base025;
		margin-top: 3px;
		opacity: 1;
		height: $base2;
		vertical-align: top;

		@include media($mediumLayout) {
			display: none;
		}

		svg {
			stroke: $colorSecond;
			@include ease(transform, $durationBase, $easeBase);
		}

		.dlx-body.dlx-js-fullyInvertedHeader &,
		.dlx-body.dlx-js-invertedHeader .dlx-pageHeader:not(.dlx-js-scrolledDown) & {
			svg {
				stroke: $colorBaseInverted;
			}
		}

		.dlx-pageHeaderSpecial__togglerLink.dlx-js-toggled & {
			svg {
				transform: scaleY(-1);
			}
		}

		.dlx-mainMenu__menuSections--additional & {
			margin-top: -$base025;

			@include media($smallLayout) {
				margin-top: -$base05;
			}

			@include media($mediumLayout) {
				margin-top: -$base05;
			}

			@include media($xLargeLayout) {
				margin-top: -$base05 - $base025;
			}
		}
	}
}
