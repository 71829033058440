.dlx-contactPersonWithImage {
	position: relative;

	&::before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		height: 1px;
		width: 100vw;
		background-color: $colorSeparateLine;
	}

	&:last-of-type::after {
		content: '';
		position: absolute;
		bottom: 0;
		left: 0;
		height: 1px;
		width: 100vw;
		background-color: $colorSeparateLine;
	}

	&__name {
		@include font(header);
		color: $colorSecond;
		margin-bottom: $base1;
		margin-right: $base8;
		margin-top: $base05;

		@include media($smallLayout) {
			margin-bottom: $base2;
		}

		@include media($xLargeLayout) {
			margin-bottom: $base3;
			margin-top: $base;
		}
	}


	&__row {
		width: 100%;
		position: relative;
		@include staticColumns();
		margin-bottom: $base;

		&:last-of-type {
			margin-bottom: $base2;
		}
	}


	&__rowName {
		vertical-align: middle;
		@include font(subhead);
		color: $colorLabel;
		margin-top: $base05;

		width: staticColumnSize(2, 12, $gutterBase, $gutterBase);

		@include media($smallLayout) {
			margin-top: $base05 + $base025;
			width: staticColumnSize(2, 9, $gutterBase, $gutterBase);
		}
		@include media($mediumLayout) {
			margin-top: $base;
			width: staticColumnSize(1, 4, $gutterMedium, $gutterMedium);
		}
		@include media($xLargeLayout) {
			width: staticColumnSize(1, 4, $gutterXLarge, $gutterXLarge);
		}
	}

	&__rowValue {
		@include font(base);
		vertical-align: middle;

		width: staticColumnSize(10, 12, $gutterBase);

		@include media($smallLayout) {
			width: staticColumnSize(7, 9, $gutterBase);
		}
		@include media($mediumLayout) {
			width: staticColumnSize(3, 4, $gutterMedium);
		}
		@include media($xLargeLayout) {
			width: staticColumnSize(3, 4, $gutterXLarge);
		}
	}


	&__columns {
		position: relative;
		min-height: $linkElementMinHeight;
		padding-top: $base2;
		padding-bottom: $base;

		@include media($smallLayout) {
			padding-top: $base3;
			padding-bottom: $base2;
			min-height: $linkElementMinHeightM;
		}

		@include media($mediumLayout) {
			@include staticColumns(0);
		}

		@include media($xLargeLayout) {
			padding-top: $base5;
			padding-bottom: $base5;
			min-height: $linkElementMinHeightXL;
		}
	}


	&__columnLeft {
		@include dlx-wideContentLeft();
	}


	&__columnRight {
		@include dlx-wideContentRight();
	}


	&__image {
		overflow: hidden;
		padding-bottom: 100%;
		position: relative;
		width: 100%;
	}


	&__img {
		@include objectFit(cover);
		display: block;
		height: 100%;
		width: 100%;
		position: absolute;
	}


	&__subtitle {
		@include font(subheader);
	}
}
