.dlx-blockTeaser {
	overflow: hidden;
	position: relative;
	//margin-bottom: -$base8;
	width: 100%;

	.dlx-block:last-of-type & {
		margin-bottom: -$base8;
	}

	&__container {
		@include dlx-container();
		color: $colorBaseInverted;
		margin-bottom: $base4;
		margin-top: $base2;

		@include media($smallLayout) {
			margin-bottom: $base5;
			margin-top: $base4;
		}

		@include media($mediumLayout) {
			margin-bottom: $base8;
			margin-top: $base6;
		}

		@include media($xLargeLayout) {
			margin-top: $base9;
			margin-bottom: $base10;
		}

		&::after {
			content: '';
			background-color: $colorOverlayTransparent;
			position: absolute;
			bottom: 0;
			left: 0;
			width: 100%;
			height: 100%;
			right: 0;
			top: 0;
			z-index: -1;
			@include ease(background-color, $durationLong, $easeBase);

			.dlx-blockTeaser:hover & {
				background-color: $colorOverlayTransparentDark;
			}
		}
	}

	&__content {
		@include dlx-mainContent(true);
		position: relative;
		z-index: 1;
	}

	&__icon {
		@include dlx-fadeUp(0.4s, '.dlx-blockTeaser');
		color: $colorBaseInverted;
		display: block;
		height: $baseIconSize;
		margin-bottom: $base8;
		margin-top: $base2;
		width: $baseIconSize;
		@include media($smallLayout) {
			margin-bottom: $base * 11;
			margin-top: $base3;
		}
		@include media($mediumLayout) {
			display: none;
		}
	}

	&__image {
		display: block;
		//testing horizontal parallax
		height: 100%; //150%;
		margin-left: -10%;
		margin-top: 0;//-10%;
		position: relative;
		width: 130%; //100%;
		z-index: -1;
	}

	&__imageContainer {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: -1;
	}

	&__img {
		@include objectFit(cover);
		display: block;
		height: 100%;
		width: 100%;
	}

	&__marginContent {
		@include dlx-marginContent();

		@include media($mediumLayout) {
			float: left;
		}
	}

	&__text {
		@include font(header);
		@include dlx-fadeUp(0.4s, '.dlx-blockTeaser');
	}

	&__title {
		@include dlx-fadeUp(0.3s, '.dlx-blockTeaser');
		@include font(head);
		margin-bottom: $base2;
		@include media($mediumLayout) {
			margin-bottom: $base * 20;
		}
		@include media($xLargeLayout) {
			margin-bottom: $base * 25;
		}
	}

	&__subtitle {
		@include font(subhead);
		@include dlx-fadeUp(0.2s, '.dlx-blockTeaser');
		color: $colorSecondInverted;
		display: inline-block;
		margin-bottom: $base;
		margin-right: $base2;
		position: relative;
		vertical-align: top;

		.dlx-blockTeaser__content & {
			&:first-of-type {
				display: inline-block;
				@include media($mediumLayout) {
					display: none;
				}

				&::before {
					display: none;
				}
			}

			&::before {
				content: '';
				background-color: $colorBaseInverted;
				width: 2px;
				height: $base2;
				position: absolute;
				left: -$base - $base05;
				top: -$base025;
				@include media($mediumLayout) {
					display: none;
				}
			}
		}

		.dlx-blockTeaser__marginContent & {
			display: none;
			@include media($mediumLayout) {
				display: block;
			}
		}
	}
}
