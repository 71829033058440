.dlx-table {
	width: 100%;
	margin-top: $base3;
	margin-bottom: $base3;

	&__cell {
		@include font(subhead);
		padding-bottom: $tableSpacing;
		position: relative;
		padding-top: $tableSpacing;

		@include media($mediumLayout) {
			padding-bottom: $tableSpacingLarge;
			padding-top: $tableSpacingLarge;
		}

		&::after {
			background-color: $colorSeparateLine;
			bottom: 0;
			content: '';
			display: block;
			height: 1px;
			left: 0;
			position: absolute;
			width: calc(100% - #{$gutterBase});

			@include media($mediumLayout) {
				width: calc(100% - #{$gutterMedium});
			}

			@include media($xLargeLayout) {
				width: calc(100% - #{$gutterXLarge});
			}
		}
	}

	&__headCell {
		@include font(subhead);
		padding-bottom: $tableSpacing;
		padding-top: $tableSpacing;
		position: relative;
		text-align: left;
		color: $colorSecond;
		text-transform: uppercase;

		@include media($mediumLayout) {
			padding-bottom: $tableSpacingLarge;
			padding-top: $tableSpacingLarge;
		}

		&::after {
			background-color: $colorSeparateLineDark;
			bottom: 0;
			content: '';
			display: block;
			height: 1px;
			left: 0;
			position: absolute;
			width: calc(100% - #{$gutterBase});

			@include media($mediumLayout) {
				width: calc(100% - #{$gutterMedium});
			}

			@include media($xLargeLayout) {
				width: calc(100% - #{$gutterXLarge});
			}
		}
	}
}
