.dlx-blockLargeInfo {
	position: relative;
	padding-bottom: $base2;
	padding-top: $base2;

	@include media($mediumLayout) {
		padding-bottom: $base4;
		padding-top: $base4;
	}

	&__items {
		@include dlx-fadeUp();
		@include dlx-mainContent();
		display: block;
	}

	&__item {
		margin-bottom: $base3;

		&:last-child {
			margin-bottom: 0;
		}
	}

	&__itemHead {
		@include font(subheader);
		margin-bottom: $base05;
	}

	&__itemContent {
		@include font(header);
		color: $colorSecond;
	}
}
