.dlx-event {
	margin-bottom: $base5;
	position: relative;

	&::after {
		content: '';
		position: absolute;
		bottom: -$base2;
		left: 0;
		right: -70vw;
		height: 1px;
		width: 200vw;
		background-color: $colorSeparateLine;
		z-index: 0;
	}

	@include media($mediumLayout) {
		margin-top: $base2;
		margin-bottom: $base7;
	}

	&__additional {
		margin-top: $base;

		@include media($mediumLayout) {
			margin-top: $base;
		}
	}

	&__category {
		@include font(subhead);
		color: $colorSecond;
		display: inline-block;
		text-transform: uppercase;
		margin-right: $base2;
	}

	&__date {
		@include font(subhead);
		display: inline-block;
	}

	&__headline {
		margin-top: $base;
		margin-bottom: $base2;
		@include font(header);
	}

	&__image {
		width: 100%;
		//min-height: $base10 * 2.7;
		background-color: lightgrey;
	}

	&__img {
		width: 100%;
	}

	&__link {
		//
	}

	&__readMore {
		@include font(subhead);
		color: $colorSecond;
		display: inline-block;
		text-transform: uppercase;
		margin-top: $base3;
	}

	&__time {
		@include font(subhead);
		display: inline-block;

		&::before {
			content: "| ";
			display: inline-block;
		}
	}
}
