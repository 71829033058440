.dlx-blockPeople {
	padding-bottom: $base2;
	padding-top: $base2;

	@include media($mediumLayout) {
		padding-bottom: $base4;
		padding-top: $base4;
	}

	&__image {
		width: 100%;
	}

	&__item {
		@include dlx-mainContent();

		@for $i from 0 through 20 {
			&:nth-child(#{$i}) {
				@include dlx-fadeUp($durationBase * ($i - 1), '.dlx-blockPeople__list');
			}
		}


		&--wide {
			@include dlx-mainContentWide();
		}
	}

	&__img {
		width: 100%;
	}

	&__list {
		position: relative;
	}
}
