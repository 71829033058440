.dlx-anchorMenu {
	@include dlx-container();
	@include fadeOut($durationBase);
	background-color: $additionalMenuBg;
	left: 0;
	max-width: 100%;
	position: absolute;
	top: $base5;
	width: 100%;

	.dlx-pageHeaderSpecial__container.dlx-js-active & {
		@include fadeIn($durationBase);
	}

	.dlx-body.dlx-js-fullyInvertedHeader &,
	.dlx-body.dlx-js-invertedHeader .dlx-pageHeader:not(.dlx-js-scrolledDown) & {
		background-color: transparent;
	}

	@include media($mediumLayout) {
		background-color: transparent;
		margin-top: $base05;
		opacity: 1;
		position: relative;
		top: auto;
		visibility: visible;
		width: auto;
	}


	&__list {
		padding-top: $base * 1.4;
		padding-bottom: $base * 1.5;
		white-space: nowrap;
	}

	&__item {
		display: inline-block;
		margin-right: $base4;

		&:last-of-type {
			margin-right: 0;
		}
	}

	&__anchor {
		@include font(smallbutton);
		color: $colorSecond;
	}
}
