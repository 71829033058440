.dlx-filteredArticle {
	display: none;
	width: 100%;
	margin-top: $base4;

	&.dlx-js-active.dlx-js-show {
		display: flex;
		flex-direction: column;
	}

	&--noimage {
		background-color: $colorSecondInverted;
	}


	@include media($smallLayout) {
		width: dynamicColumnSize(2, 4, $gutterBase);
	}

	@include media($mediumLayout) {
		margin-bottom: $gutterMedium;
		width: dynamicColumnSize(3, 9, $gutterMedium);
	}

	@include media($xLargeLayout) {
		margin-bottom: $gutterXLarge;
		width: dynamicColumnSize(3, 9, $gutterXLarge);
	}


	&__copy {
		@include font(subheader);
	}


	&__headline {
		@include font(headersmaller);
		margin-top: $base;
		margin-bottom: $base2;
	}


	&__additional {
		margin-top: $base;

		@include media($mediumLayout) {
			margin-top: $base;
		}
	}

	&__category {
		@include font(subhead);
		color: $colorSecond;
		display: inline-block;
		text-transform: uppercase;
		margin-right: $base2;
	}

	&__date {
		@include font(subhead);
		display: inline-block;
	}

	&__imageContainer {
		//
	}

	&__image {
		object-fit: cover;
		overflow: hidden;
		padding-bottom: 54%;
		position: relative;
		width: 100%;
		background-color: grey;
	}

	&__imageSpace {
		object-fit: cover;
		overflow: hidden;
		padding-bottom: 54%;
		position: relative;
		width: 100%;
	}

	&__img {
		@include objectFit(cover);
		display: block;
		height: 100%;
		width: 100%;
		position: absolute;
	}

	&__link {
		//
	}

	&__textContainer {
		.dlx-filteredArticle--noimage & {
			padding: 0 $base * 1.5 $base * 1.5 $base * 1.5;

		}
	}

	&__time {
		@include font(subhead);
		display: inline-block;

		&::before {
			content: "| ";
			display: inline-block;
		}
	}
}
