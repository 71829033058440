.dlx-backgroundVideo {
	&__image {
		display: block;
	}

	&__imageContainer {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: -1;
	}

	&__img {
		@include objectFit(cover);
		display: block;
		height: 100%;
		width: 100%;
		position: absolute;
	}

	&__video {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		pointer-events: none;
		overflow: hidden;

		iframe {
			width: 100vw;
			height: 56.25vw; /* Given a 16:9 aspect ratio, 9/16*100 = 56.25 */
			min-height: 100vh;
			min-width: 190vh; /* Given a 16:9 aspect ratio, 16/9*100 = 177.77 */
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
		}
	}
}
