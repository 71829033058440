.dlx-blockFeatureList {
	margin-top: $base4;

	@include media($smallLayout) {
		margin-left: staticColumnPush(2, 12, $gutterBase);
	}

	@include media($xLargeLayout) {
		margin-top: $base8;
		margin-left: staticColumnPush(2, 12, $gutterXLarge);
	}

	&__head {
		@include font(subhead);
		color: $colorSubhead;
		text-transform: uppercase;
		margin-bottom: $verticalMarginHalf;
	}

	&__list {
		@include dynamicColumns($gutterBase, 0);

		@include media($mediumLayout) {
			@include dynamicColumns($gutterMedium, 0);
		}

		@include media($xLargeLayout) {
			@include dynamicColumns($gutterXLarge, 0);
		}
	}
}
