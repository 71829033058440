.dlx-mediumHeader {
	@include dlx-mediumContent();
	@include font(subhead);
	display: block;
	position: relative;
	padding-top: $base6;
	margin-bottom: $base6;
	text-transform: uppercase;

	&::after {
		content: '';
		position: absolute;
		bottom: -$base3;
		left: 0;
		right: -100vw;
		height: 1px;
		width: 100vw;
		background-color: $colorSeparateLine;
		z-index: 0;
	}

	&--top {
		padding-top: $base * 15;
	}
}
