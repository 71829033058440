.dlx-button {
	@include button();

	&--galleryNav {
		background-color: $colorGalleryNavBg;
		border-radius: 50%;
		color: $colorModalGalleryNav;
		height: $base6;
		position: absolute;
		width: $base6;

		.dlx-gallery & {
			@include ease(opacity visibility transform, $durationLong, $easeBase);
			@include fadeOut();
			background-color: $colorGalleryNavBg;
			bottom: $base2;
			z-index: 3;
		}

		.dlx-gallery__itemsContainter:hover & {
			@include fadeIn();
		}

		.dlx-modalGallery & {
			background-color: $colorModalGalleryNavBg;
			margin-top: -$base3;
			top: 50%;
		}
	}

	&--galleryNavNext {
		.dlx-gallery & {
			right: 0;// $base2;
		}

		.dlx-modalGallery & {
			right: 0;
		}
	}

	&--galleryNavPrev {
		.dlx-gallery & {
			left: 0;// $base2;
			transform: rotate(180deg);
		}

		.dlx-gallery__itemsContainter:hover & {
			@include media($mediumLayout) {
				//transform: translateX(#{staticColumnSize(-3, 12, $gutterMedium)}) rotate(180deg);
			}

			@include media($xLargeLayout) {
				//transform: translateX(#{staticColumnSize(-3, 12, $gutterXLarge)}) rotate(180deg);
			}
		}

		.dlx-modalGallery & {
			left: 0;
			transform: rotate(180deg);
		}
	}

	&--modalToggler {
		color: $colorModalGalleryNav;
		height: $base2;
		position: absolute;
		right: 0;
		top: 0;
		width: $base2;
	}


	&--pagination {
		@include ease(background-color, $durationBase, $easeBase);
		background-color: $colorSquareButton;

		&:hover {
			background-color: $colorBaseInverted;
		}
	}

	&--paginationSeparator {
		cursor: default;
		background-color: transparent;
		border: 1px solid transparent;
	}

	&--paginationCurrent {
		cursor: default;
		pointer-events: none;
		background-color: $colorSquareButtonLightBgHover;
	}

	&--trackersOptions {
		//text-align: center;
		margin-top: $base2;
		//width: staticColumnSize(1, 1, $gutterBase);
	}

	&--trackersSave {
		//width: staticColumnSize(1, 1, $gutterBase);
	}

	&--trackersOk {
		width: staticColumnSize(1, 1, $gutterBase);

		.dlx-trackers:not(.js-confirmMode) & {
			display: none;
		}
	}

	&__label {
		.dlx-button--trackersDetails.dlx-js-toggled &--show,
		.dlx-button--trackersDetails:not(.dlx-js-toggled) &--hide,
		.dlx-trackers.dlx-js-expanded .dlx-button--trackersOptions &--show,
		.dlx-trackers:not(.dlx-js-expanded) .dlx-button--trackersOptions &--hide {
			display: none;
		}

		.dlx-button--pagination & {
			vertical-align: middle;
			@include font(subhead);
			color: $colorSecond;
			display: inline-block;
			width: auto;
			position: relative;
			text-transform: uppercase;
			margin-top: -1px; //just to align nicer
			z-index: 1;
		}
	}
}
