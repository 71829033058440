.dlx-pictureHeader {
	position: relative;
	width: 100%;
	display: block;
	overflow: hidden;
	margin-bottom: $base3;

	&__container {
		@include dlx-container();
		color: $colorBaseInverted;
		padding-bottom: $base2;
		padding-top: $base6;

		@include media($smallLayout) {
			padding-bottom: $base2;
			padding-top: $base7;
		}

		@include media($mediumLayout) {
			padding-bottom: $base2;
			padding-top: $base * 12;
		}
	}


	&__content {
		@include dlx-mainContent(true);
	}

	&__imageContainer {
		display: block;
		height: 100%;
		left: 0;
		overflow: hidden;
		position: absolute;
		top: 0;
		width: 100vw;
		@include dlx-wideContent(true);
		z-index: -1;
	}

	&__imageParallax {
		display: block;
		position: relative;
		width: 100%;
		height: 100%;
	}

	&__image {
		width: 120%;
		height: 100%;//140%;
		left: 0;
		//min-height: 140%;
		position: absolute;
		top: 0;

		&::after {
			background-color: $colorOverlayTransparent;
			content: '';
			height: 100%;
			left: 0;
			position: absolute;
			top: 0;
			width: 100%;
			z-index: 100;
		}
	}

	&__img {
		@include objectFit(cover);
		@include fadeInOnLoad(dlx-js-loaded, $durationOpacityShowAnim, $easeBase);
		display: block;
		height: 100%;
		width: 100%;
	}


	&__textarea {
		@include font(header);
		@include dlx-fadeUp(0s, '.dlx-pictureHeader');
		margin-bottom: $base2;
		@include media($smallLayout) {
			margin-bottom: $base3;
		}
		@include media($xLargeLayout) {
			margin-bottom: $base5;
		}
	}


	&__title {
		@include font(largehead);
		margin-bottom: $base * 15;
		@include media($smallLayout) {
			margin-bottom: $base * 20;
		}
		@include media($mediumLayout) {
			margin-bottom: $base * 20;
		}
		@include media($xLargeLayout) {
			margin-bottom: $base * 25;
		}
	}


	&__subtitle {
		@include font(subhead);
		@include dlx-fadeUp(0s, '.dlx-pictureHeader');
		color: $colorSecondInverted;
		margin-bottom: 0;
		position: relative;
		text-transform: uppercase;
	}


	&__subtitleHighlight {
		@include colorSet($colorsSetActive, $colorsSetLength, color, true, false, '.dlx-pictureHeader');
	}
}
