.dlx-blockSignUp {
	position: relative;
	padding-top: $base2;
	padding-bottom: $base;

	&::before {
		background-color: $colorBaseInverted;
		content: '';
		position: absolute;
		top: 0;
		width: 100vw;
		height: 100%;
		z-index: -1;
		left: -$containerPaddingBase;

		@include media($smallLayout) {
			left: -$containerPaddingSmall;
		}

		@include media($mediumLayout) {
			left: staticColumnSize(2, 12, $gutterMedium, $gutterMedium - $base6);
		}

		@include media($xLargeLayout) {
			left: staticColumnSize(2, 12, $gutterXLarge, $gutterXLarge - $base6);
		}
	}

	@include media($smallLayout) {
		padding-top: $base3;
		padding-bottom: $base2;
	}

	@include media($xLargeLayout) {
		padding-top: $base5;
		padding-bottom: $base5;
	}

	&__content {
		@include dlx-fadeUp();
		@include dlx-mainContent();
	}
}
