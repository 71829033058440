.dlx-galleryNavigation {
	&__action {
		@include ease(background-color, $duration2);
		background-color: $colorGalleryNavigation;
		border-radius: 50%;
		cursor: pointer;
		display: block;
		font-size: 0;
		height: 100%;
		line-height: 0;
		overflow: hidden;
		text-indent: -1000px;
		width: 100%;

		&.dlx-js-current {
			background-color: $colorGalleryNavigationCurrent;
		}
	}


	&__item {
		height: $galleryNavigationSizeXSmall;
		width: $galleryNavigationSizeXSmall;

		@include media($smallLayout) {
			height: $galleryNavigationSizeSmall;
			width: $galleryNavigationSizeSmall;
		}

		& + & {
			margin-left: $galleryNavigationSpaceXSmall;

			@include media($smallLayout) {
				margin-left: $galleryNavigationSpaceSmall;
			}
		}
	}


	&__items {
		display: flex;
		flex-direction: row;
	}
}
