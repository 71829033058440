.dlx-formField {
	@include font(base);
	background-color: $colorBgBase;
	border: none;
	color: $colorBase;
	height: $formElementsHeight;
	padding-left: $base2;
	padding-right: $base2;
	vertical-align: top;

	@include media($xLargeLayout) {
		height: $formElementsHeightXL;
	}

    &--select {
	  @include font(base);
	  appearance: none;
	  border: 1px solid $colorSecond;
	  background: transparent;
	  border-radius: 0;

	  background-image: linear-gradient(45deg,transparent 50%,grey 0),linear-gradient(135deg,grey 50%,transparent 0);
	  background-position: calc(100% - 20px) calc(1em + 5px),calc(100% - 10px) calc(1em + 5px),calc(100% - 2.5em) 0.5em;
	  background-size: 10px 10px,10px 10px,1px 1.5em;
	  background-repeat: no-repeat;
    }
	&--select:invalid {
		@include font(subheader);
		color: $colorSecond;
		opacity: 0.5;
	}

	&:focus {
		border: 1px solid $colorSeparateLine;
		background-color: $colorBaseInverted;
	}

	&--inverted {
		background-color: transparent;
		border: 1px solid $colorSecond;

		&:focus {
			border: 1px solid transparent;
			background-color: $colorBgBase;
		}
	}

	&--background {
		border: 1px solid transparent;
		background-color: $colorBaseInverted;

		&:focus {
			border: 1px solid $colorSeparateLine;
			background-color: $colorBaseInverted;

			.dlx-body.dlx-js-fullyInvertedHeader & {
				border: 1px solid transparent;
			}
		}
	}

	&--invertedLight {
		background-color: transparent;
		border: 1px solid $colorSeparateLine;

		&:focus {
			border: 1px solid transparent;
			border: 1px solid $colorSecond;
		}

		&::placeholder {
			@include font(subheader);
		}
	}

	&--large {
		height: $formElementsHeight * 2;
		padding-bottom: $base;
		padding-top: $base;
		resize: none;

		@include media($xLargeLayout) {
			height: $formElementsHeightXL * 2;
		}
	}

	&__selectPlaceholder {
	  color: $colorSecond;
	  opacity: 0.5;
	  @include font(subheader);

	  &:disabled {
			display: none;
		}
	}

	&::placeholder {
		color: $colorSecond;
		opacity: 0.5;
	}
}
