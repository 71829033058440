.dlx-mainMenuToggler {
	@include ease(opacity, $durationShort, $easeBase);
	@include font(subhead);
	color: $colorSubhead;
	cursor: pointer;
	left: 0;
	position: absolute;
	text-transform: uppercase;
	top: $headerPaddingSmall;
	z-index: 30;

	.dlx-pageHeader.dlx-js-menuHidden & {
		opacity: 0;
	}

	.dlx-body.dlx-js-invertedHeader & {
		color: $colorBaseInverted;
		@include media($mediumLayout) {
			color: $colorSubhead;
		}
	}

	.dlx-body.dlx-js-searchOpen & {
		display: none;
		@include media($mediumLayout) {
			display: block;
		}
	}

	.dlx-body.dlx-js-invertedHeader .dlx-pageHeader.dlx-js-scrolledDown & {
		color: $colorSubhead;
	}

	@include media($mediumLayout) {
		cursor: auto;
		pointer-events: none;
		top: 0;
	}

	&__icon {
		color: $colorIcon;
		display: none;

		.dlx-mainMenuToggler.dlx-js-close & {
			display: block;
		}
	}

	&__text {
		display: block;
		min-height: $base3;

		.dlx-mainMenuToggler.dlx-js-close & {
			display: none;
		}

		.dlx-body.dlx-js-fullyInvertedHeader & {
			color: $colorBaseInverted;
		}
	}
}
