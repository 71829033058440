@charset "UTF-8";
/*
  This mixin can be used to set the object-fit:
  @include object-fit(contain);
  or object-fit and object-position:
  @include object-fit(cover, 50% 50%);
*/
@font-face {
  font-family: "Roboto";
  src: url("../fonts/roboto-v29-latin-regular.woff2") format("woff2"), url("../fonts/roboto-v29-latin-regular.woff") format("woff");
  font-display: swap;
  font-weight: normal;
}
@font-face {
  font-family: "Roboto";
  src: url("../fonts/roboto-v29-latin-italic.woff2") format("woff2"), url("../fonts/roboto-v29-latin-italic.woff") format("woff");
  font-display: swap;
  font-style: italic;
  font-weight: normal;
}
@font-face {
  font-family: "Roboto";
  src: url("../fonts/roboto-v29-latin-regular.woff2") format("woff2"), url("../fonts/roboto-v29-latin-regular.woff") format("woff");
  font-display: swap;
  font-weight: bold;
}
@font-face {
  font-family: "Roboto";
  src: url("../fonts/roboto-v29-latin-200.woff2") format("woff2"), url("../fonts/roboto-v29-latin-200.woff") format("woff");
  font-display: swap;
  font-weight: 200;
}
@font-face {
  font-family: "Roboto";
  src: url("../fonts/roboto-v29-latin-300.woff2") format("woff2"), url("../fonts/roboto-v29-latin-300.woff") format("woff");
  font-display: swap;
  font-weight: 300;
}
@font-face {
  font-family: "Roboto";
  src: url("../fonts/roboto-v29-latin-500.woff2") format("woff2"), url("../fonts/roboto-v29-latin-500.woff") format("woff");
  font-display: swap;
  font-weight: 500;
}
@font-face {
  font-family: "Roboto";
  src: url("../fonts/roboto-v29-latin-700.woff2") format("woff2"), url("../fonts/roboto-v29-latin-700.woff") format("woff");
  font-display: swap;
  font-weight: 700;
}
@font-face {
  font-family: "Roboto";
  src: url("../fonts/roboto-v29-latin-regular.woff2") format("woff2"), url("../fonts/roboto-v29-latin-regular.woff") format("woff");
  font-display: swap;
  font-weight: normal;
}
@font-face {
  font-family: "Roboto";
  src: url("../fonts/roboto-v29-latin-italic.woff2") format("woff2"), url("../fonts/roboto-v29-latin-italic.woff") format("woff");
  font-display: swap;
  font-style: italic;
  font-weight: normal;
}
@font-face {
  font-family: "Roboto";
  src: url("../fonts/roboto-v29-latin-regular.woff2") format("woff2"), url("../fonts/roboto-v29-latin-regular.woff") format("woff");
  font-display: swap;
  font-weight: bold;
}
@font-face {
  font-family: "Roboto";
  src: url("../fonts/roboto-v29-latin-200.woff2") format("woff2"), url("../fonts/roboto-v29-latin-200.woff") format("woff");
  font-display: swap;
  font-weight: 200;
}
@font-face {
  font-family: "Roboto";
  src: url("../fonts/roboto-v29-latin-300.woff2") format("woff2"), url("../fonts/roboto-v29-latin-300.woff") format("woff");
  font-display: swap;
  font-weight: 300;
}
@font-face {
  font-family: "Roboto";
  src: url("../fonts/roboto-v29-latin-500.woff2") format("woff2"), url("../fonts/roboto-v29-latin-500.woff") format("woff");
  font-display: swap;
  font-weight: 500;
}
@font-face {
  font-family: "Roboto";
  src: url("../fonts/roboto-v29-latin-700.woff2") format("woff2"), url("../fonts/roboto-v29-latin-700.woff") format("woff");
  font-display: swap;
  font-weight: 700;
}
@keyframes loader-animation {
  0% {
    transform: scale(1);
  }
  80% {
    transform: scale(0.75);
  }
  100% {
    transform: scale(1);
  }
}
.dlx-address {
  margin-bottom: 5rem;
  width: 100%;
}
@media (min-width: 720px) {
  .dlx-address {
    width: calc(50% + -1rem + 0px);
  }
}
@media (min-width: 1024px) {
  .dlx-address {
    margin-bottom: 7rem;
    width: calc(50% + -1.6rem + 0px);
  }
}
@media (min-width: 1450px) {
  .dlx-address {
    margin-bottom: 9rem;
    width: calc(50% + -1rem + 0px);
  }
}
.dlx-address--main {
  width: 100%;
}
@media (min-width: 720px) {
  .dlx-address--main {
    width: calc(88.8888888889% + -0.2222222222rem + 0px);
  }
}
@media (min-width: 1024px) {
  .dlx-address--main {
    width: 100%;
  }
}
@media (min-width: 1450px) {
  .dlx-address--main {
    width: 100%;
  }
}
.dlx-address__link {
  font-family: Roboto, sans-serif;
  font-size: 1.3rem;
  font-weight: bold;
  letter-spacing: 0.1rem;
  line-height: 1.2307692308;
  display: block;
  position: relative;
  margin-top: 2rem;
  margin-left: calc(16.6666666667% + 0.3333333333rem + 0px);
}
@media (min-width: 720px) {
  .dlx-address__link {
    font-size: 1.4rem;
    line-height: 1.2857142857;
  }
}
@media (min-width: 1450px) {
  .dlx-address__link {
    font-size: 1.8rem;
    line-height: 1.2222222222;
  }
}
@media (min-width: 720px) {
  .dlx-address__link {
    margin-left: calc(25% + 0.5rem + 0px);
  }
}
@media (min-width: 1024px) {
  .dlx-address__link {
    margin-top: 3rem;
    margin-left: calc(33.3333333333% + 1.0666666667rem + 0px);
  }
}
@media (min-width: 1450px) {
  .dlx-address__link {
    margin-left: calc(33.3333333333% + 0.6666666667rem + 0px);
  }
}
.dlx-address__linkIcon {
  left: -5rem;
  position: absolute;
  top: -0.5rem;
  width: 3.5rem;
}
.dlx-address__linkText {
  text-transform: uppercase;
  color: #6a665b;
}
.dlx-address__title {
  font-size: 1.8rem;
  line-height: 1.3333333333;
  font-weight: normal;
  font-weight: bold;
  margin-bottom: 2rem;
}
@media (min-width: 720px) {
  .dlx-address__title {
    font-size: 2rem;
    line-height: 1.4;
  }
}
@media (min-width: 1450px) {
  .dlx-address__title {
    font-size: 2.4rem;
    line-height: 1.4166666667;
  }
}
.dlx-address__row {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  margin-bottom: 1rem;
}
.dlx-address__row > * {
  flex-grow: 0;
  flex-shrink: 0;
}
.dlx-address__rowName {
  font-family: Roboto, sans-serif;
  font-size: 1.3rem;
  font-weight: bold;
  letter-spacing: 0.1rem;
  line-height: 1.2307692308;
  color: #ccc5b5;
  margin-top: 0.25rem;
  vertical-align: middle;
  text-transform: uppercase;
  width: calc(16.6666666667% + -1.6666666667rem + 2rem);
}
@media (min-width: 720px) {
  .dlx-address__rowName {
    font-size: 1.4rem;
    line-height: 1.2857142857;
  }
}
@media (min-width: 1450px) {
  .dlx-address__rowName {
    font-size: 1.8rem;
    line-height: 1.2222222222;
  }
}
@media (min-width: 720px) {
  .dlx-address__rowName {
    margin-top: 0.5rem;
    width: calc(25% + -1.5rem + 2rem);
  }
}
@media (min-width: 1024px) {
  .dlx-address__rowName {
    width: calc(33.3333333333% + -2.1333333333rem + 0px);
  }
}
@media (min-width: 1450px) {
  .dlx-address__rowName {
    width: calc(33.3333333333% + -1.3333333333rem + 0px);
  }
}
@media (min-width: 720px) {
  .dlx-address--main .dlx-address__rowName {
    width: calc(12.5% + -1.75rem + 0px);
  }
}
@media (min-width: 1024px) {
  .dlx-address--main .dlx-address__rowName {
    width: calc(16.6666666667% + -2.6666666667rem + 0px);
  }
}
@media (min-width: 1450px) {
  .dlx-address--main .dlx-address__rowName {
    width: calc(16.6666666667% + -1.6666666667rem + 0px);
  }
}
.dlx-address__rowValue {
  font-family: Roboto, sans-serif;
  font-size: 1.6rem;
  font-weight: normal;
  line-height: 1.375;
  vertical-align: middle;
  width: calc(83.3333333333% + -0.3333333333rem + 0px);
}
@media (min-width: 1450px) {
  .dlx-address__rowValue {
    font-size: 1.8rem;
    line-height: 1.3333333333;
  }
}
.dlx-address--main .dlx-address__rowValue {
  font-size: 1.8rem;
  line-height: 1.3333333333;
  font-weight: normal;
}
@media (min-width: 720px) {
  .dlx-address--main .dlx-address__rowValue {
    font-size: 2rem;
    line-height: 1.4;
  }
}
@media (min-width: 1450px) {
  .dlx-address--main .dlx-address__rowValue {
    font-size: 2.4rem;
    line-height: 1.4166666667;
  }
}
@media (min-width: 720px) {
  .dlx-address--main .dlx-address__rowValue {
    width: calc(75% + -0.5rem + 0px);
  }
}
@media (min-width: 1024px) {
  .dlx-address--main .dlx-address__rowValue {
    width: calc(83.3333333333% + -0.5333333333rem + 0px);
  }
}
@media (min-width: 1450px) {
  .dlx-address--main .dlx-address__rowValue {
    width: calc(83.3333333333% + -0.3333333333rem + 0px);
  }
}
@media (min-width: 720px) {
  .dlx-address__rowValue {
    width: calc(75% + -0.5rem + 0px);
  }
}
@media (min-width: 1024px) {
  .dlx-address__rowValue {
    width: calc(66.6666666667% + -1.0666666667rem + 0px);
  }
}
@media (min-width: 1450px) {
  .dlx-address__rowValue {
    width: calc(66.6666666667% + -0.6666666667rem + 0px);
  }
}

.dlx-button {
  align-content: center;
  align-items: center;
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  position: relative;
  text-decoration: none;
}
.dlx-button::before {
  content: " ";
  display: block;
  margin-left: -1px;
  overflow: hidden;
  position: relative;
  visibility: hidden;
  width: 1px;
  z-index: 1;
}
.dlx-button__label, .dlx-button__icon, .dlx-button__icon svg {
  display: inline-block;
  flex-grow: 0;
  flex-shrink: 0;
}
.dlx-button__label {
  flex-grow: 1;
}
.dlx-button__icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  align-content: center;
}
.dlx-button__icon figure,
.dlx-button__icon span[data-type=svg] {
  width: inherit;
  height: inherit;
  display: inline-block;
  flex-grow: 0;
  flex-shrink: 0;
}
.dlx-button__label + .dlx-button__icon, .dlx-button__icon + .dlx-button__label {
  margin-left: 1em;
}
.dlx-button--galleryNav {
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 50%;
  color: #6a665b;
  height: 6rem;
  position: absolute;
  width: 6rem;
}
.dlx-gallery .dlx-button--galleryNav {
  transition: opacity 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) , visibility 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) , transform 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) ;
  opacity: 0;
  visibility: hidden;
  background-color: rgba(255, 255, 255, 0.8);
  bottom: 2rem;
  z-index: 3;
}
.dlx-gallery__itemsContainter:hover .dlx-button--galleryNav {
  opacity: 1;
  visibility: inherit;
}
.dlx-modalGallery .dlx-button--galleryNav {
  background-color: #ffffff;
  margin-top: -3rem;
  top: 50%;
}
.dlx-gallery .dlx-button--galleryNavNext {
  right: 0;
}
.dlx-modalGallery .dlx-button--galleryNavNext {
  right: 0;
}
.dlx-gallery .dlx-button--galleryNavPrev {
  left: 0;
  transform: rotate(180deg);
}
.dlx-modalGallery .dlx-button--galleryNavPrev {
  left: 0;
  transform: rotate(180deg);
}
.dlx-button--modalToggler {
  color: #6a665b;
  height: 2rem;
  position: absolute;
  right: 0;
  top: 0;
  width: 2rem;
}
.dlx-button--pagination {
  transition: background-color 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94) ;
  background-color: #ece9e5;
}
.dlx-button--pagination:hover {
  background-color: #ffffff;
}
.dlx-button--paginationSeparator {
  cursor: default;
  background-color: transparent;
  border: 1px solid transparent;
}
.dlx-button--paginationCurrent {
  cursor: default;
  pointer-events: none;
  background-color: #ccc5b5;
}
.dlx-button--trackersOptions {
  margin-top: 2rem;
}
.dlx-button--trackersOk {
  width: 100%;
}
.dlx-trackers:not(.js-confirmMode) .dlx-button--trackersOk {
  display: none;
}
.dlx-button--trackersDetails.dlx-js-toggled .dlx-button__label--show, .dlx-button--trackersDetails:not(.dlx-js-toggled) .dlx-button__label--hide, .dlx-trackers.dlx-js-expanded .dlx-button--trackersOptions .dlx-button__label--show, .dlx-trackers:not(.dlx-js-expanded) .dlx-button--trackersOptions .dlx-button__label--hide {
  display: none;
}
.dlx-button--pagination .dlx-button__label {
  vertical-align: middle;
  font-family: Roboto, sans-serif;
  font-size: 1.3rem;
  font-weight: bold;
  letter-spacing: 0.1rem;
  line-height: 1.2307692308;
  color: #6a665b;
  display: inline-block;
  width: auto;
  position: relative;
  text-transform: uppercase;
  margin-top: -1px;
  z-index: 1;
}
@media (min-width: 720px) {
  .dlx-button--pagination .dlx-button__label {
    font-size: 1.4rem;
    line-height: 1.2857142857;
  }
}
@media (min-width: 1450px) {
  .dlx-button--pagination .dlx-button__label {
    font-size: 1.8rem;
    line-height: 1.2222222222;
  }
}

.dlx-blockCaseStudies {
  padding-top: 2rem;
  padding-bottom: 2rem;
  position: relative;
  clear: both;
}
@media (min-width: 720px) {
  .dlx-blockCaseStudies {
    padding-bottom: 4rem;
  }
}
@media (min-width: 1024px) {
  .dlx-blockCaseStudies {
    padding-bottom: 6rem;
  }
}
@media (min-width: 1450px) {
  .dlx-blockCaseStudies {
    padding-bottom: 8rem;
  }
}

/*
	This module behaves differently on mobile, desktop and edge.
	There's a few tricky solutions.
	Please be very careful when making any changes.
	Please test carefully whenever changed.
	PLease also check case-study.js
*/
.dlx-caseStudyItem {
  margin-top: 4rem;
  position: relative;
  z-index: 1;
}
.dlx-caseStudyItem.dlx-js-front {
  z-index: 10;
}
.dlx-caseStudyItem:first-of-type {
  margin-top: 2rem;
}
@media (min-width: 720px) {
  .dlx-caseStudyItem:first-of-type {
    margin-top: 2rem;
  }
}
@media (min-width: 1024px) {
  .dlx-caseStudyItem:first-of-type {
    margin-top: 5rem;
  }
}
@media (min-width: 1450px) {
  .dlx-caseStudyItem:first-of-type {
    margin-top: 5rem;
  }
}
@media (min-width: 720px) {
  .dlx-caseStudyItem {
    margin-top: 6rem;
  }
}
@media (min-width: 1024px) {
  .dlx-caseStudyItem {
    margin-top: 8rem;
  }
}
@media (min-width: 1450px) {
  .dlx-caseStudyItem {
    margin-top: 10rem;
  }
}
.dlx-caseStudyItem__imageEffect {
  height: 33rem;
  transition: clip-path 1.4s  ;
  position: relative;
  clip-path: inset(0 0 0 0);
  z-index: 1;
  height: 40rem;
  width: 100%;
}
@media (min-width: 720px) {
  .dlx-caseStudyItem:nth-of-type(3n) .dlx-caseStudyItem__imageEffect {
    height: 33rem;
    width: calc(75% + -0.5rem + 0px);
    margin-left: calc(8.3333333333% + 0.1666666667rem + 0px);
  }
}
@media (min-width: 1024px) {
  .dlx-caseStudyItem:nth-of-type(3n) .dlx-caseStudyItem__imageEffect {
    height: 46rem;
    width: calc(58.3333333333% + -1.3333333333rem + 0px);
    margin-left: calc(33.3333333333% + 0.6666666667rem + 0px);
  }
}
@media (min-width: 1450px) {
  .dlx-caseStudyItem:nth-of-type(3n) .dlx-caseStudyItem__imageEffect {
    height: 70rem;
    width: calc(58.3333333333% + -0.8333333333rem + 0px);
    margin-left: calc(33.3333333333% + 0.6666666667rem + 0px);
  }
}
@media (min-width: 720px) {
  .dlx-caseStudyItem:nth-of-type(3n+1) .dlx-caseStudyItem__imageEffect {
    height: 33rem;
    width: calc(66.6666666667% + -0.6666666667rem + 0px);
    margin-left: calc(8.3333333333% + 0.1666666667rem + 0px);
  }
}
@media (min-width: 1024px) {
  .dlx-caseStudyItem:nth-of-type(3n+1) .dlx-caseStudyItem__imageEffect {
    height: 46rem;
    width: calc(50% + -1.6rem + 0px);
    margin-left: calc(33.3333333333% + 0.6666666667rem + 0px);
  }
}
@media (min-width: 1450px) {
  .dlx-caseStudyItem:nth-of-type(3n+1) .dlx-caseStudyItem__imageEffect {
    height: 70rem;
    width: calc(50% + -1rem + 0px);
    margin-left: calc(33.3333333333% + 0.6666666667rem + 0px);
  }
}
@media (min-width: 720px) {
  .dlx-caseStudyItem:nth-of-type(3n+2) .dlx-caseStudyItem__imageEffect {
    height: 33rem;
    width: calc(83.3333333333% + -0.3333333333rem + 0px);
    margin-left: 0;
  }
}
@media (min-width: 1024px) {
  .dlx-caseStudyItem:nth-of-type(3n+2) .dlx-caseStudyItem__imageEffect {
    height: 46rem;
    width: calc(58.3333333333% + -1.3333333333rem + 0px);
    margin-left: calc(25% + 0.5rem + 0px);
  }
}
@media (min-width: 1450px) {
  .dlx-caseStudyItem:nth-of-type(3n+2) .dlx-caseStudyItem__imageEffect {
    height: 70rem;
    width: calc(58.3333333333% + -0.8333333333rem + 0px);
    margin-left: calc(25% + 0.5rem + 0px);
  }
}
.dlx-caseStudyItem.dlx-js-front .dlx-caseStudyItem__imageEffect {
  z-index: 10;
}
.dlx-caseStudyItem.dlx-js-expanded .dlx-caseStudyItem__imageEffect {
  clip-path: inset(calc(-46rem * 2) calc(-46rem * 2) calc(-46rem * 2) calc(-46rem * 2));
}
@media (min-width: 1450px) {
  .dlx-caseStudyItem.dlx-js-expanded .dlx-caseStudyItem__imageEffect {
    clip-path: inset(calc(-70rem * 2) calc(-70rem * 2) calc(-70rem * 2) calc(-70rem * 2));
  }
}
.dlx-caseStudyItem__image {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
.dlx-caseStudyItem__imageClip {
  display: none;
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 18;
  border: 1px solid #f8f8f7;
}
@supports (clip-path: inset(0 0 0 0)) {
  .dlx-caseStudyItem__imageClip {
    display: block;
  }
}
.dlx-caseStudyItem.dlx-js-front .dlx-caseStudyItem__imageClip {
  z-index: 18;
}
.dlx-caseStudyItem__imageClip::before {
  transition: transform 1.4s  ;
  content: "";
  position: absolute;
  pointer-events: none;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  outline-width: 100vw;
  outline-color: #f8f8f7;
  outline-style: solid;
  outline-offset: 0;
  backface-visibility: hidden;
}
.dlx-caseStudyItem.dlx-js-expanded .dlx-caseStudyItem__imageClip::before {
  transform: scale(5) translateZ(0);
}
@supports (clip-path: inset(0 0 0 0)) {
  .dlx-caseStudyItem__imageContainer {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 7;
    pointer-events: none;
  }
  .dlx-body .dlx-caseStudyItem .dlx-caseStudyItem__imageContainer {
    height: auto;
    width: auto;
    margin-left: 0;
  }
}
.dlx-caseStudyItem.dlx-js-front .dlx-caseStudyItem__imageContainer {
  z-index: 17;
}
.dlx-caseStudyItem__img {
  object-fit: cover;
  font-family: "object-fit: cover";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  html.no-js .dlx-caseStudyItem__img {
    box-sizing: border-box;
    padding-left: 100%;
    background-size: cover;
    background-position: 50% 50%;
  }
}
.dlx-caseStudyItem__infoContainer {
  z-index: 17;
  position: relative;
  margin-left: -1.5rem;
}
.dlx-caseStudyItem__infoContainer[data-dlx-intersect] {
  opacity: 0;
  transform: translateY(2rem);
}
.dlx-caseStudyItem__infoContainer.dlx-js-inside {
  transition: opacity 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s, transform 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s;
  opacity: 1;
  transform: translateY(0);
}
.dlx-caseStudyItem__info {
  width: 100%;
  margin-left: 0;
  transition: background-color 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) ;
  margin-bottom: 1rem;
  padding: 1.5rem;
  position: relative;
}
@media (min-width: 720px) {
  .dlx-caseStudyItem__info {
    margin-left: calc(16.6666666667% + -1.6666666667rem + 2rem);
    width: calc(75% + -0.5rem + 0px);
  }
}
@media (min-width: 1024px) {
  .dlx-caseStudyItem__info {
    margin-left: calc(41.6666666667% + -1.8666666667rem + 3.2rem);
    width: calc(50% + -1.6rem + 0px);
  }
}
@media (min-width: 1450px) {
  .dlx-caseStudyItem__info {
    margin-left: calc(41.6666666667% + -1.1666666667rem + 2rem);
    width: calc(50% + -1rem + 0px);
  }
}
@media (min-width: 720px) {
  .dlx-caseStudyItem.dlx-js-expanded .dlx-caseStudyItem__info {
    background-color: rgba(0, 0, 0, 0.2);
  }
}
.dlx-caseStudyItem__title {
  transition: color 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) ;
  font-family: Roboto, sans-serif;
  font-size: 2.2rem;
  font-weight: normal;
  line-height: 1.1818181818;
  margin-bottom: 1rem;
}
@media (min-width: 720px) {
  .dlx-caseStudyItem__title {
    font-size: 2.8rem;
    line-height: 1.2857142857;
  }
}
@media (min-width: 1450px) {
  .dlx-caseStudyItem__title {
    font-size: 4rem;
    line-height: 1.1;
  }
}
@media (min-width: 720px) {
  .dlx-caseStudyItem.dlx-js-expanded .dlx-caseStudyItem__title {
    color: #ffffff;
  }
}
@media (min-width: 1450px) {
  .dlx-caseStudyItem__title {
    margin-bottom: 1.5rem;
  }
}
.dlx-caseStudyItem__subtitle {
  transition: color 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) ;
  font-family: Roboto, sans-serif;
  font-size: 1.3rem;
  font-weight: bold;
  letter-spacing: 0.1rem;
  line-height: 1.2307692308;
  color: #6a665b;
}
@media (min-width: 720px) {
  .dlx-caseStudyItem__subtitle {
    font-size: 1.4rem;
    line-height: 1.2857142857;
  }
}
@media (min-width: 1450px) {
  .dlx-caseStudyItem__subtitle {
    font-size: 1.8rem;
    line-height: 1.2222222222;
  }
}
@media (min-width: 720px) {
  .dlx-caseStudyItem.dlx-js-expanded .dlx-caseStudyItem__subtitle {
    color: #ffffff;
  }
}

.dlx-blockConfiguratorLink {
  padding-left: 1rem;
  padding-right: 1rem;
  width: 100%;
  transition: background-color 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94) ;
  cursor: pointer;
}
@media (min-width: 720px) {
  .dlx-blockConfiguratorLink {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
@media (min-width: 1024px) {
  .dlx-blockConfiguratorLink {
    padding-left: 3.2rem;
    padding-right: 3.2rem;
  }
}
@media (min-width: 1450px) {
  .dlx-blockConfiguratorLink {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
.dlx-blockConfiguratorLink--color1 {
  background-color: #c9dfe2;
}
.dlx-blockConfiguratorLink--color2 {
  background-color: #e3e791;
}
.dlx-blockConfiguratorLink--color3 {
  background-color: #b1c077;
}
.dlx-blockConfiguratorLink--color4 {
  background-color: #f3d580;
}
.dlx-blockConfiguratorLink--color5 {
  background-color: #eab29d;
}
.dlx-blockConfiguratorLink--color6 {
  background-color: #cacac9;
}
.dlx-blockConfiguratorLink--color1.dlx-blockConfiguratorLink:hover {
  background-color: #7695a3;
}

.dlx-blockConfiguratorLink--color2.dlx-blockConfiguratorLink:hover {
  background-color: #d1d92b;
}

.dlx-blockConfiguratorLink--color3.dlx-blockConfiguratorLink:hover {
  background-color: #778d34;
}

.dlx-blockConfiguratorLink--color4.dlx-blockConfiguratorLink:hover {
  background-color: #ebba00;
}

.dlx-blockConfiguratorLink--color5.dlx-blockConfiguratorLink:hover {
  background-color: #da7a61;
}

.dlx-blockConfiguratorLink--color6.dlx-blockConfiguratorLink:hover {
  background-color: #afafae;
}

.dlx-blockConfiguratorLink__anchor {
  display: block;
  position: relative;
  height: 100%;
  padding-top: 3rem;
  padding-bottom: 4rem;
}
@media (min-width: 720px) {
  .dlx-blockConfiguratorLink__anchor {
    padding-top: 3rem;
    padding-bottom: 5rem;
  }
}
@media (min-width: 1024px) {
  .dlx-blockConfiguratorLink__anchor {
    padding-top: 3rem;
    padding-bottom: 8rem;
  }
}
@media (min-width: 1450px) {
  .dlx-blockConfiguratorLink__anchor {
    padding-top: 5rem;
    padding-bottom: 10rem;
  }
}
.dlx-blockConfiguratorLink__content {
  width: 100%;
  margin-left: 0;
}
.dlx-blockConfiguratorLink__content[data-dlx-intersect] {
  opacity: 0;
  transform: translateY(2rem);
}
.dlx-blockConfiguratorLink__content.dlx-js-inside {
  transition: opacity 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s, transform 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s;
  opacity: 1;
  transform: translateY(0);
}
@media (min-width: 720px) {
  .dlx-blockConfiguratorLink__content {
    margin-left: calc(16.6666666667% + -1.6666666667rem + 2rem);
    width: calc(75% + -0.5rem + 0px);
  }
}
@media (min-width: 1024px) {
  .dlx-blockConfiguratorLink__content {
    margin-left: calc(41.6666666667% + -1.8666666667rem + 3.2rem);
    width: calc(50% + -1.6rem + 0px);
  }
}
@media (min-width: 1450px) {
  .dlx-blockConfiguratorLink__content {
    margin-left: calc(41.6666666667% + -1.1666666667rem + 2rem);
    width: calc(50% + -1rem + 0px);
  }
}
.dlx-blockConfiguratorLink__description {
  margin-top: 2rem;
}
@media (min-width: 720px) {
  .dlx-blockConfiguratorLink__description {
    margin-top: 3rem;
  }
}
@media (min-width: 1024px) {
  .dlx-blockConfiguratorLink__description {
    margin-top: 3rem;
  }
}
@media (min-width: 1450px) {
  .dlx-blockConfiguratorLink__description {
    margin-top: 5rem;
  }
}
@media (min-width: 1024px) {
  .dlx-blockConfiguratorLink__link {
    margin-top: -3rem;
  }
}
@media (min-width: 1450px) {
  .dlx-blockConfiguratorLink__link {
    margin-top: -5rem;
  }
}

.dlx-blockContact {
  margin-top: -1px;
  padding-top: 3rem;
  padding-bottom: 3rem;
  position: relative;
}
.dlx-blockContact--simple {
  background-color: #ffffff;
  padding-left: 1rem;
  padding-right: 1rem;
  width: 100%;
}
@media (min-width: 720px) {
  .dlx-blockContact--simple {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
@media (min-width: 1024px) {
  .dlx-blockContact--simple {
    padding-left: 3.2rem;
    padding-right: 3.2rem;
  }
}
@media (min-width: 1450px) {
  .dlx-blockContact--simple {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
.dlx-blockContact--simple::after {
  display: none;
}
@media (min-width: 1450px) {
  .dlx-blockContact {
    padding-top: 5rem;
  }
}
.dlx-blockContact::after {
  background-color: #ccc5b5;
  content: "";
  height: 1px;
  left: 0;
  position: absolute;
  top: 0;
  width: 100vw;
}
@media (min-width: 720px) {
  .dlx-blockContact::after {
    left: calc(16.6666666667% + -1.6666666667rem + 2rem);
  }
}
@media (min-width: 1024px) {
  .dlx-blockContact::after {
    left: calc(16.6666666667% + -2.6666666667rem + 3.2rem);
  }
}
@media (min-width: 1450px) {
  .dlx-blockContact::after {
    left: calc(16.6666666667% + -1.6666666667rem + 2rem);
  }
}
.dlx-blockContact__button {
  margin-bottom: 2rem;
}
.dlx-blockContact__content {
  width: 100%;
  margin-left: 0;
  width: 100%;
  padding-top: 1rem;
}
.dlx-blockContact__content[data-dlx-intersect] {
  opacity: 0;
  transform: translateY(2rem);
}
.dlx-blockContact__content.dlx-js-inside {
  transition: opacity 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s, transform 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s;
  opacity: 1;
  transform: translateY(0);
}
@media (min-width: 720px) {
  .dlx-blockContact__content {
    margin-left: calc(16.6666666667% + -1.6666666667rem + 2rem);
    width: calc(75% + -0.5rem + 0px);
  }
}
@media (min-width: 1024px) {
  .dlx-blockContact__content {
    margin-left: calc(41.6666666667% + -1.8666666667rem + 3.2rem);
    width: calc(50% + -1.6rem + 0px);
  }
}
@media (min-width: 1450px) {
  .dlx-blockContact__content {
    margin-left: calc(41.6666666667% + -1.1666666667rem + 2rem);
    width: calc(50% + -1rem + 0px);
  }
}
.dlx-blockContact__content--columns {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 0;
}
.dlx-blockContact__content--columns > * {
  flex-grow: 0;
  flex-shrink: 0;
  margin-top: 0;
}
.dlx-blockContact__column {
  width: 100%;
  width: 100%;
}
@media (min-width: 720px) {
  .dlx-blockContact__column:first-of-type {
    width: calc(66.6666666667% + -0.6666666667rem + 0px);
  }
}
@media (min-width: 1024px) {
  .dlx-blockContact__column:first-of-type {
    width: 100%;
  }
}
@media (min-width: 1280px) {
  .dlx-blockContact__column:first-of-type {
    width: calc(66.6666666667% + -1.0666666667rem + 0px);
  }
}
@media (min-width: 1450px) {
  .dlx-blockContact__column:first-of-type {
    width: calc(50% + -1rem + 0px);
  }
}
@media (min-width: 720px) {
  .dlx-blockContact__column:last-of-type {
    margin-left: 2rem;
    width: calc(33.3333333333% + -1.3333333333rem + 0px);
  }
}
@media (min-width: 1024px) {
  .dlx-blockContact__column:last-of-type {
    margin-left: 0;
    width: 100%;
  }
}
@media (min-width: 1280px) {
  .dlx-blockContact__column:last-of-type {
    margin-left: 3.2rem;
    width: calc(33.3333333333% + -2.1333333333rem + 0px);
  }
}
@media (min-width: 1450px) {
  .dlx-blockContact__column:last-of-type {
    margin-left: 2rem;
    width: calc(50% + -1rem + 0px);
  }
}
.dlx-blockContact__link {
  font-family: Roboto, sans-serif;
  font-size: 2.2rem;
  font-weight: normal;
  line-height: 1.1818181818;
  margin-bottom: 2rem;
  display: block;
}
@media (min-width: 720px) {
  .dlx-blockContact__link {
    font-size: 2.8rem;
    line-height: 1.2857142857;
  }
}
@media (min-width: 1450px) {
  .dlx-blockContact__link {
    font-size: 4rem;
    line-height: 1.1;
  }
}
.dlx-blockContact__title {
  font-family: Roboto, sans-serif;
  font-size: 1.3rem;
  font-weight: bold;
  letter-spacing: 0.1rem;
  line-height: 1.2307692308;
  font-weight: normal;
  margin-bottom: 1rem;
  display: block;
}
@media (min-width: 720px) {
  .dlx-blockContact__title {
    font-size: 1.4rem;
    line-height: 1.2857142857;
  }
}
@media (min-width: 1450px) {
  .dlx-blockContact__title {
    font-size: 1.8rem;
    line-height: 1.2222222222;
  }
}

.dlx-blockEvent {
  padding-bottom: 2rem;
  padding-top: 2rem;
}
@media (min-width: 1024px) {
  .dlx-blockEvent {
    padding-bottom: 4rem;
    padding-top: 4rem;
  }
}
.dlx-blockEvent__content {
  width: 100%;
  margin-left: 0;
}
.dlx-blockEvent__content[data-dlx-intersect] {
  opacity: 0;
  transform: translateY(2rem);
}
.dlx-blockEvent__content.dlx-js-inside {
  transition: opacity 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s, transform 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s;
  opacity: 1;
  transform: translateY(0);
}
@media (min-width: 720px) {
  .dlx-blockEvent__content {
    margin-left: calc(16.6666666667% + -1.6666666667rem + 2rem);
    width: calc(75% + -0.5rem + 0px);
  }
}
@media (min-width: 1024px) {
  .dlx-blockEvent__content {
    margin-left: calc(41.6666666667% + -1.8666666667rem + 3.2rem);
    width: calc(50% + -1.6rem + 0px);
  }
}
@media (min-width: 1450px) {
  .dlx-blockEvent__content {
    margin-left: calc(41.6666666667% + -1.1666666667rem + 2rem);
    width: calc(50% + -1rem + 0px);
  }
}
.dlx-blockEvent__contentHead {
  font-family: Roboto, sans-serif;
  font-size: 1.5rem;
  font-weight: normal;
  line-height: 1.3333333333;
  margin-bottom: 0.5rem;
}
@media (min-width: 720px) {
  .dlx-blockEvent__contentHead {
    font-size: 1.6rem;
    line-height: 1.375;
  }
}
@media (min-width: 1450px) {
  .dlx-blockEvent__contentHead {
    font-size: 1.8rem;
    line-height: 1.3333333333;
  }
}
.dlx-blockEvent__contentInfo {
  font-family: Roboto, sans-serif;
  font-size: 2.2rem;
  font-weight: normal;
  line-height: 1.1818181818;
  margin-bottom: 3rem;
  color: #6a665b;
}
@media (min-width: 720px) {
  .dlx-blockEvent__contentInfo {
    font-size: 2.8rem;
    line-height: 1.2857142857;
  }
}
@media (min-width: 1450px) {
  .dlx-blockEvent__contentInfo {
    font-size: 4rem;
    line-height: 1.1;
  }
}
.dlx-blockEvent__contentText {
  font-size: 1.8rem;
  line-height: 1.3333333333;
  font-weight: normal;
}
@media (min-width: 720px) {
  .dlx-blockEvent__contentText {
    font-size: 2rem;
    line-height: 1.4;
  }
}
@media (min-width: 1450px) {
  .dlx-blockEvent__contentText {
    font-size: 2.4rem;
    line-height: 1.4166666667;
  }
}
.dlx-blockEvent__section {
  position: relative;
  margin-top: 6rem;
}
.dlx-blockEvent__section:first-of-type {
  margin-top: 0;
}

.dlx-blockGlossary[data-dlx-intersect] .dlx-blockGlossary__item:nth-child(0) {
  opacity: 0;
  transform: translateY(2rem);
}
.dlx-blockGlossary.dlx-js-inside .dlx-blockGlossary__item:nth-child(0) {
  transition: opacity 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s, transform 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s;
  opacity: 1;
  transform: translateY(0);
}
.dlx-blockGlossary[data-dlx-intersect] .dlx-blockGlossary__item:nth-child(1) {
  opacity: 0;
  transform: translateY(2rem);
}
.dlx-blockGlossary.dlx-js-inside .dlx-blockGlossary__item:nth-child(1) {
  transition: opacity 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.05s, transform 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.05s;
  opacity: 1;
  transform: translateY(0);
}
.dlx-blockGlossary[data-dlx-intersect] .dlx-blockGlossary__item:nth-child(2) {
  opacity: 0;
  transform: translateY(2rem);
}
.dlx-blockGlossary.dlx-js-inside .dlx-blockGlossary__item:nth-child(2) {
  transition: opacity 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.1s, transform 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.1s;
  opacity: 1;
  transform: translateY(0);
}
.dlx-blockGlossary[data-dlx-intersect] .dlx-blockGlossary__item:nth-child(3) {
  opacity: 0;
  transform: translateY(2rem);
}
.dlx-blockGlossary.dlx-js-inside .dlx-blockGlossary__item:nth-child(3) {
  transition: opacity 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.15s, transform 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.15s;
  opacity: 1;
  transform: translateY(0);
}
.dlx-blockGlossary[data-dlx-intersect] .dlx-blockGlossary__item:nth-child(4) {
  opacity: 0;
  transform: translateY(2rem);
}
.dlx-blockGlossary.dlx-js-inside .dlx-blockGlossary__item:nth-child(4) {
  transition: opacity 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.2s, transform 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.2s;
  opacity: 1;
  transform: translateY(0);
}
.dlx-blockGlossary[data-dlx-intersect] .dlx-blockGlossary__item:nth-child(5) {
  opacity: 0;
  transform: translateY(2rem);
}
.dlx-blockGlossary.dlx-js-inside .dlx-blockGlossary__item:nth-child(5) {
  transition: opacity 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.25s, transform 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.25s;
  opacity: 1;
  transform: translateY(0);
}
.dlx-blockGlossary[data-dlx-intersect] .dlx-blockGlossary__item:nth-child(6) {
  opacity: 0;
  transform: translateY(2rem);
}
.dlx-blockGlossary.dlx-js-inside .dlx-blockGlossary__item:nth-child(6) {
  transition: opacity 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.3s, transform 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.3s;
  opacity: 1;
  transform: translateY(0);
}
.dlx-blockGlossary[data-dlx-intersect] .dlx-blockGlossary__item:nth-child(7) {
  opacity: 0;
  transform: translateY(2rem);
}
.dlx-blockGlossary.dlx-js-inside .dlx-blockGlossary__item:nth-child(7) {
  transition: opacity 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.35s, transform 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.35s;
  opacity: 1;
  transform: translateY(0);
}
.dlx-blockGlossary[data-dlx-intersect] .dlx-blockGlossary__item:nth-child(8) {
  opacity: 0;
  transform: translateY(2rem);
}
.dlx-blockGlossary.dlx-js-inside .dlx-blockGlossary__item:nth-child(8) {
  transition: opacity 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.4s, transform 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.4s;
  opacity: 1;
  transform: translateY(0);
}
.dlx-blockGlossary[data-dlx-intersect] .dlx-blockGlossary__item:nth-child(9) {
  opacity: 0;
  transform: translateY(2rem);
}
.dlx-blockGlossary.dlx-js-inside .dlx-blockGlossary__item:nth-child(9) {
  transition: opacity 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.45s, transform 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.45s;
  opacity: 1;
  transform: translateY(0);
}
.dlx-blockGlossary[data-dlx-intersect] .dlx-blockGlossary__item:nth-child(10) {
  opacity: 0;
  transform: translateY(2rem);
}
.dlx-blockGlossary.dlx-js-inside .dlx-blockGlossary__item:nth-child(10) {
  transition: opacity 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.5s, transform 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.5s;
  opacity: 1;
  transform: translateY(0);
}
.dlx-blockGlossary[data-dlx-intersect] .dlx-blockGlossary__item:nth-child(11) {
  opacity: 0;
  transform: translateY(2rem);
}
.dlx-blockGlossary.dlx-js-inside .dlx-blockGlossary__item:nth-child(11) {
  transition: opacity 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.55s, transform 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.55s;
  opacity: 1;
  transform: translateY(0);
}
.dlx-blockGlossary[data-dlx-intersect] .dlx-blockGlossary__item:nth-child(12) {
  opacity: 0;
  transform: translateY(2rem);
}
.dlx-blockGlossary.dlx-js-inside .dlx-blockGlossary__item:nth-child(12) {
  transition: opacity 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.6s, transform 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.6s;
  opacity: 1;
  transform: translateY(0);
}
.dlx-blockGlossary[data-dlx-intersect] .dlx-blockGlossary__item:nth-child(13) {
  opacity: 0;
  transform: translateY(2rem);
}
.dlx-blockGlossary.dlx-js-inside .dlx-blockGlossary__item:nth-child(13) {
  transition: opacity 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.65s, transform 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.65s;
  opacity: 1;
  transform: translateY(0);
}
.dlx-blockGlossary[data-dlx-intersect] .dlx-blockGlossary__item:nth-child(14) {
  opacity: 0;
  transform: translateY(2rem);
}
.dlx-blockGlossary.dlx-js-inside .dlx-blockGlossary__item:nth-child(14) {
  transition: opacity 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.7s, transform 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.7s;
  opacity: 1;
  transform: translateY(0);
}
.dlx-blockGlossary[data-dlx-intersect] .dlx-blockGlossary__item:nth-child(15) {
  opacity: 0;
  transform: translateY(2rem);
}
.dlx-blockGlossary.dlx-js-inside .dlx-blockGlossary__item:nth-child(15) {
  transition: opacity 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.75s, transform 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.75s;
  opacity: 1;
  transform: translateY(0);
}
.dlx-blockGlossary[data-dlx-intersect] .dlx-blockGlossary__item:nth-child(16) {
  opacity: 0;
  transform: translateY(2rem);
}
.dlx-blockGlossary.dlx-js-inside .dlx-blockGlossary__item:nth-child(16) {
  transition: opacity 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.8s, transform 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.8s;
  opacity: 1;
  transform: translateY(0);
}
.dlx-blockGlossary[data-dlx-intersect] .dlx-blockGlossary__item:nth-child(17) {
  opacity: 0;
  transform: translateY(2rem);
}
.dlx-blockGlossary.dlx-js-inside .dlx-blockGlossary__item:nth-child(17) {
  transition: opacity 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.85s, transform 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.85s;
  opacity: 1;
  transform: translateY(0);
}
.dlx-blockGlossary[data-dlx-intersect] .dlx-blockGlossary__item:nth-child(18) {
  opacity: 0;
  transform: translateY(2rem);
}
.dlx-blockGlossary.dlx-js-inside .dlx-blockGlossary__item:nth-child(18) {
  transition: opacity 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.9s, transform 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.9s;
  opacity: 1;
  transform: translateY(0);
}
.dlx-blockGlossary[data-dlx-intersect] .dlx-blockGlossary__item:nth-child(19) {
  opacity: 0;
  transform: translateY(2rem);
}
.dlx-blockGlossary.dlx-js-inside .dlx-blockGlossary__item:nth-child(19) {
  transition: opacity 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.95s, transform 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.95s;
  opacity: 1;
  transform: translateY(0);
}
.dlx-blockGlossary[data-dlx-intersect] .dlx-blockGlossary__item:nth-child(20) {
  opacity: 0;
  transform: translateY(2rem);
}
.dlx-blockGlossary.dlx-js-inside .dlx-blockGlossary__item:nth-child(20) {
  transition: opacity 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 1s, transform 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 1s;
  opacity: 1;
  transform: translateY(0);
}

.dlx-blockHeadquarters {
  width: 100%;
  margin-left: 0;
  position: relative;
  padding-top: 2rem;
}
@media (min-width: 720px) {
  .dlx-blockHeadquarters {
    margin-left: calc(16.6666666667% + -1.6666666667rem + 2rem);
    width: calc(75% + -0.5rem + 0px);
  }
}
@media (min-width: 1024px) {
  .dlx-blockHeadquarters {
    margin-left: calc(41.6666666667% + -1.8666666667rem + 3.2rem);
    width: calc(50% + -1.6rem + 0px);
  }
}
@media (min-width: 1450px) {
  .dlx-blockHeadquarters {
    margin-left: calc(41.6666666667% + -1.1666666667rem + 2rem);
    width: calc(50% + -1rem + 0px);
  }
}
.dlx-blockHeadquarters[data-dlx-intersect] {
  opacity: 0;
  transform: translateY(2rem);
}
.dlx-blockHeadquarters.dlx-js-inside {
  transition: opacity 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s, transform 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s;
  opacity: 1;
  transform: translateY(0);
}
@media (min-width: 1024px) {
  .dlx-blockHeadquarters {
    padding-top: 3rem;
  }
}
@media (min-width: 1450px) {
  .dlx-blockHeadquarters {
    padding-top: 5rem;
  }
}
.dlx-blockHeadquarters::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 1px;
  width: 100vw;
  background-color: #ccc5b5;
}
.dlx-blockHeadquarters__items {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 0;
  margin-top: 4rem;
}
.dlx-blockHeadquarters__items > * {
  flex-grow: 0;
  flex-shrink: 0;
  margin-top: 0;
}
@media (min-width: 1024px) {
  .dlx-blockHeadquarters__items {
    margin-top: 6rem;
  }
}
@media (min-width: 1450px) {
  .dlx-blockHeadquarters__items {
    margin-top: 8rem;
  }
}

.dlx-blockImage {
  margin-top: 2rem;
  margin-bottom: 2rem;
}
.dlx-blockImage[data-dlx-intersect] {
  opacity: 0;
  transform: translateY(2rem);
}
.dlx-blockImage.dlx-js-inside {
  transition: opacity 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s, transform 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s;
  opacity: 1;
  transform: translateY(0);
}
@media (min-width: 1024px) {
  .dlx-blockImage {
    margin-bottom: 4rem;
  }
}
.dlx-blockImage__caption {
  font-family: Roboto, sans-serif;
  font-size: 1.5rem;
  font-weight: normal;
  line-height: 1.3333333333;
  width: 100%;
  margin-left: 0;
  margin-top: 1rem;
}
@media (min-width: 720px) {
  .dlx-blockImage__caption {
    font-size: 1.6rem;
    line-height: 1.375;
  }
}
@media (min-width: 1450px) {
  .dlx-blockImage__caption {
    font-size: 1.8rem;
    line-height: 1.3333333333;
  }
}
@media (min-width: 720px) {
  .dlx-blockImage__caption {
    margin-left: calc(16.6666666667% + -1.6666666667rem + 2rem);
    width: calc(50% + -1rem + 0px);
  }
}
@media (min-width: 1024px) {
  .dlx-blockImage__caption {
    margin-left: calc(41.6666666667% + -1.8666666667rem + 3.2rem);
    width: calc(33.3333333333% + -2.1333333333rem + 0px);
  }
}
@media (min-width: 1450px) {
  .dlx-blockImage__caption {
    margin-left: calc(41.6666666667% + -1.1666666667rem + 2rem);
    width: calc(25% + -1.5rem + 0px);
  }
}
.dlx-blockImage__img {
  opacity: 0;
  overflow: hidden;
  height: 0;
  margin-left: -1rem;
  width: calc(100% + 2 * 1rem);
}
.dlx-blockImage__img.dlx-js-loaded {
  transition: opacity 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) , height 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) ;
  opacity: 1;
  height: 100%;
}
@media (min-width: 720px) {
  .dlx-blockImage__img {
    margin-left: 0;
    width: calc(100% + 2rem);
  }
}
@media (min-width: 1024px) {
  .dlx-blockImage__img {
    margin-left: calc(16.6666666667% + 0.5333333333rem + 0px);
    width: calc(83.3333333333% + -0.5333333333rem + 3.2rem);
  }
}
@media (min-width: 1450px) {
  .dlx-blockImage__img {
    margin-left: calc(16.6666666667% + 0.3333333333rem + 0px);
    width: calc(83.3333333333% + -0.3333333333rem + 2rem);
  }
}
.dlx-blockImage__img--alternate {
  width: 100%;
  margin-left: 0;
}
@media (min-width: 720px) {
  .dlx-blockImage__img--alternate {
    margin-left: calc(16.6666666667% + -1.6666666667rem + 2rem);
    width: calc(75% + -0.5rem + 0px);
  }
}
@media (min-width: 1024px) {
  .dlx-blockImage__img--alternate {
    margin-left: calc(41.6666666667% + -1.8666666667rem + 3.2rem);
    width: calc(50% + -1.6rem + 0px);
  }
}
@media (min-width: 1450px) {
  .dlx-blockImage__img--alternate {
    margin-left: calc(41.6666666667% + -1.1666666667rem + 2rem);
    width: calc(50% + -1rem + 0px);
  }
}

.dlx-blockLinkImage {
  position: relative;
}
.dlx-blockLinkImage__anchor {
  transition: background-color 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94) ;
  display: block;
  height: 100%;
  position: relative;
  text-decoration: none;
  width: 100%;
}
.dlx-blockLinkImage__anchor:hover {
  background-color: #ece9e5;
}
.dlx-blockLinkImage__anchor::before {
  content: "";
  position: absolute;
  top: 0;
  left: -2rem;
  height: 100%;
  width: 2rem;
  background-color: inherit;
}
@media (min-width: 1024px) {
  .dlx-blockLinkImage__anchor::before {
    left: -3.2rem;
    width: 3.2rem;
  }
}
@media (min-width: 1450px) {
  .dlx-blockLinkImage__anchor::before {
    left: -2rem;
    width: 2rem;
  }
}
.dlx-blockLinkImage__anchor::after, .dlx-blockLinkImage__anchor:last-of-type::after {
  content: "";
  position: absolute;
  top: 0;
  left: auto;
  right: -30vw;
  height: 100%;
  width: 30vw;
  background-color: inherit;
  z-index: -1;
}
.dlx-blockLinks__item--highlight .dlx-blockLinkImage__anchor {
  color: #323232;
}
.dlx-blockLink--color1.dlx-blockLinks__item--highlight .dlx-blockLinkImage__anchor {
  background-color: #c9dfe2;
}

.dlx-blockLink--color2.dlx-blockLinks__item--highlight .dlx-blockLinkImage__anchor {
  background-color: #e3e791;
}

.dlx-blockLink--color3.dlx-blockLinks__item--highlight .dlx-blockLinkImage__anchor {
  background-color: #b1c077;
}

.dlx-blockLink--color4.dlx-blockLinks__item--highlight .dlx-blockLinkImage__anchor {
  background-color: #f3d580;
}

.dlx-blockLink--color5.dlx-blockLinks__item--highlight .dlx-blockLinkImage__anchor {
  background-color: #eab29d;
}

.dlx-blockLink--color6.dlx-blockLinks__item--highlight .dlx-blockLinkImage__anchor {
  background-color: #cacac9;
}

.dlx-blockLink--color1.dlx-blockLinks__item--highlight .dlx-blockLinkImage__anchor:hover {
  background-color: #7695a3;
}

.dlx-blockLink--color2.dlx-blockLinks__item--highlight .dlx-blockLinkImage__anchor:hover {
  background-color: #d1d92b;
}

.dlx-blockLink--color3.dlx-blockLinks__item--highlight .dlx-blockLinkImage__anchor:hover {
  background-color: #778d34;
}

.dlx-blockLink--color4.dlx-blockLinks__item--highlight .dlx-blockLinkImage__anchor:hover {
  background-color: #ebba00;
}

.dlx-blockLink--color5.dlx-blockLinks__item--highlight .dlx-blockLinkImage__anchor:hover {
  background-color: #da7a61;
}

.dlx-blockLink--color6.dlx-blockLinks__item--highlight .dlx-blockLinkImage__anchor:hover {
  background-color: #afafae;
}

.dlx-blockLinkImage__content {
  position: relative;
  margin-bottom: 2rem;
}
@media (min-width: 720px) {
  .dlx-blockLinkImage__content {
    margin-bottom: 2rem;
  }
}
@media (min-width: 1450px) {
  .dlx-blockLinkImage__content {
    margin-bottom: 3rem;
  }
}
.dlx-blockLinkImage__columns {
  position: relative;
  min-height: 15rem;
  padding-top: 2rem;
  padding-bottom: 1rem;
}
@media (min-width: 720px) {
  .dlx-blockLinkImage__columns {
    padding-top: 3rem;
    padding-bottom: 2rem;
    min-height: 20rem;
  }
}
@media (min-width: 1024px) {
  .dlx-blockLinkImage__columns {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 0;
  }
  .dlx-blockLinkImage__columns > * {
    flex-grow: 0;
    flex-shrink: 0;
    margin-top: 0;
  }
}
@media (min-width: 1450px) {
  .dlx-blockLinkImage__columns {
    padding-top: 5rem;
    padding-bottom: 5rem;
    min-height: 24rem;
  }
}
.dlx-blockLinkImage__columnLeft {
  width: 100%;
  margin-left: 0;
  display: flex;
  flex-direction: column;
}
@media (min-width: 720px) {
  .dlx-blockLinkImage__columnLeft {
    padding-right: 2rem;
    width: calc(90% + -0.2rem + 0px);
  }
}
@media (min-width: 1024px) {
  .dlx-blockLinkImage__columnLeft {
    padding-right: 3.2rem;
    width: calc(57.1428571429% + -1.3714285714rem + 3.2rem);
  }
}
@media (min-width: 1450px) {
  .dlx-blockLinkImage__columnLeft {
    padding-right: 2rem;
    width: calc(57.1428571429% + -0.8571428571rem + 2rem);
  }
}
.dlx-blockLinkImage__columnRight {
  width: 100%;
  margin-left: 0;
}
@media (min-width: 720px) {
  .dlx-blockLinkImage__columnRight {
    width: calc(70% + -0.6rem + 0px);
  }
}
@media (min-width: 1024px) {
  .dlx-blockLinkImage__columnRight {
    width: calc(42.8571428571% + -1.8285714286rem + 0px);
  }
}
@media (min-width: 1450px) {
  .dlx-blockLinkImage__columnRight {
    width: calc(42.8571428571% + -1.1428571429rem + 0px);
  }
}
.dlx-blockLinkImage__description {
  flex-grow: 1;
  margin-bottom: 2rem;
}
@media (min-width: 720px) {
  .dlx-blockLinkImage__description {
    margin-bottom: 2rem;
  }
}
@media (min-width: 1450px) {
  .dlx-blockLinkImage__description {
    margin-bottom: 3rem;
  }
}
.dlx-blockLinkImage__icon {
  color: #7695a3;
  height: 6rem;
  width: 6rem;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}
@media (min-width: 1024px) {
  .dlx-blockLinkImage__icon {
    display: none;
  }
}
.dlx-blockLinkImage__iconBottom {
  color: #7695a3;
  height: 6rem;
  width: 6rem;
  display: none;
  position: relative;
}
@media (min-width: 1024px) {
  .dlx-blockLinkImage__iconBottom {
    display: block;
  }
}
.dlx-blockLinkImage__image {
  overflow: hidden;
  padding-bottom: 100%;
  position: relative;
  width: 100%;
}
.dlx-blockLinkImage__img {
  object-fit: cover;
  font-family: "object-fit: cover";
  display: block;
  height: 100%;
  width: 100%;
  position: absolute;
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  html.no-js .dlx-blockLinkImage__img {
    box-sizing: border-box;
    padding-left: 100%;
    background-size: cover;
    background-position: 50% 50%;
  }
}
.dlx-blockLinkImage__title {
  font-family: Roboto, sans-serif;
  font-size: 2.2rem;
  font-weight: normal;
  line-height: 1.1818181818;
  margin-top: 1rem;
  color: #6a665b;
  margin-right: 8rem;
}
@media (min-width: 720px) {
  .dlx-blockLinkImage__title {
    font-size: 2.8rem;
    line-height: 1.2857142857;
  }
}
@media (min-width: 1450px) {
  .dlx-blockLinkImage__title {
    font-size: 4rem;
    line-height: 1.1;
  }
}
.dlx-blockLinkImage__subtitle {
  font-family: Roboto, sans-serif;
  font-size: 1.5rem;
  font-weight: normal;
  line-height: 1.3333333333;
}
@media (min-width: 720px) {
  .dlx-blockLinkImage__subtitle {
    font-size: 1.6rem;
    line-height: 1.375;
  }
}
@media (min-width: 1450px) {
  .dlx-blockLinkImage__subtitle {
    font-size: 1.8rem;
    line-height: 1.3333333333;
  }
}

.dlx-blockLink {
  display: block;
  position: relative;
}
.dlx-blockLink--configurator::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  height: 1px;
  width: 100vw;
  background-color: #323232;
}
.dlx-blockLink__anchor {
  transition: background-color 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94) ;
  display: block;
  height: 100%;
  position: relative;
  text-decoration: none;
  width: 100%;
}
.dlx-blockLink__anchor:hover {
  background-color: #ece9e5;
}
.dlx-blockLink__anchor::before {
  content: "";
  position: absolute;
  top: 0;
  left: -2rem;
  height: 100%;
  width: 2rem;
  background-color: inherit;
}
@media (min-width: 1024px) {
  .dlx-blockLink__anchor::before {
    left: -3.2rem;
    width: 3.2rem;
  }
}
@media (min-width: 1450px) {
  .dlx-blockLink__anchor::before {
    left: -2rem;
    width: 2rem;
  }
}
.dlx-blockLink__anchor::after, .dlx-blockLink__anchor:last-of-type::after {
  content: "";
  position: absolute;
  top: 0;
  left: auto;
  right: -30vw;
  height: 100%;
  width: 30vw;
  background-color: inherit;
  z-index: -1;
}
.dlx-blockLink--configurator .dlx-blockLink__anchor:hover {
  background-color: transparent;
}
.dlx-blockLinks__item--highlight .dlx-blockLink__anchor {
  color: #323232;
}
.dlx-blockLink--color1.dlx-blockLinks__item--highlight .dlx-blockLink__anchor {
  background-color: #c9dfe2;
}

.dlx-blockLink--color2.dlx-blockLinks__item--highlight .dlx-blockLink__anchor {
  background-color: #e3e791;
}

.dlx-blockLink--color3.dlx-blockLinks__item--highlight .dlx-blockLink__anchor {
  background-color: #b1c077;
}

.dlx-blockLink--color4.dlx-blockLinks__item--highlight .dlx-blockLink__anchor {
  background-color: #f3d580;
}

.dlx-blockLink--color5.dlx-blockLinks__item--highlight .dlx-blockLink__anchor {
  background-color: #eab29d;
}

.dlx-blockLink--color6.dlx-blockLinks__item--highlight .dlx-blockLink__anchor {
  background-color: #cacac9;
}

.dlx-blockLink--color1.dlx-blockLinks__item--highlight .dlx-blockLink__anchor:hover {
  background-color: #7695a3;
}

.dlx-blockLink--color2.dlx-blockLinks__item--highlight .dlx-blockLink__anchor:hover {
  background-color: #d1d92b;
}

.dlx-blockLink--color3.dlx-blockLinks__item--highlight .dlx-blockLink__anchor:hover {
  background-color: #778d34;
}

.dlx-blockLink--color4.dlx-blockLinks__item--highlight .dlx-blockLink__anchor:hover {
  background-color: #ebba00;
}

.dlx-blockLink--color5.dlx-blockLinks__item--highlight .dlx-blockLink__anchor:hover {
  background-color: #da7a61;
}

.dlx-blockLink--color6.dlx-blockLinks__item--highlight .dlx-blockLink__anchor:hover {
  background-color: #afafae;
}

.dlx-blockLink__content {
  position: relative;
  min-height: 15rem;
  padding-top: 2rem;
  padding-bottom: 2rem;
}
@media (min-width: 720px) {
  .dlx-blockLink__content {
    padding-top: 3rem;
    padding-bottom: 3rem;
    min-height: 20rem;
  }
}
@media (min-width: 1450px) {
  .dlx-blockLink__content {
    padding-top: 5rem;
    padding-bottom: 5rem;
    min-height: 24rem;
  }
}
.dlx-blockLink__downloadIcon {
  transition: opacity 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94) ;
  color: #7695a3;
  height: 6rem;
  width: 6rem;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}
.dlx-blockLink--highlight .dlx-blockLink__downloadIcon {
  color: #323232;
}
.dlx-blockLink__downloadTitle {
  font-family: Roboto, sans-serif;
  font-size: 1.3rem;
  font-weight: bold;
  letter-spacing: 0.1rem;
  line-height: 1.2307692308;
  position: absolute;
  text-transform: uppercase;
  left: 8rem;
  top: 50%;
  transform: translateY(-50%);
  color: #6a665b;
}
@media (min-width: 720px) {
  .dlx-blockLink__downloadTitle {
    font-size: 1.4rem;
    line-height: 1.2857142857;
  }
}
@media (min-width: 1450px) {
  .dlx-blockLink__downloadTitle {
    font-size: 1.8rem;
    line-height: 1.2222222222;
  }
}
.dlx-blockLink--highlight .dlx-blockLink__downloadTitle {
  color: #323232;
}
.dlx-blockLink__excerpt {
  padding-top: 2rem;
  padding-bottom: 2rem;
}
@media (min-width: 720px) {
  .dlx-blockLink__excerpt {
    padding-bottom: 3rem;
    padding-right: 0;
    width: calc(66.6666666667% + -0.6666666667rem + 0px);
  }
}
@media (min-width: 1024px) {
  .dlx-blockLink__excerpt {
    width: calc(66.6666666667% + -1.0666666667rem + 0px);
  }
}
@media (min-width: 1450px) {
  .dlx-blockLink__excerpt {
    padding-bottom: 5rem;
    width: calc(66.6666666667% + -0.6666666667rem + 0px);
  }
}
.dlx-blockLink__icon {
  transition: color 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94) ;
  color: #7695a3;
  height: 6rem;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 6rem;
}
.dlx-blockLinks__item--highlight .dlx-blockLink__icon {
  color: #323232;
}
.dlx-blockLink--configurator .dlx-blockLink__icon {
  color: #323232;
}
.dlx-blockLink__imageContainer {
  position: relative;
  z-index: 1;
  height: auto;
  overflow: hidden;
  width: 100%;
}
@media (min-width: 720px) {
  .dlx-blockLink__imageContainer {
    width: calc(100% + calc(11.1111111111% + -1.7777777778rem + 2rem) + 2rem);
  }
}
@media (min-width: 1024px) {
  .dlx-blockLink__imageContainer {
    width: calc(100% + calc(16.6666666667% + -2.6666666667rem + 3.2rem) + 3.2rem);
  }
}
@media (min-width: 1450px) {
  .dlx-blockLink__imageContainer {
    width: calc(100% + calc(16.6666666667% + -1.6666666667rem + 2rem) + 2rem);
  }
}
.dlx-blockLink__image {
  position: relative;
  z-index: 1;
  height: auto;
  padding-bottom: 100%;
  width: 100%;
}
@media (min-width: 720px) {
  .dlx-blockLink__image {
    padding-bottom: calc(70% + -0.6rem + 0px);
  }
}
@media (min-width: 1024px) {
  .dlx-blockLink__image {
    padding-bottom: calc(42.8571428571% + -1.8285714286rem + 0px);
  }
}
@media (min-width: 1450px) {
  .dlx-blockLink__image {
    padding-bottom: calc(42.8571428571% + -1.1428571429rem + 0px);
  }
}
.dlx-blockLink__img {
  object-fit: cover;
  font-family: "object-fit: cover";
  display: block;
  height: 100%;
  width: 100%;
  position: absolute;
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  html.no-js .dlx-blockLink__img {
    box-sizing: border-box;
    padding-left: 100%;
    background-size: cover;
    background-position: 50% 50%;
  }
}
.dlx-blockLink__title {
  font-family: Roboto, sans-serif;
  font-size: 2.2rem;
  font-weight: normal;
  line-height: 1.1818181818;
  color: #6a665b;
  padding-right: 8rem;
  position: relative;
}
@media (min-width: 720px) {
  .dlx-blockLink__title {
    font-size: 2.8rem;
    line-height: 1.2857142857;
  }
}
@media (min-width: 1450px) {
  .dlx-blockLink__title {
    font-size: 4rem;
    line-height: 1.1;
  }
}
.dlx-blockLinks__item--highlight .dlx-blockLink__title {
  color: #323232;
}
.dlx-blockLink--configurator .dlx-blockLink__title {
  color: #323232;
}
.dlx-blockLink__subtitle {
  font-family: Roboto, sans-serif;
  font-size: 1.5rem;
  font-weight: normal;
  line-height: 1.3333333333;
  margin-bottom: 1rem;
}
@media (min-width: 720px) {
  .dlx-blockLink__subtitle {
    font-size: 1.6rem;
    line-height: 1.375;
  }
}
@media (min-width: 1450px) {
  .dlx-blockLink__subtitle {
    font-size: 1.8rem;
    line-height: 1.3333333333;
  }
}

.dlx-blockLinks {
  padding-bottom: 2rem;
  padding-top: 2rem;
}
@media (min-width: 1024px) {
  .dlx-blockLinks {
    padding-bottom: 4rem;
    padding-top: 4rem;
  }
}
.dlx-blockLinks__head {
  font-size: 1.8rem;
  line-height: 1.3333333333;
  font-weight: normal;
  font-weight: bold;
  margin-top: 2rem;
  margin-bottom: 1rem;
}
@media (min-width: 720px) {
  .dlx-blockLinks__head {
    font-size: 2rem;
    line-height: 1.4;
  }
}
@media (min-width: 1450px) {
  .dlx-blockLinks__head {
    font-size: 2.4rem;
    line-height: 1.4166666667;
  }
}
@media (min-width: 720px) {
  .dlx-blockLinks__head {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
}
@media (min-width: 1450px) {
  .dlx-blockLinks__head {
    margin-top: 3rem;
    margin-bottom: 3rem;
  }
}
.dlx-blockLinks__item {
  width: 100%;
  margin-left: 0;
}
@media (min-width: 720px) {
  .dlx-blockLinks__item {
    margin-left: calc(16.6666666667% + -1.6666666667rem + 2rem);
    width: calc(75% + -0.5rem + 0px);
  }
}
@media (min-width: 1024px) {
  .dlx-blockLinks__item {
    margin-left: calc(41.6666666667% + -1.8666666667rem + 3.2rem);
    width: calc(50% + -1.6rem + 0px);
  }
}
@media (min-width: 1450px) {
  .dlx-blockLinks__item {
    margin-left: calc(41.6666666667% + -1.1666666667rem + 2rem);
    width: calc(50% + -1rem + 0px);
  }
}
.dlx-blockLinks__list[data-dlx-intersect] .dlx-blockLinks__item:nth-child(0) {
  opacity: 0;
  transform: translateY(2rem);
}
.dlx-blockLinks__list.dlx-js-inside .dlx-blockLinks__item:nth-child(0) {
  transition: opacity 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s, transform 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s;
  opacity: 1;
  transform: translateY(0);
}
.dlx-blockLinks__list[data-dlx-intersect] .dlx-blockLinks__item:nth-child(1) {
  opacity: 0;
  transform: translateY(2rem);
}
.dlx-blockLinks__list.dlx-js-inside .dlx-blockLinks__item:nth-child(1) {
  transition: opacity 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.05s, transform 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.05s;
  opacity: 1;
  transform: translateY(0);
}
.dlx-blockLinks__list[data-dlx-intersect] .dlx-blockLinks__item:nth-child(2) {
  opacity: 0;
  transform: translateY(2rem);
}
.dlx-blockLinks__list.dlx-js-inside .dlx-blockLinks__item:nth-child(2) {
  transition: opacity 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.1s, transform 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.1s;
  opacity: 1;
  transform: translateY(0);
}
.dlx-blockLinks__list[data-dlx-intersect] .dlx-blockLinks__item:nth-child(3) {
  opacity: 0;
  transform: translateY(2rem);
}
.dlx-blockLinks__list.dlx-js-inside .dlx-blockLinks__item:nth-child(3) {
  transition: opacity 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.15s, transform 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.15s;
  opacity: 1;
  transform: translateY(0);
}
.dlx-blockLinks__list[data-dlx-intersect] .dlx-blockLinks__item:nth-child(4) {
  opacity: 0;
  transform: translateY(2rem);
}
.dlx-blockLinks__list.dlx-js-inside .dlx-blockLinks__item:nth-child(4) {
  transition: opacity 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.2s, transform 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.2s;
  opacity: 1;
  transform: translateY(0);
}
.dlx-blockLinks__list[data-dlx-intersect] .dlx-blockLinks__item:nth-child(5) {
  opacity: 0;
  transform: translateY(2rem);
}
.dlx-blockLinks__list.dlx-js-inside .dlx-blockLinks__item:nth-child(5) {
  transition: opacity 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.25s, transform 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.25s;
  opacity: 1;
  transform: translateY(0);
}
.dlx-blockLinks__list[data-dlx-intersect] .dlx-blockLinks__item:nth-child(6) {
  opacity: 0;
  transform: translateY(2rem);
}
.dlx-blockLinks__list.dlx-js-inside .dlx-blockLinks__item:nth-child(6) {
  transition: opacity 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.3s, transform 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.3s;
  opacity: 1;
  transform: translateY(0);
}
.dlx-blockLinks__list[data-dlx-intersect] .dlx-blockLinks__item:nth-child(7) {
  opacity: 0;
  transform: translateY(2rem);
}
.dlx-blockLinks__list.dlx-js-inside .dlx-blockLinks__item:nth-child(7) {
  transition: opacity 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.35s, transform 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.35s;
  opacity: 1;
  transform: translateY(0);
}
.dlx-blockLinks__list[data-dlx-intersect] .dlx-blockLinks__item:nth-child(8) {
  opacity: 0;
  transform: translateY(2rem);
}
.dlx-blockLinks__list.dlx-js-inside .dlx-blockLinks__item:nth-child(8) {
  transition: opacity 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.4s, transform 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.4s;
  opacity: 1;
  transform: translateY(0);
}
.dlx-blockLinks__list[data-dlx-intersect] .dlx-blockLinks__item:nth-child(9) {
  opacity: 0;
  transform: translateY(2rem);
}
.dlx-blockLinks__list.dlx-js-inside .dlx-blockLinks__item:nth-child(9) {
  transition: opacity 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.45s, transform 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.45s;
  opacity: 1;
  transform: translateY(0);
}
.dlx-blockLinks__list[data-dlx-intersect] .dlx-blockLinks__item:nth-child(10) {
  opacity: 0;
  transform: translateY(2rem);
}
.dlx-blockLinks__list.dlx-js-inside .dlx-blockLinks__item:nth-child(10) {
  transition: opacity 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.5s, transform 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.5s;
  opacity: 1;
  transform: translateY(0);
}
.dlx-blockLinks__list[data-dlx-intersect] .dlx-blockLinks__item:nth-child(11) {
  opacity: 0;
  transform: translateY(2rem);
}
.dlx-blockLinks__list.dlx-js-inside .dlx-blockLinks__item:nth-child(11) {
  transition: opacity 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.55s, transform 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.55s;
  opacity: 1;
  transform: translateY(0);
}
.dlx-blockLinks__list[data-dlx-intersect] .dlx-blockLinks__item:nth-child(12) {
  opacity: 0;
  transform: translateY(2rem);
}
.dlx-blockLinks__list.dlx-js-inside .dlx-blockLinks__item:nth-child(12) {
  transition: opacity 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.6s, transform 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.6s;
  opacity: 1;
  transform: translateY(0);
}
.dlx-blockLinks__list[data-dlx-intersect] .dlx-blockLinks__item:nth-child(13) {
  opacity: 0;
  transform: translateY(2rem);
}
.dlx-blockLinks__list.dlx-js-inside .dlx-blockLinks__item:nth-child(13) {
  transition: opacity 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.65s, transform 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.65s;
  opacity: 1;
  transform: translateY(0);
}
.dlx-blockLinks__list[data-dlx-intersect] .dlx-blockLinks__item:nth-child(14) {
  opacity: 0;
  transform: translateY(2rem);
}
.dlx-blockLinks__list.dlx-js-inside .dlx-blockLinks__item:nth-child(14) {
  transition: opacity 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.7s, transform 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.7s;
  opacity: 1;
  transform: translateY(0);
}
.dlx-blockLinks__list[data-dlx-intersect] .dlx-blockLinks__item:nth-child(15) {
  opacity: 0;
  transform: translateY(2rem);
}
.dlx-blockLinks__list.dlx-js-inside .dlx-blockLinks__item:nth-child(15) {
  transition: opacity 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.75s, transform 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.75s;
  opacity: 1;
  transform: translateY(0);
}
.dlx-blockLinks__list[data-dlx-intersect] .dlx-blockLinks__item:nth-child(16) {
  opacity: 0;
  transform: translateY(2rem);
}
.dlx-blockLinks__list.dlx-js-inside .dlx-blockLinks__item:nth-child(16) {
  transition: opacity 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.8s, transform 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.8s;
  opacity: 1;
  transform: translateY(0);
}
.dlx-blockLinks__list[data-dlx-intersect] .dlx-blockLinks__item:nth-child(17) {
  opacity: 0;
  transform: translateY(2rem);
}
.dlx-blockLinks__list.dlx-js-inside .dlx-blockLinks__item:nth-child(17) {
  transition: opacity 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.85s, transform 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.85s;
  opacity: 1;
  transform: translateY(0);
}
.dlx-blockLinks__list[data-dlx-intersect] .dlx-blockLinks__item:nth-child(18) {
  opacity: 0;
  transform: translateY(2rem);
}
.dlx-blockLinks__list.dlx-js-inside .dlx-blockLinks__item:nth-child(18) {
  transition: opacity 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.9s, transform 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.9s;
  opacity: 1;
  transform: translateY(0);
}
.dlx-blockLinks__list[data-dlx-intersect] .dlx-blockLinks__item:nth-child(19) {
  opacity: 0;
  transform: translateY(2rem);
}
.dlx-blockLinks__list.dlx-js-inside .dlx-blockLinks__item:nth-child(19) {
  transition: opacity 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.95s, transform 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.95s;
  opacity: 1;
  transform: translateY(0);
}
.dlx-blockLinks__list[data-dlx-intersect] .dlx-blockLinks__item:nth-child(20) {
  opacity: 0;
  transform: translateY(2rem);
}
.dlx-blockLinks__list.dlx-js-inside .dlx-blockLinks__item:nth-child(20) {
  transition: opacity 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 1s, transform 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 1s;
  opacity: 1;
  transform: translateY(0);
}
.dlx-blockLinks__item--wide {
  width: 100%;
  margin-left: 0;
}
@media (min-width: 720px) {
  .dlx-blockLinks__item--wide {
    margin-left: calc(16.6666666667% + 0.3333333333rem + 0px);
    width: calc(83.3333333333% + -0.3333333333rem + 2rem);
  }
}
@media (min-width: 1024px) {
  .dlx-blockLinks__item--wide {
    margin-left: calc(41.6666666667% + 1.3333333333rem + 0px);
    width: calc(58.3333333333% + -1.3333333333rem + 3.2rem);
  }
}
@media (min-width: 1450px) {
  .dlx-blockLinks__item--wide {
    margin-left: calc(41.6666666667% + 0.8333333333rem + 0px);
    width: calc(58.3333333333% + -0.8333333333rem + 2rem);
  }
}
.dlx-blockLinks__item::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 1px;
  width: 100vw;
  z-index: 2;
  background-color: #ccc5b5;
}
.dlx-blockLinks__item:last-of-type::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  height: 1px;
  width: 100vw;
  z-index: 2;
  background-color: #ccc5b5;
}
.dlx-blockLinks__item--highlight::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  height: 1px;
  width: 100vw;
  background-color: #323232;
  z-index: 1;
}
.dlx-blockLinks__item--highlight::before {
  display: none;
}
.dlx-blockLinks__item--highlight:last-of-type::after {
  display: none;
}
.dlx-blockNonExisting {
  margin-top: 26rem;
  margin-bottom: 12rem;
}

.dlx-blockPeople {
  padding-bottom: 2rem;
  padding-top: 2rem;
}
@media (min-width: 1024px) {
  .dlx-blockPeople {
    padding-bottom: 4rem;
    padding-top: 4rem;
  }
}
.dlx-blockPeople__image {
  width: 100%;
}
.dlx-blockPeople__item {
  width: 100%;
  margin-left: 0;
}
@media (min-width: 720px) {
  .dlx-blockPeople__item {
    margin-left: calc(16.6666666667% + -1.6666666667rem + 2rem);
    width: calc(75% + -0.5rem + 0px);
  }
}
@media (min-width: 1024px) {
  .dlx-blockPeople__item {
    margin-left: calc(41.6666666667% + -1.8666666667rem + 3.2rem);
    width: calc(50% + -1.6rem + 0px);
  }
}
@media (min-width: 1450px) {
  .dlx-blockPeople__item {
    margin-left: calc(41.6666666667% + -1.1666666667rem + 2rem);
    width: calc(50% + -1rem + 0px);
  }
}
.dlx-blockPeople__list[data-dlx-intersect] .dlx-blockPeople__item:nth-child(0) {
  opacity: 0;
  transform: translateY(2rem);
}
.dlx-blockPeople__list.dlx-js-inside .dlx-blockPeople__item:nth-child(0) {
  transition: opacity 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) -0.2s, transform 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) -0.2s;
  opacity: 1;
  transform: translateY(0);
}
.dlx-blockPeople__list[data-dlx-intersect] .dlx-blockPeople__item:nth-child(1) {
  opacity: 0;
  transform: translateY(2rem);
}
.dlx-blockPeople__list.dlx-js-inside .dlx-blockPeople__item:nth-child(1) {
  transition: opacity 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s, transform 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s;
  opacity: 1;
  transform: translateY(0);
}
.dlx-blockPeople__list[data-dlx-intersect] .dlx-blockPeople__item:nth-child(2) {
  opacity: 0;
  transform: translateY(2rem);
}
.dlx-blockPeople__list.dlx-js-inside .dlx-blockPeople__item:nth-child(2) {
  transition: opacity 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.2s, transform 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.2s;
  opacity: 1;
  transform: translateY(0);
}
.dlx-blockPeople__list[data-dlx-intersect] .dlx-blockPeople__item:nth-child(3) {
  opacity: 0;
  transform: translateY(2rem);
}
.dlx-blockPeople__list.dlx-js-inside .dlx-blockPeople__item:nth-child(3) {
  transition: opacity 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.4s, transform 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.4s;
  opacity: 1;
  transform: translateY(0);
}
.dlx-blockPeople__list[data-dlx-intersect] .dlx-blockPeople__item:nth-child(4) {
  opacity: 0;
  transform: translateY(2rem);
}
.dlx-blockPeople__list.dlx-js-inside .dlx-blockPeople__item:nth-child(4) {
  transition: opacity 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.6s, transform 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.6s;
  opacity: 1;
  transform: translateY(0);
}
.dlx-blockPeople__list[data-dlx-intersect] .dlx-blockPeople__item:nth-child(5) {
  opacity: 0;
  transform: translateY(2rem);
}
.dlx-blockPeople__list.dlx-js-inside .dlx-blockPeople__item:nth-child(5) {
  transition: opacity 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.8s, transform 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.8s;
  opacity: 1;
  transform: translateY(0);
}
.dlx-blockPeople__list[data-dlx-intersect] .dlx-blockPeople__item:nth-child(6) {
  opacity: 0;
  transform: translateY(2rem);
}
.dlx-blockPeople__list.dlx-js-inside .dlx-blockPeople__item:nth-child(6) {
  transition: opacity 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 1s, transform 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 1s;
  opacity: 1;
  transform: translateY(0);
}
.dlx-blockPeople__list[data-dlx-intersect] .dlx-blockPeople__item:nth-child(7) {
  opacity: 0;
  transform: translateY(2rem);
}
.dlx-blockPeople__list.dlx-js-inside .dlx-blockPeople__item:nth-child(7) {
  transition: opacity 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 1.2s, transform 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 1.2s;
  opacity: 1;
  transform: translateY(0);
}
.dlx-blockPeople__list[data-dlx-intersect] .dlx-blockPeople__item:nth-child(8) {
  opacity: 0;
  transform: translateY(2rem);
}
.dlx-blockPeople__list.dlx-js-inside .dlx-blockPeople__item:nth-child(8) {
  transition: opacity 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 1.4s, transform 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 1.4s;
  opacity: 1;
  transform: translateY(0);
}
.dlx-blockPeople__list[data-dlx-intersect] .dlx-blockPeople__item:nth-child(9) {
  opacity: 0;
  transform: translateY(2rem);
}
.dlx-blockPeople__list.dlx-js-inside .dlx-blockPeople__item:nth-child(9) {
  transition: opacity 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 1.6s, transform 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 1.6s;
  opacity: 1;
  transform: translateY(0);
}
.dlx-blockPeople__list[data-dlx-intersect] .dlx-blockPeople__item:nth-child(10) {
  opacity: 0;
  transform: translateY(2rem);
}
.dlx-blockPeople__list.dlx-js-inside .dlx-blockPeople__item:nth-child(10) {
  transition: opacity 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 1.8s, transform 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 1.8s;
  opacity: 1;
  transform: translateY(0);
}
.dlx-blockPeople__list[data-dlx-intersect] .dlx-blockPeople__item:nth-child(11) {
  opacity: 0;
  transform: translateY(2rem);
}
.dlx-blockPeople__list.dlx-js-inside .dlx-blockPeople__item:nth-child(11) {
  transition: opacity 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 2s, transform 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 2s;
  opacity: 1;
  transform: translateY(0);
}
.dlx-blockPeople__list[data-dlx-intersect] .dlx-blockPeople__item:nth-child(12) {
  opacity: 0;
  transform: translateY(2rem);
}
.dlx-blockPeople__list.dlx-js-inside .dlx-blockPeople__item:nth-child(12) {
  transition: opacity 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 2.2s, transform 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 2.2s;
  opacity: 1;
  transform: translateY(0);
}
.dlx-blockPeople__list[data-dlx-intersect] .dlx-blockPeople__item:nth-child(13) {
  opacity: 0;
  transform: translateY(2rem);
}
.dlx-blockPeople__list.dlx-js-inside .dlx-blockPeople__item:nth-child(13) {
  transition: opacity 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 2.4s, transform 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 2.4s;
  opacity: 1;
  transform: translateY(0);
}
.dlx-blockPeople__list[data-dlx-intersect] .dlx-blockPeople__item:nth-child(14) {
  opacity: 0;
  transform: translateY(2rem);
}
.dlx-blockPeople__list.dlx-js-inside .dlx-blockPeople__item:nth-child(14) {
  transition: opacity 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 2.6s, transform 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 2.6s;
  opacity: 1;
  transform: translateY(0);
}
.dlx-blockPeople__list[data-dlx-intersect] .dlx-blockPeople__item:nth-child(15) {
  opacity: 0;
  transform: translateY(2rem);
}
.dlx-blockPeople__list.dlx-js-inside .dlx-blockPeople__item:nth-child(15) {
  transition: opacity 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 2.8s, transform 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 2.8s;
  opacity: 1;
  transform: translateY(0);
}
.dlx-blockPeople__list[data-dlx-intersect] .dlx-blockPeople__item:nth-child(16) {
  opacity: 0;
  transform: translateY(2rem);
}
.dlx-blockPeople__list.dlx-js-inside .dlx-blockPeople__item:nth-child(16) {
  transition: opacity 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 3s, transform 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 3s;
  opacity: 1;
  transform: translateY(0);
}
.dlx-blockPeople__list[data-dlx-intersect] .dlx-blockPeople__item:nth-child(17) {
  opacity: 0;
  transform: translateY(2rem);
}
.dlx-blockPeople__list.dlx-js-inside .dlx-blockPeople__item:nth-child(17) {
  transition: opacity 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 3.2s, transform 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 3.2s;
  opacity: 1;
  transform: translateY(0);
}
.dlx-blockPeople__list[data-dlx-intersect] .dlx-blockPeople__item:nth-child(18) {
  opacity: 0;
  transform: translateY(2rem);
}
.dlx-blockPeople__list.dlx-js-inside .dlx-blockPeople__item:nth-child(18) {
  transition: opacity 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 3.4s, transform 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 3.4s;
  opacity: 1;
  transform: translateY(0);
}
.dlx-blockPeople__list[data-dlx-intersect] .dlx-blockPeople__item:nth-child(19) {
  opacity: 0;
  transform: translateY(2rem);
}
.dlx-blockPeople__list.dlx-js-inside .dlx-blockPeople__item:nth-child(19) {
  transition: opacity 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 3.6s, transform 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 3.6s;
  opacity: 1;
  transform: translateY(0);
}
.dlx-blockPeople__list[data-dlx-intersect] .dlx-blockPeople__item:nth-child(20) {
  opacity: 0;
  transform: translateY(2rem);
}
.dlx-blockPeople__list.dlx-js-inside .dlx-blockPeople__item:nth-child(20) {
  transition: opacity 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 3.8s, transform 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 3.8s;
  opacity: 1;
  transform: translateY(0);
}
.dlx-blockPeople__item--wide {
  width: 100%;
  margin-left: 0;
}
@media (min-width: 720px) {
  .dlx-blockPeople__item--wide {
    margin-left: calc(16.6666666667% + 0.3333333333rem + 0px);
    width: calc(83.3333333333% + -0.3333333333rem + 2rem);
  }
}
@media (min-width: 1024px) {
  .dlx-blockPeople__item--wide {
    margin-left: calc(41.6666666667% + 1.3333333333rem + 0px);
    width: calc(58.3333333333% + -1.3333333333rem + 3.2rem);
  }
}
@media (min-width: 1450px) {
  .dlx-blockPeople__item--wide {
    margin-left: calc(41.6666666667% + 0.8333333333rem + 0px);
    width: calc(58.3333333333% + -0.8333333333rem + 2rem);
  }
}
.dlx-blockPeople__img {
  width: 100%;
}
.dlx-blockPeople__list {
  position: relative;
}

.dlx-blockSignUp {
  position: relative;
  padding-top: 2rem;
  padding-bottom: 1rem;
}
.dlx-blockSignUp::before {
  background-color: #ffffff;
  content: "";
  position: absolute;
  top: 0;
  width: 100vw;
  height: 100%;
  z-index: -1;
  left: -1rem;
}
@media (min-width: 720px) {
  .dlx-blockSignUp::before {
    left: -2rem;
  }
}
@media (min-width: 1024px) {
  .dlx-blockSignUp::before {
    left: calc(16.6666666667% + -2.6666666667rem + -2.8rem);
  }
}
@media (min-width: 1450px) {
  .dlx-blockSignUp::before {
    left: calc(16.6666666667% + -1.6666666667rem + -4rem);
  }
}
@media (min-width: 720px) {
  .dlx-blockSignUp {
    padding-top: 3rem;
    padding-bottom: 2rem;
  }
}
@media (min-width: 1450px) {
  .dlx-blockSignUp {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }
}
.dlx-blockSignUp__content {
  width: 100%;
  margin-left: 0;
}
.dlx-blockSignUp__content[data-dlx-intersect] {
  opacity: 0;
  transform: translateY(2rem);
}
.dlx-blockSignUp__content.dlx-js-inside {
  transition: opacity 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s, transform 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s;
  opacity: 1;
  transform: translateY(0);
}
@media (min-width: 720px) {
  .dlx-blockSignUp__content {
    margin-left: calc(16.6666666667% + -1.6666666667rem + 2rem);
    width: calc(75% + -0.5rem + 0px);
  }
}
@media (min-width: 1024px) {
  .dlx-blockSignUp__content {
    margin-left: calc(41.6666666667% + -1.8666666667rem + 3.2rem);
    width: calc(50% + -1.6rem + 0px);
  }
}
@media (min-width: 1450px) {
  .dlx-blockSignUp__content {
    margin-left: calc(41.6666666667% + -1.1666666667rem + 2rem);
    width: calc(50% + -1rem + 0px);
  }
}

.dlx-blockTeaser {
  overflow: hidden;
  position: relative;
  width: 100%;
}
.dlx-block:last-of-type .dlx-blockTeaser {
  margin-bottom: -8rem;
}
.dlx-blockTeaser__container {
  padding-left: 1rem;
  padding-right: 1rem;
  width: 100%;
  color: #ffffff;
  margin-bottom: 4rem;
  margin-top: 2rem;
}
@media (min-width: 720px) {
  .dlx-blockTeaser__container {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
@media (min-width: 1024px) {
  .dlx-blockTeaser__container {
    padding-left: 3.2rem;
    padding-right: 3.2rem;
  }
}
@media (min-width: 1450px) {
  .dlx-blockTeaser__container {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
@media (min-width: 720px) {
  .dlx-blockTeaser__container {
    margin-bottom: 5rem;
    margin-top: 4rem;
  }
}
@media (min-width: 1024px) {
  .dlx-blockTeaser__container {
    margin-bottom: 8rem;
    margin-top: 6rem;
  }
}
@media (min-width: 1450px) {
  .dlx-blockTeaser__container {
    margin-top: 9rem;
    margin-bottom: 10rem;
  }
}
.dlx-blockTeaser__container::after {
  content: "";
  background-color: rgba(0, 0, 0, 0.2);
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  right: 0;
  top: 0;
  z-index: -1;
  transition: background-color 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) ;
}
.dlx-blockTeaser:hover .dlx-blockTeaser__container::after {
  background-color: rgba(0, 0, 0, 0.4);
}
.dlx-blockTeaser__content {
  width: 100%;
  margin-left: 0;
  position: relative;
  z-index: 1;
}
@media (min-width: 720px) {
  .dlx-blockTeaser__content {
    margin-left: 0;
    width: calc(75% + -0.5rem + 0px);
  }
}
@media (min-width: 1024px) {
  .dlx-blockTeaser__content {
    margin-left: calc(41.6666666667% + -1.8666666667rem + 3.2rem);
    width: calc(50% + -1.6rem + 0px);
  }
}
@media (min-width: 1450px) {
  .dlx-blockTeaser__content {
    margin-left: calc(41.6666666667% + -1.1666666667rem + 2rem);
    width: calc(50% + -1rem + 0px);
  }
}
.dlx-blockTeaser__icon {
  color: #ffffff;
  display: block;
  height: 6rem;
  margin-bottom: 8rem;
  margin-top: 2rem;
  width: 6rem;
}
.dlx-blockTeaser[data-dlx-intersect] .dlx-blockTeaser__icon {
  opacity: 0;
  transform: translateY(2rem);
}
.dlx-blockTeaser.dlx-js-inside .dlx-blockTeaser__icon {
  transition: opacity 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.4s, transform 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.4s;
  opacity: 1;
  transform: translateY(0);
}
@media (min-width: 720px) {
  .dlx-blockTeaser__icon {
    margin-bottom: 11rem;
    margin-top: 3rem;
  }
}
@media (min-width: 1024px) {
  .dlx-blockTeaser__icon {
    display: none;
  }
}
.dlx-blockTeaser__image {
  display: block;
  height: 100%;
  margin-left: -10%;
  margin-top: 0;
  position: relative;
  width: 130%;
  z-index: -1;
}
.dlx-blockTeaser__imageContainer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}
.dlx-blockTeaser__img {
  object-fit: cover;
  font-family: "object-fit: cover";
  display: block;
  height: 100%;
  width: 100%;
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  html.no-js .dlx-blockTeaser__img {
    box-sizing: border-box;
    padding-left: 100%;
    background-size: cover;
    background-position: 50% 50%;
  }
}
.dlx-blockTeaser__marginContent {
  width: 100%;
}
@media (min-width: 720px) {
  .dlx-blockTeaser__marginContent {
    margin-left: calc(16.6666666667% + 0.3333333333rem + 0px);
    width: calc(50% + -1rem + 0px);
  }
}
@media (min-width: 1024px) {
  .dlx-blockTeaser__marginContent {
    margin-left: calc(16.6666666667% + 0.5333333333rem + 0px);
    width: calc(25% + -2.4rem + 0px);
  }
}
@media (min-width: 1450px) {
  .dlx-blockTeaser__marginContent {
    margin-left: calc(16.6666666667% + 0.3333333333rem + 0px);
    width: calc(16.6666666667% + -1.6666666667rem + 0px);
  }
}
@media (min-width: 1024px) {
  .dlx-blockTeaser__marginContent {
    float: left;
  }
}
.dlx-blockTeaser__text {
  font-family: Roboto, sans-serif;
  font-size: 2.2rem;
  font-weight: normal;
  line-height: 1.1818181818;
}
@media (min-width: 720px) {
  .dlx-blockTeaser__text {
    font-size: 2.8rem;
    line-height: 1.2857142857;
  }
}
@media (min-width: 1450px) {
  .dlx-blockTeaser__text {
    font-size: 4rem;
    line-height: 1.1;
  }
}
.dlx-blockTeaser[data-dlx-intersect] .dlx-blockTeaser__text {
  opacity: 0;
  transform: translateY(2rem);
}
.dlx-blockTeaser.dlx-js-inside .dlx-blockTeaser__text {
  transition: opacity 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.4s, transform 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.4s;
  opacity: 1;
  transform: translateY(0);
}
.dlx-blockTeaser__title {
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-size: 3rem;
  font-weight: 500;
  line-height: 1.1666666667;
  margin-bottom: 2rem;
}
.dlx-blockTeaser[data-dlx-intersect] .dlx-blockTeaser__title {
  opacity: 0;
  transform: translateY(2rem);
}
.dlx-blockTeaser.dlx-js-inside .dlx-blockTeaser__title {
  transition: opacity 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.3s, transform 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.3s;
  opacity: 1;
  transform: translateY(0);
}
@media (min-width: 720px) {
  .dlx-blockTeaser__title {
    font-size: 4.5rem;
    line-height: 1.2222222222;
  }
}
@media (min-width: 1450px) {
  .dlx-blockTeaser__title {
    font-size: 7rem;
    line-height: 1.1142857143;
  }
}
@media (min-width: 1024px) {
  .dlx-blockTeaser__title {
    margin-bottom: 20rem;
  }
}
@media (min-width: 1450px) {
  .dlx-blockTeaser__title {
    margin-bottom: 25rem;
  }
}
.dlx-blockTeaser__subtitle {
  font-family: Roboto, sans-serif;
  font-size: 1.3rem;
  font-weight: bold;
  letter-spacing: 0.1rem;
  line-height: 1.2307692308;
  color: #ece9e5;
  display: inline-block;
  margin-bottom: 1rem;
  margin-right: 2rem;
  position: relative;
  vertical-align: top;
}
@media (min-width: 720px) {
  .dlx-blockTeaser__subtitle {
    font-size: 1.4rem;
    line-height: 1.2857142857;
  }
}
@media (min-width: 1450px) {
  .dlx-blockTeaser__subtitle {
    font-size: 1.8rem;
    line-height: 1.2222222222;
  }
}
.dlx-blockTeaser[data-dlx-intersect] .dlx-blockTeaser__subtitle {
  opacity: 0;
  transform: translateY(2rem);
}
.dlx-blockTeaser.dlx-js-inside .dlx-blockTeaser__subtitle {
  transition: opacity 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.2s, transform 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.2s;
  opacity: 1;
  transform: translateY(0);
}
.dlx-blockTeaser__content .dlx-blockTeaser__subtitle:first-of-type {
  display: inline-block;
}
@media (min-width: 1024px) {
  .dlx-blockTeaser__content .dlx-blockTeaser__subtitle:first-of-type {
    display: none;
  }
}
.dlx-blockTeaser__content .dlx-blockTeaser__subtitle:first-of-type::before {
  display: none;
}
.dlx-blockTeaser__content .dlx-blockTeaser__subtitle::before {
  content: "";
  background-color: #ffffff;
  width: 2px;
  height: 2rem;
  position: absolute;
  left: -1.5rem;
  top: -0.25rem;
}
@media (min-width: 1024px) {
  .dlx-blockTeaser__content .dlx-blockTeaser__subtitle::before {
    display: none;
  }
}
.dlx-blockTeaser__marginContent .dlx-blockTeaser__subtitle {
  display: none;
}
@media (min-width: 1024px) {
  .dlx-blockTeaser__marginContent .dlx-blockTeaser__subtitle {
    display: block;
  }
}

.dlx-blockText {
  width: 100%;
  margin-left: 0;
  margin-bottom: 3rem;
  margin-top: 3rem;
  position: relative;
}
@media (min-width: 720px) {
  .dlx-blockText {
    margin-left: calc(16.6666666667% + -1.6666666667rem + 2rem);
    width: calc(75% + -0.5rem + 0px);
  }
}
@media (min-width: 1024px) {
  .dlx-blockText {
    margin-left: calc(41.6666666667% + -1.8666666667rem + 3.2rem);
    width: calc(50% + -1.6rem + 0px);
  }
}
@media (min-width: 1450px) {
  .dlx-blockText {
    margin-left: calc(41.6666666667% + -1.1666666667rem + 2rem);
    width: calc(50% + -1rem + 0px);
  }
}
@media (min-width: 1450px) {
  .dlx-blockText {
    margin-top: 6rem;
    margin-bottom: 6rem;
  }
}

.dlx-blockTitleText {
  padding-left: 1rem;
  padding-right: 1rem;
  width: 100%;
  clear: both;
  padding-bottom: 2rem;
  padding-top: 2rem;
  position: relative;
}
.dlx-blockTitleText--color1 {
  background-color: #c9dfe2;
}
.dlx-blockTitleText--color2 {
  background-color: #e3e791;
}
.dlx-blockTitleText--color3 {
  background-color: #b1c077;
}
.dlx-blockTitleText--color4 {
  background-color: #f3d580;
}
.dlx-blockTitleText--color5 {
  background-color: #eab29d;
}
.dlx-blockTitleText--color6 {
  background-color: #cacac9;
}
@media (min-width: 720px) {
  .dlx-blockTitleText {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
@media (min-width: 1024px) {
  .dlx-blockTitleText {
    padding-left: 3.2rem;
    padding-right: 3.2rem;
  }
}
@media (min-width: 1450px) {
  .dlx-blockTitleText {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
@media (min-width: 1024px) {
  .dlx-blockTitleText {
    padding-bottom: 4rem;
    padding-top: 4rem;
  }
}
.dlx-blockTitleText__text {
  width: 100%;
  margin-left: 0;
}
@media (min-width: 720px) {
  .dlx-blockTitleText__text {
    margin-left: calc(16.6666666667% + -1.6666666667rem + 2rem);
    width: calc(75% + -0.5rem + 0px);
  }
}
@media (min-width: 1024px) {
  .dlx-blockTitleText__text {
    margin-left: calc(41.6666666667% + -1.8666666667rem + 3.2rem);
    width: calc(50% + -1.6rem + 0px);
  }
}
@media (min-width: 1450px) {
  .dlx-blockTitleText__text {
    margin-left: calc(41.6666666667% + -1.1666666667rem + 2rem);
    width: calc(50% + -1rem + 0px);
  }
}
.dlx-blockTitleText__content {
  transition: opacity 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) ;
  padding-bottom: 3rem;
  padding-top: 3rem;
}
.dlx-blockTitleText__content.dlx-js-collapsed {
  opacity: 0;
  position: absolute;
}
.dlx-blockTitleText__header {
  font-family: Roboto, sans-serif;
  font-size: 1.5rem;
  font-weight: normal;
  line-height: 1.3333333333;
  color: #6a665b;
  cursor: pointer;
  font-weight: bold;
  padding-bottom: 3rem;
  padding-top: 3rem;
  position: relative;
  text-transform: uppercase;
}
@media (min-width: 720px) {
  .dlx-blockTitleText__header {
    font-size: 1.6rem;
    line-height: 1.375;
  }
}
@media (min-width: 1450px) {
  .dlx-blockTitleText__header {
    font-size: 1.8rem;
    line-height: 1.3333333333;
  }
}
.dlx-blockTitleText__icon {
  display: inline-block;
  opacity: 1;
  position: absolute;
  right: 0;
  top: 50%;
  margin-top: 0.25rem;
  transform: translateY(-50%);
  vertical-align: top;
}
.dlx-blockTitleText__icon svg {
  fill: #6a665b;
  transition: transform 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94) ;
}
.dlx-blockTitleText__togglerLink.dlx-js-toggled .dlx-blockTitleText__icon svg {
  transform: scaleY(-1);
}
.dlx-blockTitleText__inner {
  width: 100%;
  margin-left: 0;
  overflow: hidden;
  position: relative;
  transition: height 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) ;
}
@media (min-width: 720px) {
  .dlx-blockTitleText__inner {
    margin-left: calc(16.6666666667% + -1.6666666667rem + 2rem);
    width: calc(75% + -0.5rem + 0px);
  }
}
@media (min-width: 1024px) {
  .dlx-blockTitleText__inner {
    margin-left: calc(41.6666666667% + -1.8666666667rem + 3.2rem);
    width: calc(50% + -1.6rem + 0px);
  }
}
@media (min-width: 1450px) {
  .dlx-blockTitleText__inner {
    margin-left: calc(41.6666666667% + -1.1666666667rem + 2rem);
    width: calc(50% + -1rem + 0px);
  }
}
@media (min-width: 1024px) {
  .dlx-blockTitleText__inner {
    padding-bottom: 8rem;
  }
}
.dlx-blockTitleText__togglerLink {
  position: relative;
  width: 100%;
  color: #323232;
  text-decoration: none;
  margin-top: -1px;
  text-align: left;
}
.dlx-blockTitleText__togglerLink::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 1px;
  width: 100vw;
  background-color: #ccc5b5;
}
.dlx-blockTitleText__togglerLink:last-of-type::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  height: 1px;
  width: 100vw;
  background-color: #ccc5b5;
}

.dlx-caseStudy {
  padding-left: 1rem;
  padding-right: 1rem;
  width: 100%;
}
@media (min-width: 720px) {
  .dlx-caseStudy {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
@media (min-width: 1024px) {
  .dlx-caseStudy {
    padding-left: 3.2rem;
    padding-right: 3.2rem;
  }
}
@media (min-width: 1450px) {
  .dlx-caseStudy {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
.dlx-caseStudy__header {
  padding-bottom: 1rem;
}
.dlx-caseStudy__head {
  width: 100%;
  margin-left: 0;
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-size: 3rem;
  font-weight: 500;
  line-height: 1.1666666667;
  padding-top: 7rem;
  margin-bottom: 5rem;
}
@media (min-width: 720px) {
  .dlx-caseStudy__head {
    margin-left: 0;
    width: calc(75% + -0.5rem + 0px);
  }
}
@media (min-width: 1024px) {
  .dlx-caseStudy__head {
    margin-left: calc(41.6666666667% + -1.8666666667rem + 3.2rem);
    width: calc(50% + -1.6rem + 0px);
  }
}
@media (min-width: 1450px) {
  .dlx-caseStudy__head {
    margin-left: calc(41.6666666667% + -1.1666666667rem + 2rem);
    width: calc(50% + -1rem + 0px);
  }
}
@media (min-width: 720px) {
  .dlx-caseStudy__head {
    font-size: 4.5rem;
    line-height: 1.2222222222;
  }
}
@media (min-width: 1450px) {
  .dlx-caseStudy__head {
    font-size: 7rem;
    line-height: 1.1142857143;
  }
}
@media (min-width: 1024px) {
  .dlx-caseStudy__head {
    padding-top: 12rem;
    margin-bottom: 8rem;
  }
}
@media (min-width: 1450px) {
  .dlx-caseStudy__head {
    margin-bottom: 12rem;
  }
}
.dlx-caseStudy__subhead {
  width: 100%;
  margin-left: 0;
  font-family: Roboto, sans-serif;
  font-size: 1.3rem;
  font-weight: bold;
  letter-spacing: 0.1rem;
  line-height: 1.2307692308;
  color: #6a665b;
  margin-bottom: 1rem;
  text-transform: uppercase;
}
.dlx-caseStudy[data-dlx-intersect] .dlx-caseStudy__subhead {
  opacity: 0;
  transform: translateY(2rem);
}
.dlx-caseStudy.dlx-js-inside .dlx-caseStudy__subhead {
  transition: opacity 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s, transform 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s;
  opacity: 1;
  transform: translateY(0);
}
@media (min-width: 720px) {
  .dlx-caseStudy__subhead {
    margin-left: calc(16.6666666667% + -1.6666666667rem + 2rem);
    width: calc(75% + -0.5rem + 0px);
  }
}
@media (min-width: 1024px) {
  .dlx-caseStudy__subhead {
    margin-left: calc(41.6666666667% + -1.8666666667rem + 3.2rem);
    width: calc(50% + -1.6rem + 0px);
  }
}
@media (min-width: 1450px) {
  .dlx-caseStudy__subhead {
    margin-left: calc(41.6666666667% + -1.1666666667rem + 2rem);
    width: calc(50% + -1rem + 0px);
  }
}
@media (min-width: 720px) {
  .dlx-caseStudy__subhead {
    font-size: 1.4rem;
    line-height: 1.2857142857;
  }
}
@media (min-width: 1450px) {
  .dlx-caseStudy__subhead {
    font-size: 1.8rem;
    line-height: 1.2222222222;
  }
}
.dlx-caseStudy__lead {
  width: 100%;
  margin-left: 0;
  color: #323232;
}
.dlx-caseStudy[data-dlx-intersect] .dlx-caseStudy__lead {
  opacity: 0;
  transform: translateY(2rem);
}
.dlx-caseStudy.dlx-js-inside .dlx-caseStudy__lead {
  transition: opacity 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s, transform 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s;
  opacity: 1;
  transform: translateY(0);
}
@media (min-width: 720px) {
  .dlx-caseStudy__lead {
    margin-left: calc(16.6666666667% + -1.6666666667rem + 2rem);
    width: calc(75% + -0.5rem + 0px);
  }
}
@media (min-width: 1024px) {
  .dlx-caseStudy__lead {
    margin-left: calc(41.6666666667% + -1.8666666667rem + 3.2rem);
    width: calc(50% + -1.6rem + 0px);
  }
}
@media (min-width: 1450px) {
  .dlx-caseStudy__lead {
    margin-left: calc(41.6666666667% + -1.1666666667rem + 2rem);
    width: calc(50% + -1rem + 0px);
  }
}

.dlx-checkbox {
  font-family: Roboto, sans-serif;
  font-size: 1.5rem;
  font-weight: normal;
  line-height: 1.3333333333;
  cursor: pointer;
  display: block;
}
@media (min-width: 720px) {
  .dlx-checkbox {
    font-size: 1.6rem;
    line-height: 1.375;
  }
}
@media (min-width: 1450px) {
  .dlx-checkbox {
    font-size: 1.8rem;
    line-height: 1.3333333333;
  }
}
.dlx-checkbox__checkbox {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
  overflow: hidden;
  clip: rect(0 0 0 0);
}
.dlx-checkbox__label {
  margin-left: 3rem;
  display: block;
}
.dlx-checkbox__label a {
  text-decoration: underline;
}
.dlx-checkbox__off {
  display: inline-block;
  float: left;
}
.dlx-checkbox__checkbox:checked ~ .dlx-checkbox__off {
  display: none;
}
.dlx-checkbox__on {
  display: none;
  float: left;
}
.dlx-checkbox__checkbox:checked ~ .dlx-checkbox__on {
  display: inline-block;
}

.dlx-contactPersonWithImage {
  position: relative;
}
.dlx-contactPersonWithImage::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 1px;
  width: 100vw;
  background-color: #ccc5b5;
}
.dlx-contactPersonWithImage:last-of-type::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  height: 1px;
  width: 100vw;
  background-color: #ccc5b5;
}
.dlx-contactPersonWithImage__name {
  font-family: Roboto, sans-serif;
  font-size: 2.2rem;
  font-weight: normal;
  line-height: 1.1818181818;
  color: #6a665b;
  margin-bottom: 1rem;
  margin-right: 8rem;
  margin-top: 0.5rem;
}
@media (min-width: 720px) {
  .dlx-contactPersonWithImage__name {
    font-size: 2.8rem;
    line-height: 1.2857142857;
  }
}
@media (min-width: 1450px) {
  .dlx-contactPersonWithImage__name {
    font-size: 4rem;
    line-height: 1.1;
  }
}
@media (min-width: 720px) {
  .dlx-contactPersonWithImage__name {
    margin-bottom: 2rem;
  }
}
@media (min-width: 1450px) {
  .dlx-contactPersonWithImage__name {
    margin-bottom: 3rem;
    margin-top: 1rem;
  }
}
.dlx-contactPersonWithImage__row {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  margin-bottom: 1rem;
}
.dlx-contactPersonWithImage__row > * {
  flex-grow: 0;
  flex-shrink: 0;
}
.dlx-contactPersonWithImage__row:last-of-type {
  margin-bottom: 2rem;
}
.dlx-contactPersonWithImage__rowName {
  vertical-align: middle;
  font-family: Roboto, sans-serif;
  font-size: 1.3rem;
  font-weight: bold;
  letter-spacing: 0.1rem;
  line-height: 1.2307692308;
  color: #ccc5b5;
  margin-top: 0.5rem;
  width: calc(16.6666666667% + -1.6666666667rem + 2rem);
}
@media (min-width: 720px) {
  .dlx-contactPersonWithImage__rowName {
    font-size: 1.4rem;
    line-height: 1.2857142857;
  }
}
@media (min-width: 1450px) {
  .dlx-contactPersonWithImage__rowName {
    font-size: 1.8rem;
    line-height: 1.2222222222;
  }
}
@media (min-width: 720px) {
  .dlx-contactPersonWithImage__rowName {
    margin-top: 0.75rem;
    width: calc(22.2222222222% + -1.5555555556rem + 2rem);
  }
}
@media (min-width: 1024px) {
  .dlx-contactPersonWithImage__rowName {
    margin-top: 1rem;
    width: calc(25% + -2.4rem + 3.2rem);
  }
}
@media (min-width: 1450px) {
  .dlx-contactPersonWithImage__rowName {
    width: calc(25% + -1.5rem + 2rem);
  }
}
.dlx-contactPersonWithImage__rowValue {
  font-size: 1.8rem;
  line-height: 1.3333333333;
  font-weight: normal;
  vertical-align: middle;
  width: calc(83.3333333333% + -0.3333333333rem + 0px);
}
@media (min-width: 720px) {
  .dlx-contactPersonWithImage__rowValue {
    font-size: 2rem;
    line-height: 1.4;
  }
}
@media (min-width: 1450px) {
  .dlx-contactPersonWithImage__rowValue {
    font-size: 2.4rem;
    line-height: 1.4166666667;
  }
}
@media (min-width: 720px) {
  .dlx-contactPersonWithImage__rowValue {
    width: calc(77.7777777778% + -0.4444444444rem + 0px);
  }
}
@media (min-width: 1024px) {
  .dlx-contactPersonWithImage__rowValue {
    width: calc(75% + -0.8rem + 0px);
  }
}
@media (min-width: 1450px) {
  .dlx-contactPersonWithImage__rowValue {
    width: calc(75% + -0.5rem + 0px);
  }
}
.dlx-contactPersonWithImage__columns {
  position: relative;
  min-height: 15rem;
  padding-top: 2rem;
  padding-bottom: 1rem;
}
@media (min-width: 720px) {
  .dlx-contactPersonWithImage__columns {
    padding-top: 3rem;
    padding-bottom: 2rem;
    min-height: 20rem;
  }
}
@media (min-width: 1024px) {
  .dlx-contactPersonWithImage__columns {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 0;
  }
  .dlx-contactPersonWithImage__columns > * {
    flex-grow: 0;
    flex-shrink: 0;
    margin-top: 0;
  }
}
@media (min-width: 1450px) {
  .dlx-contactPersonWithImage__columns {
    padding-top: 5rem;
    padding-bottom: 5rem;
    min-height: 24rem;
  }
}
.dlx-contactPersonWithImage__columnLeft {
  width: 100%;
  margin-left: 0;
}
@media (min-width: 720px) {
  .dlx-contactPersonWithImage__columnLeft {
    padding-right: 2rem;
    width: calc(90% + -0.2rem + 0px);
  }
}
@media (min-width: 1024px) {
  .dlx-contactPersonWithImage__columnLeft {
    padding-right: 3.2rem;
    width: calc(57.1428571429% + -1.3714285714rem + 3.2rem);
  }
}
@media (min-width: 1450px) {
  .dlx-contactPersonWithImage__columnLeft {
    padding-right: 2rem;
    width: calc(57.1428571429% + -0.8571428571rem + 2rem);
  }
}
.dlx-contactPersonWithImage__columnRight {
  width: 100%;
  margin-left: 0;
}
@media (min-width: 720px) {
  .dlx-contactPersonWithImage__columnRight {
    width: calc(70% + -0.6rem + 0px);
  }
}
@media (min-width: 1024px) {
  .dlx-contactPersonWithImage__columnRight {
    width: calc(42.8571428571% + -1.8285714286rem + 0px);
  }
}
@media (min-width: 1450px) {
  .dlx-contactPersonWithImage__columnRight {
    width: calc(42.8571428571% + -1.1428571429rem + 0px);
  }
}
.dlx-contactPersonWithImage__image {
  overflow: hidden;
  padding-bottom: 100%;
  position: relative;
  width: 100%;
}
.dlx-contactPersonWithImage__img {
  object-fit: cover;
  font-family: "object-fit: cover";
  display: block;
  height: 100%;
  width: 100%;
  position: absolute;
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  html.no-js .dlx-contactPersonWithImage__img {
    box-sizing: border-box;
    padding-left: 100%;
    background-size: cover;
    background-position: 50% 50%;
  }
}
.dlx-contactPersonWithImage__subtitle {
  font-family: Roboto, sans-serif;
  font-size: 1.5rem;
  font-weight: normal;
  line-height: 1.3333333333;
}
@media (min-width: 720px) {
  .dlx-contactPersonWithImage__subtitle {
    font-size: 1.6rem;
    line-height: 1.375;
  }
}
@media (min-width: 1450px) {
  .dlx-contactPersonWithImage__subtitle {
    font-size: 1.8rem;
    line-height: 1.3333333333;
  }
}

.dlx-contactPerson {
  position: relative;
  padding-top: 2rem;
  padding-bottom: 1rem;
}
.dlx-contactPerson::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 1px;
  width: 100vw;
  background-color: #ccc5b5;
}
.dlx-contactPerson:last-of-type::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  height: 1px;
  width: 100vw;
  background-color: #ccc5b5;
}
@media (min-width: 720px) {
  .dlx-contactPerson {
    padding-top: 3rem;
    padding-bottom: 2rem;
  }
}
@media (min-width: 1450px) {
  .dlx-contactPerson {
    padding-top: 5rem;
    padding-bottom: 4rem;
  }
}
.dlx-contactPerson__name {
  font-family: Roboto, sans-serif;
  font-size: 2.2rem;
  font-weight: normal;
  line-height: 1.1818181818;
  color: #6a665b;
  margin-bottom: 1.5rem;
}
@media (min-width: 720px) {
  .dlx-contactPerson__name {
    font-size: 2.8rem;
    line-height: 1.2857142857;
  }
}
@media (min-width: 1450px) {
  .dlx-contactPerson__name {
    font-size: 4rem;
    line-height: 1.1;
  }
}
@media (min-width: 1450px) {
  .dlx-contactPerson__name {
    margin-bottom: 3rem;
  }
}
.dlx-contactPerson__row {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  margin-bottom: 1rem;
}
.dlx-contactPerson__row > * {
  flex-grow: 0;
  flex-shrink: 0;
}
.dlx-contactPerson__rowName {
  vertical-align: middle;
  font-family: Roboto, sans-serif;
  font-size: 1.3rem;
  font-weight: bold;
  letter-spacing: 0.1rem;
  line-height: 1.2307692308;
  color: #ccc5b5;
  margin-top: 0.5rem;
  width: calc(16.6666666667% + -1.6666666667rem + 2rem);
}
@media (min-width: 720px) {
  .dlx-contactPerson__rowName {
    font-size: 1.4rem;
    line-height: 1.2857142857;
  }
}
@media (min-width: 1450px) {
  .dlx-contactPerson__rowName {
    font-size: 1.8rem;
    line-height: 1.2222222222;
  }
}
@media (min-width: 720px) {
  .dlx-contactPerson__rowName {
    margin-top: 0.75rem;
    width: calc(22.2222222222% + -1.5555555556rem + 2rem);
  }
}
@media (min-width: 1024px) {
  .dlx-contactPerson__rowName {
    margin-top: 1rem;
    width: calc(16.6666666667% + -2.6666666667rem + 3.2rem);
  }
}
@media (min-width: 1450px) {
  .dlx-contactPerson__rowName {
    width: calc(16.6666666667% + -1.6666666667rem + 2rem);
  }
}
.dlx-contactPerson__rowValue {
  font-size: 1.8rem;
  line-height: 1.3333333333;
  font-weight: normal;
  vertical-align: middle;
  width: calc(83.3333333333% + -0.3333333333rem + 0px);
}
@media (min-width: 720px) {
  .dlx-contactPerson__rowValue {
    font-size: 2rem;
    line-height: 1.4;
  }
}
@media (min-width: 1450px) {
  .dlx-contactPerson__rowValue {
    font-size: 2.4rem;
    line-height: 1.4166666667;
  }
}
@media (min-width: 720px) {
  .dlx-contactPerson__rowValue {
    width: calc(77.7777777778% + -0.4444444444rem + 0px);
  }
}
@media (min-width: 1024px) {
  .dlx-contactPerson__rowValue {
    width: calc(83.3333333333% + -0.5333333333rem + 0px);
  }
}
@media (min-width: 1450px) {
  .dlx-contactPerson__rowValue {
    width: calc(83.3333333333% + -0.3333333333rem + 0px);
  }
}

.dlx-footerMenu {
  margin-top: 1rem;
  margin-bottom: 2rem;
  font-family: Roboto, sans-serif;
  font-size: 1.5rem;
  font-weight: normal;
  line-height: 1.3333333333;
}
@media (min-width: 720px) {
  .dlx-footerMenu {
    font-size: 1.8rem;
    line-height: 1.3333333333;
  }
}
@media (min-width: 1024px) {
  .dlx-footerMenu {
    font-size: 1.6rem;
    line-height: 1.375;
  }
}
@media (min-width: 1450px) {
  .dlx-footerMenu {
    font-size: 1.8rem;
    line-height: 1.3333333333;
  }
}
.dlx-footerMenu--additional {
  margin-top: 0;
  margin-bottom: 0;
}
.dlx-footerMenu__icon {
  transition: opacity 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94) ;
  display: inline-block;
  margin-left: 0.25rem;
  margin-top: 2px;
  opacity: 1;
  vertical-align: top;
}
@media (min-width: 1024px) {
  .dlx-footerMenu__icon {
    opacity: 0;
  }
}
.dlx-footerMenu__link:hover .dlx-footerMenu__icon {
  opacity: 1;
}
.dlx-footerMenu__icon svg {
  fill: #6a665b;
  transition: transform 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94) ;
}
.dlx-footerMenu__link.dlx-js-toggled .dlx-footerMenu__icon svg {
  transform: scaleY(-1);
}
.dlx-footerMenu__item {
  transition: height 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) ;
  overflow: hidden;
  position: relative;
  margin-bottom: 1rem;
}
.dlx-footerMenu--additional .dlx-footerMenu__item {
  margin-bottom: 0;
}
.dlx-footerMenu__items {
  display: flex;
  flex-direction: column;
  transition: opacity 0.1s cubic-bezier(0.25, 0.46, 0.45, 0.94) ;
}
.dlx-footerMenu__menuItems {
  transition: opacity 0.1s cubic-bezier(0.25, 0.46, 0.45, 0.94) , visibility 0.1s cubic-bezier(0.25, 0.46, 0.45, 0.94) ;
  padding-bottom: 1rem;
}
.dlx-footerMenu__menuItems:focus {
  outline: 0;
}
.dlx-footerMenu__menuItems.dlx-js-collapsed {
  opacity: 0;
  position: absolute;
  visibility: hidden;
}
.dlx-footerMenu__menuItem {
  font-family: Roboto, sans-serif;
  font-size: 1.5rem;
  font-weight: normal;
  line-height: 1.3333333333;
  color: #6a665b;
  height: auto;
  padding-top: 1rem;
}
@media (min-width: 720px) {
  .dlx-footerMenu__menuItem {
    font-size: 1.4rem;
    line-height: 1.2857142857;
  }
}
@media (min-width: 1280px) {
  .dlx-footerMenu__menuItem {
    font-size: 1.8rem;
    line-height: 1.3333333333;
  }
}
@media (min-width: 1450px) {
  .dlx-footerMenu__menuItem {
    font-size: 1.8rem;
    line-height: 1.3333333333;
  }
}
@media (min-width: 720px) {
  .dlx-footerMenu__menuItem:first-of-type {
    padding-top: 1rem;
  }
}
.dlx-footerMenu__menuLink {
  color: #6a665b;
  position: relative;
  margin: 0;
  padding: 0;
  display: inline-block;
  vertical-align: top;
}
.dlx-footerMenu__menuLink--active {
  font-weight: bold;
}
.dlx-footerMenu__section {
  flex-grow: 0;
  flex-shrink: 0;
  margin-bottom: 0;
  padding-bottom: 1rem;
  width: calc(50% + -1rem + 0px);
}
@media (min-width: 1450px) {
  .dlx-footerMenu__section {
    padding-bottom: 2rem;
  }
}
@media (min-width: 720px) {
  .dlx-footerMenu__section {
    width: calc(33.3333333333% + -1.3333333333rem + 0px);
  }
}
@media (min-width: 1024px) {
  .dlx-footerMenu__section {
    width: calc(33.3333333333% + -2.1333333333rem + 0px);
  }
}
@media (min-width: 1450px) {
  .dlx-footerMenu__section {
    width: calc(33.3333333333% + -1.3333333333rem + 0px);
  }
}
.dlx-footerMenu__section__section:last-of-type {
  margin-top: -2rem;
}
.dlx-footerMenu__section--wide {
  width: 100%;
  display: block;
  padding-bottom: 3rem;
}
@media (min-width: 1450px) {
  .dlx-footerMenu__section--wide {
    padding-bottom: 5rem;
  }
}
.dlx-footerMenu__sections {
  vertical-align: top;
  padding-bottom: 2rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 0;
}
.dlx-footerMenu__sections > * {
  flex-grow: 0;
  flex-shrink: 0;
  margin-top: 0;
}
.dlx-footerMenu__title {
  margin-bottom: 2rem;
  text-transform: uppercase;
  font-weight: normal;
}

.dlx-footer {
  padding-left: 1rem;
  padding-right: 1rem;
  width: 100%;
  background-color: #ccc5b5;
  padding-bottom: 6rem;
  padding-top: 2.5rem;
  z-index: 1;
}
@media (min-width: 720px) {
  .dlx-footer {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
@media (min-width: 1024px) {
  .dlx-footer {
    padding-left: 3.2rem;
    padding-right: 3.2rem;
  }
}
@media (min-width: 1450px) {
  .dlx-footer {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
@media (min-width: 720px) {
  .dlx-footer {
    padding-bottom: 8rem;
    padding-top: 4rem;
  }
}
@media (min-width: 1024px) {
  .dlx-footer {
    padding-bottom: 10rem;
    padding-top: 4rem;
  }
}
@media (min-width: 1450px) {
  .dlx-footer {
    padding-bottom: 12rem;
    padding-top: 6rem;
  }
}
.dlx-body.dlx-js-homePage .dlx-footer {
  visibility: hidden;
}
.dlx-footer__address {
  font-family: Roboto, sans-serif;
  font-size: 1.5rem;
  font-weight: normal;
  line-height: 1.3333333333;
  vertical-align: top;
  margin-right: 2rem;
  width: calc(50% + -1rem + 0px);
}
@media (min-width: 720px) {
  .dlx-footer__address {
    font-size: 1.8rem;
    line-height: 1.3333333333;
  }
}
@media (min-width: 1024px) {
  .dlx-footer__address {
    font-size: 1.6rem;
    line-height: 1.375;
  }
}
@media (min-width: 1450px) {
  .dlx-footer__address {
    font-size: 1.8rem;
    line-height: 1.3333333333;
  }
}
@media (min-width: 720px) {
  .dlx-footer__address {
    width: calc(66.6666666667% + -0.6666666667rem + 0px);
  }
}
@media (min-width: 1024px) {
  .dlx-footer__address {
    margin-right: 3.2rem;
    width: calc(66.6666666667% + -1.0666666667rem + 0px);
  }
}
@media (min-width: 1450px) {
  .dlx-footer__address {
    margin-right: 2rem;
    width: calc(66.6666666667% + -0.6666666667rem + 0px);
  }
}
.dlx-footer__content {
  width: 100%;
}
.dlx-footer__info {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
}
.dlx-footer__info > * {
  flex-grow: 0;
  flex-shrink: 0;
}
.dlx-footer__impressum {
  font-family: Roboto, sans-serif;
  font-size: 1.5rem;
  font-weight: normal;
  line-height: 1.3333333333;
  align-self: flex-end;
  width: calc(50% + -1rem + 0px);
}
@media (min-width: 720px) {
  .dlx-footer__impressum {
    font-size: 1.8rem;
    line-height: 1.3333333333;
  }
}
@media (min-width: 1024px) {
  .dlx-footer__impressum {
    font-size: 1.6rem;
    line-height: 1.375;
  }
}
@media (min-width: 1450px) {
  .dlx-footer__impressum {
    font-size: 1.8rem;
    line-height: 1.3333333333;
  }
}
@media (min-width: 720px) {
  .dlx-footer__impressum {
    width: calc(33.3333333333% + -1.3333333333rem + 0px);
  }
}
@media (min-width: 1024px) {
  .dlx-footer__impressum {
    width: calc(33.3333333333% + -2.1333333333rem + 0px);
  }
}
@media (min-width: 1450px) {
  .dlx-footer__impressum {
    width: calc(33.3333333333% + -1.3333333333rem + 0px);
  }
}
.dlx-footer__mainContent {
  width: 100%;
  margin-left: 0;
}
@media (min-width: 720px) {
  .dlx-footer__mainContent {
    margin-left: calc(16.6666666667% + -1.6666666667rem + 2rem);
    width: calc(75% + -0.5rem + 0px);
  }
}
@media (min-width: 1024px) {
  .dlx-footer__mainContent {
    margin-left: calc(41.6666666667% + -1.8666666667rem + 3.2rem);
    width: calc(50% + -1.6rem + 0px);
  }
}
@media (min-width: 1450px) {
  .dlx-footer__mainContent {
    margin-left: calc(41.6666666667% + -1.1666666667rem + 2rem);
    width: calc(50% + -1rem + 0px);
  }
}
.dlx-footer__menu {
  width: 100%;
}

.dlx-footnoteLink {
  transition: background-color 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) ;
  background-color: #c9dfe2;
  cursor: pointer;
  display: inline;
}
@media (min-width: 720px) {
  .dlx-footnoteLink {
    position: relative;
  }
}
.dlx-footnoteLink:hover, .dlx-footnoteLink.dlx-js-active {
  background-color: #7695a3;
}
.dlx-footnoteLink__text {
  position: relative;
}

.dlx-footnote {
  cursor: default;
  left: 0;
  opacity: 0;
  position: absolute;
  transform: translate(0, -100%);
  visibility: hidden;
  width: 100%;
  z-index: 1;
  margin-top: 1rem;
}
@media (min-width: 720px) {
  .dlx-footnote {
    left: 50%;
    position: absolute;
    transform: translate(-50%, -100%);
    width: 42rem;
  }
}
.dlx-footnoteLink.dlx-js-active .dlx-footnote {
  transition: opacity 0.1s cubic-bezier(0.25, 0.46, 0.45, 0.94) , visibility 0.1s cubic-bezier(0.25, 0.46, 0.45, 0.94) , margin-top 0.1s cubic-bezier(0.25, 0.46, 0.45, 0.94) ;
  margin-top: 0;
  opacity: 1;
  visibility: visible;
  z-index: 50;
}
.dlx-footnote__content {
  background-color: #c9dfe2;
  display: block;
  margin: 0 1rem 1rem 1rem;
  padding: 1rem;
}
@media (min-width: 720px) {
  .dlx-footnote__content {
    margin: 0 2rem 1rem 2rem;
    padding: 2rem;
  }
}
.dlx-footnote__head {
  font-family: Roboto, sans-serif;
  font-size: 2.2rem;
  font-weight: normal;
  line-height: 1.1818181818;
  display: block;
  margin-bottom: 1rem;
}
@media (min-width: 720px) {
  .dlx-footnote__head {
    font-size: 2.8rem;
    line-height: 1.2857142857;
  }
}
@media (min-width: 1450px) {
  .dlx-footnote__head {
    font-size: 4rem;
    line-height: 1.1;
  }
}
@media (min-width: 720px) {
  .dlx-footnote__head {
    margin-bottom: 2rem;
  }
}
.dlx-footnote__subhead {
  font-family: Roboto, sans-serif;
  font-size: 1.3rem;
  font-weight: bold;
  letter-spacing: 0.1rem;
  line-height: 1.2307692308;
  text-transform: uppercase;
  color: #6a665b;
  display: block;
  margin-bottom: 3rem;
}
@media (min-width: 720px) {
  .dlx-footnote__subhead {
    font-size: 1.4rem;
    line-height: 1.2857142857;
  }
}
@media (min-width: 1450px) {
  .dlx-footnote__subhead {
    font-size: 1.8rem;
    line-height: 1.2222222222;
  }
}
@media (min-width: 720px) {
  .dlx-footnote__subhead {
    margin-bottom: 5rem;
  }
}
@media (min-width: 1450px) {
  .dlx-footnote__subhead {
    margin-bottom: 6rem;
  }
}
.dlx-footnote__text {
  font-family: Roboto, sans-serif;
  font-size: 1.5rem;
  font-weight: normal;
  line-height: 1.3333333333;
  display: block;
}
@media (min-width: 720px) {
  .dlx-footnote__text {
    font-size: 1.6rem;
    line-height: 1.375;
  }
}
@media (min-width: 1450px) {
  .dlx-footnote__text {
    font-size: 1.8rem;
    line-height: 1.3333333333;
  }
}

.dlx-formFieldLabel {
  font-family: Roboto, sans-serif;
  font-size: 1.3rem;
  font-weight: bold;
  letter-spacing: 0.1rem;
  line-height: 1.2307692308;
  margin-bottom: 1rem;
  text-transform: uppercase;
  display: block;
}
@media (min-width: 720px) {
  .dlx-formFieldLabel {
    font-size: 1.4rem;
    line-height: 1.2857142857;
  }
}
@media (min-width: 1450px) {
  .dlx-formFieldLabel {
    font-size: 1.8rem;
    line-height: 1.2222222222;
  }
}
.dlx-formFieldLabel--invisible {
  opacity: 0;
  display: none;
}
@media (min-width: 720px) {
  .dlx-formFieldLabel--invisible {
    display: block;
  }
}

.dlx-formField {
  font-size: 1.8rem;
  line-height: 1.3333333333;
  font-weight: normal;
  background-color: #f8f8f7;
  border: none;
  color: #323232;
  height: 5.4rem;
  padding-left: 2rem;
  padding-right: 2rem;
  vertical-align: top;
}
@media (min-width: 720px) {
  .dlx-formField {
    font-size: 2rem;
    line-height: 1.4;
  }
}
@media (min-width: 1450px) {
  .dlx-formField {
    font-size: 2.4rem;
    line-height: 1.4166666667;
  }
}
@media (min-width: 1450px) {
  .dlx-formField {
    height: 6rem;
  }
}
.dlx-formField--select {
  font-size: 1.8rem;
  line-height: 1.3333333333;
  font-weight: normal;
  appearance: none;
  border: 1px solid #6a665b;
  background: transparent;
  border-radius: 0;
  background-image: linear-gradient(45deg, transparent 50%, grey 0), linear-gradient(135deg, grey 50%, transparent 0);
  background-position: calc(100% - 20px) calc(1em + 5px), calc(100% - 10px) calc(1em + 5px), calc(100% - 2.5em) 0.5em;
  background-size: 10px 10px, 10px 10px, 1px 1.5em;
  background-repeat: no-repeat;
}
@media (min-width: 720px) {
  .dlx-formField--select {
    font-size: 2rem;
    line-height: 1.4;
  }
}
@media (min-width: 1450px) {
  .dlx-formField--select {
    font-size: 2.4rem;
    line-height: 1.4166666667;
  }
}
.dlx-formField--select:invalid {
  font-family: Roboto, sans-serif;
  font-size: 1.5rem;
  font-weight: normal;
  line-height: 1.3333333333;
  color: #6a665b;
  opacity: 0.5;
}
@media (min-width: 720px) {
  .dlx-formField--select:invalid {
    font-size: 1.6rem;
    line-height: 1.375;
  }
}
@media (min-width: 1450px) {
  .dlx-formField--select:invalid {
    font-size: 1.8rem;
    line-height: 1.3333333333;
  }
}
.dlx-formField:focus {
  border: 1px solid #ccc5b5;
  background-color: #ffffff;
}
.dlx-formField--inverted {
  background-color: transparent;
  border: 1px solid #6a665b;
}
.dlx-formField--inverted:focus {
  border: 1px solid transparent;
  background-color: #f8f8f7;
}
.dlx-formField--background {
  border: 1px solid transparent;
  background-color: #ffffff;
}
.dlx-formField--background:focus {
  border: 1px solid #ccc5b5;
  background-color: #ffffff;
}
.dlx-body.dlx-js-fullyInvertedHeader .dlx-formField--background:focus {
  border: 1px solid transparent;
}
.dlx-formField--invertedLight {
  background-color: transparent;
  border: 1px solid #ccc5b5;
}
.dlx-formField--invertedLight:focus {
  border: 1px solid transparent;
  border: 1px solid #6a665b;
}
.dlx-formField--invertedLight::placeholder {
  font-family: Roboto, sans-serif;
  font-size: 1.5rem;
  font-weight: normal;
  line-height: 1.3333333333;
}
@media (min-width: 720px) {
  .dlx-formField--invertedLight::placeholder {
    font-size: 1.6rem;
    line-height: 1.375;
  }
}
@media (min-width: 1450px) {
  .dlx-formField--invertedLight::placeholder {
    font-size: 1.8rem;
    line-height: 1.3333333333;
  }
}
.dlx-formField--large {
  height: 10.8rem;
  padding-bottom: 1rem;
  padding-top: 1rem;
  resize: none;
}
@media (min-width: 1450px) {
  .dlx-formField--large {
    height: 12rem;
  }
}
.dlx-formField__selectPlaceholder {
  color: #6a665b;
  opacity: 0.5;
  font-family: Roboto, sans-serif;
  font-size: 1.5rem;
  font-weight: normal;
  line-height: 1.3333333333;
}
@media (min-width: 720px) {
  .dlx-formField__selectPlaceholder {
    font-size: 1.6rem;
    line-height: 1.375;
  }
}
@media (min-width: 1450px) {
  .dlx-formField__selectPlaceholder {
    font-size: 1.8rem;
    line-height: 1.3333333333;
  }
}
.dlx-formField__selectPlaceholder:disabled {
  display: none;
}
.dlx-formField::placeholder {
  color: #6a665b;
  opacity: 0.5;
}

.dlx-form {
  color: #6a665b;
}
.dlx-form__button {
  transition: opacity 0.2s  ;
  min-width: 100%;
}
@media (min-width: 720px) {
  .dlx-form__button {
    min-width: calc(75% + -0.5rem + 0px);
  }
}
@media (min-width: 1024px) {
  .dlx-form__button {
    min-width: calc(66.6666666667% + -1.0666666667rem + 0px);
  }
}
@media (min-width: 1450px) {
  .dlx-form__button {
    min-width: calc(66.6666666667% + -0.6666666667rem + 0px);
  }
}
.dlx-form.dlx-js-busy .dlx-form__button {
  pointer-events: none;
  opacity: 0.5;
}
.dlx-form__checkbox {
  margin-top: 2rem;
}
.dlx-form__fileInput {
  display: none;
}
.dlx-form__footnote {
  font-family: Roboto, sans-serif;
  font-size: 1.5rem;
  font-weight: normal;
  line-height: 1.3333333333;
  margin-top: 2rem;
}
@media (min-width: 720px) {
  .dlx-form__footnote {
    font-size: 1.6rem;
    line-height: 1.375;
  }
}
@media (min-width: 1450px) {
  .dlx-form__footnote {
    font-size: 1.8rem;
    line-height: 1.3333333333;
  }
}
.dlx-form__fullpart {
  display: block;
  width: 100%;
  margin-bottom: 1rem;
}
.dlx-form__input {
  width: 100%;
}
.dlx-form__parts {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 0;
}
.dlx-form__parts > * {
  flex-grow: 0;
  flex-shrink: 0;
  margin-top: 0;
}
.dlx-form__part {
  margin-bottom: 1rem;
  width: 100%;
}
@media (min-width: 720px) {
  .dlx-form__part {
    width: calc(50% + -1rem + 0px);
  }
}
@media (min-width: 1024px) {
  .dlx-form__part {
    margin-bottom: 2rem;
    width: calc(50% + -1.6rem + 0px);
  }
}
@media (min-width: 1450px) {
  .dlx-form__part {
    margin-bottom: 3rem;
    width: calc(50% + -1rem + 0px);
  }
}
.dlx-form__part--message {
  margin-bottom: 0;
}
@media (min-width: 1024px) {
  .dlx-form__part--message {
    margin-bottom: 0;
  }
}
@media (min-width: 1450px) {
  .dlx-form__part--message {
    margin-bottom: 0;
  }
}
.dlx-form__largeField {
  padding-bottom: 4rem;
  width: 100%;
}
@media (min-width: 1450px) {
  .dlx-form__largeField {
    padding-bottom: 6rem;
  }
}

.dlx-gallery {
  margin-bottom: 4rem;
  margin-top: 0rem;
  touch-action: pan-y;
}
.dlx-gallery[data-dlx-intersect] {
  opacity: 0;
  transform: translateY(2rem);
}
.dlx-gallery.dlx-js-inside {
  transition: opacity 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s, transform 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s;
  opacity: 1;
  transform: translateY(0);
}
@media (min-width: 720px) {
  .dlx-gallery {
    margin-left: calc(16.6666666667% + 0.3333333333rem + 0px);
    width: calc(83.3333333333% + -0.3333333333rem + 0px);
  }
}
@media (min-width: 1024px) {
  .dlx-gallery {
    margin-left: calc(41.6666666667% + 1.3333333333rem + 0px);
    width: calc(58.3333333333% + -1.3333333333rem + 0px);
  }
}
@media (min-width: 1450px) {
  .dlx-gallery {
    margin-left: calc(41.6666666667% + 0.8333333333rem + 0px);
    width: calc(58.3333333333% + -0.8333333333rem + 0px);
  }
}
.dlx-gallery__captions {
  margin-top: 1rem;
}
@media (min-width: 720px) {
  .dlx-gallery__captions {
    flex-grow: 1;
    margin-top: 0;
  }
}
.dlx-gallery__nextPrev {
  display: none;
}
.dlx-gallery__info {
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
}
@media (min-width: 720px) {
  .dlx-gallery__info {
    flex-direction: row;
  }
}
.dlx-gallery__itemsContainter {
  position: relative;
}
@media (min-width: 720px) {
  .dlx-gallery__navigation {
    flex-grow: 0;
    margin-right: 6rem;
  }
}

.dlx-galleryCaptions__caption {
  font-family: Roboto, sans-serif;
  font-size: 1.6rem;
  font-weight: normal;
  line-height: 1.375;
}
@media (min-width: 1450px) {
  .dlx-galleryCaptions__caption {
    font-size: 1.8rem;
    line-height: 1.3333333333;
  }
}
.dlx-galleryCaptions__item {
  transition: opacity 0.2s  , visibility 0.2s  ;
  opacity: 0;
  visibility: hidden;
  display: block;
  position: relative;
  width: 100%;
  flex-shrink: 0;
}
.dlx-galleryCaptions__item:nth-child(2) {
  transform: translateX(-100%);
}
.dlx-galleryCaptions__item:nth-child(3) {
  transform: translateX(-200%);
}
.dlx-galleryCaptions__item:nth-child(4) {
  transform: translateX(-300%);
}
.dlx-galleryCaptions__item:nth-child(5) {
  transform: translateX(-400%);
}
.dlx-galleryCaptions__item:nth-child(6) {
  transform: translateX(-500%);
}
.dlx-galleryCaptions__item:nth-child(7) {
  transform: translateX(-600%);
}
.dlx-galleryCaptions__item:nth-child(8) {
  transform: translateX(-700%);
}
.dlx-galleryCaptions__item:nth-child(9) {
  transform: translateX(-800%);
}
.dlx-galleryCaptions__item:nth-child(10) {
  transform: translateX(-900%);
}
.dlx-galleryCaptions__item.dlx-js-current {
  opacity: 1;
  visibility: inherit;
  z-index: 1;
}
.dlx-galleryCaptions__items {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  position: relative;
}

.dlx-galleryItems {
  position: relative;
  touch-action: pan-y;
}
.dlx-galleryItems--modal {
  display: none;
  height: 100%;
  width: 100%;
}
.dlx-modalGallery .dlx-galleryItems--modal.dlx-js-active {
  display: block;
}
.dlx-galleryItems--normal::before {
  content: "";
  display: block;
  padding-bottom: 66.6666666667%;
}
.dlx-galleryItems__image {
  position: absolute;
  height: 100%;
  left: 0;
  top: 0;
  width: 100%;
}
.dlx-galleryItems__imageContainer {
  height: 100%;
  position: relative;
}
.dlx-galleryItems--normal .dlx-galleryItems__imageContainer {
  margin-left: -1rem;
  margin-right: -1rem;
}
@media (min-width: 720px) {
  .dlx-galleryItems--normal .dlx-galleryItems__imageContainer {
    margin-left: 0;
    margin-right: -2rem;
  }
}
@media (min-width: 1024px) {
  .dlx-galleryItems--normal .dlx-galleryItems__imageContainer {
    margin-left: 0;
    margin-right: -3.2rem;
  }
}
@media (min-width: 1450px) {
  .dlx-galleryItems--normal .dlx-galleryItems__imageContainer {
    margin-left: 0;
    margin-right: -2rem;
  }
}
.dlx-galleryItems__img {
  object-fit: cover;
  font-family: "object-fit: cover";
  height: 100%;
  position: relative;
  width: 100%;
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  html.no-js .dlx-galleryItems__img {
    box-sizing: border-box;
    padding-left: 100%;
    background-size: cover;
    background-position: 50% 50%;
  }
}
.dlx-galleryItems--modal .dlx-galleryItems__img {
  object-fit: contain;
  font-family: "object-fit: contain";
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  html.no-js .dlx-galleryItems--modal .dlx-galleryItems__img {
    box-sizing: border-box;
    padding-left: 100%;
    background-size: contain;
    background-position: 50% 50%;
  }
}
.dlx-galleryItems__item {
  transition: opacity 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) , visibility 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) , transform 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) ;
  opacity: 0;
  visibility: hidden;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1;
}
.dlx-galleryItems__item::before {
  content: '{"allowZoom": false}';
  display: none;
}
@supports (--varName: "whatever") {
  .dlx-galleryItems__item {
    --jsValues: '{"allowZoom": false}';
  }
}
.dlx-galleryItems__item.dlx-js-current {
  opacity: 1;
  visibility: inherit;
  z-index: 3;
}
@media (min-width: 720px) {
  .dlx-galleryItems--normal .dlx-galleryItems__item.dlx-js-current {
    cursor: pointer;
  }
  .dlx-galleryItems--normal .dlx-galleryItems__item.dlx-js-current::before {
    content: '{"allowZoom": true}';
    display: none;
  }
  @supports (--varName: "whatever") {
    .dlx-galleryItems--normal .dlx-galleryItems__item.dlx-js-current {
      --jsValues: '{"allowZoom": true}';
    }
  }
}
@media (min-width: 1024px) {
  .dlx-gallery__itemsContainter:hover .dlx-galleryItems__item.dlx-js-current {
    transform: translateX(calc(-14.2857142857% + -3.6571428571rem + 0px));
  }
}
@media (min-width: 1450px) {
  .dlx-gallery__itemsContainter:hover .dlx-galleryItems__item.dlx-js-current {
    transform: translateX(calc(-14.2857142857% + -2.2857142857rem + 0px));
  }
}
.dlx-galleryItems__item.dlx-js-next {
  cursor: pointer;
}
@media (min-width: 1024px) {
  .dlx-gallery__itemsContainter:hover .dlx-galleryItems--normal .dlx-galleryItems__item.dlx-js-next {
    z-index: 2;
    transition: opacity 0s  , visibility 0s  ;
    opacity: 1;
    visibility: inherit;
  }
}
.dlx-galleryItems__nextIcon {
  bottom: 2rem;
  color: #6a665b;
  display: none;
  height: 6rem;
  position: absolute;
  right: 2rem;
  width: 6rem;
}
@media (min-width: 1024px) {
  .dlx-galleryItems__item.dlx-js-next .dlx-galleryItems__nextIcon {
    display: block;
  }
}

.dlx-galleryNavigation__action {
  transition: background-color 0.2s  ;
  background-color: #ccc5b5;
  border-radius: 50%;
  cursor: pointer;
  display: block;
  font-size: 0;
  height: 100%;
  line-height: 0;
  overflow: hidden;
  text-indent: -1000px;
  width: 100%;
}
.dlx-galleryNavigation__action.dlx-js-current {
  background-color: #7A7977;
}
.dlx-galleryNavigation__item {
  height: 0.8rem;
  width: 0.8rem;
}
@media (min-width: 720px) {
  .dlx-galleryNavigation__item {
    height: 1.3rem;
    width: 1.3rem;
  }
}
.dlx-galleryNavigation__item + .dlx-galleryNavigation__item {
  margin-left: 0.5rem;
}
@media (min-width: 720px) {
  .dlx-galleryNavigation__item + .dlx-galleryNavigation__item {
    margin-left: 1rem;
  }
}
.dlx-galleryNavigation__items {
  display: flex;
  flex-direction: row;
}

.dlx-glossaryItem {
  position: relative;
}
.dlx-glossaryItem.dlx-js-showLetter::before {
  width: 100%;
  font-family: Roboto, sans-serif;
  font-size: 2.2rem;
  font-weight: normal;
  line-height: 1.1818181818;
  content: attr(data-dlx-letter);
  font-weight: bold;
  text-transform: uppercase;
  position: relative;
  display: block;
  padding-top: 2rem;
  padding-bottom: 1rem;
}
@media (min-width: 720px) {
  .dlx-glossaryItem.dlx-js-showLetter::before {
    margin-left: calc(16.6666666667% + 0.3333333333rem + 0px);
    width: calc(50% + -1rem + 0px);
  }
}
@media (min-width: 1024px) {
  .dlx-glossaryItem.dlx-js-showLetter::before {
    margin-left: calc(16.6666666667% + 0.5333333333rem + 0px);
    width: calc(25% + -2.4rem + 0px);
  }
}
@media (min-width: 1450px) {
  .dlx-glossaryItem.dlx-js-showLetter::before {
    margin-left: calc(16.6666666667% + 0.3333333333rem + 0px);
    width: calc(16.6666666667% + -1.6666666667rem + 0px);
  }
}
@media (min-width: 720px) {
  .dlx-glossaryItem.dlx-js-showLetter::before {
    font-size: 2.8rem;
    line-height: 1.2857142857;
  }
}
@media (min-width: 1450px) {
  .dlx-glossaryItem.dlx-js-showLetter::before {
    font-size: 4rem;
    line-height: 1.1;
  }
}
@media (min-width: 1024px) {
  .dlx-glossaryItem.dlx-js-showLetter::before {
    float: left;
    position: absolute;
  }
}
.dlx-glossaryItem__content {
  width: 100%;
  margin-left: 0;
  transition: opacity 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) ;
  padding-bottom: 3rem;
  padding-top: 3rem;
}
@media (min-width: 720px) {
  .dlx-glossaryItem__content {
    margin-left: calc(16.6666666667% + -1.6666666667rem + 2rem);
    width: calc(75% + -0.5rem + 0px);
  }
}
@media (min-width: 1024px) {
  .dlx-glossaryItem__content {
    margin-left: calc(41.6666666667% + -1.8666666667rem + 3.2rem);
    width: calc(50% + -1.6rem + 0px);
  }
}
@media (min-width: 1450px) {
  .dlx-glossaryItem__content {
    margin-left: calc(41.6666666667% + -1.1666666667rem + 2rem);
    width: calc(50% + -1rem + 0px);
  }
}
.dlx-glossaryItem__content.dlx-js-collapsed {
  opacity: 0;
  position: absolute;
}
.dlx-glossaryItem__header {
  font-family: Roboto, sans-serif;
  font-size: 1.5rem;
  font-weight: normal;
  line-height: 1.3333333333;
  color: #6a665b;
  cursor: pointer;
  font-weight: bold;
  padding-bottom: 3rem;
  padding-top: 3rem;
  position: relative;
  text-transform: uppercase;
}
@media (min-width: 720px) {
  .dlx-glossaryItem__header {
    font-size: 1.6rem;
    line-height: 1.375;
  }
}
@media (min-width: 1450px) {
  .dlx-glossaryItem__header {
    font-size: 1.8rem;
    line-height: 1.3333333333;
  }
}
.dlx-glossaryItem__icon {
  display: inline-block;
  opacity: 1;
  position: absolute;
  right: 0;
  top: 50%;
  margin-top: 0.25rem;
  transform: translateY(-50%);
  vertical-align: top;
}
.dlx-glossaryItem__icon svg {
  fill: #6a665b;
  transition: transform 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94) ;
}
.dlx-glossaryItem__togglerLink.dlx-js-toggled .dlx-glossaryItem__icon svg {
  transform: scaleY(-1);
}
.dlx-glossaryItem__inner {
  overflow: hidden;
  position: relative;
  transition: height 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) ;
}
.dlx-glossaryItem__togglerLink {
  width: 100%;
  margin-left: 0;
  position: relative;
  width: 100%;
  color: #323232;
  text-decoration: none;
  margin-top: -1px;
  text-align: left;
}
@media (min-width: 720px) {
  .dlx-glossaryItem__togglerLink {
    margin-left: calc(16.6666666667% + -1.6666666667rem + 2rem);
    width: calc(75% + -0.5rem + 0px);
  }
}
@media (min-width: 1024px) {
  .dlx-glossaryItem__togglerLink {
    margin-left: calc(41.6666666667% + -1.8666666667rem + 3.2rem);
    width: calc(50% + -1.6rem + 0px);
  }
}
@media (min-width: 1450px) {
  .dlx-glossaryItem__togglerLink {
    margin-left: calc(41.6666666667% + -1.1666666667rem + 2rem);
    width: calc(50% + -1rem + 0px);
  }
}
.dlx-glossaryItem__togglerLink::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 1px;
  width: 100vw;
  background-color: #ccc5b5;
}
.dlx-glossaryItem__togglerLink:last-of-type::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  height: 1px;
  width: 100vw;
  background-color: #ccc5b5;
}

.dlx-headerMargin {
  width: 100%;
  margin-bottom: 2rem;
  z-index: 2;
  clear: both;
}
@media (min-width: 720px) {
  .dlx-headerMargin {
    margin-left: calc(16.6666666667% + 0.3333333333rem + 0px);
    width: calc(50% + -1rem + 0px);
  }
}
@media (min-width: 1024px) {
  .dlx-headerMargin {
    margin-left: calc(16.6666666667% + 0.5333333333rem + 0px);
    width: calc(25% + -2.4rem + 0px);
  }
}
@media (min-width: 1450px) {
  .dlx-headerMargin {
    margin-left: calc(16.6666666667% + 0.3333333333rem + 0px);
    width: calc(16.6666666667% + -1.6666666667rem + 0px);
  }
}
.dlx-headerMargin[data-dlx-intersect] {
  opacity: 0;
  transform: translateY(2rem);
}
.dlx-headerMargin.dlx-js-inside {
  transition: opacity 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s, transform 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s;
  opacity: 1;
  transform: translateY(0);
}
@media (min-width: 720px) {
  .dlx-headerMargin {
    margin-bottom: 3rem;
  }
}
@media (min-width: 1024px) {
  .dlx-headerMargin {
    float: left;
  }
}
.dlx-headerMargin__additional {
  margin-bottom: 4rem;
  font-family: Roboto, sans-serif;
  font-size: 1.3rem;
  font-weight: bold;
  letter-spacing: 0.1rem;
  line-height: 1.2307692308;
  font-weight: normal;
}
@media (min-width: 720px) {
  .dlx-headerMargin__additional {
    font-size: 1.4rem;
    line-height: 1.2857142857;
  }
}
@media (min-width: 1450px) {
  .dlx-headerMargin__additional {
    font-size: 1.8rem;
    line-height: 1.2222222222;
  }
}
.dlx-headerMargin__additional .title {
  display: block;
  text-transform: uppercase;
  color: #ccc5b5;
  margin-top: 1rem;
  font-weight: bold;
  margin-bottom: -1.5rem;
}
.dlx-headerMargin__additionalHeader {
  font-weight: bold;
  margin-bottom: 2rem;
  font-family: Roboto, sans-serif;
  font-size: 1.3rem;
  font-weight: bold;
  letter-spacing: 0.1rem;
  line-height: 1.2307692308;
}
@media (min-width: 720px) {
  .dlx-headerMargin__additionalHeader {
    font-size: 1.4rem;
    line-height: 1.2857142857;
  }
}
@media (min-width: 1450px) {
  .dlx-headerMargin__additionalHeader {
    font-size: 1.8rem;
    line-height: 1.2222222222;
  }
}
.dlx-headerMargin__image {
  width: 100%;
  height: auto;
  margin-bottom: 1rem;
}
.dlx-headerMargin__img {
  width: 100%;
  height: auto;
}
.dlx-headerMargin__subtitle {
  font-family: Roboto, sans-serif;
  font-size: 1.5rem;
  font-weight: normal;
  line-height: 1.3333333333;
}
@media (min-width: 720px) {
  .dlx-headerMargin__subtitle {
    font-size: 1.6rem;
    line-height: 1.375;
  }
}
@media (min-width: 1450px) {
  .dlx-headerMargin__subtitle {
    font-size: 1.8rem;
    line-height: 1.3333333333;
  }
}
.dlx-headerMargin__title {
  font-family: Roboto, sans-serif;
  font-size: 2.2rem;
  font-weight: normal;
  line-height: 1.1818181818;
  margin-bottom: 1rem;
}
@media (min-width: 720px) {
  .dlx-headerMargin__title {
    font-size: 2.8rem;
    line-height: 1.2857142857;
  }
}
@media (min-width: 1450px) {
  .dlx-headerMargin__title {
    font-size: 4rem;
    line-height: 1.1;
  }
}
@media (min-width: 720px) {
  .dlx-headerMargin__title {
    margin-bottom: 2rem;
  }
}
@media (min-width: 1450px) {
  .dlx-headerMargin__title {
    margin-bottom: 3rem;
  }
}

.dlx-headerSmall {
  width: 100%;
  margin-left: 0;
}
@media (min-width: 720px) {
  .dlx-headerSmall {
    margin-left: calc(16.6666666667% + -1.6666666667rem + 2rem);
    width: calc(75% + -0.5rem + 0px);
  }
}
@media (min-width: 1024px) {
  .dlx-headerSmall {
    margin-left: calc(41.6666666667% + -1.8666666667rem + 3.2rem);
    width: calc(50% + -1.6rem + 0px);
  }
}
@media (min-width: 1450px) {
  .dlx-headerSmall {
    margin-left: calc(41.6666666667% + -1.1666666667rem + 2rem);
    width: calc(50% + -1rem + 0px);
  }
}
.dlx-headerSmall__title {
  font-size: 1.8rem;
  line-height: 1.3333333333;
  font-weight: normal;
  font-weight: bold;
  margin-top: 2rem;
  margin-bottom: 1rem;
}
@media (min-width: 720px) {
  .dlx-headerSmall__title {
    font-size: 2rem;
    line-height: 1.4;
  }
}
@media (min-width: 1450px) {
  .dlx-headerSmall__title {
    font-size: 2.4rem;
    line-height: 1.4166666667;
  }
}
@media (min-width: 720px) {
  .dlx-headerSmall__title {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
}
@media (min-width: 1450px) {
  .dlx-headerSmall__title {
    margin-top: 3rem;
    margin-bottom: 3rem;
  }
}

.dlx-imageFullRight {
  width: calc(100% + 1rem);
}
@media (min-width: 720px) {
  .dlx-imageFullRight {
    width: calc(100% + calc(11.1111111111% + -1.7777777778rem + 2rem) + 2rem);
  }
}
@media (min-width: 1024px) {
  .dlx-imageFullRight {
    width: calc(100% + calc(16.6666666667% + -2.6666666667rem + 3.2rem) + 3.2rem);
  }
}
@media (min-width: 1450px) {
  .dlx-imageFullRight {
    width: calc(100% + calc(16.6666666667% + -1.6666666667rem + 2rem) + 2rem);
  }
}
.dlx-imageFullRight__img {
  width: 100%;
}

.dlx-mainMenu {
  transition: opacity 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94) , visibility 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94) ;
  opacity: 0;
  visibility: hidden;
  background-color: #f8f8f7;
  display: block;
  height: calc(100vh - 5.2rem);
  left: 0;
  position: absolute;
  top: 5.2rem;
  width: 100%;
  z-index: 20;
  overflow-y: auto;
}
@media (min-width: 1024px) {
  .dlx-body.dlx-js-idle .dlx-mainMenu {
    transition: opacity 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94) , visibility 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94) ;
    opacity: 0;
    visibility: hidden;
  }
}
.dlx-mainMenu.dlx-js-beforeActive:not(.dlx-js-fixed) {
  will-change: opacity;
  transition: opacity 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94) , visibility 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94) ;
  opacity: 1;
  visibility: inherit;
}
@media (min-width: 1024px) {
  .dlx-mainMenu {
    transition: opacity 0  , visibility 0  ;
    opacity: 1;
    visibility: inherit;
    background-color: transparent;
    height: calc(100vh - 9rem);
    padding-top: 9rem;
    position: fixed;
    top: 0;
    width: 22rem;
    overflow-y: visible;
  }
}
.dlx-pageHeader--special .dlx-mainMenu {
  transition: opacity 0  , visibility 0  ;
  opacity: 1;
  visibility: inherit;
  background-color: transparent;
}
.dlx-mainMenu__container {
  padding-left: 1rem;
  padding-right: 1rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  min-height: 100%;
  padding-bottom: 1rem;
  padding-top: 3rem;
  width: 100%;
}
@media (min-width: 720px) {
  .dlx-mainMenu__container {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
@media (min-width: 1024px) {
  .dlx-mainMenu__container {
    padding-left: 3.2rem;
    padding-right: 3.2rem;
  }
}
@media (min-width: 1450px) {
  .dlx-mainMenu__container {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
@media (min-width: 720px) {
  .dlx-mainMenu__container {
    padding-bottom: 10rem;
    padding-top: 10rem;
  }
}
@media (min-width: 1024px) {
  .dlx-mainMenu__container {
    padding-right: 0;
    padding-top: 3rem;
  }
}
@media (min-width: 1450px) {
  .dlx-mainMenu__container {
    padding-top: 3rem;
  }
}
.dlx-mainMenu__sectionMain {
  overflow: hidden;
  transition: opacity 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94) , visibility 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94) ;
  opacity: 1;
  visibility: inherit;
}
@media (min-width: 1024px) {
  .dlx-pageHeader.dlx-js-menuHidden .dlx-mainMenu__sectionMain {
    transition: opacity 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94) , visibility 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94) ;
    opacity: 0;
    visibility: hidden;
  }
}
@media (min-width: 1024px) {
  .dlx-mainMenu__sectionMain {
    z-index: 5;
  }
}
.dlx-mainMenu__sectionAdditional {
  left: 0;
  margin-top: auto;
  padding-top: 4rem;
  padding-bottom: 10rem;
}
@media (min-width: 720px) {
  .dlx-mainMenu__sectionAdditional {
    right: 2rem;
    padding-bottom: 0;
  }
}
@media (min-width: 1024px) {
  .dlx-mainMenu__sectionAdditional {
    display: block;
    left: auto;
    position: fixed;
    right: 3.2rem;
    top: 2rem;
    z-index: 20;
    padding-top: 0;
  }
}
@media (min-width: 1450px) {
  .dlx-mainMenu__sectionAdditional {
    right: 2rem;
  }
}
.dlx-pageHeader--special .dlx-mainMenu__sectionAdditional {
  display: block;
  left: auto;
  position: fixed;
  right: 2rem;
  top: 2rem;
  z-index: 20;
  padding-top: 0;
  padding-bottom: 0;
}
@media (min-width: 720px) {
  .dlx-pageHeader--special .dlx-mainMenu__sectionAdditional {
    right: 3.2rem;
    padding-bottom: 0;
  }
}
@media (min-width: 1024px) {
  .dlx-pageHeader--special .dlx-mainMenu__sectionAdditional {
    top: 2.8rem;
  }
}
.dlx-pageHeader--special .dlx-mainMenu__sectionAdditional::before {
  transition: background-color 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94) ;
  content: "";
  position: absolute;
  width: calc(100% + 2 * 1rem);
  height: calc(100% - 4 * 1rem);
  top: 4rem;
  z-index: -1;
  left: -1rem;
  background-color: #f8f8f7;
}
.dlx-body.dlx-js-invertedHeader .dlx-pageHeader--special:not(.dlx-js-scrolledDown) .dlx-mainMenu__sectionAdditional::before {
  background-color: transparent;
}
@media (min-width: 1024px) {
  .dlx-mainMenu__sectionAdditional::before {
    transition: background-color 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94) ;
    content: "";
    position: absolute;
    width: calc(100% + 2 * 1rem);
    height: calc(100% - 4 * 1rem);
    top: 4rem;
    z-index: -1;
    left: -1rem;
    background-color: #f8f8f7;
  }
}
@media (min-width: 1024px) {
  .dlx-body.dlx-js-fullyInvertedHeader .dlx-mainMenu__sectionAdditional::before, .dlx-body.dlx-js-invertedHeader .dlx-pageHeader:not(.dlx-js-scrolledDown) .dlx-mainMenu__sectionAdditional::before {
    background-color: transparent;
  }
}
.dlx-mainMenu__icon {
  transition: opacity 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94) ;
  display: inline-block;
  margin-left: 0.25rem;
  margin-top: 2px;
  opacity: 1;
  height: 2rem;
  width: 3rem;
  vertical-align: top;
}
@media (min-width: 1024px) {
  .dlx-mainMenu__icon {
    opacity: 0;
  }
}
@media (min-width: 1450px) {
  .dlx-mainMenu__icon {
    margin-top: 0;
  }
}
.dlx-mainMenu__togglerLink:hover .dlx-mainMenu__icon, .dlx-mainMenu__togglerLink:focus .dlx-mainMenu__icon {
  opacity: 1;
}
.dlx-mainMenu__icon svg {
  fill: #6a665b;
  stroke: #6a665b;
  transition: transform 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94) ;
}
@media (min-width: 1024px) {
  .dlx-body.dlx-js-fullyInvertedHeader .dlx-mainMenu__icon svg, .dlx-body.dlx-js-invertedHeader .dlx-pageHeader:not(.dlx-js-scrolledDown) .dlx-mainMenu__menuSections--additional .dlx-mainMenu__icon svg {
    fill: #ffffff;
    stroke: #ffffff;
  }
}
.dlx-body.dlx-js-fullyInvertedHeader .dlx-pageHeader--special .dlx-mainMenu__icon svg, .dlx-body.dlx-js-invertedHeader .dlx-pageHeader--special:not(.dlx-js-scrolledDown) .dlx-mainMenu__menuSections--additional .dlx-mainMenu__icon svg {
  fill: #ffffff;
  stroke: #ffffff;
}
.dlx-mainMenu__togglerLink.dlx-js-toggled .dlx-mainMenu__icon svg {
  transform: scaleY(-1);
}
.dlx-mainMenu__menuSections--additional .dlx-mainMenu__icon {
  margin-top: -0.25rem;
}
@media (min-width: 720px) {
  .dlx-mainMenu__menuSections--additional .dlx-mainMenu__icon {
    margin-top: -0.5rem;
  }
}
@media (min-width: 1024px) {
  .dlx-mainMenu__menuSections--additional .dlx-mainMenu__icon {
    margin-top: -0.5rem;
  }
}
@media (min-width: 1450px) {
  .dlx-mainMenu__menuSections--additional .dlx-mainMenu__icon {
    margin-top: -0.75rem;
  }
}
.dlx-mainMenu__menuItems {
  transition: opacity 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94) , visibility 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94) ;
  display: block;
  padding-bottom: 1rem;
  position: relative;
}
.dlx-mainMenu__menuItems:focus {
  outline: 0;
}
.dlx-mainMenu__menuItems.dlx-js-collapsed {
  opacity: 0;
  position: absolute;
  visibility: hidden;
}
.dlx-mainMenu__menuItem {
  font-family: Roboto, sans-serif;
  font-size: 1.8rem;
  font-weight: normal;
  line-height: 1.1111111111;
  color: #6a665b;
  height: auto;
  padding-top: 1.5rem;
}
@media (min-width: 720px) {
  .dlx-mainMenu__menuItem {
    font-size: 1.4rem;
    line-height: 1.2857142857;
  }
}
@media (min-width: 1280px) {
  .dlx-mainMenu__menuItem {
    font-size: 1.8rem;
    line-height: 1.3333333333;
  }
}
@media (min-width: 1450px) {
  .dlx-mainMenu__menuItem {
    font-size: 1.8rem;
    line-height: 1.3333333333;
  }
}
@media (min-width: 1024px) {
  .dlx-body.dlx-js-fullyInvertedHeader .dlx-mainMenu__menuItem, .dlx-body.dlx-js-invertedHeader .dlx-pageHeader:not(.dlx-js-scrolledDown) .dlx-mainMenu__menuSections--additional .dlx-mainMenu__menuItem {
    color: #ffffff;
  }
}
.dlx-body.dlx-js-fullyInvertedHeader .dlx-pageHeader--special:not(.dlx-js-scrolledDown) .dlx-mainMenu__menuItem, .dlx-body.dlx-js-invertedHeader .dlx-pageHeader--special:not(.dlx-js-scrolledDown) .dlx-mainMenu__menuSections--additional .dlx-mainMenu__menuItem {
  color: #ffffff;
}
@media (min-width: 720px) {
  .dlx-mainMenu__menuItem:first-of-type {
    padding-top: 1rem;
  }
}
.dlx-mainMenu__menuLink {
  color: #6a665b;
  position: relative;
  margin: 0;
  padding: 0;
  display: inline-block;
  vertical-align: top;
}
.dlx-mainMenu__menuLink--active {
  font-weight: bold;
}
@media (min-width: 1024px) {
  .dlx-body.dlx-js-fullyInvertedHeader .dlx-mainMenu__menuLink, .dlx-body.dlx-js-invertedHeader .dlx-pageHeader:not(.dlx-js-scrolledDown) .dlx-mainMenu__menuSections--additional .dlx-mainMenu__menuLink {
    color: #ffffff;
  }
}
.dlx-body.dlx-js-fullyInvertedHeader .dlx-pageHeader--special:not(.dlx-js-scrolledDown) .dlx-mainMenu__menuLink, .dlx-body.dlx-js-invertedHeader .dlx-pageHeader--special:not(.dlx-js-scrolledDown) .dlx-mainMenu__menuSections--additional .dlx-mainMenu__menuLink {
  color: #ffffff;
}
.dlx-mainMenu__menuSections--additional .dlx-mainMenu__menuLink {
  font-family: Roboto, sans-serif;
  font-size: 1.3rem;
  font-weight: bold;
  letter-spacing: 0.1rem;
  line-height: 1.2307692308;
  text-transform: uppercase;
  font-weight: normal;
}
@media (min-width: 720px) {
  .dlx-mainMenu__menuSections--additional .dlx-mainMenu__menuLink {
    font-size: 1.4rem;
    line-height: 1.2857142857;
  }
}
@media (min-width: 1450px) {
  .dlx-mainMenu__menuSections--additional .dlx-mainMenu__menuLink {
    font-size: 1.8rem;
    line-height: 1.2222222222;
  }
}
.dlx-pageHeader--special .dlx-mainMenu__menuSections--additional .dlx-mainMenu__menuLink {
  font-weight: normal;
}
.dlx-mainMenu__menuItem--current .dlx-mainMenu__menuLink {
  font-weight: bold;
}
.dlx-mainMenu__menuSection {
  transition: height 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) ;
  overflow: hidden;
  padding-bottom: 1.5rem;
  position: relative;
}
.dlx-mainMenu__menuSections--additional .dlx-mainMenu__menuSection {
  padding-bottom: 1rem;
}
@media (min-width: 1024px) {
  .dlx-mainMenu__menuSections--additional .dlx-mainMenu__menuSection {
    width: auto;
    display: inline-block;
    margin-left: 1.5rem;
  }
}
.dlx-mainMenu__menuSection:last-of-type {
  padding-bottom: 0;
}
.dlx-mainMenu__menuSections {
  transition: opacity 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94) ;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
.dlx-mainMenu__menuSections--additional {
  transition: opacity 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94) , visibility 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94) ;
  opacity: 1;
  visibility: inherit;
}
.dlx-body.dlx-js-searchOpen .dlx-mainMenu__menuSections--additional {
  transition: opacity 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94) , visibility 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94) ;
  opacity: 0;
  visibility: hidden;
}
@media (min-width: 1024px) {
  .dlx-mainMenu__menuSections--additional {
    flex-direction: row;
  }
}
@media (min-width: 720px) {
  .dlx-mainMenu__menuSections {
    margin-left: calc(16.6666666667% + 0.3333333333rem + 0px);
  }
}
@media (min-width: 1024px) {
  .dlx-mainMenu__menuSections {
    margin-left: 0;
  }
}
.dlx-mainMenu__togglerLink {
  transition: color 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94) ;
  white-space: nowrap;
}
.dlx-mainMenu__togglerLink:hover {
  color: #6a665b;
}
.dlx-mainMenu__menuSections--additional .dlx-mainMenu__togglerLink {
  text-transform: uppercase;
}
.dlx-mainMenu__togglerLink--impressum {
  margin-right: 2rem;
  display: none;
}
.dlx-body.dlx-js-homePage .dlx-mainMenu__togglerLink--impressum {
  display: block;
}
.dlx-mainMenu__title {
  font-size: 1.8rem;
  line-height: 1.3333333333;
  font-weight: normal;
  display: inline-block;
  font-weight: 500;
}
@media (min-width: 720px) {
  .dlx-mainMenu__title {
    font-size: 2rem;
    line-height: 1.4;
  }
}
@media (min-width: 1450px) {
  .dlx-mainMenu__title {
    font-size: 2.4rem;
    line-height: 1.4166666667;
  }
}
.dlx-mainMenu__menuSections--additional .dlx-mainMenu__title {
  font-family: Roboto, sans-serif;
  font-size: 1.3rem;
  font-weight: bold;
  letter-spacing: 0.1rem;
  line-height: 1.2307692308;
  color: #6a665b;
  vertical-align: top;
}
@media (min-width: 720px) {
  .dlx-mainMenu__menuSections--additional .dlx-mainMenu__title {
    font-size: 1.4rem;
    line-height: 1.2857142857;
  }
}
@media (min-width: 1450px) {
  .dlx-mainMenu__menuSections--additional .dlx-mainMenu__title {
    font-size: 1.8rem;
    line-height: 1.2222222222;
  }
}
.dlx-pageHeader--special .dlx-mainMenu__title {
  font-weight: normal;
}
@media (min-width: 1024px) {
  .dlx-body.dlx-js-fullyInvertedHeader .dlx-mainMenu__title, .dlx-body.dlx-js-invertedHeader .dlx-pageHeader:not(.dlx-js-scrolledDown) .dlx-mainMenu__menuSections--additional .dlx-mainMenu__title {
    color: #ffffff;
  }
}
.dlx-body.dlx-js-fullyInvertedHeader .dlx-pageHeader--special:not(.dlx-js-scrolledDown) .dlx-mainMenu__title, .dlx-body.dlx-js-invertedHeader .dlx-pageHeader--special:not(.dlx-js-scrolledDown) .dlx-mainMenu__menuSections--additional .dlx-mainMenu__title {
  color: #ffffff;
}

.dlx-modalGallery {
  transition: opacity 0.4s  , visibility 0.4s  ;
  opacity: 0;
  visibility: hidden;
  background-color: #f8f8f7;
  display: none;
  height: 100vh;
  left: 0;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 100;
}
@media (min-width: 720px) {
  .dlx-modalGallery {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
@media (min-width: 1024px) {
  .dlx-modalGallery {
    padding-left: 3.2rem;
    padding-right: 3.2rem;
  }
}
@media (min-width: 1450px) {
  .dlx-modalGallery {
    padding-left: 2rem;
    padding-right: 2rem;
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
}
.dlx-modalGallery.dlx-js-fixed {
  position: fixed;
}
.dlx-modalGallery.dlx-js-beforeActive {
  display: block;
  will-change: opacity, visibility;
}
.dlx-modalGallery.dlx-js-duringActive {
  opacity: 1;
  visibility: inherit;
}
.dlx-modalGallery__content {
  height: 100%;
  position: relative;
  z-index: 1;
}
.dlx-modalGallery__nav {
  bottom: 1rem;
  left: 1rem;
  position: absolute;
  right: 1rem;
  top: 1rem;
  z-index: 2;
}
@media (min-width: 720px) {
  .dlx-modalGallery__nav {
    left: 2rem;
    right: 2rem;
  }
}
@media (min-width: 1024px) {
  .dlx-modalGallery__nav {
    left: 3.2rem;
    right: 3.2rem;
  }
}
@media (min-width: 1450px) {
  .dlx-modalGallery__nav {
    left: 2rem;
    right: 2rem;
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
}

.dlx-newsletter__thankyou {
  display: none;
}

.dlx-pageHeader {
  height: 5.2rem;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 12;
}
@media (min-width: 1024px) {
  .dlx-pageHeader {
    height: 9rem;
  }
}
@media (min-width: 1024px) {
  .dlx-pageHeader--special {
    height: 7.2rem;
  }
}
.dlx-pageHeader__content {
  position: relative;
}
.dlx-pageHeader__head {
  top: 0;
  padding-left: 1rem;
  padding-right: 1rem;
  width: 100%;
  height: 100%;
}
@media (min-width: 720px) {
  .dlx-pageHeader__head {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
@media (min-width: 1024px) {
  .dlx-pageHeader__head {
    padding-left: 3.2rem;
    padding-right: 3.2rem;
  }
}
@media (min-width: 1450px) {
  .dlx-pageHeader__head {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
.dlx-pageHeader__head::before {
  transition: opacity 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94) ;
  background-color: #f8f8f7;
  content: "";
  height: 1rem;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
.dlx-pageHeader__head::after {
  transition: opacity 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94) ;
  background-color: #f8f8f7;
  bottom: 0;
  content: "";
  height: 1rem;
  left: 0;
  opacity: 0;
  position: absolute;
  width: 100%;
}
@media (min-width: 1024px) {
  .dlx-pageHeader__head::before {
    height: 2rem;
  }
  .dlx-pageHeader__head::after {
    height: 2rem;
  }
}
.dlx-pageHeader.dlx-js-scrolledDown .dlx-pageHeader__head::after, .dlx-pageHeader.dlx-js-scrolledDown .dlx-pageHeader__head::before, .dlx-main.dlx-js-covered .dlx-pageHeader__head::after, .dlx-main.dlx-js-covered .dlx-pageHeader__head::before {
  opacity: 1;
}
.dlx-pageHeader__homeLink {
  transition: opacity 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94) ;
  width: 10rem;
  height: 3.2rem;
  background-color: transparent;
  display: block;
  float: right;
  cursor: pointer;
  margin-top: 1rem;
  position: relative;
}
@media (min-width: 1024px) {
  .dlx-pageHeader__homeLink {
    width: 14.5rem;
    height: 5rem;
  }
}
.dlx-pageHeader--special .dlx-pageHeader__homeLink {
  width: 10rem;
  height: 3.2rem;
}
@media (min-width: 1024px) {
  .dlx-pageHeader--special .dlx-pageHeader__homeLink {
    width: 10rem;
    height: 3.2rem;
  }
}
.dlx-pageHeader__homeLink::before {
  transition: opacity 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94) ;
  opacity: 0;
  background-color: #f8f8f7;
  content: "";
  height: 100%;
  left: -100vw;
  pointer-events: none;
  position: absolute;
  top: 0;
  width: 100vw;
  z-index: -1;
}
.dlx-pageHeader__homeLink::after {
  transition: opacity 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94) ;
  opacity: 0;
  background-color: #f8f8f7;
  content: "";
  height: 100%;
  pointer-events: none;
  position: absolute;
  right: -100vw;
  top: 0;
  width: 100vw;
  z-index: -1;
}
.dlx-pageHeader.dlx-js-scrolledDown .dlx-pageHeader__homeLink {
  background-color: transparent;
}
.dlx-pageHeader.dlx-js-scrolledDown .dlx-pageHeader__homeLink::after, .dlx-pageHeader.dlx-js-scrolledDown .dlx-pageHeader__homeLink::before, .dlx-main.dlx-js-covered .dlx-pageHeader__homeLink::after, .dlx-main.dlx-js-covered .dlx-pageHeader__homeLink::before {
  opacity: 1;
}
@media (min-width: 1024px) {
  .dlx-pageHeader__homeLink {
    margin-top: 2rem;
    float: none;
    margin-left: calc(41.6666666667% + 1.3333333333rem + 0px);
  }
}
@media (min-width: 1450px) {
  .dlx-pageHeader__homeLink {
    float: none;
    margin-left: calc(41.6666666667% + 0.8333333333rem + 0px);
  }
}
.dlx-pageHeader__homeLink--special {
  float: left;
}
@media (min-width: 1024px) {
  .dlx-pageHeader__homeLink--special {
    margin-left: 0;
  }
}
@media (min-width: 1450px) {
  .dlx-pageHeader__homeLink--special {
    margin-left: 0;
  }
}
.dlx-pageHeader__logo {
  transition: opacity 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94) ;
  color: #6a665b;
  display: block;
  opacity: 1;
  position: relative;
}
.dlx-pageHeader__logo svg {
  width: 10rem;
  height: 3.2rem;
}
@media (min-width: 1024px) {
  .dlx-pageHeader__logo svg {
    width: 14.5rem;
    height: 5rem;
  }
}
.dlx-pageHeader--special .dlx-pageHeader__logo svg {
  width: 10rem;
  height: 3.2rem;
}
@media (min-width: 1024px) {
  .dlx-pageHeader--special .dlx-pageHeader__logo svg {
    width: 10rem;
    height: 3.2rem;
  }
}
.dlx-body.dlx-js-invertedHeader .dlx-pageHeader:not(.dlx-js-scrolledDown) .dlx-pageHeader__logo, .dlx-body.dlx-js-fullyInvertedHeader .dlx-pageHeader:not(.dlx-js-scrolledDown) .dlx-pageHeader__logo {
  color: #ffffff;
  display: block;
}
.dlx-pageHeader.dlx-js-scrolledDown .dlx-pageHeader__logo, .dlx-main.dlx-js-covered .dlx-pageHeader__logo {
  opacity: 0;
}
.dlx-pageHeader__logo--inverted {
  color: #f8f8f7;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  display: block;
}
.dlx-pageHeader__logo--inverted::after {
  width: 10rem;
  height: 3.2rem;
  background-color: rgba(0, 0, 0, 0.1);
  content: "";
  left: 0;
  position: absolute;
  top: 0;
  z-index: -1;
}
@media (min-width: 1024px) {
  .dlx-pageHeader__logo--inverted::after {
    width: 14.5rem;
    height: 5rem;
  }
}
.dlx-pageHeader--special .dlx-pageHeader__logo--inverted::after {
  width: 10rem;
  height: 3.2rem;
}
@media (min-width: 1024px) {
  .dlx-pageHeader--special .dlx-pageHeader__logo--inverted::after {
    width: 10rem;
    height: 3.2rem;
  }
}
.dlx-body.dlx-js-invertedHeader .dlx-pageHeader__logo--inverted {
  color: #f8f8f7;
}
.dlx-main.dlx-js-covered .dlx-pageHeader__logo--inverted {
  color: #f8f8f7;
  display: block;
  opacity: 1;
}
.dlx-pageHeader.dlx-js-scrolledDown .dlx-pageHeader__logo--inverted {
  display: block;
  opacity: 1;
}
.dlx-pageHeader__logoSvg--small {
  display: block;
  width: 10rem;
  height: 3.2rem;
}
@media (min-width: 1024px) {
  .dlx-pageHeader--special .dlx-pageHeader__logoSvg--small {
    display: block;
  }
}
@media (min-width: 1024px) {
  .dlx-pageHeader__logoSvg--small {
    display: none;
  }
}
.dlx-pageHeader__logoSvg--large {
  display: none;
  width: 14.5rem;
  height: 5rem;
}
@media (min-width: 1024px) {
  .dlx-pageHeader--special .dlx-pageHeader__logoSvg--large {
    display: none;
  }
}
@media (min-width: 1024px) {
  .dlx-pageHeader__logoSvg--large {
    display: block;
  }
}
.dlx-pagination {
  width: 100%;
  margin-left: 0;
  margin-bottom: 3rem;
  margin-top: 3rem;
  position: relative;
}
@media (min-width: 720px) {
  .dlx-pagination {
    margin-left: calc(16.6666666667% + -1.6666666667rem + 2rem);
    width: calc(75% + -0.5rem + 0px);
  }
}
@media (min-width: 1024px) {
  .dlx-pagination {
    margin-left: calc(41.6666666667% + -1.8666666667rem + 3.2rem);
    width: calc(50% + -1.6rem + 0px);
  }
}
@media (min-width: 1450px) {
  .dlx-pagination {
    margin-left: calc(41.6666666667% + -1.1666666667rem + 2rem);
    width: calc(50% + -1rem + 0px);
  }
}
@media (min-width: 1450px) {
  .dlx-pagination {
    margin-top: 6rem;
    margin-bottom: 6rem;
  }
}
.dlx-pagination__item {
  margin-right: 1rem;
  margin-bottom: 1rem;
}
.dlx-pagination__item:not(.dlx-pagination__item--arrow) {
  display: none;
}
@media (min-width: 720px) {
  .dlx-pagination__item:not(.dlx-pagination__item--arrow) {
    display: block;
  }
}
.dlx-pagination__item--arrowPrev {
  left: -100%;
}
.dlx-pagination__itemContent {
  padding: 1rem 2rem;
}
.dlx-pagination__items {
  display: flex;
  flex-direction: row;
  margin-right: -2rem;
  flex-wrap: wrap;
}

.dlx-pictureHeader {
  position: relative;
  width: 100%;
  display: block;
  overflow: hidden;
  margin-bottom: 3rem;
}
.dlx-pictureHeader__container {
  padding-left: 1rem;
  padding-right: 1rem;
  width: 100%;
  color: #ffffff;
  padding-bottom: 2rem;
  padding-top: 6rem;
}
@media (min-width: 720px) {
  .dlx-pictureHeader__container {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
@media (min-width: 1024px) {
  .dlx-pictureHeader__container {
    padding-left: 3.2rem;
    padding-right: 3.2rem;
  }
}
@media (min-width: 1450px) {
  .dlx-pictureHeader__container {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
@media (min-width: 720px) {
  .dlx-pictureHeader__container {
    padding-bottom: 2rem;
    padding-top: 7rem;
  }
}
@media (min-width: 1024px) {
  .dlx-pictureHeader__container {
    padding-bottom: 2rem;
    padding-top: 12rem;
  }
}
.dlx-pictureHeader__content {
  width: 100%;
  margin-left: 0;
}
@media (min-width: 720px) {
  .dlx-pictureHeader__content {
    margin-left: 0;
    width: calc(75% + -0.5rem + 0px);
  }
}
@media (min-width: 1024px) {
  .dlx-pictureHeader__content {
    margin-left: calc(41.6666666667% + -1.8666666667rem + 3.2rem);
    width: calc(50% + -1.6rem + 0px);
  }
}
@media (min-width: 1450px) {
  .dlx-pictureHeader__content {
    margin-left: calc(41.6666666667% + -1.1666666667rem + 2rem);
    width: calc(50% + -1rem + 0px);
  }
}
.dlx-pictureHeader__imageContainer {
  display: block;
  height: 100%;
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 0;
  width: 100vw;
  margin-left: -1rem;
  width: calc(100% + 2 * 1rem);
  z-index: -1;
}
@media (min-width: 720px) {
  .dlx-pictureHeader__imageContainer {
    margin-left: -2rem;
    width: calc(100% + 2 * 2rem);
  }
}
@media (min-width: 1024px) {
  .dlx-pictureHeader__imageContainer {
    margin-left: calc(16.6666666667% + 0.5333333333rem + 0px);
    width: calc(83.3333333333% + -0.5333333333rem + 3.2rem);
  }
}
@media (min-width: 1450px) {
  .dlx-pictureHeader__imageContainer {
    margin-left: calc(16.6666666667% + 0.3333333333rem + 0px);
    width: calc(83.3333333333% + -0.3333333333rem + 2rem);
  }
}
.dlx-pictureHeader__imageParallax {
  display: block;
  position: relative;
  width: 100%;
  height: 100%;
}
.dlx-pictureHeader__image {
  width: 120%;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
}
.dlx-pictureHeader__image::after {
  background-color: rgba(0, 0, 0, 0.2);
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 100;
}
.dlx-pictureHeader__img {
  object-fit: cover;
  font-family: "object-fit: cover";
  opacity: 0;
  overflow: hidden;
  height: 0;
  display: block;
  height: 100%;
  width: 100%;
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  html.no-js .dlx-pictureHeader__img {
    box-sizing: border-box;
    padding-left: 100%;
    background-size: cover;
    background-position: 50% 50%;
  }
}
.dlx-pictureHeader__img.dlx-js-loaded {
  transition: opacity 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) , height 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) ;
  opacity: 1;
  height: 100%;
}
.dlx-pictureHeader__textarea {
  font-family: Roboto, sans-serif;
  font-size: 2.2rem;
  font-weight: normal;
  line-height: 1.1818181818;
  margin-bottom: 2rem;
}
@media (min-width: 720px) {
  .dlx-pictureHeader__textarea {
    font-size: 2.8rem;
    line-height: 1.2857142857;
  }
}
@media (min-width: 1450px) {
  .dlx-pictureHeader__textarea {
    font-size: 4rem;
    line-height: 1.1;
  }
}
.dlx-pictureHeader[data-dlx-intersect] .dlx-pictureHeader__textarea {
  opacity: 0;
  transform: translateY(2rem);
}
.dlx-pictureHeader.dlx-js-inside .dlx-pictureHeader__textarea {
  transition: opacity 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s, transform 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s;
  opacity: 1;
  transform: translateY(0);
}
@media (min-width: 720px) {
  .dlx-pictureHeader__textarea {
    margin-bottom: 3rem;
  }
}
@media (min-width: 1450px) {
  .dlx-pictureHeader__textarea {
    margin-bottom: 5rem;
  }
}
.dlx-pictureHeader__title {
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-size: 4rem;
  font-weight: 500;
  line-height: 1.125;
  margin-bottom: 15rem;
}
@media (min-width: 720px) {
  .dlx-pictureHeader__title {
    font-size: 6rem;
    line-height: 1.0833333333;
  }
}
@media (min-width: 1450px) {
  .dlx-pictureHeader__title {
    font-size: 10rem;
    line-height: 1.1;
  }
}
@media (min-width: 720px) {
  .dlx-pictureHeader__title {
    margin-bottom: 20rem;
  }
}
@media (min-width: 1024px) {
  .dlx-pictureHeader__title {
    margin-bottom: 20rem;
  }
}
@media (min-width: 1450px) {
  .dlx-pictureHeader__title {
    margin-bottom: 25rem;
  }
}
.dlx-pictureHeader__subtitle {
  font-family: Roboto, sans-serif;
  font-size: 1.3rem;
  font-weight: bold;
  letter-spacing: 0.1rem;
  line-height: 1.2307692308;
  color: #ece9e5;
  margin-bottom: 0;
  position: relative;
  text-transform: uppercase;
}
@media (min-width: 720px) {
  .dlx-pictureHeader__subtitle {
    font-size: 1.4rem;
    line-height: 1.2857142857;
  }
}
@media (min-width: 1450px) {
  .dlx-pictureHeader__subtitle {
    font-size: 1.8rem;
    line-height: 1.2222222222;
  }
}
.dlx-pictureHeader[data-dlx-intersect] .dlx-pictureHeader__subtitle {
  opacity: 0;
  transform: translateY(2rem);
}
.dlx-pictureHeader.dlx-js-inside .dlx-pictureHeader__subtitle {
  transition: opacity 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s, transform 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s;
  opacity: 1;
  transform: translateY(0);
}
.dlx-pictureHeader--color1 .dlx-pictureHeader__subtitleHighlight {
  color: #7695a3;
}
.dlx-pictureHeader--color2 .dlx-pictureHeader__subtitleHighlight {
  color: #d1d92b;
}
.dlx-pictureHeader--color3 .dlx-pictureHeader__subtitleHighlight {
  color: #778d34;
}
.dlx-pictureHeader--color4 .dlx-pictureHeader__subtitleHighlight {
  color: #ebba00;
}
.dlx-pictureHeader--color5 .dlx-pictureHeader__subtitleHighlight {
  color: #da7a61;
}
.dlx-pictureHeader--color6 .dlx-pictureHeader__subtitleHighlight {
  color: #afafae;
}

.dlx-squareButton {
  transition: background-color 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94) ;
  background-color: #ece9e5;
  cursor: pointer;
  display: inline-block;
  height: 5.4rem;
  line-height: 5.4rem;
  min-width: 11rem;
  overflow: hidden;
  padding-left: 2rem;
  padding-right: 2rem;
  position: relative;
  text-align: center;
}
@media (min-width: 1450px) {
  .dlx-squareButton {
    height: 6rem;
  }
}
@media (min-width: 1450px) {
  .dlx-squareButton {
    line-height: 6rem;
  }
}
.dlx-squareButton::after {
  transition: opacity 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94) , width 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94) , height 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94) ;
  background-color: #ffffff;
  border-radius: 50%;
  content: "";
  filter: blur(10px);
  height: 10px;
  left: 50%;
  opacity: 0.4;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 10px;
}
.dlx-squareButton:disabled {
  opacity: 0.3;
  cursor: pointer;
  pointer-events: none;
}
.dlx-squareButton:hover::after {
  opacity: 1;
  width: 100px;
  height: 100px;
}
.dlx-squareButton:hover {
  background-color: #ffffff;
}
.dlx-squareButton--color1.dlx-squareButton--colorSet {
  background-color: #c9dfe2;
}

.dlx-squareButton--color2.dlx-squareButton--colorSet {
  background-color: #e3e791;
}

.dlx-squareButton--color3.dlx-squareButton--colorSet {
  background-color: #b1c077;
}

.dlx-squareButton--color4.dlx-squareButton--colorSet {
  background-color: #f3d580;
}

.dlx-squareButton--color5.dlx-squareButton--colorSet {
  background-color: #eab29d;
}

.dlx-squareButton--color6.dlx-squareButton--colorSet {
  background-color: #cacac9;
}

.dlx-squareButton--color1.dlx-squareButton--colorSet::after {
  background-color: #7695a3;
}

.dlx-squareButton--color2.dlx-squareButton--colorSet::after {
  background-color: #d1d92b;
}

.dlx-squareButton--color3.dlx-squareButton--colorSet::after {
  background-color: #778d34;
}

.dlx-squareButton--color4.dlx-squareButton--colorSet::after {
  background-color: #ebba00;
}

.dlx-squareButton--color5.dlx-squareButton--colorSet::after {
  background-color: #da7a61;
}

.dlx-squareButton--color6.dlx-squareButton--colorSet::after {
  background-color: #afafae;
}

.dlx-squareButton--color1.dlx-squareButton--colorSet:hover {
  background-color: #7695a3;
}

.dlx-squareButton--color2.dlx-squareButton--colorSet:hover {
  background-color: #d1d92b;
}

.dlx-squareButton--color3.dlx-squareButton--colorSet:hover {
  background-color: #778d34;
}

.dlx-squareButton--color4.dlx-squareButton--colorSet:hover {
  background-color: #ebba00;
}

.dlx-squareButton--color5.dlx-squareButton--colorSet:hover {
  background-color: #da7a61;
}

.dlx-squareButton--color6.dlx-squareButton--colorSet:hover {
  background-color: #afafae;
}

.dlx-squareButton--lightBg {
  background-color: #ccc5b5;
}
.dlx-squareButton--lightBg::after {
  background-color: #ece9e5;
}
.dlx-squareButton--lightBg:hover {
  background-color: #ece9e5;
}
.dlx-squareButton--specialBg {
  background-color: #778d34;
}
.dlx-squareButton--specialBg::after {
  background-color: #b1c077;
}
.dlx-squareButton--specialBg:hover {
  background-color: #b1c077;
}
.dlx-squareButton--specialBg2 {
  background-color: #c9dfe2;
}
.dlx-squareButton--specialBg2::after {
  background-color: #7695a3;
}
.dlx-squareButton--specialBg2:hover {
  background-color: #7695a3;
}
.dlx-squareButton--specialBg3 {
  background-color: #eab29d;
}
.dlx-squareButton--specialBg3::after {
  background-color: #da7a61;
}
.dlx-squareButton--specialBg3:hover {
  background-color: #da7a61;
}
.dlx-squareButton--invertedBg {
  background-color: #ffffff;
  border: 1px solid #ccc5b5;
}
.dlx-squareButton--invertedBg::after {
  background-color: #ece9e5;
}
.dlx-squareButton--invertedBg:hover {
  background-color: #ece9e5;
}
.dlx-squareButton--invertedBgAlt {
  background-color: #ffffff;
  border: 1px solid #ccc5b5;
}
.dlx-squareButton--invertedBgAlt::after {
  background-color: #ece9e5;
}
.dlx-squareButton--invertedBgAlt:hover {
  background-color: #ece9e5;
}
.dlx-squareButton--borderBg {
  box-shadow: -3px -3px -3px -3px #ffffff inset;
  background-color: transparent;
}
.dlx-squareButton--borderBg::before {
  border: 3px solid #ffffff;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  content: "";
}
.dlx-squareButton--borderBg::after {
  background-color: #ffffff;
}
.dlx-squareButton--borderBg:hover {
  background-color: #ffffff;
}
.dlx-squareButton--alignToLabel {
  margin-top: 2.7rem;
}
@media (min-width: 1450px) {
  .dlx-squareButton--alignToLabel {
    margin-top: 3.1rem;
  }
}
.dlx-squareButton--trackersOptions {
  text-align: center;
  width: 100%;
}
.dlx-squareButton--trackersSave {
  margin-top: 3rem;
}
.dlx-squareButton--trackersOk {
  width: 100%;
}
.dlx-trackers:not(.js-confirmMode) .dlx-squareButton--trackersOk {
  display: none;
}
.dlx-squareButton--trackersAcceptAll {
  margin-left: 2rem;
}
@media (min-width: 1024px) {
  .dlx-squareButton--trackersAcceptAll {
    margin-left: 3.2rem;
  }
}
@media (min-width: 1450px) {
  .dlx-squareButton--trackersAcceptAll {
    margin-left: 2rem;
  }
}
.dlx-squareButton--full {
  width: 100%;
}
.dlx-squareButton--wider {
  min-width: 25rem;
}
.dlx-squareButton__label {
  vertical-align: middle;
  font-family: Roboto, sans-serif;
  font-size: 1.3rem;
  font-weight: bold;
  letter-spacing: 0.1rem;
  line-height: 1.2307692308;
  color: #6a665b;
  display: inline-block;
  width: auto;
  position: relative;
  text-transform: uppercase;
  margin-top: -1px;
  z-index: 1;
}
@media (min-width: 720px) {
  .dlx-squareButton__label {
    font-size: 1.4rem;
    line-height: 1.2857142857;
  }
}
@media (min-width: 1450px) {
  .dlx-squareButton__label {
    font-size: 1.8rem;
    line-height: 1.2222222222;
  }
}
.dlx-squareButton--specialBg .dlx-squareButton__label {
  color: #ffffff;
  font-size: 1.8rem;
  line-height: 1.3333333333;
  font-weight: normal;
}
@media (min-width: 720px) {
  .dlx-squareButton--specialBg .dlx-squareButton__label {
    font-size: 2rem;
    line-height: 1.4;
  }
}
@media (min-width: 1450px) {
  .dlx-squareButton--specialBg .dlx-squareButton__label {
    font-size: 2.4rem;
    line-height: 1.4166666667;
  }
}
.dlx-squareButton--invertedBg .dlx-squareButton__label {
  font-size: 1.8rem;
  line-height: 1.3333333333;
  font-weight: normal;
}
@media (min-width: 720px) {
  .dlx-squareButton--invertedBg .dlx-squareButton__label {
    font-size: 2rem;
    line-height: 1.4;
  }
}
@media (min-width: 1450px) {
  .dlx-squareButton--invertedBg .dlx-squareButton__label {
    font-size: 2.4rem;
    line-height: 1.4166666667;
  }
}
.dlx-squareButton--borderBg .dlx-squareButton__label {
  color: #ffffff;
}
.dlx-squareButton--borderBg:hover .dlx-squareButton__label {
  color: #6a665b;
}
.dlx-squareButton--colorSet:hover .dlx-squareButton__label {
  color: #ffffff;
}

.dlx-textHeader--search[data-dlx-intersect] {
  opacity: 0;
  transform: translateY(2rem);
}
.dlx-textHeader--search.dlx-js-inside {
  transition: opacity 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s, transform 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s;
  opacity: 1;
  transform: translateY(0);
}
.dlx-textHeader__container {
  padding-left: 1rem;
  padding-right: 1rem;
  width: 100%;
  color: #323232;
  margin-bottom: 6rem;
  padding-top: 6rem;
}
@media (min-width: 720px) {
  .dlx-textHeader__container {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
@media (min-width: 1024px) {
  .dlx-textHeader__container {
    padding-left: 3.2rem;
    padding-right: 3.2rem;
  }
}
@media (min-width: 1450px) {
  .dlx-textHeader__container {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
@media (min-width: 720px) {
  .dlx-textHeader__container {
    margin-bottom: 8rem;
    padding-top: 7rem;
  }
}
@media (min-width: 1024px) {
  .dlx-textHeader__container {
    margin-bottom: 10rem;
    padding-top: 12rem;
  }
}
.dlx-textHeader__content {
  width: 100%;
  margin-left: 0;
}
@media (min-width: 720px) {
  .dlx-textHeader__content {
    margin-left: 0;
    width: calc(75% + -0.5rem + 0px);
  }
}
@media (min-width: 1024px) {
  .dlx-textHeader__content {
    margin-left: calc(41.6666666667% + -1.8666666667rem + 3.2rem);
    width: calc(50% + -1.6rem + 0px);
  }
}
@media (min-width: 1450px) {
  .dlx-textHeader__content {
    margin-left: calc(41.6666666667% + -1.1666666667rem + 2rem);
    width: calc(50% + -1rem + 0px);
  }
}
.dlx-textHeader__searchQuery {
  font-style: italic;
}
.dlx-textHeader__textarea {
  font-family: Roboto, sans-serif;
  font-size: 2.2rem;
  font-weight: normal;
  line-height: 1.1818181818;
}
@media (min-width: 720px) {
  .dlx-textHeader__textarea {
    font-size: 2.8rem;
    line-height: 1.2857142857;
  }
}
@media (min-width: 1450px) {
  .dlx-textHeader__textarea {
    font-size: 4rem;
    line-height: 1.1;
  }
}
.dlx-textHeader[data-dlx-intersect] .dlx-textHeader__textarea {
  opacity: 0;
  transform: translateY(2rem);
}
.dlx-textHeader.dlx-js-inside .dlx-textHeader__textarea {
  transition: opacity 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s, transform 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s;
  opacity: 1;
  transform: translateY(0);
}
.dlx-textHeader__title {
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-size: 3rem;
  font-weight: 500;
  line-height: 1.1666666667;
  margin-bottom: 4rem;
}
@media (min-width: 720px) {
  .dlx-textHeader__title {
    font-size: 4.5rem;
    line-height: 1.2222222222;
  }
}
@media (min-width: 1450px) {
  .dlx-textHeader__title {
    font-size: 7rem;
    line-height: 1.1142857143;
  }
}
@media (min-width: 1024px) {
  .dlx-textHeader__title {
    margin-bottom: 6rem;
  }
}
@media (min-width: 1450px) {
  .dlx-textHeader__title {
    margin-bottom: 8rem;
  }
}
.dlx-textHeader--search .dlx-textHeader__title {
  font-family: Roboto, sans-serif;
  font-size: 2.2rem;
  font-weight: normal;
  line-height: 1.1818181818;
}
@media (min-width: 720px) {
  .dlx-textHeader--search .dlx-textHeader__title {
    font-size: 2.8rem;
    line-height: 1.2857142857;
  }
}
@media (min-width: 1450px) {
  .dlx-textHeader--search .dlx-textHeader__title {
    font-size: 4rem;
    line-height: 1.1;
  }
}

.dlx-ugc[data-dlx-intersect] {
  opacity: 0;
  transform: translateY(2rem);
}
.dlx-ugc.dlx-js-inside {
  transition: opacity 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s, transform 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s;
  opacity: 1;
  transform: translateY(0);
}
.dlx-ugc p {
  margin-bottom: 2rem;
}
.dlx-ugc p:last-of-type {
  margin-bottom: 0;
}
.dlx-ugc p + ul {
  margin-top: 2rem;
}
.dlx-ugc ul {
  list-style: none;
  padding-left: 0;
}
.dlx-ugc ul li {
  position: relative;
  margin-bottom: 1rem;
}
@media (min-width: 1024px) {
  .dlx-ugc ul li {
    margin-bottom: 1.5rem;
  }
}
.dlx-ugc ul li::before {
  content: "● ";
  list-style: none;
  margin-right: 1rem;
  padding-left: 0;
  opacity: 0;
}
.dlx-ugc.dlx-js-inside li:nth-child(0)::before {
  transition: opacity 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s;
  opacity: 1;
}
.dlx-ugc.dlx-js-inside li:nth-child(1)::before {
  transition: opacity 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.05s;
  opacity: 1;
}
.dlx-ugc.dlx-js-inside li:nth-child(2)::before {
  transition: opacity 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.1s;
  opacity: 1;
}
.dlx-ugc.dlx-js-inside li:nth-child(3)::before {
  transition: opacity 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.15s;
  opacity: 1;
}
.dlx-ugc.dlx-js-inside li:nth-child(4)::before {
  transition: opacity 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.2s;
  opacity: 1;
}
.dlx-ugc.dlx-js-inside li:nth-child(5)::before {
  transition: opacity 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.25s;
  opacity: 1;
}
.dlx-ugc.dlx-js-inside li:nth-child(6)::before {
  transition: opacity 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.3s;
  opacity: 1;
}
.dlx-ugc.dlx-js-inside li:nth-child(7)::before {
  transition: opacity 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.35s;
  opacity: 1;
}
.dlx-ugc.dlx-js-inside li:nth-child(8)::before {
  transition: opacity 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.4s;
  opacity: 1;
}
.dlx-ugc.dlx-js-inside li:nth-child(9)::before {
  transition: opacity 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.45s;
  opacity: 1;
}
.dlx-ugc.dlx-js-inside li:nth-child(10)::before {
  transition: opacity 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.5s;
  opacity: 1;
}
.dlx-ugc.dlx-js-inside li:nth-child(11)::before {
  transition: opacity 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.55s;
  opacity: 1;
}
.dlx-ugc.dlx-js-inside li:nth-child(12)::before {
  transition: opacity 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.6s;
  opacity: 1;
}
.dlx-ugc.dlx-js-inside li:nth-child(13)::before {
  transition: opacity 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.65s;
  opacity: 1;
}
.dlx-ugc.dlx-js-inside li:nth-child(14)::before {
  transition: opacity 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.7s;
  opacity: 1;
}
.dlx-ugc.dlx-js-inside li:nth-child(15)::before {
  transition: opacity 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.75s;
  opacity: 1;
}
.dlx-ugc.dlx-js-inside li:nth-child(16)::before {
  transition: opacity 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.8s;
  opacity: 1;
}
.dlx-ugc.dlx-js-inside li:nth-child(17)::before {
  transition: opacity 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.85s;
  opacity: 1;
}
.dlx-ugc.dlx-js-inside li:nth-child(18)::before {
  transition: opacity 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.9s;
  opacity: 1;
}
.dlx-ugc.dlx-js-inside li:nth-child(19)::before {
  transition: opacity 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.95s;
  opacity: 1;
}
.dlx-ugc.dlx-js-inside li:nth-child(20)::before {
  transition: opacity 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 1s;
  opacity: 1;
}
.dlx-ugc--checkmarklist ul li {
  padding-left: 3rem;
}
.dlx-ugc--checkmarklist ul li::before {
  background-image: url("/assets/img/check-mark.svg");
  background-repeat: no-repeat;
  background-size: 1.5rem 1.5rem;
  background-position: left top;
  content: "";
  display: inline-block;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0.25rem;
  width: 2rem;
}
@media (min-width: 720px) {
  .dlx-ugc--checkmarklist ul li::before {
    top: 0.6rem;
  }
}
@media (min-width: 1024px) {
  .dlx-ugc--checkmarklist ul li::before {
    top: 0.6rem;
  }
}
@media (min-width: 1450px) {
  .dlx-ugc--checkmarklist ul li::before {
    top: 0.75rem;
  }
}
.dlx-ugc a {
  border-bottom: 2px solid #7695a3;
  background-color: transparent;
  transition: background-color 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94) ;
}
.dlx-ugc a:hover {
  background-color: #c9dfe2;
}
.dlx-ugc h1,
.dlx-ugc h2,
.dlx-ugc h3 {
  font-size: 2rem;
  line-height: 1.4;
  font-weight: bold;
  margin-bottom: 1rem;
  margin-top: 2rem;
}
@media (min-width: 720px) {
  .dlx-ugc h1,
.dlx-ugc h2,
.dlx-ugc h3 {
    font-size: 2.2rem;
    line-height: 1.3636363636;
  }
}
@media (min-width: 1450px) {
  .dlx-ugc h1,
.dlx-ugc h2,
.dlx-ugc h3 {
    font-size: 2.8rem;
    line-height: 1.2857142857;
  }
}
@media (min-width: 1024px) {
  .dlx-ugc h1,
.dlx-ugc h2,
.dlx-ugc h3 {
    margin-top: 4rem;
    margin-bottom: 2rem;
  }
}
.dlx-ugc blockquote {
  font-family: Roboto, sans-serif;
  font-size: 2.2rem;
  font-weight: normal;
  line-height: 1.1818181818;
  color: #6a665b;
  display: block;
  margin-bottom: 2rem;
  margin-left: 0;
  margin-right: 0;
  margin-top: 2rem;
  padding-left: 2rem;
  padding-right: 0;
  padding-top: 0;
  padding-bottom: 0;
  position: relative;
  width: 100%;
}
@media (min-width: 720px) {
  .dlx-ugc blockquote {
    font-size: 2.8rem;
    line-height: 1.2857142857;
  }
}
@media (min-width: 1450px) {
  .dlx-ugc blockquote {
    font-size: 4rem;
    line-height: 1.1;
  }
}
.dlx-ugc blockquote:first-child {
  margin-top: 0;
}
@media (min-width: 720px) {
  .dlx-ugc blockquote:first-child {
    margin-top: 0;
  }
}
@media (min-width: 1024px) {
  .dlx-ugc blockquote:first-child {
    margin-top: 0;
  }
}
@media (min-width: 1450px) {
  .dlx-ugc blockquote:first-child {
    margin-top: 0;
  }
}
.dlx-ugc blockquote:last-child {
  margin-bottom: 0;
}
@media (min-width: 720px) {
  .dlx-ugc blockquote:last-child {
    margin-bottom: 0;
  }
}
@media (min-width: 1024px) {
  .dlx-ugc blockquote:last-child {
    margin-bottom: 0;
  }
}
@media (min-width: 1450px) {
  .dlx-ugc blockquote:last-child {
    margin-bottom: 0;
  }
}
@media (min-width: 720px) {
  .dlx-ugc blockquote {
    margin-bottom: 3rem;
    margin-top: 3rem;
  }
}
@media (min-width: 1024px) {
  .dlx-ugc blockquote {
    margin-bottom: 4rem;
    padding-left: 3rem;
    margin-top: 4rem;
  }
}
@media (min-width: 1450px) {
  .dlx-ugc blockquote {
    margin-bottom: 6rem;
    margin-top: 6rem;
  }
}
.dlx-ugc blockquote a {
  transition: color 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94) ;
  font-family: Roboto, sans-serif;
  font-size: 1.3rem;
  font-weight: bold;
  letter-spacing: 0.1rem;
  line-height: 1.2307692308;
  background-color: transparent;
  border-bottom: none;
  color: #6a665b;
  display: block;
  margin-top: 1.5rem;
  text-decoration: none;
  text-transform: uppercase;
}
@media (min-width: 720px) {
  .dlx-ugc blockquote a {
    font-size: 1.4rem;
    line-height: 1.2857142857;
  }
}
@media (min-width: 1450px) {
  .dlx-ugc blockquote a {
    font-size: 1.8rem;
    line-height: 1.2222222222;
  }
}
.dlx-ugc blockquote a:hover {
  color: #7695a3;
  background-color: transparent;
}
@media (min-width: 1024px) {
  .dlx-ugc blockquote a {
    margin-top: 2rem;
  }
}
@media (min-width: 1450px) {
  .dlx-ugc blockquote a {
    margin-top: 3rem;
  }
}
.dlx-ugc blockquote::before {
  background-color: #ccc5b5;
  content: "";
  display: block;
  width: 1px;
  height: 0;
  left: 0;
  bottom: 0;
  position: absolute;
}
.dlx-ugc.dlx-js-inside blockquote::before {
  transition: height 1s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.5s;
  height: calc(100% + 0.5rem);
}

.dlx-video {
  margin-left: -1rem;
  width: calc(100% + 2 * 1rem);
  margin-top: 2rem;
  margin-bottom: 2rem;
}
@media (min-width: 720px) {
  .dlx-video {
    margin-left: 0;
    width: calc(100% + 2rem);
  }
}
@media (min-width: 1024px) {
  .dlx-video {
    margin-left: calc(16.6666666667% + 0.5333333333rem + 0px);
    width: calc(83.3333333333% + -0.5333333333rem + 3.2rem);
  }
}
@media (min-width: 1450px) {
  .dlx-video {
    margin-left: calc(16.6666666667% + 0.3333333333rem + 0px);
    width: calc(83.3333333333% + -0.3333333333rem + 2rem);
  }
}
@media (min-width: 1024px) {
  .dlx-video {
    margin-bottom: 4rem;
  }
}
.dlx-video--alternate {
  width: 100%;
  margin-left: 0;
}
@media (min-width: 720px) {
  .dlx-video--alternate {
    margin-left: calc(16.6666666667% + -1.6666666667rem + 2rem);
    width: calc(75% + -0.5rem + 0px);
  }
}
@media (min-width: 1024px) {
  .dlx-video--alternate {
    margin-left: calc(41.6666666667% + -1.8666666667rem + 3.2rem);
    width: calc(50% + -1.6rem + 0px);
  }
}
@media (min-width: 1450px) {
  .dlx-video--alternate {
    margin-left: calc(41.6666666667% + -1.1666666667rem + 2rem);
    width: calc(50% + -1rem + 0px);
  }
}
.dlx-video__caption {
  font-family: Roboto, sans-serif;
  font-size: 1.5rem;
  font-weight: normal;
  line-height: 1.3333333333;
  width: 100%;
  margin-left: 0;
  margin-top: 1rem;
}
@media (min-width: 720px) {
  .dlx-video__caption {
    font-size: 1.6rem;
    line-height: 1.375;
  }
}
@media (min-width: 1450px) {
  .dlx-video__caption {
    font-size: 1.8rem;
    line-height: 1.3333333333;
  }
}
@media (min-width: 720px) {
  .dlx-video__caption {
    margin-left: calc(16.6666666667% + -1.6666666667rem + 2rem);
    width: calc(50% + -1rem + 0px);
  }
}
@media (min-width: 1024px) {
  .dlx-video__caption {
    margin-left: calc(41.6666666667% + -1.8666666667rem + 3.2rem);
    width: calc(33.3333333333% + -2.1333333333rem + 0px);
  }
}
@media (min-width: 1450px) {
  .dlx-video__caption {
    margin-left: calc(41.6666666667% + -1.1666666667rem + 2rem);
    width: calc(25% + -1.5rem + 0px);
  }
}
.dlx-video__content {
  position: relative;
}
.dlx-video__content::before {
  content: "";
  display: block;
  padding-bottom: 56.25%;
}
.dlx-video__content::after {
  background-color: #323232;
  content: "";
  height: 100%;
  left: 0;
  opacity: 0.3;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 2;
}
.dlx-video__icon {
  color: #f8f8f7;
  height: 4rem;
  left: 50%;
  position: absolute;
  top: 50%;
  width: 4rem;
  margin-left: -2rem;
  margin-top: -2rem;
}
.dlx-video__icon [data-type=svg] {
  width: 100%;
  height: 100%;
}
.dlx-video__img {
  object-fit: cover;
  font-family: "object-fit: cover";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1;
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  html.no-js .dlx-video__img {
    box-sizing: border-box;
    padding-left: 100%;
    background-size: cover;
    background-position: 50% 50%;
  }
}
.dlx-video__link {
  display: block;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 3;
}
.dlx-video__player {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 4;
}

.dlx-blockLargeInfo {
  position: relative;
  padding-bottom: 2rem;
  padding-top: 2rem;
}
@media (min-width: 1024px) {
  .dlx-blockLargeInfo {
    padding-bottom: 4rem;
    padding-top: 4rem;
  }
}
.dlx-blockLargeInfo__items {
  width: 100%;
  margin-left: 0;
  display: block;
}
.dlx-blockLargeInfo__items[data-dlx-intersect] {
  opacity: 0;
  transform: translateY(2rem);
}
.dlx-blockLargeInfo__items.dlx-js-inside {
  transition: opacity 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s, transform 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s;
  opacity: 1;
  transform: translateY(0);
}
@media (min-width: 720px) {
  .dlx-blockLargeInfo__items {
    margin-left: calc(16.6666666667% + -1.6666666667rem + 2rem);
    width: calc(75% + -0.5rem + 0px);
  }
}
@media (min-width: 1024px) {
  .dlx-blockLargeInfo__items {
    margin-left: calc(41.6666666667% + -1.8666666667rem + 3.2rem);
    width: calc(50% + -1.6rem + 0px);
  }
}
@media (min-width: 1450px) {
  .dlx-blockLargeInfo__items {
    margin-left: calc(41.6666666667% + -1.1666666667rem + 2rem);
    width: calc(50% + -1rem + 0px);
  }
}
.dlx-blockLargeInfo__item {
  margin-bottom: 3rem;
}
.dlx-blockLargeInfo__item:last-child {
  margin-bottom: 0;
}
.dlx-blockLargeInfo__itemHead {
  font-family: Roboto, sans-serif;
  font-size: 1.5rem;
  font-weight: normal;
  line-height: 1.3333333333;
  margin-bottom: 0.5rem;
}
@media (min-width: 720px) {
  .dlx-blockLargeInfo__itemHead {
    font-size: 1.6rem;
    line-height: 1.375;
  }
}
@media (min-width: 1450px) {
  .dlx-blockLargeInfo__itemHead {
    font-size: 1.8rem;
    line-height: 1.3333333333;
  }
}
.dlx-blockLargeInfo__itemContent {
  font-family: Roboto, sans-serif;
  font-size: 2.2rem;
  font-weight: normal;
  line-height: 1.1818181818;
  color: #6a665b;
}
@media (min-width: 720px) {
  .dlx-blockLargeInfo__itemContent {
    font-size: 2.8rem;
    line-height: 1.2857142857;
  }
}
@media (min-width: 1450px) {
  .dlx-blockLargeInfo__itemContent {
    font-size: 4rem;
    line-height: 1.1;
  }
}

.dlx-blockInquiry {
  padding-left: 1rem;
  padding-right: 1rem;
  width: 100%;
  background-color: #c9dfe2;
  padding-bottom: 1rem;
  padding-top: 2rem;
}
@media (min-width: 720px) {
  .dlx-blockInquiry {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
@media (min-width: 1024px) {
  .dlx-blockInquiry {
    padding-left: 3.2rem;
    padding-right: 3.2rem;
  }
}
@media (min-width: 1450px) {
  .dlx-blockInquiry {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
@media (min-width: 720px) {
  .dlx-blockInquiry {
    padding-top: 3rem;
    padding-bottom: 2rem;
  }
}
@media (min-width: 1450px) {
  .dlx-blockInquiry {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }
}
.dlx-blockInquiry__content {
  width: 100%;
  margin-left: 0;
}
.dlx-blockInquiry__content[data-dlx-intersect] {
  opacity: 0;
  transform: translateY(2rem);
}
.dlx-blockInquiry__content.dlx-js-inside {
  transition: opacity 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s, transform 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s;
  opacity: 1;
  transform: translateY(0);
}
@media (min-width: 720px) {
  .dlx-blockInquiry__content {
    margin-left: calc(16.6666666667% + -1.6666666667rem + 2rem);
    width: calc(75% + -0.5rem + 0px);
  }
}
@media (min-width: 1024px) {
  .dlx-blockInquiry__content {
    margin-left: calc(41.6666666667% + -1.8666666667rem + 3.2rem);
    width: calc(50% + -1.6rem + 0px);
  }
}
@media (min-width: 1450px) {
  .dlx-blockInquiry__content {
    margin-left: calc(41.6666666667% + -1.1666666667rem + 2rem);
    width: calc(50% + -1rem + 0px);
  }
}

.dlx-radioButton {
  background-color: #f8f8f7;
  cursor: pointer;
  display: inline-block;
  height: 5.4rem;
  padding-left: 2rem;
  padding-right: 2rem;
  position: relative;
  margin-bottom: 2rem;
  margin-right: 1.5rem;
}
.dlx-radioButton--alternativeColor {
  background-color: #ccc5b5;
}
.dlx-radioButton--alternativeColor2 {
  background-color: #c9dfe2;
}
@media (min-width: 1024px) {
  .dlx-radioButton {
    margin-right: 2.7rem;
  }
}
@media (min-width: 1450px) {
  .dlx-radioButton {
    margin-right: 1.5rem;
  }
}
.dlx-radioButton__input {
  display: none;
}
@media (min-width: 1450px) {
  .dlx-radioButton {
    height: 6rem;
  }
}
.dlx-radioButton__label {
  display: inline-block;
  line-height: 5.4rem;
}
.dlx-radioButton__label::before {
  transition: opacity 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94) ;
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  background-color: #7695a3;
}
.dlx-radioButton--alternativeColor .dlx-radioButton__label::before {
  background-color: #6a665b;
}
.dlx-radioButton--alternativeColor2 .dlx-radioButton__label::before {
  background-color: #7695a3;
}
.dlx-radioButton__input:checked ~ .dlx-radioButton__label::before {
  opacity: 1;
}
@media (min-width: 1450px) {
  .dlx-radioButton__label {
    line-height: 6rem;
  }
}
.dlx-radioButton__labelText {
  transition: opacity 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94) ;
  font-family: Roboto, sans-serif;
  font-size: 1.3rem;
  font-weight: bold;
  letter-spacing: 0.1rem;
  line-height: 1.2307692308;
  color: #6a665b;
  position: relative;
  text-transform: uppercase;
  width: auto;
  vertical-align: middle;
  margin-top: -1px;
}
@media (min-width: 720px) {
  .dlx-radioButton__labelText {
    font-size: 1.4rem;
    line-height: 1.2857142857;
  }
}
@media (min-width: 1450px) {
  .dlx-radioButton__labelText {
    font-size: 1.8rem;
    line-height: 1.2222222222;
  }
}
.dlx-radioButton__input:checked ~ .dlx-radioButton__label .dlx-radioButton__labelText {
  color: #ffffff;
}

.dlx-mainMenuToggler {
  transition: opacity 0.1s cubic-bezier(0.25, 0.46, 0.45, 0.94) ;
  font-family: Roboto, sans-serif;
  font-size: 1.3rem;
  font-weight: bold;
  letter-spacing: 0.1rem;
  line-height: 1.2307692308;
  color: #6a665b;
  cursor: pointer;
  left: 0;
  position: absolute;
  text-transform: uppercase;
  top: 1rem;
  z-index: 30;
}
@media (min-width: 720px) {
  .dlx-mainMenuToggler {
    font-size: 1.4rem;
    line-height: 1.2857142857;
  }
}
@media (min-width: 1450px) {
  .dlx-mainMenuToggler {
    font-size: 1.8rem;
    line-height: 1.2222222222;
  }
}
.dlx-pageHeader.dlx-js-menuHidden .dlx-mainMenuToggler {
  opacity: 0;
}
.dlx-body.dlx-js-invertedHeader .dlx-mainMenuToggler {
  color: #ffffff;
}
@media (min-width: 1024px) {
  .dlx-body.dlx-js-invertedHeader .dlx-mainMenuToggler {
    color: #6a665b;
  }
}
.dlx-body.dlx-js-searchOpen .dlx-mainMenuToggler {
  display: none;
}
@media (min-width: 1024px) {
  .dlx-body.dlx-js-searchOpen .dlx-mainMenuToggler {
    display: block;
  }
}
.dlx-body.dlx-js-invertedHeader .dlx-pageHeader.dlx-js-scrolledDown .dlx-mainMenuToggler {
  color: #6a665b;
}
@media (min-width: 1024px) {
  .dlx-mainMenuToggler {
    cursor: auto;
    pointer-events: none;
    top: 0;
  }
}
.dlx-mainMenuToggler__icon {
  color: #7695a3;
  display: none;
}
.dlx-mainMenuToggler.dlx-js-close .dlx-mainMenuToggler__icon {
  display: block;
}
.dlx-mainMenuToggler__text {
  display: block;
  min-height: 3rem;
}
.dlx-mainMenuToggler.dlx-js-close .dlx-mainMenuToggler__text {
  display: none;
}
.dlx-body.dlx-js-fullyInvertedHeader .dlx-mainMenuToggler__text {
  color: #ffffff;
}

.dlx-homepage {
  display: none;
  background-color: rgba(0, 0, 0, 0.4);
}
.dlx-homepage.dlx-js-initiated {
  display: block;
}
.dlx-homepage__dotsNav {
  transition: opacity 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) , visibility 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) ;
  opacity: 1;
  visibility: inherit;
  position: absolute;
  right: 1rem;
  top: 50%;
  transform: translateY(-50%);
}
@media (min-width: 1024px) {
  .dlx-body.dlx-js-idle .dlx-homepage__dotsNav {
    transition: opacity 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) , visibility 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) ;
    opacity: 0;
    visibility: hidden;
  }
}
@media (min-width: 720px) {
  .dlx-homepage__dotsNav {
    right: 2rem;
  }
}
@media (min-width: 1024px) {
  .dlx-homepage__dotsNav {
    right: 3.2rem;
  }
}
@media (min-width: 1450px) {
  .dlx-homepage__dotsNav {
    right: 2rem;
  }
}
.dlx-dotsNav__buttons {
  display: block;
  position: relative;
}
.dlx-dotsNav__button {
  display: block;
  height: 1.2rem;
  margin-bottom: 1rem;
  position: relative;
  width: 1.2rem;
  cursor: pointer;
  background-color: transparent;
}
.dlx-dotsNav__dot {
  transition: width 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94) , height 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94) , background-color 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94) ;
  background-color: #ccc5b5;
  border-radius: 50%;
  display: block;
  height: 0.8rem;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 0.8rem;
}
.dlx-dotsNav__button.dlx-js-active .dlx-dotsNav__dot {
  height: 1.2rem;
  width: 1.2rem;
}
.dlx-dotsNav__button--color1.dlx-dotsNav__button.dlx-js-active .dlx-dotsNav__dot {
  background-color: #7695a3;
}

.dlx-dotsNav__button--color2.dlx-dotsNav__button.dlx-js-active .dlx-dotsNav__dot {
  background-color: #d1d92b;
}

.dlx-dotsNav__button--color3.dlx-dotsNav__button.dlx-js-active .dlx-dotsNav__dot {
  background-color: #778d34;
}

.dlx-dotsNav__button--color4.dlx-dotsNav__button.dlx-js-active .dlx-dotsNav__dot {
  background-color: #ebba00;
}

.dlx-dotsNav__button--color5.dlx-dotsNav__button.dlx-js-active .dlx-dotsNav__dot {
  background-color: #da7a61;
}

.dlx-dotsNav__button--color6.dlx-dotsNav__button.dlx-js-active .dlx-dotsNav__dot {
  background-color: #afafae;
}

.dlx-homepageSlide {
  background-color: #323232;
  width: 100vw;
  height: 100vh;
  position: relative;
  padding-left: 1rem;
  padding-right: 1rem;
  width: 100%;
}
@media (min-width: 720px) {
  .dlx-homepageSlide {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
@media (min-width: 1024px) {
  .dlx-homepageSlide {
    padding-left: 3.2rem;
    padding-right: 3.2rem;
  }
}
@media (min-width: 1450px) {
  .dlx-homepageSlide {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
.dlx-homepageSlide::after {
  transition: background-color 0.5s cubic-bezier(0.215, 0.61, 0.355, 1) ;
  background-color: rgba(0, 0, 0, 0.2);
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
.dlx-body.dlx-js-idle .dlx-homepageSlide::after {
  background-color: transparent;
}
.dlx-homepageSlide__content {
  position: relative;
  height: 100%;
  color: #ffffff;
  padding-top: 6rem;
  z-index: 1;
}
@media (min-width: 720px) {
  .dlx-homepageSlide__content {
    padding-top: 7rem;
  }
}
@media (min-width: 1024px) {
  .dlx-homepageSlide__content {
    padding-top: 12rem;
  }
}
@media (min-width: 1450px) {
  .dlx-homepageSlide__content {
    padding-top: 18rem;
  }
}
.dlx-homepageSlide__head {
  width: 100%;
  margin-left: 0;
  transition: opacity 0.5s cubic-bezier(0.215, 0.61, 0.355, 1) ;
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-size: 4rem;
  font-weight: 500;
  line-height: 1.125;
  opacity: 0;
}
@media (min-width: 720px) {
  .dlx-homepageSlide__head {
    margin-left: 0;
    width: calc(75% + -0.5rem + 0px);
  }
}
@media (min-width: 1024px) {
  .dlx-homepageSlide__head {
    margin-left: calc(41.6666666667% + -1.8666666667rem + 3.2rem);
    width: calc(50% + -1.6rem + 0px);
  }
}
@media (min-width: 1450px) {
  .dlx-homepageSlide__head {
    margin-left: calc(41.6666666667% + -1.1666666667rem + 2rem);
    width: calc(50% + -1rem + 0px);
  }
}
@media (min-width: 720px) {
  .dlx-homepageSlide__head {
    font-size: 6rem;
    line-height: 1.0833333333;
  }
}
@media (min-width: 1450px) {
  .dlx-homepageSlide__head {
    font-size: 10rem;
    line-height: 1.1;
  }
}
.dlx-homepageSlide.dlx-js-active .dlx-homepageSlide__head {
  opacity: 0.01;
}
.dlx-homepageSlide__image {
  display: block;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
.dlx-homepageSlide__img {
  object-fit: cover;
  font-family: "object-fit: cover";
  display: block;
  height: 100%;
  width: 100%;
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  html.no-js .dlx-homepageSlide__img {
    box-sizing: border-box;
    padding-left: 100%;
    background-size: cover;
    background-position: 50% 50%;
  }
}
.dlx-homepageSlide__links {
  position: absolute;
  width: 100%;
  bottom: 3rem;
}
@media (min-width: 720px) {
  .dlx-homepageSlide__links {
    bottom: 4rem;
  }
}
@media (min-width: 1450px) {
  .dlx-homepageSlide__links {
    bottom: 8rem;
  }
}
.dlx-homepageSlide__subhead {
  width: 100%;
  margin-left: 0;
  transition: opacity 0.5s cubic-bezier(0.215, 0.61, 0.355, 1) ;
  bottom: 1rem;
  opacity: 0;
  position: absolute;
  width: 100%;
}
@media (min-width: 720px) {
  .dlx-homepageSlide__subhead {
    margin-left: 0;
    width: calc(75% + -0.5rem + 0px);
  }
}
@media (min-width: 1024px) {
  .dlx-homepageSlide__subhead {
    margin-left: calc(41.6666666667% + -1.8666666667rem + 3.2rem);
    width: calc(50% + -1.6rem + 0px);
  }
}
@media (min-width: 1450px) {
  .dlx-homepageSlide__subhead {
    margin-left: calc(41.6666666667% + -1.1666666667rem + 2rem);
    width: calc(50% + -1rem + 0px);
  }
}
.dlx-homepageSlide.dlx-js-active .dlx-homepageSlide__subhead {
  transition: opacity 0.5s cubic-bezier(0.215, 0.61, 0.355, 1) 0.5s;
  opacity: 1;
}
@media (min-width: 720px) {
  .dlx-homepageSlide__subhead {
    bottom: 2rem;
    width: 100%;
  }
}
@media (min-width: 1450px) {
  .dlx-homepageSlide__subhead {
    bottom: 3rem;
  }
}
.dlx-homepageSlide__subheadText {
  font-family: Roboto, sans-serif;
  font-size: 1.3rem;
  font-weight: bold;
  letter-spacing: 0.1rem;
  line-height: 1.2307692308;
  color: #ece9e5;
  display: block;
  margin-bottom: 0;
  position: relative;
  text-transform: uppercase;
  text-align: right;
  transition: opacity 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) , visibility 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) ;
  opacity: 1;
  visibility: inherit;
}
@media (min-width: 720px) {
  .dlx-homepageSlide__subheadText {
    font-size: 1.4rem;
    line-height: 1.2857142857;
  }
}
@media (min-width: 1450px) {
  .dlx-homepageSlide__subheadText {
    font-size: 1.8rem;
    line-height: 1.2222222222;
  }
}
@media (min-width: 1024px) {
  .dlx-body.dlx-js-idle .dlx-homepageSlide__subheadText {
    transition: opacity 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) , visibility 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) ;
    opacity: 0;
    visibility: hidden;
  }
}
@media (min-width: 1024px) {
  .dlx-homepageSlide__subheadText {
    text-align: left;
  }
}
.dlx-homepageSlide--color1 .dlx-homepageSlide__subheadTextHighlight {
  color: #7695a3;
}
.dlx-homepageSlide--color2 .dlx-homepageSlide__subheadTextHighlight {
  color: #d1d92b;
}
.dlx-homepageSlide--color3 .dlx-homepageSlide__subheadTextHighlight {
  color: #778d34;
}
.dlx-homepageSlide--color4 .dlx-homepageSlide__subheadTextHighlight {
  color: #ebba00;
}
.dlx-homepageSlide--color5 .dlx-homepageSlide__subheadTextHighlight {
  color: #da7a61;
}
.dlx-homepageSlide--color6 .dlx-homepageSlide__subheadTextHighlight {
  color: #afafae;
}
.dlx-homepageSlide__video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  overflow: hidden;
}
.dlx-homepageSlide__video iframe {
  width: 100vw;
  height: 56.25vw;
  /* Given a 16:9 aspect ratio, 9/16*100 = 56.25 */
  min-height: 100vh;
  min-width: 177.77vh;
  /* Given a 16:9 aspect ratio, 16/9*100 = 177.77 */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.dlx-homepageTimer {
  background-color: rgba(0, 0, 0, 0.2);
  bottom: 0;
  height: 0.5rem;
  left: 0;
  position: absolute;
  width: 100%;
}
@media (min-width: 1024px) {
  .dlx-homepageTimer {
    height: 0.75rem;
  }
}
.dlx-homepageTimer.dlx-js-hidden {
  transition: opacity 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) , visibility 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) ;
  opacity: 0;
  visibility: hidden;
}
.dlx-homepageTimer__scroll {
  transition: opacity 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) , visibility 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) ;
  opacity: 1;
  visibility: inherit;
  bottom: 5rem;
  position: absolute;
  right: 1rem;
  display: none;
}
.dlx-homepageTimer__scroll svg {
  fill: #ccc5b5;
}
@media (min-width: 1024px) {
  .dlx-body.dlx-js-idle .dlx-homepageTimer__scroll {
    transition: opacity 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) , visibility 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) ;
    opacity: 0;
    visibility: hidden;
  }
}
@media (min-width: 720px) {
  .dlx-homepageTimer__scroll {
    right: 2rem;
  }
}
@media (min-width: 1024px) {
  .dlx-homepageTimer__scroll {
    display: block;
    right: 3.2rem;
  }
}
@media (min-width: 1450px) {
  .dlx-homepageTimer__scroll {
    right: 2rem;
  }
}
.dlx-homepageTimer__strip {
  background-color: #ffffff;
  bottom: 0;
  height: 0.5rem;
  left: 0;
  position: absolute;
  width: 0;
}

.dlx-homepageLink {
  width: 100%;
  margin-left: 0;
  position: relative;
  opacity: 0;
  margin-bottom: 2rem;
  transform: translateY(2rem);
}
@media (min-width: 720px) {
  .dlx-homepageLink {
    margin-left: 0;
    width: calc(75% + -0.5rem + 0px);
  }
}
@media (min-width: 1024px) {
  .dlx-homepageLink {
    margin-left: calc(41.6666666667% + -1.8666666667rem + 3.2rem);
    width: calc(50% + -1.6rem + 0px);
  }
}
@media (min-width: 1450px) {
  .dlx-homepageLink {
    margin-left: calc(41.6666666667% + -1.1666666667rem + 2rem);
    width: calc(50% + -1rem + 0px);
  }
}
.dlx-homepageLink:nth-child(even) {
  width: 100%;
  margin-left: 0;
}
@media (min-width: 720px) {
  .dlx-homepageLink:nth-child(even) {
    margin-left: calc(16.6666666667% + -1.6666666667rem + 2rem);
    width: calc(75% + -0.5rem + 0px);
  }
}
@media (min-width: 1024px) {
  .dlx-homepageLink:nth-child(even) {
    margin-left: calc(16.6666666667% + -2.6666666667rem + 3.2rem);
    width: calc(50% + -1.6rem + 0px);
  }
}
@media (min-width: 1450px) {
  .dlx-homepageLink:nth-child(even) {
    margin-left: calc(16.6666666667% + -1.6666666667rem + 2rem);
    width: calc(50% + -1rem + 0px);
  }
}
.dlx-homepageLink:nth-child(0) {
  transition: opacity 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) , transform 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) ;
}
.dlx-homepageLink:nth-child(1) {
  transition: opacity 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) , transform 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) ;
}
.dlx-homepageLink:nth-child(2) {
  transition: opacity 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) , transform 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) ;
}
.dlx-homepageLink:nth-child(3) {
  transition: opacity 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) , transform 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) ;
}
.dlx-homepageLink:nth-child(4) {
  transition: opacity 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) , transform 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) ;
}
.dlx-homepageLink:nth-child(5) {
  transition: opacity 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) , transform 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) ;
}
.dlx-homepageLink:nth-child(6) {
  transition: opacity 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) , transform 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) ;
}
.dlx-homepageLink:nth-child(7) {
  transition: opacity 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) , transform 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) ;
}
.dlx-homepageLink:nth-child(8) {
  transition: opacity 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) , transform 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) ;
}
.dlx-homepageLink:nth-child(9) {
  transition: opacity 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) , transform 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) ;
}
.dlx-homepageLink:nth-child(10) {
  transition: opacity 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) , transform 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) ;
}
.dlx-homepageSlide.dlx-js-active .dlx-homepageLink {
  opacity: 1;
  transform: translateY(0);
}
.dlx-homepageSlide.dlx-js-active .dlx-homepageLink:nth-child(0) {
  transition: opacity 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s, transform 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s;
}
.dlx-homepageSlide.dlx-js-active .dlx-homepageLink:nth-child(1) {
  transition: opacity 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.2s, transform 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.2s;
}
.dlx-homepageSlide.dlx-js-active .dlx-homepageLink:nth-child(2) {
  transition: opacity 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.4s, transform 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.4s;
}
.dlx-homepageSlide.dlx-js-active .dlx-homepageLink:nth-child(3) {
  transition: opacity 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.6s, transform 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.6s;
}
.dlx-homepageSlide.dlx-js-active .dlx-homepageLink:nth-child(4) {
  transition: opacity 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.8s, transform 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.8s;
}
.dlx-homepageSlide.dlx-js-active .dlx-homepageLink:nth-child(5) {
  transition: opacity 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 1s, transform 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 1s;
}
.dlx-homepageSlide.dlx-js-active .dlx-homepageLink:nth-child(6) {
  transition: opacity 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 1.2s, transform 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 1.2s;
}
.dlx-homepageSlide.dlx-js-active .dlx-homepageLink:nth-child(7) {
  transition: opacity 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 1.4s, transform 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 1.4s;
}
.dlx-homepageSlide.dlx-js-active .dlx-homepageLink:nth-child(8) {
  transition: opacity 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 1.6s, transform 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 1.6s;
}
.dlx-homepageSlide.dlx-js-active .dlx-homepageLink:nth-child(9) {
  transition: opacity 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 1.8s, transform 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 1.8s;
}
.dlx-homepageSlide.dlx-js-active .dlx-homepageLink:nth-child(10) {
  transition: opacity 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 2s, transform 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 2s;
}
@media (min-width: 720px) {
  .dlx-homepageLink {
    margin-bottom: 4rem;
  }
}
@media (min-width: 1450px) {
  .dlx-homepageLink {
    margin-bottom: 4rem;
  }
}
@media (max-height: 500px) {
  .dlx-homepageLink {
    margin-bottom: 2rem;
  }
}
.dlx-homepageLink__anchor {
  display: block;
  padding-left: 2.5rem;
}
@media (min-width: 720px) {
  .dlx-homepageLink__anchor {
    padding-left: 5.5rem;
  }
}
@media (min-width: 1450px) {
  .dlx-homepageLink__anchor {
    padding-left: 7rem;
  }
}
@media (max-height: 500px) {
  .dlx-homepageLink__anchor {
    padding-left: 2.5rem;
  }
}
.dlx-homepageLink__dot {
  height: 1.5rem;
  left: -2.5rem;
  opacity: 0;
  position: absolute;
  top: 0.5rem;
  transform: scale(0);
  width: 1.5rem;
}
.dlx-homepageLink:nth-child(1) .dlx-homepageLink__dot {
  transition: opacity 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.2s, transform 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.2s;
}
.dlx-homepageLink:nth-child(2) .dlx-homepageLink__dot {
  transition: opacity 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.4s, transform 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.4s;
}
.dlx-homepageLink:nth-child(3) .dlx-homepageLink__dot {
  transition: opacity 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.6s, transform 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.6s;
}
.dlx-homepageLink:nth-child(4) .dlx-homepageLink__dot {
  transition: opacity 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.8s, transform 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.8s;
}
.dlx-homepageLink:nth-child(5) .dlx-homepageLink__dot {
  transition: opacity 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 1s, transform 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 1s;
}
.dlx-homepageLink:nth-child(6) .dlx-homepageLink__dot {
  transition: opacity 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 1.2s, transform 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 1.2s;
}
.dlx-homepageLink:nth-child(7) .dlx-homepageLink__dot {
  transition: opacity 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 1.4s, transform 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 1.4s;
}
.dlx-homepageLink:nth-child(8) .dlx-homepageLink__dot {
  transition: opacity 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 1.6s, transform 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 1.6s;
}
.dlx-homepageLink:nth-child(9) .dlx-homepageLink__dot {
  transition: opacity 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 1.8s, transform 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 1.8s;
}
.dlx-homepageLink:nth-child(10) .dlx-homepageLink__dot {
  transition: opacity 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 2s, transform 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 2s;
}
.dlx-homepageSlide.dlx-js-active .dlx-homepageLink__dot {
  opacity: 1;
  transform: scale(1);
}
@media (min-width: 720px) {
  .dlx-homepageLink__dot {
    height: 3.5rem;
    left: -5.5rem;
    top: 0;
    width: 3.5rem;
  }
}
@media (max-height: 500px) {
  .dlx-homepageLink__dot {
    height: 1.5rem;
    left: -2.5rem;
    top: 0.5rem;
    width: 1.5rem;
  }
}
@media (min-width: 1450px) {
  .dlx-homepageLink__dot {
    height: 5rem;
    left: -7rem;
    top: -0.5rem;
    width: 5rem;
  }
}
.dlx-homepageLink__dotShape {
  transition: transform 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) ;
  border-radius: 50%;
  display: block;
  height: 100%;
  transform: scale(1);
  width: 100%;
}
.dlx-homepageSlide--color1 .dlx-homepageLink__dotShape {
  background-color: #7695a3;
}
.dlx-homepageSlide--color2 .dlx-homepageLink__dotShape {
  background-color: #d1d92b;
}
.dlx-homepageSlide--color3 .dlx-homepageLink__dotShape {
  background-color: #778d34;
}
.dlx-homepageSlide--color4 .dlx-homepageLink__dotShape {
  background-color: #ebba00;
}
.dlx-homepageSlide--color5 .dlx-homepageLink__dotShape {
  background-color: #da7a61;
}
.dlx-homepageSlide--color6 .dlx-homepageLink__dotShape {
  background-color: #afafae;
}
@media (min-width: 1024px) {
  .dlx-body.dlx-js-idle .dlx-homepageLink__dotShape {
    transform: scale(0.5);
  }
}
.dlx-homepageLink:hover .dlx-homepageLink__dotShape {
  transform: scale(0.75);
}
.dlx-homepageLink__content {
  position: relative;
  margin-top: 0.5rem;
}
.dlx-homepageLink__subtitle {
  font-family: Roboto, sans-serif;
  font-size: 1.3rem;
  font-weight: bold;
  letter-spacing: 0.1rem;
  line-height: 1.2307692308;
  text-transform: uppercase;
  transition: opacity 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) , visibility 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) ;
  opacity: 1;
  visibility: inherit;
}
.dlx-homepageSlide--color1 .dlx-homepageLink__subtitle {
  color: #7695a3;
}
.dlx-homepageSlide--color2 .dlx-homepageLink__subtitle {
  color: #d1d92b;
}
.dlx-homepageSlide--color3 .dlx-homepageLink__subtitle {
  color: #778d34;
}
.dlx-homepageSlide--color4 .dlx-homepageLink__subtitle {
  color: #ebba00;
}
.dlx-homepageSlide--color5 .dlx-homepageLink__subtitle {
  color: #da7a61;
}
.dlx-homepageSlide--color6 .dlx-homepageLink__subtitle {
  color: #afafae;
}
@media (min-width: 720px) {
  .dlx-homepageLink__subtitle {
    font-size: 1.4rem;
    line-height: 1.2857142857;
  }
}
@media (min-width: 1450px) {
  .dlx-homepageLink__subtitle {
    font-size: 1.8rem;
    line-height: 1.2222222222;
  }
}
@media (min-width: 1024px) {
  .dlx-body.dlx-js-idle .dlx-homepageLink__subtitle {
    transition: opacity 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) , visibility 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) ;
    opacity: 0;
    visibility: hidden;
  }
}
.dlx-homepageLink__title {
  position: relative;
  font-family: Roboto, sans-serif;
  font-size: 2.2rem;
  font-weight: normal;
  line-height: 1.1818181818;
  transition: opacity 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) , visibility 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) ;
  opacity: 1;
  visibility: inherit;
}
@media (min-width: 720px) {
  .dlx-homepageLink__title {
    font-size: 2.8rem;
    line-height: 1.2857142857;
  }
}
@media (min-width: 1450px) {
  .dlx-homepageLink__title {
    font-size: 4rem;
    line-height: 1.1;
  }
}
@media (max-height: 500px) {
  .dlx-homepageLink__title {
    font-size: 1.8rem;
    line-height: 1.3333333333;
    font-weight: normal;
  }
}
@media (max-height: 500px) and (min-width: 720px) {
  .dlx-homepageLink__title {
    font-size: 2rem;
    line-height: 1.4;
  }
}
@media (max-height: 500px) and (min-width: 1450px) {
  .dlx-homepageLink__title {
    font-size: 2.4rem;
    line-height: 1.4166666667;
  }
}
@media (min-width: 1024px) {
  .dlx-body.dlx-js-idle .dlx-homepageLink__title {
    transition: opacity 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) , visibility 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) ;
    opacity: 0;
    visibility: hidden;
  }
}

.dlx-blockTeam {
  margin-left: 0;
  width: 100%;
}
@media (min-width: 720px) {
  .dlx-blockTeam {
    margin-left: 0;
    width: 100%;
  }
}
@media (min-width: 1024px) {
  .dlx-blockTeam {
    margin-left: calc(25% + 0.8rem + 0px);
    width: calc(66.6666666667% + -1.0666666667rem + 0px);
  }
}
@media (min-width: 1450px) {
  .dlx-blockTeam {
    margin-left: calc(25% + 0.5rem + 0px);
    width: calc(66.6666666667% + -0.6666666667rem + 0px);
  }
}
.dlx-blockTeam[data-dlx-intersect] {
  opacity: 0;
  transform: translateY(2rem);
}
.dlx-blockTeam.dlx-js-inside {
  transition: opacity 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s, transform 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s;
  opacity: 1;
  transform: translateY(0);
}
.dlx-blockTeam__items {
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-left: -2rem;
  margin-top: 0;
}
.dlx-blockTeam__items > * {
  flex-grow: 0;
  flex-shrink: 0;
  margin-left: 2rem;
  margin-top: 0;
}
@media (min-width: 1024px) {
  .dlx-blockTeam__items {
    position: relative;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin-left: -3.2rem;
    margin-top: 0;
  }
  .dlx-blockTeam__items > * {
    flex-grow: 0;
    flex-shrink: 0;
    margin-left: 3.2rem;
    margin-top: 0;
  }
}
@media (min-width: 1450px) {
  .dlx-blockTeam__items {
    position: relative;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin-left: -2rem;
    margin-top: 0;
  }
  .dlx-blockTeam__items > * {
    flex-grow: 0;
    flex-shrink: 0;
    margin-left: 2rem;
    margin-top: 0;
  }
}
.dlx-teamTile {
  margin-bottom: 2rem;
  margin-right: 0;
  position: relative;
  width: calc(50% - 2rem + 0px);
  transition: z-index 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) ;
  z-index: 0;
}
.dlx-teamTile--imageBackground {
  color: #ffffff;
}
.dlx-teamTile__personName {
  font-family: Roboto, sans-serif;
  font-size: 2.2rem;
  font-weight: normal;
  line-height: 1.1818181818;
}
@media (min-width: 720px) {
  .dlx-teamTile__personName {
    font-size: 2.8rem;
    line-height: 1.2857142857;
  }
}
@media (min-width: 1450px) {
  .dlx-teamTile__personName {
    font-size: 2.8rem;
    line-height: 1.2857142857;
  }
}
.dlx-teamTile.dlx-teamTile--expandable .dlx-teamTile__personName--mobileHidden {
  opacity: 0;
}
@media (min-width: 720px) {
  .dlx-teamTile.dlx-teamTile--expandable .dlx-teamTile__personName--mobileHidden {
    opacity: 1;
  }
}
.dlx-teamTile__personPosition {
  font-family: Roboto, sans-serif;
  font-size: 1.4rem;
  font-weight: bold;
  line-height: 1;
}
@media (min-width: 720px) {
  .dlx-teamTile__personPosition {
    font-size: 1.4rem;
    line-height: 1.2857142857;
  }
}
.dlx-teamTile.dlx-teamTile--expandable .dlx-teamTile__personPosition--mobileHidden {
  opacity: 0;
}
@media (min-width: 720px) {
  .dlx-teamTile.dlx-teamTile--expandable .dlx-teamTile__personPosition--mobileHidden {
    opacity: 1;
  }
}
.dlx-teamTile__personDescription {
  padding-top: 3rem;
  font-size: 1.6rem;
  line-height: 1.25;
  font-weight: normal;
}
@media (min-width: 720px) {
  .dlx-teamTile__personDescription {
    padding-top: 9.8rem;
  }
}
@media (min-width: 720px) {
  .dlx-teamTile__personDescription {
    font-size: 1.8rem;
    line-height: 1.5555555556;
  }
}
@media (min-width: 1450px) {
  .dlx-teamTile__personDescription {
    font-size: 2rem;
    line-height: 28, 20;
  }
}
.dlx-teamTile.dlx-teamTile--expandable {
  width: calc(50% - 2rem + 0px);
}
@media (min-width: 720px) {
  .dlx-teamTile.dlx-teamTile--expandable {
    width: calc(25% - 2rem + 0px);
  }
}
@media (min-width: 720px) and (min-width: 1024px) {
  .dlx-teamTile.dlx-teamTile--expandable {
    margin-bottom: 3.2rem;
    width: calc(25% - 3.2rem + 0px);
  }
}
@media (min-width: 720px) and (min-width: 1450px) {
  .dlx-teamTile.dlx-teamTile--expandable {
    margin-bottom: 2rem;
    width: calc(25% - 2rem + 0px);
  }
}
.dlx-teamTile:hover {
  z-index: 3;
}
@media (min-width: 720px) {
  .dlx-teamTile {
    width: calc(25% - 2rem + 0px);
  }
}
@media (min-width: 1024px) {
  .dlx-teamTile {
    margin-bottom: 3.2rem;
    width: calc(25% - 3.2rem + 0px);
  }
}
@media (min-width: 1450px) {
  .dlx-teamTile {
    margin-bottom: 2rem;
    width: calc(25% - 2rem + 0px);
  }
}
.dlx-teamTile__container {
  padding-bottom: 138%;
  width: 100%;
}
.dlx-teamTile__icon {
  display: block;
  position: relative;
  margin-top: 2rem;
  margin-left: 1rem;
  opacity: 0;
}
.dlx-teamTile.dlx-teamTile--expandable .dlx-teamTile__icon {
  opacity: 1;
}
@media (min-width: 1024px) {
  .dlx-teamTile.dlx-teamTile--expandable .dlx-teamTile__icon {
    opacity: 0;
  }
}
.dlx-teamTile:hover .dlx-teamTile__icon {
  transition: opacity 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.3s;
  opacity: 1;
}
.dlx-teamTile__inner {
  transition: width 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) , height 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) ;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
.dlx-teamTile--color1 .dlx-teamTile__inner {
  background-color: #c9dfe2;
}
.dlx-teamTile--color2 .dlx-teamTile__inner {
  background-color: #e3e791;
}
.dlx-teamTile--color3 .dlx-teamTile__inner {
  background-color: #b1c077;
}
.dlx-teamTile--color4 .dlx-teamTile__inner {
  background-color: #f3d580;
}
.dlx-teamTile--color5 .dlx-teamTile__inner {
  background-color: #eab29d;
}
.dlx-teamTile--color6 .dlx-teamTile__inner {
  background-color: #cacac9;
}
.dlx-teamTile.dlx-teamTile--expandable:hover .dlx-teamTile__inner {
  transition: width 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) , height 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) ;
  height: calc(200% + 2rem);
  width: calc(200% + 2rem);
}
@media (min-width: 1024px) {
  .dlx-teamTile.dlx-teamTile--expandable:hover .dlx-teamTile__inner {
    width: calc(200% + 3.2rem);
    height: calc(200% + 3.2rem);
  }
}
@media (min-width: 1450px) {
  .dlx-teamTile.dlx-teamTile--expandable:hover .dlx-teamTile__inner {
    width: calc(200% + 2rem);
    height: calc(200% + 2rem);
  }
}
.dlx-teamTile.dlx-teamTile--expandable:nth-child(2n) .dlx-teamTile__inner {
  left: auto;
  right: 0;
}
@media (min-width: 1024px) {
  .dlx-teamTile.dlx-teamTile--expandable:nth-child(2n) .dlx-teamTile__inner {
    left: 0;
    right: auto;
  }
}
.dlx-teamTile.dlx-teamTile--expandable:nth-child(4n) .dlx-teamTile__inner {
  left: auto;
  right: 0;
}
.dlx-teamTile.dlx-teamTile--expandable:nth-last-child(-n+2) .dlx-teamTile__inner {
  bottom: 0;
  top: auto;
}
@media (min-width: 1024px) {
  .dlx-teamTile.dlx-teamTile--expandable:nth-last-child(-n+4) .dlx-teamTile__inner {
    bottom: 0;
    top: auto;
  }
}
.dlx-teamTile__content {
  font-family: Roboto, sans-serif;
  font-size: 1.3rem;
  font-weight: bold;
  letter-spacing: 0.1rem;
  line-height: 1.2307692308;
  bottom: 1rem;
  font-weight: bold;
  left: 1rem;
  position: absolute;
  opacity: 0;
}
@media (min-width: 720px) {
  .dlx-teamTile__content {
    font-size: 1.4rem;
    line-height: 1.2857142857;
  }
}
@media (min-width: 1450px) {
  .dlx-teamTile__content {
    font-size: 1.8rem;
    line-height: 1.2222222222;
  }
}
.dlx-teamTile__content--color1 {
  background: #7695a3;
}
.dlx-teamTile__content--withImageUnderlay-color1 {
  background: rgba(118, 149, 163, 0.9);
}
.dlx-teamTile__content--color2 {
  background: #ebba00;
}
.dlx-teamTile__content--withImageUnderlay-color2 {
  background: rgba(235, 186, 0, 0.9);
}
.dlx-teamTile__content--color3 {
  background: #778d34;
}
.dlx-teamTile__content--withImageUnderlay-color3 {
  background: rgba(119, 141, 52, 0.9);
}
.dlx-teamTile__content--color4 {
  background: #da7a61;
}
.dlx-teamTile__content--withImageUnderlay-color4 {
  background: rgba(218, 122, 97, 0.9);
}
.dlx-teamTile__content--color5 {
  background: #64556B;
}
.dlx-teamTile__content--withImageUnderlay-color5 {
  background: rgba(100, 85, 107, 0.9);
}
.dlx-teamTile__content--hover {
  top: 0rem;
  left: 0rem;
  padding: 1.5rem;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  height: 100%;
}
.dlx-teamTile.dlx-teamTile--expandable .dlx-teamTile__content {
  opacity: 1;
}
@media (min-width: 1024px) {
  .dlx-teamTile.dlx-teamTile--expandable .dlx-teamTile__content {
    opacity: 0;
  }
}
.dlx-teamTile.dlx-teamTile--cardHover .dlx-teamTile__content {
  opacity: 0;
}
@media (min-width: 1024px) {
  .dlx-teamTile.dlx-teamTile--cardHover .dlx-teamTile__content {
    opacity: 0;
  }
}
.dlx-teamTile:hover .dlx-teamTile__content {
  transition: opacity 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) ;
  opacity: 1;
}
.dlx-teamTile.dlx-teamTile--expandable:hover .dlx-teamTile__content {
  transition: opacity 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.3s;
}
.dlx-teamTile__image {
  display: block;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 0;
}
.dlx-teamTile__image::after {
  content: "";
  background-color: rgba(0, 0, 0, 0.2);
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  right: 0;
  top: 0;
  z-index: 0;
  opacity: 0;
}
.dlx-teamTile.dlx-teamTile--expandable .dlx-teamTile__image::after {
  opacity: 1;
}
@media (min-width: 1024px) {
  .dlx-teamTile.dlx-teamTile--expandable .dlx-teamTile__image::after {
    opacity: 0;
  }
}
.dlx-teamTile:hover .dlx-teamTile__image::after {
  transition: opacity 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) ;
  opacity: 1;
}
.dlx-teamTile__img {
  object-fit: cover;
  font-family: "object-fit: cover";
  display: block;
  height: 100%;
  width: 100%;
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  html.no-js .dlx-teamTile__img {
    box-sizing: border-box;
    padding-left: 100%;
    background-size: cover;
    background-position: 50% 50%;
  }
}
.dlx-teamTile__link {
  display: block;
  height: 100%;
  text-decoration: none;
  width: 100%;
  position: relative;
}
.dlx-teamTile__quote {
  position: relative;
  padding-left: 1rem;
  padding-top: 1rem;
  opacity: 0;
}
.dlx-teamTile.dlx-teamTile--expandable .dlx-teamTile__quote {
  opacity: 1;
}
@media (min-width: 1024px) {
  .dlx-teamTile.dlx-teamTile--expandable .dlx-teamTile__quote {
    opacity: 0;
  }
}
.dlx-teamTile:hover .dlx-teamTile__quote {
  transition: opacity 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.3s;
  opacity: 1;
}

.dlx-cursorEffect {
  display: none;
  pointer-events: none;
  position: fixed;
  z-index: 14;
  top: 0;
  left: 0;
}
@media (min-width: 1024px) {
  .dlx-cursorEffect {
    display: block;
  }
}
.dlx-cursorEffect__dot {
  transition: width 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) , height 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) , opacity 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) ;
  background-color: #ffffff;
  display: block;
  position: absolute;
  height: 0;
  left: 0;
  top: 0;
  width: 0;
  border-radius: 50%;
  transform: translate(-50%, -50%);
  z-index: 6;
  opacity: 0;
}
.dlx-cursorEffect.dlx-js-active .dlx-cursorEffect__dot {
  transition: width 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94) , height 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94) , opacity 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94) ;
  width: 10rem;
  height: 10rem;
  opacity: 0.85;
}

.dlx-blockTable {
  width: 100%;
  margin-left: 0;
}
@media (min-width: 720px) {
  .dlx-blockTable {
    margin-left: calc(16.6666666667% + -1.6666666667rem + 2rem);
    width: calc(75% + -0.5rem + 0px);
  }
}
@media (min-width: 1024px) {
  .dlx-blockTable {
    margin-left: calc(41.6666666667% + -1.8666666667rem + 3.2rem);
    width: calc(50% + -1.6rem + 0px);
  }
}
@media (min-width: 1450px) {
  .dlx-blockTable {
    margin-left: calc(41.6666666667% + -1.1666666667rem + 2rem);
    width: calc(50% + -1rem + 0px);
  }
}
.dlx-blockTable[data-dlx-intersect] {
  opacity: 0;
  transform: translateY(2rem);
}
.dlx-blockTable.dlx-js-inside {
  transition: opacity 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s, transform 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s;
  opacity: 1;
  transform: translateY(0);
}

.dlx-table {
  width: 100%;
  margin-top: 3rem;
  margin-bottom: 3rem;
}
.dlx-table__cell {
  font-family: Roboto, sans-serif;
  font-size: 1.3rem;
  font-weight: bold;
  letter-spacing: 0.1rem;
  line-height: 1.2307692308;
  padding-bottom: 1.5rem;
  position: relative;
  padding-top: 1.5rem;
}
@media (min-width: 720px) {
  .dlx-table__cell {
    font-size: 1.4rem;
    line-height: 1.2857142857;
  }
}
@media (min-width: 1450px) {
  .dlx-table__cell {
    font-size: 1.8rem;
    line-height: 1.2222222222;
  }
}
@media (min-width: 1024px) {
  .dlx-table__cell {
    padding-bottom: 3rem;
    padding-top: 3rem;
  }
}
.dlx-table__cell::after {
  background-color: #ccc5b5;
  bottom: 0;
  content: "";
  display: block;
  height: 1px;
  left: 0;
  position: absolute;
  width: calc(100% - 2rem);
}
@media (min-width: 1024px) {
  .dlx-table__cell::after {
    width: calc(100% - 3.2rem);
  }
}
@media (min-width: 1450px) {
  .dlx-table__cell::after {
    width: calc(100% - 2rem);
  }
}
.dlx-table__headCell {
  font-family: Roboto, sans-serif;
  font-size: 1.3rem;
  font-weight: bold;
  letter-spacing: 0.1rem;
  line-height: 1.2307692308;
  padding-bottom: 1.5rem;
  padding-top: 1.5rem;
  position: relative;
  text-align: left;
  color: #6a665b;
  text-transform: uppercase;
}
@media (min-width: 720px) {
  .dlx-table__headCell {
    font-size: 1.4rem;
    line-height: 1.2857142857;
  }
}
@media (min-width: 1450px) {
  .dlx-table__headCell {
    font-size: 1.8rem;
    line-height: 1.2222222222;
  }
}
@media (min-width: 1024px) {
  .dlx-table__headCell {
    padding-bottom: 3rem;
    padding-top: 3rem;
  }
}
.dlx-table__headCell::after {
  background-color: #6a665b;
  bottom: 0;
  content: "";
  display: block;
  height: 1px;
  left: 0;
  position: absolute;
  width: calc(100% - 2rem);
}
@media (min-width: 1024px) {
  .dlx-table__headCell::after {
    width: calc(100% - 3.2rem);
  }
}
@media (min-width: 1450px) {
  .dlx-table__headCell::after {
    width: calc(100% - 2rem);
  }
}

.dlx-search {
  padding-left: 1rem;
  padding-right: 1rem;
  width: 100%;
  transition: opacity 0.1s cubic-bezier(0.25, 0.46, 0.45, 0.94) , visibility 0.1s cubic-bezier(0.25, 0.46, 0.45, 0.94) ;
  opacity: 0;
  visibility: hidden;
  background-color: #f8f8f7;
  display: block;
  height: calc(100vh - 5.2rem);
  left: 0;
  position: absolute;
  top: 5.2rem;
  width: 100%;
  z-index: 30;
  pointer-events: none;
}
@media (min-width: 720px) {
  .dlx-search {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
@media (min-width: 1024px) {
  .dlx-search {
    padding-left: 3.2rem;
    padding-right: 3.2rem;
  }
}
@media (min-width: 1450px) {
  .dlx-search {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
@media (min-width: 1024px) {
  .dlx-search {
    background-color: transparent;
    position: fixed;
    top: 2rem;
  }
}
.dlx-search--embedded {
  opacity: 1;
  visibility: inherit;
  padding-left: 0;
  padding-right: 0;
  width: 100%;
  height: auto;
  z-index: auto;
  margin-bottom: 2rem;
  pointer-events: auto;
  position: relative;
  top: auto;
}
@media (min-width: 720px) {
  .dlx-search--embedded {
    padding-left: 0;
    padding-right: 0;
  }
}
@media (min-width: 1024px) {
  .dlx-search--embedded {
    padding-left: 0;
    padding-right: 0;
  }
}
@media (min-width: 1450px) {
  .dlx-search--embedded {
    padding-left: 0;
    padding-right: 0;
  }
}
@media (min-width: 1024px) {
  .dlx-search--embedded {
    margin-bottom: 4rem;
    position: relative;
    top: auto;
  }
}
.dlx-body.dlx-js-searchOpen .dlx-search {
  transition: opacity 0.1s cubic-bezier(0.25, 0.46, 0.45, 0.94) , visibility 0.1s cubic-bezier(0.25, 0.46, 0.45, 0.94) ;
  opacity: 1;
  visibility: inherit;
}
.dlx-search__button {
  vertical-align: top;
  margin-top: 2rem;
  width: 100%;
}
@media (min-width: 720px) {
  .dlx-search--embedded .dlx-search__button {
    margin-left: 0;
  }
}
@media (min-width: 720px) {
  .dlx-search__button {
    margin-left: calc(16.6666666667% + 0.3333333333rem + 0px);
    width: calc(66.6666666667% + -0.6666666667rem + 0px);
  }
}
@media (min-width: 1024px) {
  .dlx-search__button {
    margin-top: 0;
    margin-left: 0;
    width: auto;
  }
}
.dlx-search__close {
  position: absolute;
  left: -0.5rem;
  cursor: pointer;
  top: -4.7rem;
}
@media (min-width: 1024px) {
  .dlx-search__close {
    position: relative;
    top: 0;
    left: -0.5rem;
    display: inline-block;
    margin-top: 1.6rem;
  }
}
.dlx-search--embedded .dlx-search__close {
  display: none;
}
.dlx-search__closeIcon [data-type=svg] {
  color: #7695a3;
  transform: scaleX(-1);
}
@media (min-width: 1024px) {
  .dlx-body.dlx-js-fullyInvertedHeader .dlx-search__closeIcon [data-type=svg], .dlx-body.dlx-js-invertedHeader .dlx-pageHeader:not(.dlx-js-scrolledDown) .dlx-search__closeIcon [data-type=svg] {
    color: #ffffff;
  }
}
@media (min-width: 1024px) {
  .dlx-search__closeIcon [data-type=svg] {
    color: #6a665b;
    transform: scaleX(1);
  }
}
.dlx-search__closeIcon--normal {
  display: block;
}
@media (min-width: 1024px) {
  .dlx-search__closeIcon--normal {
    display: none;
  }
}
.dlx-search__closeIcon--desktop {
  display: none;
}
@media (min-width: 1024px) {
  .dlx-search__closeIcon--desktop {
    display: block;
  }
}
.dlx-search__content {
  position: relative;
  width: 100%;
  margin-left: 0;
}
@media (min-width: 1024px) {
  .dlx-search:not(.dlx-search--embedded) .dlx-search__content {
    float: right;
    text-align: right;
    margin-left: calc(41.6666666667% + 1.3333333333rem + 0px);
    width: calc(50% + -1.6rem + 0px);
  }
}
@media (min-width: 1450px) {
  .dlx-search:not(.dlx-search--embedded) .dlx-search__content {
    margin-left: calc(41.6666666667% + 0.8333333333rem + 0px);
    width: calc(50% + -1rem + 0px);
  }
}
.dlx-body.dlx-js-searchOpen .dlx-search__form {
  pointer-events: all;
}
.dlx-search__inputContainer {
  width: 100%;
  padding-top: 3rem;
}
@media (min-width: 720px) {
  .dlx-search__inputContainer {
    margin-left: calc(16.6666666667% + 0.3333333333rem + 0px);
    width: calc(66.6666666667% + -0.6666666667rem + 0px);
  }
}
@media (min-width: 720px) {
  .dlx-search--embedded .dlx-search__inputContainer {
    margin-left: 0;
  }
}
@media (min-width: 1024px) {
  .dlx-search__inputContainer {
    display: inline-block;
    margin-left: 0;
    margin-right: 2.7rem;
    padding-top: 0;
    vertical-align: top;
    width: calc(50% + -1.6rem + 0px);
  }
}
@media (min-width: 1450px) {
  .dlx-search__inputContainer {
    width: calc(50% + -1rem + 0px);
    margin-right: 1.5rem;
  }
}

.dlx-highlight {
  background-color: #b1c077;
  display: inline;
  padding: 1px;
}

.dlx-homeHeadline {
  padding-left: 1rem;
  padding-right: 1rem;
  width: 100%;
  display: block;
  left: 0;
  position: absolute;
  top: 0;
  z-index: 11;
}
@media (min-width: 720px) {
  .dlx-homeHeadline {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
@media (min-width: 1024px) {
  .dlx-homeHeadline {
    padding-left: 3.2rem;
    padding-right: 3.2rem;
  }
}
@media (min-width: 1450px) {
  .dlx-homeHeadline {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
.dlx-homeHeadline__text {
  width: 100%;
  margin-left: 0;
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-size: 4rem;
  font-weight: 500;
  line-height: 1.125;
  font-weight: bold;
  color: #ffffff;
  padding-top: 6rem;
}
@media (min-width: 720px) {
  .dlx-homeHeadline__text {
    margin-left: 0;
    width: calc(75% + -0.5rem + 0px);
  }
}
@media (min-width: 1024px) {
  .dlx-homeHeadline__text {
    margin-left: calc(41.6666666667% + -1.8666666667rem + 3.2rem);
    width: calc(50% + -1.6rem + 0px);
  }
}
@media (min-width: 1450px) {
  .dlx-homeHeadline__text {
    margin-left: calc(41.6666666667% + -1.1666666667rem + 2rem);
    width: calc(50% + -1rem + 0px);
  }
}
@media (min-width: 720px) {
  .dlx-homeHeadline__text {
    font-size: 6rem;
    line-height: 1.0833333333;
  }
}
@media (min-width: 1450px) {
  .dlx-homeHeadline__text {
    font-size: 10rem;
    line-height: 1.1;
  }
}
@media (min-width: 720px) {
  .dlx-homeHeadline__text {
    padding-top: 7rem;
  }
}
@media (min-width: 1024px) {
  .dlx-homeHeadline__text {
    padding-top: 12rem;
  }
}
@media (min-width: 1450px) {
  .dlx-homeHeadline__text {
    padding-top: 18rem;
  }
}
@media (max-height: 500px) {
  .dlx-homeHeadline__text {
    padding-top: 7rem;
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-size: 3rem;
    font-weight: 500;
    line-height: 1.1666666667;
  }
}
@media (max-height: 500px) and (min-width: 720px) {
  .dlx-homeHeadline__text {
    font-size: 4.5rem;
    line-height: 1.2222222222;
  }
}
@media (max-height: 500px) and (min-width: 1450px) {
  .dlx-homeHeadline__text {
    font-size: 7rem;
    line-height: 1.1142857143;
  }
}
.dlx-homeHeadline__word {
  display: inline-block;
  overflow: hidden;
}
.dlx-homeHeadline__wordText {
  display: block;
}
.dlx-homeHeadline__word--delay1 .dlx-homeHeadline__wordText {
  transition: transform 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.05s, opacity 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.05s;
}
.dlx-homeHeadline__word--delay2 .dlx-homeHeadline__wordText {
  transition: transform 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.1s, opacity 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.1s;
}
.dlx-homeHeadline__word--delay3 .dlx-homeHeadline__wordText {
  transition: transform 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.15s, opacity 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.15s;
}
.dlx-homeHeadline__word--delay4 .dlx-homeHeadline__wordText {
  transition: transform 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.2s, opacity 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.2s;
}
.dlx-homeHeadline__word--delay5 .dlx-homeHeadline__wordText {
  transition: transform 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.25s, opacity 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.25s;
}
.dlx-homeHeadline__word--delay6 .dlx-homeHeadline__wordText {
  transition: transform 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.3s, opacity 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.3s;
}
.dlx-homeHeadline__word--delay7 .dlx-homeHeadline__wordText {
  transition: transform 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.35s, opacity 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.35s;
}
.dlx-homeHeadline__word--delay8 .dlx-homeHeadline__wordText {
  transition: transform 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.4s, opacity 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.4s;
}
.dlx-homeHeadline__word--delay9 .dlx-homeHeadline__wordText {
  transition: transform 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.45s, opacity 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.45s;
}
.dlx-homeHeadline__word--delay10 .dlx-homeHeadline__wordText {
  transition: transform 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.5s, opacity 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.5s;
}
.dlx-homeHeadline__word--delay11 .dlx-homeHeadline__wordText {
  transition: transform 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.55s, opacity 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.55s;
}
.dlx-homeHeadline__word--delay12 .dlx-homeHeadline__wordText {
  transition: transform 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.6s, opacity 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.6s;
}
.dlx-homeHeadline__word--delay13 .dlx-homeHeadline__wordText {
  transition: transform 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.65s, opacity 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.65s;
}
.dlx-homeHeadline__word--delay14 .dlx-homeHeadline__wordText {
  transition: transform 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.7s, opacity 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.7s;
}
.dlx-homeHeadline__word--delay15 .dlx-homeHeadline__wordText {
  transition: transform 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.75s, opacity 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.75s;
}
.dlx-homeHeadline__word--delay16 .dlx-homeHeadline__wordText {
  transition: transform 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.8s, opacity 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.8s;
}
.dlx-homeHeadline__word--delay17 .dlx-homeHeadline__wordText {
  transition: transform 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.85s, opacity 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.85s;
}
.dlx-homeHeadline__word--delay18 .dlx-homeHeadline__wordText {
  transition: transform 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.9s, opacity 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.9s;
}
.dlx-homeHeadline__word--delay19 .dlx-homeHeadline__wordText {
  transition: transform 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.95s, opacity 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.95s;
}
.dlx-homeHeadline__word--delay20 .dlx-homeHeadline__wordText {
  transition: transform 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 1s, opacity 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 1s;
}
.dlx-homeHeadline.dlx-js-up .dlx-homeHeadline__wordText {
  transform: translateY(-120%);
  opacity: 0;
  will-change: transform, opacity;
}
.dlx-homeHeadline.dlx-js-down .dlx-homeHeadline__wordText {
  transform: translateY(120%);
  opacity: 0;
  will-change: transform, opacity;
}
.dlx-homeHeadline.dlx-js-hide.dlx-js-up .dlx-homeHeadline__wordText {
  transform: translateY(120%);
  opacity: 0;
  will-change: transform, opacity;
}
.dlx-homeHeadline.dlx-js-hide.dlx-js-down .dlx-homeHeadline__wordText {
  transform: translateY(-120%);
  opacity: 0;
  will-change: transform, opacity;
}
.dlx-homeHeadline.dlx-js-animate .dlx-homeHeadline__wordText {
  transform: translateY(0);
  opacity: 1;
}

.dlx-animatedHeadline__word {
  display: inline-block;
  overflow: hidden;
}
.dlx-animatedHeadline__wordText {
  display: block;
  transform: translateY(120%);
  opacity: 0;
}
.dlx-animatedHeadline__word--delay1 .dlx-animatedHeadline__wordText {
  transition: transform 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.05s, opacity 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.05s;
}
.dlx-animatedHeadline__word--delay2 .dlx-animatedHeadline__wordText {
  transition: transform 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.1s, opacity 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.1s;
}
.dlx-animatedHeadline__word--delay3 .dlx-animatedHeadline__wordText {
  transition: transform 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.15s, opacity 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.15s;
}
.dlx-animatedHeadline__word--delay4 .dlx-animatedHeadline__wordText {
  transition: transform 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.2s, opacity 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.2s;
}
.dlx-animatedHeadline__word--delay5 .dlx-animatedHeadline__wordText {
  transition: transform 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.25s, opacity 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.25s;
}
.dlx-animatedHeadline__word--delay6 .dlx-animatedHeadline__wordText {
  transition: transform 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.3s, opacity 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.3s;
}
.dlx-animatedHeadline__word--delay7 .dlx-animatedHeadline__wordText {
  transition: transform 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.35s, opacity 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.35s;
}
.dlx-animatedHeadline__word--delay8 .dlx-animatedHeadline__wordText {
  transition: transform 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.4s, opacity 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.4s;
}
.dlx-animatedHeadline__word--delay9 .dlx-animatedHeadline__wordText {
  transition: transform 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.45s, opacity 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.45s;
}
.dlx-animatedHeadline__word--delay10 .dlx-animatedHeadline__wordText {
  transition: transform 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.5s, opacity 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.5s;
}
.dlx-animatedHeadline__word--delay11 .dlx-animatedHeadline__wordText {
  transition: transform 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.55s, opacity 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.55s;
}
.dlx-animatedHeadline__word--delay12 .dlx-animatedHeadline__wordText {
  transition: transform 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.6s, opacity 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.6s;
}
.dlx-animatedHeadline__word--delay13 .dlx-animatedHeadline__wordText {
  transition: transform 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.65s, opacity 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.65s;
}
.dlx-animatedHeadline__word--delay14 .dlx-animatedHeadline__wordText {
  transition: transform 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.7s, opacity 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.7s;
}
.dlx-animatedHeadline__word--delay15 .dlx-animatedHeadline__wordText {
  transition: transform 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.75s, opacity 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.75s;
}
.dlx-animatedHeadline__word--delay16 .dlx-animatedHeadline__wordText {
  transition: transform 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.8s, opacity 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.8s;
}
.dlx-animatedHeadline__word--delay17 .dlx-animatedHeadline__wordText {
  transition: transform 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.85s, opacity 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.85s;
}
.dlx-animatedHeadline__word--delay18 .dlx-animatedHeadline__wordText {
  transition: transform 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.9s, opacity 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.9s;
}
.dlx-animatedHeadline__word--delay19 .dlx-animatedHeadline__wordText {
  transition: transform 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.95s, opacity 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.95s;
}
.dlx-animatedHeadline__word--delay20 .dlx-animatedHeadline__wordText {
  transition: transform 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 1s, opacity 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 1s;
}
.dlx-animatedHeadline.dlx-js-animate .dlx-animatedHeadline__wordText {
  transform: translateY(0);
  opacity: 1;
}

.dlx-blockCallToAction {
  margin-top: -1px;
  padding-top: 4rem;
  padding-bottom: 4rem;
  position: relative;
}
.dlx-blockCallToAction--simple {
  background-color: #ffffff;
  padding-left: 1rem;
  padding-right: 1rem;
  width: 100%;
}
@media (min-width: 720px) {
  .dlx-blockCallToAction--simple {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
@media (min-width: 1024px) {
  .dlx-blockCallToAction--simple {
    padding-left: 3.2rem;
    padding-right: 3.2rem;
  }
}
@media (min-width: 1450px) {
  .dlx-blockCallToAction--simple {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
.dlx-blockCallToAction--simple::after {
  display: none;
}
.dlx-blockCallToAction__content {
  width: 100%;
  margin-left: 0;
  width: 100%;
  padding-top: 1rem;
  position: relative;
}
.dlx-blockCallToAction__content[data-dlx-intersect] {
  opacity: 0;
  transform: translateY(2rem);
}
.dlx-blockCallToAction__content.dlx-js-inside {
  transition: opacity 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s, transform 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s;
  opacity: 1;
  transform: translateY(0);
}
@media (min-width: 720px) {
  .dlx-blockCallToAction__content {
    margin-left: calc(16.6666666667% + -1.6666666667rem + 2rem);
    width: calc(75% + -0.5rem + 0px);
  }
}
@media (min-width: 1024px) {
  .dlx-blockCallToAction__content {
    margin-left: calc(41.6666666667% + -1.8666666667rem + 3.2rem);
    width: calc(50% + -1.6rem + 0px);
  }
}
@media (min-width: 1450px) {
  .dlx-blockCallToAction__content {
    margin-left: calc(41.6666666667% + -1.1666666667rem + 2rem);
    width: calc(50% + -1rem + 0px);
  }
}
@media (min-width: 1024px) {
  .dlx-blockCallToAction__content {
    padding-top: 0;
  }
}
.dlx-blockCallToAction__columns {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 0;
}
.dlx-blockCallToAction__columns > * {
  flex-grow: 0;
  flex-shrink: 0;
  margin-top: 0;
}
.dlx-blockCallToAction__column {
  width: 100%;
  width: 100%;
}
@media (min-width: 720px) {
  .dlx-blockCallToAction__column:first-of-type {
    width: calc(55.5555555556% + -0.8888888889rem + 0px);
  }
}
@media (min-width: 1024px) {
  .dlx-blockCallToAction__column:first-of-type {
    width: 100%;
  }
}
@media (min-width: 1280px) {
  .dlx-blockCallToAction__column:first-of-type {
    width: calc(66.6666666667% + -1.0666666667rem + 0px);
  }
}
@media (min-width: 1450px) {
  .dlx-blockCallToAction__column:first-of-type {
    width: calc(50% + -1rem + 0px);
  }
}
@media (min-width: 720px) {
  .dlx-blockCallToAction__column:last-of-type {
    width: calc(44.4444444444% + -1.1111111111rem + 0px);
  }
}
@media (min-width: 1024px) {
  .dlx-blockCallToAction__column:last-of-type {
    margin-left: 0;
    width: 100%;
  }
}
@media (min-width: 1280px) {
  .dlx-blockCallToAction__column:last-of-type {
    width: calc(33.3333333333% + -2.1333333333rem + 0px);
  }
}
@media (min-width: 1450px) {
  .dlx-blockCallToAction__column:last-of-type {
    width: calc(50% + -1rem + 0px);
  }
}
.dlx-blockCallToAction__link {
  font-family: Roboto, sans-serif;
  font-size: 2.2rem;
  font-weight: normal;
  line-height: 1.1818181818;
  margin-bottom: 2rem;
  display: block;
}
@media (min-width: 720px) {
  .dlx-blockCallToAction__link {
    font-size: 2.8rem;
    line-height: 1.2857142857;
  }
}
@media (min-width: 1450px) {
  .dlx-blockCallToAction__link {
    font-size: 4rem;
    line-height: 1.1;
  }
}
.dlx-blockCallToAction__title {
  font-family: Roboto, sans-serif;
  font-size: 1.3rem;
  font-weight: bold;
  letter-spacing: 0.1rem;
  line-height: 1.2307692308;
  font-weight: normal;
  margin-bottom: 1rem;
  display: block;
}
@media (min-width: 720px) {
  .dlx-blockCallToAction__title {
    font-size: 1.4rem;
    line-height: 1.2857142857;
  }
}
@media (min-width: 1450px) {
  .dlx-blockCallToAction__title {
    font-size: 1.8rem;
    line-height: 1.2222222222;
  }
}

.dlx-radioButtons {
  display: block;
  padding-bottom: 2rem;
  width: 100%;
}
@media (min-width: 1450px) {
  .dlx-radioButtons {
    padding-bottom: 4rem;
  }
}

.dlx-products {
  margin-bottom: 8rem;
}

.dlx-blockKeywords {
  font-family: Roboto, sans-serif;
  font-size: 1.3rem;
  font-weight: bold;
  letter-spacing: 0.1rem;
  line-height: 1.2307692308;
  width: 100%;
  margin-left: 0;
  margin-bottom: 3rem;
  margin-top: 3rem;
  position: relative;
  text-transform: uppercase;
  color: #ccc5b5;
}
@media (min-width: 720px) {
  .dlx-blockKeywords {
    font-size: 1.4rem;
    line-height: 1.2857142857;
  }
}
@media (min-width: 1450px) {
  .dlx-blockKeywords {
    font-size: 1.8rem;
    line-height: 1.2222222222;
  }
}
@media (min-width: 720px) {
  .dlx-blockKeywords {
    margin-left: calc(16.6666666667% + -1.6666666667rem + 2rem);
    width: calc(75% + -0.5rem + 0px);
  }
}
@media (min-width: 1024px) {
  .dlx-blockKeywords {
    margin-left: calc(41.6666666667% + -1.8666666667rem + 3.2rem);
    width: calc(50% + -1.6rem + 0px);
  }
}
@media (min-width: 1450px) {
  .dlx-blockKeywords {
    margin-left: calc(41.6666666667% + -1.1666666667rem + 2rem);
    width: calc(50% + -1rem + 0px);
  }
}
@media (min-width: 1450px) {
  .dlx-blockKeywords {
    margin-top: 6rem;
    margin-bottom: 6rem;
  }
}
.dlx-blockKeywords__label {
  color: #323232;
}

.dlx-trackers {
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  left: 0;
  position: fixed;
  right: 0;
  min-height: 100vh;
  z-index: 20;
  pointer-events: none;
}
html.ie .dlx-trackers {
  height: 99vh;
}
.dlx-trackers.dlx-js-fixed {
  position: fixed;
}
.dlx-trackers.dlx-js-beforeActive {
  position: relative;
}
.dlx-trackers.dlx-js-hidden {
  display: none;
}
.dlx-trackers::before {
  transition: opacity visibility 0.4s ;
  opacity: 0;
  visibility: hidden;
  content: "";
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.2);
  left: 0;
  pointer-events: auto;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 0;
}
.dlx-trackers.dlx-js-modal::before, .dlx-trackers.dlx-js-beforeActive::before {
  transition: opacity 0.2s  , visibility 0.2s  ;
  opacity: 1;
  visibility: inherit;
}
.dlx-trackers__actions {
  margin-top: 2rem;
}
@media (min-width: 1024px) {
  .dlx-trackers__actions {
    width: calc(33.3333333333% + -1.3333333333rem + 0px);
  }
}
.dlx-trackers__container {
  transition: height 0.4s  ;
  background-color: #6a665b;
  color: #ffffff;
  padding: 2rem 0;
  pointer-events: auto;
  position: relative;
  flex-grow: 0;
  flex-shrink: 0;
}
.dlx-trackers__details {
  padding-left: 1rem;
  padding-right: 1rem;
  width: 100%;
  transition: opacity 0.2s  , visibility 0.2s  ;
  left: 0;
  padding-top: 2rem;
  position: relative;
  right: 0;
  top: 0;
}
@media (min-width: 720px) {
  .dlx-trackers__details {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
@media (min-width: 1024px) {
  .dlx-trackers__details {
    padding-left: 3.2rem;
    padding-right: 3.2rem;
  }
}
@media (min-width: 1450px) {
  .dlx-trackers__details {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
.dlx-trackers__details.dlx-js-collapsed {
  opacity: 0;
  position: absolute;
  visibility: hidden;
}
@media (min-width: 1024px) {
  .dlx-trackers__detailsActions {
    margin-left: calc(66.6666666667% + 1.3333333333rem + 0px);
    width: calc(33.3333333333% + -1.3333333333rem + 0px);
  }
}
.dlx-trackers__group {
  transition: height 0.4s  ;
  position: relative;
  overflow: hidden;
}
.dlx-trackers__group + .dlx-trackers__group {
  margin-top: 4rem;
}
.dlx-trackers__groupActions {
  margin-top: 1rem;
  margin-bottom: 2rem;
}
@media (min-width: 1024px) {
  .dlx-trackers__groupActions {
    margin-top: 0;
    margin-left: calc(8.3333333333% + 0.1666666667rem + 0px);
    width: calc(33.3333333333% + -1.3333333333rem + 0px);
  }
}
@media (min-width: 1450px) {
  .dlx-trackers__groupActions {
    margin-left: 0;
    width: calc(25% + -1.5rem + 0px);
    margin-right: calc(8.3333333333% + 0.1666666667rem + 0px);
  }
}
.dlx-trackers__groupDetails {
  transition: opacity 0.2s  , visibility 0.2s  ;
  left: 0;
  position: relative;
  right: 0;
  bottom: 0;
  margin-top: 2rem;
}
.dlx-trackers__groupDetails.dlx-js-collapsed {
  opacity: 0;
  position: absolute;
  visibility: hidden;
}
@media (min-width: 1024px) {
  .dlx-trackers__groupInfo {
    width: calc(58.3333333333% + -0.8333333333rem + 0px);
  }
}
.dlx-trackers__groupSummary {
  margin-top: 1rem;
  padding-top: 2rem;
}
@media (min-width: 1024px) {
  .dlx-trackers__groupSummary {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
  }
  .dlx-trackers__groupSummary > * {
    flex-grow: 0;
    flex-shrink: 0;
  }
}
@media (min-width: 1024px) {
  .dlx-trackers__info {
    width: calc(66.6666666667% + -0.6666666667rem + 0px);
  }
}
.dlx-trackers__summary {
  padding-left: 1rem;
  padding-right: 1rem;
  width: 100%;
}
@media (min-width: 720px) {
  .dlx-trackers__summary {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
@media (min-width: 1024px) {
  .dlx-trackers__summary {
    padding-left: 3.2rem;
    padding-right: 3.2rem;
  }
}
@media (min-width: 1450px) {
  .dlx-trackers__summary {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
@media (min-width: 1024px) {
  .dlx-trackers__summary {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
  }
  .dlx-trackers__summary > * {
    flex-grow: 0;
    flex-shrink: 0;
  }
}
@media (min-width: 1024px) {
  .dlx-trackers__tracker {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
  }
  .dlx-trackers__tracker > * {
    flex-grow: 0;
    flex-shrink: 0;
  }
}
.dlx-trackers__tracker + .dlx-trackers__tracker {
  margin-top: -1rem;
  padding-top: 3rem;
}
.dlx-trackers__trackers {
  padding-top: 2rem;
}
.dlx-trackers__trackerActions {
  margin-top: 1rem;
  margin-bottom: 2rem;
}
@media (min-width: 1024px) {
  .dlx-trackers__trackerActions {
    margin-top: 0;
    margin-left: calc(8.3333333333% + 0.1666666667rem + 0px);
    width: calc(33.3333333333% + -1.3333333333rem + 0px);
  }
}
@media (min-width: 1024px) {
  .dlx-trackers__trackerInfo {
    width: calc(58.3333333333% + -0.8333333333rem + 0px);
  }
}

.dlx-field {
  font-size: 1.8rem;
  line-height: 1.3333333333;
  font-weight: normal;
  width: 100%;
}
@media (min-width: 720px) {
  .dlx-field {
    font-size: 2rem;
    line-height: 1.4;
  }
}
@media (min-width: 1450px) {
  .dlx-field {
    font-size: 2.4rem;
    line-height: 1.4166666667;
  }
}
.dlx-field--disabled {
  opacity: 0.4;
}
.dlx-field--bookingCheck {
  width: 50%;
}
@media (min-width: 1024px) {
  .dlx-field--bookingCheck {
    width: 100%;
  }
}
.dlx-booking__check--out .dlx-field--bookingCheck {
  margin-left: 50%;
}
@media (min-width: 1024px) {
  .dlx-booking__check--out .dlx-field--bookingCheck {
    margin-left: 0;
  }
}
.dlx-form .dlx-field {
  width: calc(100% - 2rem + 0px);
}
@media (min-width: 1024px) {
  .dlx-form .dlx-field {
    width: calc(50% - 2rem + 0px);
  }
}
@media (min-width: 1024px) {
  .dlx-form .dlx-field--full {
    width: calc(100% - 2rem + 0px);
  }
}
@media (min-width: 1024px) {
  .dlx-form .dlx-field--alone {
    margin-right: calc(50% );
  }
}
.dlx-form__separator + .dlx-field {
  margin-top: 4rem;
}
@media (min-width: 1024px) {
  .dlx-form__separator + .dlx-field:not(.dlx-field--full):not(.dlx-field--alone) + .dlx-field:not(.dlx-field--full):not(.dlx-field--alone) {
    margin-top: 4rem;
  }
}
.dlx-field__fields > .dlx-field {
  width: calc(100% - 2rem + 0px);
}
@media (min-width: 1024px) {
  .dlx-field__fields--horizontal > .dlx-field {
    display: inline-block;
    width: auto;
    margin-left: 3rem;
    margin-top: 0;
  }
}
.dlx-trackers .dlx-field__fields--horizontal > .dlx-field {
  margin-left: 0;
  align-content: flex-start;
  margin-right: 2rem;
}
@media (min-width: 1024px) {
  .dlx-trackers .dlx-field__fields--horizontal > .dlx-field {
    margin-right: 3.2rem;
  }
}
@media (min-width: 1450px) {
  .dlx-trackers .dlx-field__fields--horizontal > .dlx-field {
    margin-right: 2rem;
  }
}
@media (min-width: 1024px) {
  .dlx-field__fields--grid > .dlx-field {
    width: calc(33.3333333333% - 2rem + 0px);
  }
}
@media (min-width: 1024px) {
  .dlx-field__fields--grid > .dlx-field--double {
    width: calc(66.6666666667% - 2rem + 0px);
  }
}
.dlx-field--checkboxes .dlx-field, .dlx-field--radios .dlx-field {
  margin-top: 0;
}
.dlx-field__content {
  margin-left: -3rem;
}
.dlx-field--bookingCheck .dlx-field__content {
  margin-left: 0;
  position: relative;
}
.dlx-field--group > .dlx-field__content {
  margin-left: 0;
}
.dlx-field__description {
  margin-left: 3rem;
}
.dlx-field__description:not(.dlx-field__description--extended) {
  margin-top: 0.5rem;
}
.dlx-field--password .dlx-field__description, .dlx-field--select .dlx-field__description, .dlx-field--text .dlx-field__description, .dlx-field--textarea .dlx-field__description {
  margin-top: 0.5rem;
}
.dlx-field--checkbox .dlx-field__description, .dlx-field--radio .dlx-field__description {
  margin-left: 6rem;
}
.dlx-field__errors {
  color: red;
  margin-left: 3rem;
  margin-top: 0.5rem;
}
.dlx-field__fields {
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-left: -2rem;
  margin-top: -3rem;
  position: relative;
}
.dlx-field__fields > * {
  flex-grow: 0;
  flex-shrink: 0;
  margin-left: 2rem;
  margin-top: 3rem;
}
.dlx-field--checkboxes .dlx-field__fields, .dlx-field--radios .dlx-field__fields {
  margin-top: 0;
}
@media (min-width: 1024px) {
  .dlx-field__fields--horizontal {
    font-size: 0;
    display: block;
    margin-top: 0;
    margin-left: -3rem;
  }
}
.dlx-trackers .dlx-field__fields--horizontal {
  margin-left: 0;
}
.dlx-field__info {
  color: #f8f8f7;
  display: block;
}
.dlx-field__info--strong {
  color: #323232;
}
.dlx-field--radio .dlx-field__info, .dlx-field--checkbox .dlx-field__info {
  margin-left: 3rem;
}
.dlx-field__label + .dlx-field__info {
  margin-top: -1rem;
}
@media (min-width: 1024px) {
  .dlx-field__label + .dlx-field__info {
    margin-top: 0;
  }
}
@media (min-width: 1024px) {
  .dlx-field__inputWrapper--extended .dlx-field__info {
    padding: 0.5rem 0;
    margin-left: 0;
    width: calc(16.6666666667% - 2rem + 0px);
  }
}
@media (min-width: 1024px) {
  .dlx-field__inputWrapper--extended .dlx-field__info--compact {
    width: calc(16.6666666667% - 2rem + 0px);
  }
}
@media (min-width: 1024px) {
  .dlx-field__inputWrapper--extended .dlx-field__info--right {
    text-align: right;
  }
}
.dlx-field__inputWrapper--extended .dlx-field__info--first {
  color: #323232;
  margin-left: 3rem;
  flex-grow: 1;
}
.dlx-field__input {
  font-size: 1.8rem;
  line-height: 1.3333333333;
  font-weight: normal;
  background-color: #ccc5b5;
  color: #f8f8f7;
}
@media (min-width: 720px) {
  .dlx-field__input {
    font-size: 2rem;
    line-height: 1.4;
  }
}
@media (min-width: 1450px) {
  .dlx-field__input {
    font-size: 2.4rem;
    line-height: 1.4166666667;
  }
}
.dlx-field__input:focus {
  outline-color: currentColor;
  outline-style: solid;
  outline-width: 1px;
}
.dlx-field__input--bookingCheck {
  height: 100%;
  left: 0;
  opacity: 0;
  pointer-events: all;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1;
  -webkit-appearance: none;
}
@media (min-height: 401px) {
  .dlx-field__input--bookingCheck {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    border: 0;
    overflow: hidden;
    clip: rect(0 0 0 0);
    pointer-events: none;
    visibility: hidden;
  }
}
.dlx-field__input--password, .dlx-field__input--text {
  background-color: #f8f8f7;
  border: 1px solid #ffffff;
  padding: 0.5rem;
  width: 100%;
}
.dlx-field--invalid .dlx-field__input--password, .dlx-field--invalid .dlx-field__input--text {
  border-color: red;
}
@media (min-width: 1024px) {
  .dlx-field--full .dlx-field__input--password, .dlx-field--full .dlx-field__input--text {
    max-width: calc(66.6666666667% + -0.6666666667rem + 0px);
  }
}
@media (min-width: 1024px) {
  .dlx-field--full .dlx-field__fields .dlx-field__input--password, .dlx-field--full .dlx-field__fields .dlx-field__input--text {
    max-width: none;
  }
}
.dlx-field__label--radioText .dlx-field__input--password, .dlx-field__label--radioText .dlx-field__input--text {
  padding: 1rem 0.5rem;
}
.dlx-field--search .dlx-field__input--password, .dlx-field--search .dlx-field__input--text {
  max-width: none;
  width: auto;
  flex-grow: 1;
  flex-shrink: 1;
}
.dlx-field--bookingAmount .dlx-field__input--password, .dlx-field--bookingAmount .dlx-field__input--text {
  background-color: transparent;
  border-radius: 0;
  text-align: center;
  -webkit-appearance: textfield;
}
.dlx-field--bookingAmount .dlx-field__input--password ::placeholder, .dlx-field--bookingAmount .dlx-field__input--text ::placeholder {
  text-align: center;
}
.dlx-field--bookingAmount .dlx-field__input--password::-webkit-inner-spin-button, .dlx-field--bookingAmount .dlx-field__input--password::-webkit-outer-spin-button, .dlx-field--bookingAmount .dlx-field__input--text::-webkit-inner-spin-button, .dlx-field--bookingAmount .dlx-field__input--text::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.dlx-field__input--textarea {
  background-color: #f8f8f7;
  border: 1px solid #ffffff;
  min-height: 11.6rem;
  padding: 1rem;
  width: 100%;
}
@media (min-width: 1024px) {
  .dlx-field--full .dlx-field__input--textarea {
    max-width: calc(66.6666666667% + -0.6666666667rem + 0px);
  }
}
.dlx-field--invalid .dlx-field__input--textarea {
  border-color: red;
}
.dlx-field__input--select {
  background-color: transparent;
  border: 1px solid #ffffff;
  border-radius: 0;
  min-width: 10rem;
  padding: 0.5rem 2rem 0.5rem 0.5rem;
  position: relative;
  width: 100%;
  z-index: 2;
  appearance: none;
}
.dlx-field__input--select::-ms-expand {
  display: none;
}
.dlx-field--invalid .dlx-field__input--select {
  border-color: red;
}
.dlx-field__input--checkbox, .dlx-field__input--radio {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
  overflow: hidden;
  clip: rect(0 0 0 0);
}
.dlx-field__inputWrapper {
  margin-left: 3rem;
  position: relative;
}
.dlx-field--bookingCheck .dlx-field__inputWrapper {
  height: 100%;
  left: 0;
  margin: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1;
}
.dlx-field--select .dlx-field__inputWrapper {
  background-color: #f8f8f7;
}
@media (min-width: 1024px) {
  .dlx-field__inputWrapper--extended {
    position: relative;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    margin-right: -2rem;
  }
  .dlx-field__inputWrapper--extended > * {
    flex-grow: 0;
    flex-shrink: 0;
    margin-right: 2rem;
  }
}
@media (min-width: 1024px) {
  .dlx-field--horizontal .dlx-field__inputWrapper {
    display: inline-block;
  }
}
@media (min-width: 1024px) {
  .dlx-field--full.dlx-field--select .dlx-field__inputWrapper {
    max-width: calc(66.6666666667% + -0.6666666667rem + -2rem);
  }
}
.dlx-field--search .dlx-field__inputWrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
}
@media (min-width: 1024px) {
  .dlx-field--search .dlx-field__inputWrapper {
    max-width: calc(66.6666666667% + -0.6666666667rem + -2rem);
  }
}
.dlx-field--radioText .dlx-field__inputWrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  position: relative;
}
.dlx-field__label {
  font-size: 1.8rem;
  line-height: 1.3333333333;
  font-weight: normal;
  border-color: transparent;
  border-style: solid;
  border-width: 1px 0;
  color: #f8f8f7;
  display: block;
  margin-left: 3rem;
  padding: 0 0;
  position: relative;
}
@media (min-width: 720px) {
  .dlx-field__label {
    font-size: 2rem;
    line-height: 1.4;
  }
}
@media (min-width: 1450px) {
  .dlx-field__label {
    font-size: 2.4rem;
    line-height: 1.4166666667;
  }
}
.dlx-field__label--strong {
  color: #323232;
}
.dlx-field__label--important {
  font-weight: strong;
}
.dlx-field--bookingAmount .dlx-field__label {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
  overflow: hidden;
  clip: rect(0 0 0 0);
}
.dlx-field--bookingCheck .dlx-field__label {
  font-size: 1.8rem;
  line-height: 1.3333333333;
  font-weight: normal;
  border-color: currentColor;
  border-style: solid;
  border-width: 1rem;
  display: block;
  margin: 0;
  padding: 0.5rem 0;
  pointer-events: none;
  position: relative;
  text-align: center;
  width: 100%;
  z-index: 2;
}
@media (min-width: 720px) {
  .dlx-field--bookingCheck .dlx-field__label {
    font-size: 2rem;
    line-height: 1.4;
  }
}
@media (min-width: 1450px) {
  .dlx-field--bookingCheck .dlx-field__label {
    font-size: 2.4rem;
    line-height: 1.4166666667;
  }
}
@media (min-height: 401px) {
  .dlx-field--bookingCheck .dlx-field__label {
    border-bottom-width: 0;
  }
}
@media (min-height: 401px) {
  .dlx-booking__check.dlx-js-active .dlx-field--bookingCheck .dlx-field__label {
    background-color: #f8f8f7;
  }
}
@media (min-width: 1024px) {
  .dlx-booking__check.dlx-js-active .dlx-field--bookingCheck .dlx-field__label {
    background-color: transparent;
  }
}
@media (min-height: 401px) {
  .dlx-field--bookingCheck .dlx-field__label {
    pointer-events: all;
  }
}
@media (min-width: 1024px) {
  .dlx-field--bookingCheck .dlx-field__label {
    pointer-events: none;
  }
}
.dlx-booking__check--out .dlx-field--bookingCheck .dlx-field__label {
  border-left-width: 0;
}
@media (min-width: 1024px) {
  .dlx-booking__check--out .dlx-field--bookingCheck .dlx-field__label {
    border-left-width: 1rem;
  }
}
.dlx-field--group > .dlx-field__content > .dlx-field__label {
  margin-left: 0;
}
.dlx-field__label .dlx-field--password, .dlx-field--text .dlx-field__label {
  display: block;
}
input[type=checkbox] + .dlx-field__label--checkbox::before {
  border: 1px solid #ffffff;
  content: "";
  height: 2rem;
  left: -3rem;
  position: absolute;
  top: 0;
  vertical-align: middle;
  width: 2rem;
}
@media (min-width: 1024px) {
  input[type=checkbox] + .dlx-field__label--checkbox::before {
    top: 0.5rem;
  }
}
@media (min-width: 1450px) {
  input[type=checkbox] + .dlx-field__label--checkbox::before {
    top: 0.7rem;
  }
}
input[type=checkbox] + .dlx-field__label--checkbox::after {
  transition: opacity 0.2s  ;
  background-color: currentColor;
  content: "";
  height: 2rem;
  left: -3rem;
  opacity: 0;
  position: absolute;
  top: 0;
  transform: scale(0.5);
  vertical-align: middle;
  width: 2rem;
}
@media (min-width: 1024px) {
  input[type=checkbox] + .dlx-field__label--checkbox::after {
    top: 0.5rem;
  }
}
@media (min-width: 1450px) {
  input[type=checkbox] + .dlx-field__label--checkbox::after {
    top: 0.7rem;
  }
}
input:focus ~ input[type=checkbox] + .dlx-field__label--checkbox {
  outline-color: currentColor;
  outline-width: 1px;
  outline-style: solid;
}
input[type=checkbox]:checked + .dlx-field__label--checkbox::after {
  opacity: 1;
}
input[disabled] + .dlx-field__label--checkbox::before {
  background-color: #ffffff;
}
input:focus + .dlx-field__label--checkbox::before {
  outline-color: currentColor;
  outline-offset: 1px;
  outline-width: 1px;
  outline-style: dotted;
}
.dlx-field__label--radio {
  margin-bottom: 0.5rem;
}
input[type=radio] + .dlx-field__label--radio::before {
  border: 1px solid #ffffff;
  border-radius: 50%;
  content: "";
  height: 2rem;
  left: -3rem;
  position: absolute;
  top: 0;
  vertical-align: middle;
  width: 2rem;
}
@media (min-width: 1024px) {
  input[type=radio] + .dlx-field__label--radio::before {
    top: 0.5rem;
  }
}
@media (min-width: 1450px) {
  input[type=radio] + .dlx-field__label--radio::before {
    top: 0.7rem;
  }
}
input[type=radio] + .dlx-field__label--radio::after {
  transition: opacity 0.2s  ;
  background-color: currentColor;
  border-radius: 50%;
  content: "";
  height: 2rem;
  left: -3rem;
  opacity: 0;
  position: absolute;
  top: 0;
  transform: scale(0.5);
  vertical-align: middle;
  width: 2rem;
}
@media (min-width: 1024px) {
  input[type=radio] + .dlx-field__label--radio::after {
    top: 0.5rem;
  }
}
@media (min-width: 1450px) {
  input[type=radio] + .dlx-field__label--radio::after {
    top: 0.7rem;
  }
}
input:focus ~ input[type=radio] + .dlx-field__label--radio {
  outline-color: currentColor;
  outline-width: 1px;
  outline-style: solid;
}
input[type=radio]:checked + .dlx-field__label--radio::after {
  opacity: 1;
}
input[disabled] + .dlx-field__label--radio::before {
  background-color: #ffffff;
}
input:focus + .dlx-field__label--radio::before {
  outline-color: currentColor;
  outline-offset: 1px;
  outline-width: 1px;
  outline-style: dotted;
}
.dlx-field--radioText .dlx-field__label--radio {
  bottom: 0;
  flex-grow: 0;
  left: 0;
  padding-right: 0;
  padding-left: 0;
  position: absolute;
  right: 0;
  top: 0;
}
@media (min-width: 1024px) {
  .dlx-field__inputWrapper--extended .dlx-field__label--radio {
    flex-grow: 1;
  }
}
.dlx-field__label--radioText {
  padding: 1px 0;
  flex-grow: 1;
}
@media (min-width: 1024px) {
  .dlx-field__inputWrapper--extended .dlx-field__label {
    width: calc(50% - 2rem + -3rem);
  }
}
.dlx-field--required > .dlx-field__content > .dlx-field__label .dlx-field__labelValue::after, .dlx-field--required > .dlx-field__content > .dlx-field__inputWrapper > .dlx-field__label .dlx-field__labelValue::after {
  content: "*";
}
.dlx-field--radio .dlx-field__labelValue::before {
  content: "";
  position: absolute;
  height: 100%;
  width: 3rem;
  left: -3rem;
  top: 0;
}
.dlx-field__selectIcon {
  color: #f8f8f7;
  position: absolute;
  right: 0.5rem;
  top: 50%;
  height: 1rem;
  width: 1rem;
  text-align: center;
  transform: translateY(-50%) rotate(90deg);
  z-index: 1;
}
.dlx-field__selectIcon [data-type=svg] {
  width: auto;
  height: 100%;
}
.dlx-field__text {
  margin-left: 3rem;
}
.dlx-field__text--info {
  margin-top: 3rem;
  margin-left: 0;
  margin-bottom: 3rem;
}
.dlx-field__link {
  display: block;
  font-weight: bold;
  margin-left: 3rem;
  padding-bottom: 1rem;
  text-decoration: none;
}
html:not(.mobile) .dlx-field__link:hover, .dlx-field__link:active {
  color: #323232;
}

.dlx-seo::after {
  content: " ";
  display: table;
  clear: both;
}
.dlx-seo__content {
  display: block;
}
.dlx-seo__figure {
  margin-top: 2rem;
  display: block;
  width: 100px;
}
.dlx-seo__figure img {
  width: 100px;
}
.dlx-seo__highlight {
  color: red;
}
.dlx-seo__label {
  font-family: Roboto, sans-serif;
  font-size: 1.3rem;
  font-weight: bold;
  letter-spacing: 0.1rem;
  line-height: 1.2307692308;
  float: left;
  width: 25%;
  margin-left: 0;
  margin-top: 1.5rem;
}
@media (min-width: 720px) {
  .dlx-seo__label {
    font-size: 1.4rem;
    line-height: 1.2857142857;
  }
}
@media (min-width: 1450px) {
  .dlx-seo__label {
    font-size: 1.8rem;
    line-height: 1.2222222222;
  }
}
.dlx-seo__label--description {
  width: 70%;
  min-width: 70%;
}
.dlx-seo__label--description::after {
  content: "z";
  visibility: hidden;
  clear: both;
}
.dlx-news {
  width: 100%;
  margin-bottom: 10rem;
}
@media (min-width: 1024px) {
  .dlx-news {
    margin-left: calc(16.6666666667% + 0.5333333333rem + 0px);
    width: calc(75% + -0.8rem + 0px);
  }
}
@media (min-width: 1450px) {
  .dlx-news {
    margin-left: calc(16.6666666667% + 0.3333333333rem + 0px);
    width: calc(75% + -0.5rem + 0px);
  }
}
.dlx-news__elements {
  margin-top: 3rem;
}
@media (min-width: 720px) {
  .dlx-news__elements {
    position: relative;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin-left: -2rem;
    margin-top: 0;
  }
  .dlx-news__elements > * {
    flex-grow: 0;
    flex-shrink: 0;
    margin-left: 2rem;
    margin-top: 0;
  }
}
@media (min-width: 1024px) {
  .dlx-news__elements {
    position: relative;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin-left: -3.2rem;
    margin-top: 0;
  }
  .dlx-news__elements > * {
    flex-grow: 0;
    flex-shrink: 0;
    margin-left: 3.2rem;
    margin-top: 0;
  }
}
@media (min-width: 1450px) {
  .dlx-news__elements {
    position: relative;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin-left: -2rem;
    margin-top: 0;
  }
  .dlx-news__elements > * {
    flex-grow: 0;
    flex-shrink: 0;
    margin-left: 2rem;
    margin-top: 0;
  }
}
.dlx-news__showmore {
  margin-top: 4rem;
}

.dlx-events {
  width: 100%;
  margin-left: 0;
}
@media (min-width: 720px) {
  .dlx-events {
    margin-left: calc(16.6666666667% + -1.6666666667rem + 2rem);
    width: calc(75% + -0.5rem + 0px);
  }
}
@media (min-width: 1024px) {
  .dlx-events {
    margin-left: calc(41.6666666667% + -1.8666666667rem + 3.2rem);
    width: calc(50% + -1.6rem + 0px);
  }
}
@media (min-width: 1450px) {
  .dlx-events {
    margin-left: calc(41.6666666667% + -1.1666666667rem + 2rem);
    width: calc(50% + -1rem + 0px);
  }
}

.dlx-filter__items {
  width: 100%;
}
.dlx-filter__item {
  display: inline-block;
}

.dlx-filteredArticle {
  display: none;
  width: 100%;
  margin-top: 4rem;
}
.dlx-filteredArticle.dlx-js-active.dlx-js-show {
  display: flex;
  flex-direction: column;
}
.dlx-filteredArticle--noimage {
  background-color: #ece9e5;
}
@media (min-width: 720px) {
  .dlx-filteredArticle {
    width: calc(50% - 2rem + 0px);
  }
}
@media (min-width: 1024px) {
  .dlx-filteredArticle {
    margin-bottom: 3.2rem;
    width: calc(33.3333333333% - 3.2rem + 0px);
  }
}
@media (min-width: 1450px) {
  .dlx-filteredArticle {
    margin-bottom: 2rem;
    width: calc(33.3333333333% - 2rem + 0px);
  }
}
.dlx-filteredArticle__copy {
  font-family: Roboto, sans-serif;
  font-size: 1.5rem;
  font-weight: normal;
  line-height: 1.3333333333;
}
@media (min-width: 720px) {
  .dlx-filteredArticle__copy {
    font-size: 1.6rem;
    line-height: 1.375;
  }
}
@media (min-width: 1450px) {
  .dlx-filteredArticle__copy {
    font-size: 1.8rem;
    line-height: 1.3333333333;
  }
}
.dlx-filteredArticle__headline {
  font-family: Roboto, sans-serif;
  font-weight: normal;
  font-size: 2.2rem;
  line-height: 1.1818181818;
  margin-top: 1rem;
  margin-bottom: 2rem;
}
@media (min-width: 720px) {
  .dlx-filteredArticle__headline {
    font-size: 2.8rem;
    line-height: 1.2857142857;
  }
}
@media (min-width: 1450px) {
  .dlx-filteredArticle__headline {
    font-size: 3.2rem;
    line-height: 1.125;
  }
}
.dlx-filteredArticle__additional {
  margin-top: 1rem;
}
@media (min-width: 1024px) {
  .dlx-filteredArticle__additional {
    margin-top: 1rem;
  }
}
.dlx-filteredArticle__category {
  font-family: Roboto, sans-serif;
  font-size: 1.3rem;
  font-weight: bold;
  letter-spacing: 0.1rem;
  line-height: 1.2307692308;
  color: #6a665b;
  display: inline-block;
  text-transform: uppercase;
  margin-right: 2rem;
}
@media (min-width: 720px) {
  .dlx-filteredArticle__category {
    font-size: 1.4rem;
    line-height: 1.2857142857;
  }
}
@media (min-width: 1450px) {
  .dlx-filteredArticle__category {
    font-size: 1.8rem;
    line-height: 1.2222222222;
  }
}
.dlx-filteredArticle__date {
  font-family: Roboto, sans-serif;
  font-size: 1.3rem;
  font-weight: bold;
  letter-spacing: 0.1rem;
  line-height: 1.2307692308;
  display: inline-block;
}
@media (min-width: 720px) {
  .dlx-filteredArticle__date {
    font-size: 1.4rem;
    line-height: 1.2857142857;
  }
}
@media (min-width: 1450px) {
  .dlx-filteredArticle__date {
    font-size: 1.8rem;
    line-height: 1.2222222222;
  }
}
.dlx-filteredArticle__image {
  object-fit: cover;
  overflow: hidden;
  padding-bottom: 54%;
  position: relative;
  width: 100%;
  background-color: grey;
}
.dlx-filteredArticle__imageSpace {
  object-fit: cover;
  overflow: hidden;
  padding-bottom: 54%;
  position: relative;
  width: 100%;
}
.dlx-filteredArticle__img {
  object-fit: cover;
  font-family: "object-fit: cover";
  display: block;
  height: 100%;
  width: 100%;
  position: absolute;
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  html.no-js .dlx-filteredArticle__img {
    box-sizing: border-box;
    padding-left: 100%;
    background-size: cover;
    background-position: 50% 50%;
  }
}
.dlx-filteredArticle--noimage .dlx-filteredArticle__textContainer {
  padding: 0 1.5rem 1.5rem 1.5rem;
}
.dlx-filteredArticle__time {
  font-family: Roboto, sans-serif;
  font-size: 1.3rem;
  font-weight: bold;
  letter-spacing: 0.1rem;
  line-height: 1.2307692308;
  display: inline-block;
}
@media (min-width: 720px) {
  .dlx-filteredArticle__time {
    font-size: 1.4rem;
    line-height: 1.2857142857;
  }
}
@media (min-width: 1450px) {
  .dlx-filteredArticle__time {
    font-size: 1.8rem;
    line-height: 1.2222222222;
  }
}
.dlx-filteredArticle__time::before {
  content: "| ";
  display: inline-block;
}

.dlx-blockRelatedTopics {
  margin-top: 4rem;
}
@media (min-width: 720px) {
  .dlx-blockRelatedTopics {
    margin-left: calc(16.6666666667% + 0.3333333333rem + 0px);
  }
}
@media (min-width: 1450px) {
  .dlx-blockRelatedTopics {
    margin-top: 8rem;
    margin-left: calc(16.6666666667% + 0.3333333333rem + 0px);
  }
}
.dlx-blockRelatedTopics__head {
  font-family: Roboto, sans-serif;
  font-size: 1.3rem;
  font-weight: bold;
  letter-spacing: 0.1rem;
  line-height: 1.2307692308;
  color: #6a665b;
  text-transform: uppercase;
  margin-bottom: 3rem;
}
@media (min-width: 720px) {
  .dlx-blockRelatedTopics__head {
    font-size: 1.4rem;
    line-height: 1.2857142857;
  }
}
@media (min-width: 1450px) {
  .dlx-blockRelatedTopics__head {
    font-size: 1.8rem;
    line-height: 1.2222222222;
  }
}
.dlx-blockRelatedTopics__list {
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-left: -2rem;
  margin-top: 0;
}
.dlx-blockRelatedTopics__list > * {
  flex-grow: 0;
  flex-shrink: 0;
  margin-left: 2rem;
  margin-top: 0;
}
@media (min-width: 1024px) {
  .dlx-blockRelatedTopics__list {
    position: relative;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin-left: -3.2rem;
    margin-top: 0;
  }
  .dlx-blockRelatedTopics__list > * {
    flex-grow: 0;
    flex-shrink: 0;
    margin-left: 3.2rem;
    margin-top: 0;
  }
}
@media (min-width: 1450px) {
  .dlx-blockRelatedTopics__list {
    position: relative;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin-left: -2rem;
    margin-top: 0;
  }
  .dlx-blockRelatedTopics__list > * {
    flex-grow: 0;
    flex-shrink: 0;
    margin-left: 2rem;
    margin-top: 0;
  }
}

.dlx-relatedTopic {
  overflow: hidden;
  position: relative;
  display: inline-block;
  min-height: 20rem;
  margin-bottom: 1rem;
  width: calc(100% - 2rem + 0px);
}
@media (min-width: 720px) {
  .dlx-relatedTopic {
    min-height: 45rem;
  }
}
@media (min-width: 1024px) {
  .dlx-relatedTopic {
    margin-bottom: 3.2rem;
    width: calc(50% - 3.2rem + 0px);
  }
}
@media (min-width: 1450px) {
  .dlx-relatedTopic {
    margin-bottom: 2rem;
    width: calc(50% - 2rem + 0px);
  }
}
.dlx-relatedTopic__anchor {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}
.dlx-relatedTopic__arrow {
  position: absolute;
  right: 1rem;
  bottom: 1rem;
}
.dlx-relatedTopic__container {
  padding-left: 1rem;
  padding-right: 1rem;
  width: 100%;
  color: #ffffff;
  padding-bottom: 1rem;
  padding-top: 1rem;
}
@media (min-width: 720px) {
  .dlx-relatedTopic__container {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
@media (min-width: 1024px) {
  .dlx-relatedTopic__container {
    padding-left: 3.2rem;
    padding-right: 3.2rem;
  }
}
@media (min-width: 1450px) {
  .dlx-relatedTopic__container {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
@media (min-width: 720px) {
  .dlx-relatedTopic__container {
    padding-left: 2.5rem;
    padding-top: 2.5rem;
  }
}
.dlx-relatedTopic__container::after {
  content: "";
  background-color: rgba(0, 0, 0, 0.4);
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  right: 0;
  top: 0;
  z-index: -1;
  transition: background-color 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) , opacity 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) ;
}
.dlx-relatedTopic:hover .dlx-relatedTopic__container::after {
  opacity: 0.7;
}
.dlx-relatedTopic__image {
  display: block;
}
.dlx-relatedTopic__imageContainer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}
.dlx-relatedTopic__img {
  object-fit: cover;
  font-family: "object-fit: cover";
  display: block;
  height: 100%;
  width: 100%;
  position: absolute;
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  html.no-js .dlx-relatedTopic__img {
    box-sizing: border-box;
    padding-left: 100%;
    background-size: cover;
    background-position: 50% 50%;
  }
}
.dlx-relatedTopic__title {
  font-family: Roboto, sans-serif;
  font-size: 2.2rem;
  font-weight: normal;
  line-height: 1.1818181818;
}
@media (min-width: 720px) {
  .dlx-relatedTopic__title {
    font-size: 2.8rem;
    line-height: 1.2857142857;
  }
}
@media (min-width: 1450px) {
  .dlx-relatedTopic__title {
    font-size: 4rem;
    line-height: 1.1;
  }
}
.dlx-relatedTopic__subtitle {
  font-family: Roboto, sans-serif;
  font-size: 2.2rem;
  font-weight: 200;
  line-height: 1.1818181818;
}
@media (min-width: 720px) {
  .dlx-relatedTopic__subtitle {
    font-size: 2.8rem;
    line-height: 1.2857142857;
  }
}
@media (min-width: 1450px) {
  .dlx-relatedTopic__subtitle {
    font-size: 4rem;
    line-height: 1.1;
  }
}

.dlx-pageHeaderSpecial {
  padding-left: 1rem;
  padding-right: 1rem;
  width: 100%;
  left: 0;
  position: absolute;
  top: 0;
}
@media (min-width: 720px) {
  .dlx-pageHeaderSpecial {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
@media (min-width: 1024px) {
  .dlx-pageHeaderSpecial {
    padding-left: 3.2rem;
    padding-right: 3.2rem;
  }
}
@media (min-width: 1450px) {
  .dlx-pageHeaderSpecial {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
.dlx-pageHeaderSpecial__anchorMenu {
  display: inline-block;
}
.dlx-pageHeaderSpecial__container {
  top: 0;
}
.dlx-body.dlx-js-invertedHeader .dlx-pageHeader:not(.dlx-js-scrolledDown) .dlx-pageHeaderSpecial__container, .dlx-body.dlx-js-fullyInvertedHeader .dlx-pageHeader:not(.dlx-js-scrolledDown) .dlx-pageHeaderSpecial__container {
  color: #ffffff;
}
.dlx-pageHeaderSpecial__title {
  font-family: Roboto, sans-serif;
  font-size: 1.3rem;
  font-weight: bold;
  letter-spacing: 0.1rem;
  line-height: 1.2307692308;
  display: inline-block;
  font-weight: normal;
  vertical-align: center;
}
@media (min-width: 720px) {
  .dlx-pageHeaderSpecial__title {
    font-size: 1.4rem;
    line-height: 1.2857142857;
  }
}
@media (min-width: 1450px) {
  .dlx-pageHeaderSpecial__title {
    font-size: 1.8rem;
    line-height: 1.2222222222;
  }
}
.dlx-pageHeaderSpecial__togglerContainer {
  vertical-align: top;
  display: inline-block;
  margin-left: calc(25% + 0.5rem + 0px);
  margin-top: 1.5rem;
}
@media (min-width: 720px) {
  .dlx-pageHeaderSpecial__togglerContainer {
    float: none;
    margin-left: calc(16.6666666667% + 0.3333333333rem + 0px);
    margin-top: 1.2rem;
  }
}
@media (min-width: 1024px) {
  .dlx-pageHeaderSpecial__togglerContainer {
    float: none;
    margin-left: calc(16.6666666667% + 0.5333333333rem + 0px);
    margin-top: 2rem;
  }
}
@media (min-width: 1450px) {
  .dlx-pageHeaderSpecial__togglerContainer {
    float: none;
    margin-top: 2rem;
    margin-left: calc(16.6666666667% + 0.3333333333rem + 0px);
  }
}
.dlx-pageHeaderSpecial__togglerLink {
  white-space: nowrap;
  display: block;
}
.dlx-mainMenu__menuSections--additional .dlx-pageHeaderSpecial__togglerLink {
  text-transform: uppercase;
}
@media (min-width: 1024px) {
  .dlx-pageHeaderSpecial__togglerLink {
    pointer-events: none;
  }
}
.dlx-pageHeaderSpecial__icon {
  display: inline-block;
  margin-left: 0.25rem;
  margin-top: 3px;
  opacity: 1;
  height: 2rem;
  vertical-align: top;
}
@media (min-width: 1024px) {
  .dlx-pageHeaderSpecial__icon {
    display: none;
  }
}
.dlx-pageHeaderSpecial__icon svg {
  stroke: #6a665b;
  transition: transform 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94) ;
}
.dlx-body.dlx-js-fullyInvertedHeader .dlx-pageHeaderSpecial__icon svg, .dlx-body.dlx-js-invertedHeader .dlx-pageHeader:not(.dlx-js-scrolledDown) .dlx-pageHeaderSpecial__icon svg {
  stroke: #ffffff;
}
.dlx-pageHeaderSpecial__togglerLink.dlx-js-toggled .dlx-pageHeaderSpecial__icon svg {
  transform: scaleY(-1);
}
.dlx-mainMenu__menuSections--additional .dlx-pageHeaderSpecial__icon {
  margin-top: -0.25rem;
}
@media (min-width: 720px) {
  .dlx-mainMenu__menuSections--additional .dlx-pageHeaderSpecial__icon {
    margin-top: -0.5rem;
  }
}
@media (min-width: 1024px) {
  .dlx-mainMenu__menuSections--additional .dlx-pageHeaderSpecial__icon {
    margin-top: -0.5rem;
  }
}
@media (min-width: 1450px) {
  .dlx-mainMenu__menuSections--additional .dlx-pageHeaderSpecial__icon {
    margin-top: -0.75rem;
  }
}

.dlx-contactCta__closeButton {
  position: absolute;
  font-family: Roboto, sans-serif;
  font-size: 1.4rem;
  font-weight: normal;
  line-height: 1;
  text-transform: uppercase;
  right: 2rem;
  top: 1rem;
  cursor: pointer;
}
@media (min-width: 720px) {
  .dlx-contactCta__closeButton {
    font-size: 1.4rem;
    line-height: 1;
  }
}
@media (min-width: 720px) {
  .dlx-contactCta__closeButton {
    top: 2rem;
  }
}
.dlx-contactCta__content {
  display: block;
  z-index: 2;
  transition: transform 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94) ;
  background-color: #f8f8f7;
  border: 1px solid #ccc5b5;
  bottom: 2rem;
  padding: 3rem 2rem 2rem 2rem;
  position: fixed;
  right: 0;
  transform: translateX(100%);
}
.dlx-contactCta__content.dlx-js-active {
  transform: translateX(0);
}
@media (min-width: 720px) {
  .dlx-contactCta__content {
    padding: 2rem;
  }
}
.dlx-contactCta__flag {
  bottom: 2rem;
  left: 100%;
  position: fixed;
}
.dlx-contactCta__flagContent {
  transition: background-color 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94) ;
  background-color: #da7a61;
  color: #ffffff;
  font-weight: normal;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  text-transform: uppercase;
  transform-origin: bottom left;
  transform: rotate(-90deg);
  cursor: pointer;
}
.dlx-contactCta__flag:hover .dlx-contactCta__flagContent {
  background-color: #eab29d;
}
.dlx-contactCta__headline {
  font-family: Roboto, sans-serif;
  font-size: 2.2rem;
  font-weight: 200;
  line-height: 1.1818181818;
  margin-top: 3rem;
  margin-bottom: 2rem;
  flex-grow: 2;
}
@media (min-width: 720px) {
  .dlx-contactCta__headline {
    font-size: 2.8rem;
    line-height: 1.2857142857;
  }
}
@media (min-width: 1450px) {
  .dlx-contactCta__headline {
    font-size: 2.8rem;
    line-height: 1.2857142857;
  }
}
@media (min-width: 720px) {
  .dlx-contactCta__headline {
    margin-top: 4rem;
  }
}
.dlx-contactCta__image {
  display: block;
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 27rem;
}
.dlx-contactCta__imageContainer {
  display: flex;
  flex-basis: 100%;
  overflow: hidden;
  position: relative;
}
.dlx-contactCta__img {
  object-fit: cover;
  font-family: "object-fit: cover";
  display: block;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  html.no-js .dlx-contactCta__img {
    box-sizing: border-box;
    padding-left: 100%;
    background-size: cover;
    background-position: 50% 50%;
  }
}
.dlx-contactCta__innerContent {
  display: flex;
  flex-direction: column;
  max-width: 68rem;
  min-height: 45rem;
}
@media (min-width: 720px) {
  .dlx-contactCta__innerContent {
    flex-direction: row;
  }
}
.dlx-contactCta__label {
  padding: 1rem;
}
.dlx-contactCta__textContainer {
  flex-basis: 100%;
  display: flex;
  padding: 0;
  flex-direction: column;
}
@media (min-width: 720px) {
  .dlx-contactCta__textContainer {
    padding: 2rem 2rem 0 2rem;
  }
}
.dlx-contactCta__button {
  width: 100%;
  align-items: flex-end;
  align-self: flex-end;
  margin-top: 1rem;
  display: block;
}

.dlx-featureListItem {
  background-color: #ece9e5;
  margin-bottom: 2rem;
  overflow: hidden;
  position: relative;
  height: auto;
  cursor: pointer;
  width: calc(100% - 2rem + 0px);
}
.dlx-featureListItem::after {
  content: "";
  display: block;
  padding-bottom: 100%;
}
@media (min-width: 720px) {
  .dlx-featureListItem {
    width: calc(50% - 2rem + 0px);
  }
}
@media (min-width: 1024px) {
  .dlx-featureListItem {
    margin-bottom: 3.2rem;
    width: calc(50% - 3.2rem + 0px);
  }
}
@media (min-width: 1450px) {
  .dlx-featureListItem {
    margin-bottom: 2rem;
    width: calc(33.3333333333% - 2rem + 0px);
  }
}
.dlx-featureListItem__additional {
  transition: transform 0.3s  ;
  bottom: 0;
  left: 0;
  min-height: 15rem;
  position: absolute;
  transform: translateY(calc(100% - 10px));
  width: 100%;
}
.dlx-blockFeatureList--color1 .dlx-featureListItem__additional {
  background-color: #7695a3;
}
.dlx-blockFeatureList--color2 .dlx-featureListItem__additional {
  background-color: #d1d92b;
}
.dlx-blockFeatureList--color3 .dlx-featureListItem__additional {
  background-color: #778d34;
}
.dlx-blockFeatureList--color4 .dlx-featureListItem__additional {
  background-color: #ebba00;
}
.dlx-blockFeatureList--color5 .dlx-featureListItem__additional {
  background-color: #da7a61;
}
.dlx-blockFeatureList--color6 .dlx-featureListItem__additional {
  background-color: #afafae;
}
.dlx-featureListItem:hover .dlx-featureListItem__additional {
  transform: translateY(0);
}
.dlx-featureListItem__additionalContent {
  padding-left: 1rem;
  padding-right: 1rem;
  width: 100%;
  padding-bottom: 3rem;
  padding-top: 2rem;
  padding-left: 2rem;
}
@media (min-width: 720px) {
  .dlx-featureListItem__additionalContent {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
@media (min-width: 1024px) {
  .dlx-featureListItem__additionalContent {
    padding-left: 3.2rem;
    padding-right: 3.2rem;
  }
}
@media (min-width: 1450px) {
  .dlx-featureListItem__additionalContent {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
@media (min-width: 720px) {
  .dlx-featureListItem__additionalContent {
    padding-left: 2rem;
    padding-top: 2.5rem;
  }
}
.dlx-featureListItem__copy {
  font-size: 1.7rem;
  line-height: 1.1764705882;
  font-weight: normal;
}
@media (min-width: 720px) {
  .dlx-featureListItem__copy {
    font-size: 1.8rem;
    line-height: 1.2222222222;
  }
}
@media (min-width: 1450px) {
  .dlx-featureListItem__copy {
    font-size: 2rem;
    line-height: 1.2;
  }
}
.dlx-featureListItem__content {
  position: absolute;
  top: 0;
  width: 100%;
  padding-left: 1rem;
  padding-right: 1rem;
  width: 100%;
  padding-bottom: 1rem;
  padding-top: 2rem;
  padding-left: 2rem;
}
@media (min-width: 720px) {
  .dlx-featureListItem__content {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
@media (min-width: 1024px) {
  .dlx-featureListItem__content {
    padding-left: 3.2rem;
    padding-right: 3.2rem;
  }
}
@media (min-width: 1450px) {
  .dlx-featureListItem__content {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
@media (min-width: 720px) {
  .dlx-featureListItem__content {
    padding-left: 2rem;
    padding-top: 2.5rem;
  }
}
.dlx-featureListItem__icon {
  position: absolute;
  width: 3rem;
  height: 3rem;
  bottom: 3rem;
  right: 2rem;
}
@media (min-width: 1024px) {
  .dlx-featureListItem__icon {
    display: none;
  }
}
.dlx-featureListItem__icon::after {
  content: "";
  position: absolute;
  display: block;
  background-color: #ebba00;
  height: 3px;
  top: 50%;
  margin-top: -1px;
  left: 0;
  right: 0;
}
.dlx-featureListItem__icon::before {
  content: "";
  position: absolute;
  display: block;
  background-color: #ebba00;
  width: 3px;
  margin-left: -1px;
  left: 50%;
  top: 0;
  bottom: 0;
}
.dlx-featureListItem__title {
  font-family: Roboto, sans-serif;
  font-size: 2.2rem;
  font-weight: 200;
  line-height: 1.1818181818;
}
@media (min-width: 720px) {
  .dlx-featureListItem__title {
    font-size: 2.8rem;
    line-height: 1.2857142857;
  }
}
@media (min-width: 1450px) {
  .dlx-featureListItem__title {
    font-size: 4rem;
    line-height: 1.1;
  }
}

.dlx-blockFeatureList {
  margin-top: 4rem;
}
@media (min-width: 720px) {
  .dlx-blockFeatureList {
    margin-left: calc(16.6666666667% + 0.3333333333rem + 0px);
  }
}
@media (min-width: 1450px) {
  .dlx-blockFeatureList {
    margin-top: 8rem;
    margin-left: calc(16.6666666667% + 0.3333333333rem + 0px);
  }
}
.dlx-blockFeatureList__head {
  font-family: Roboto, sans-serif;
  font-size: 1.3rem;
  font-weight: bold;
  letter-spacing: 0.1rem;
  line-height: 1.2307692308;
  color: #6a665b;
  text-transform: uppercase;
  margin-bottom: 3rem;
}
@media (min-width: 720px) {
  .dlx-blockFeatureList__head {
    font-size: 1.4rem;
    line-height: 1.2857142857;
  }
}
@media (min-width: 1450px) {
  .dlx-blockFeatureList__head {
    font-size: 1.8rem;
    line-height: 1.2222222222;
  }
}
.dlx-blockFeatureList__list {
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-left: -2rem;
  margin-top: 0;
}
.dlx-blockFeatureList__list > * {
  flex-grow: 0;
  flex-shrink: 0;
  margin-left: 2rem;
  margin-top: 0;
}
@media (min-width: 1024px) {
  .dlx-blockFeatureList__list {
    position: relative;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin-left: -3.2rem;
    margin-top: 0;
  }
  .dlx-blockFeatureList__list > * {
    flex-grow: 0;
    flex-shrink: 0;
    margin-left: 3.2rem;
    margin-top: 0;
  }
}
@media (min-width: 1450px) {
  .dlx-blockFeatureList__list {
    position: relative;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin-left: -2rem;
    margin-top: 0;
  }
  .dlx-blockFeatureList__list > * {
    flex-grow: 0;
    flex-shrink: 0;
    margin-left: 2rem;
    margin-top: 0;
  }
}

.dlx-blockStageHeader {
  color: #ffffff;
  height: 100vh;
  padding-top: 10rem;
  overflow: hidden;
}
@media (min-width: 720px) {
  .dlx-blockStageHeader {
    padding-top: 13rem;
  }
}
@media (min-width: 1450px) {
  .dlx-blockStageHeader {
    padding-top: 17rem;
  }
}
.dlx-blockStageHeader__content {
  height: 100%;
  display: flex;
  flex-direction: column;
}
@media (min-width: 720px) {
  .dlx-blockStageHeader__content {
    margin-left: calc(16.6666666667% + 0.3333333333rem + 0px);
  }
}
@media (min-width: 1450px) {
  .dlx-blockStageHeader__content {
    margin-left: calc(16.6666666667% + 0.3333333333rem + 0px);
  }
}
.dlx-blockStageHeader__head {
  display: flex;
  flex-grow: 1;
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-size: 4rem;
  font-weight: 200;
  line-height: 1.125;
  transition: transform 0.8s  , opacity 0.8s  ;
  transform-origin: top left;
}
@media (min-width: 720px) {
  .dlx-blockStageHeader__head {
    font-size: 6rem;
    line-height: 1.0833333333;
  }
}
@media (min-width: 1450px) {
  .dlx-blockStageHeader__head {
    font-size: 10rem;
    line-height: 1.1;
  }
}
.dlx-body.dlx-js-scrolledDown .dlx-blockStageHeader__head {
  transform: scale(0.7);
  opacity: 0.5;
}
.dlx-blockStageHeader__subhead {
  padding-bottom: 4rem;
  display: flex;
  font-family: Roboto, sans-serif;
  font-size: 2.2rem;
  font-weight: 200;
  line-height: 1.1818181818;
  width: calc(70% + 1.4rem + 0px);
}
@media (min-width: 720px) {
  .dlx-blockStageHeader__subhead {
    font-size: 2.8rem;
    line-height: 1.2857142857;
  }
}
@media (min-width: 1450px) {
  .dlx-blockStageHeader__subhead {
    font-size: 4rem;
    line-height: 1.1;
  }
}
@media (min-width: 720px) {
  .dlx-blockStageHeader__subhead {
    padding-bottom: 8rem;
  }
}
@media (min-width: 1450px) {
  .dlx-blockStageHeader__subhead {
    padding-bottom: 10rem;
  }
}
.dlx-blockStageHeader__image {
  display: block;
}
.dlx-blockStageHeader__imageContainer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}
.dlx-blockStageHeader__videoContainer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}
.dlx-blockStageHeader__img {
  object-fit: cover;
  font-family: "object-fit: cover";
  display: block;
  height: 100%;
  width: 100%;
  position: absolute;
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  html.no-js .dlx-blockStageHeader__img {
    box-sizing: border-box;
    padding-left: 100%;
    background-size: cover;
    background-position: 50% 50%;
  }
}

.dlx-fullGalleryItem {
  height: 100vh;
  position: relative;
  overflow: hidden;
}
.dlx-fullGalleryItem__textContainer {
  padding-top: 4rem;
  padding-bottom: 4rem;
  background-color: rgba(50, 50, 50, 0.8);
  bottom: 0;
  color: #ffffff;
  left: 0;
  position: absolute;
  width: 100%;
  transition: transform 0.8s  ;
  transform: translateY(100%);
}
.dlx-fullGalleryItem__textContainer.dlx-js-inside {
  transform: translateY(0%);
}
.dlx-fullGalleryItem--inverted .dlx-fullGalleryItem__textContainer {
  background-color: rgba(255, 255, 255, 0.8);
  color: #323232;
}
.dlx-fullGalleryItem__textInner {
  padding-left: 1rem;
  padding-right: 1rem;
  width: 100%;
  width: 100%;
}
@media (min-width: 720px) {
  .dlx-fullGalleryItem__textInner {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
@media (min-width: 1024px) {
  .dlx-fullGalleryItem__textInner {
    padding-left: 3.2rem;
    padding-right: 3.2rem;
  }
}
@media (min-width: 1450px) {
  .dlx-fullGalleryItem__textInner {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
@media (min-width: 1024px) {
  .dlx-fullGalleryItem__textInner {
    display: flex;
  }
}
.dlx-fullGalleryItem__title {
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-size: 3rem;
  font-weight: 200;
  line-height: 1.1666666667;
  display: flex;
  width: 100%;
  margin-bottom: 2rem;
}
@media (min-width: 720px) {
  .dlx-fullGalleryItem__title {
    font-size: 4.5rem;
    line-height: 1.2222222222;
  }
}
@media (min-width: 1450px) {
  .dlx-fullGalleryItem__title {
    font-size: 7rem;
    line-height: 1.1142857143;
  }
}
@media (min-width: 1024px) {
  .dlx-fullGalleryItem__title {
    width: calc(50% + -1.6rem + 0px);
  }
}
@media (min-width: 1450px) {
  .dlx-fullGalleryItem__title {
    width: calc(50% + -1rem + 0px);
  }
}
.dlx-fullGalleryItem__subtitle {
  width: 100%;
}
@media (min-width: 1024px) {
  .dlx-fullGalleryItem__subtitle {
    width: calc(50% + -1.6rem + 0px);
  }
}
@media (min-width: 1450px) {
  .dlx-fullGalleryItem__subtitle {
    width: calc(50% + -1rem + 0px);
  }
}
.dlx-fullGalleryItem__content {
  height: 100%;
  display: flex;
  flex-direction: column;
}
@media (min-width: 720px) {
  .dlx-fullGalleryItem__content {
    margin-left: calc(16.6666666667% + 0.3333333333rem + 0px);
  }
}
@media (min-width: 1450px) {
  .dlx-fullGalleryItem__content {
    margin-left: calc(16.6666666667% + 0.3333333333rem + 0px);
  }
}
.dlx-fullGalleryItem__head {
  display: flex;
  flex-grow: 1;
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-size: 4rem;
  font-weight: 200;
  line-height: 1.125;
}
@media (min-width: 720px) {
  .dlx-fullGalleryItem__head {
    font-size: 6rem;
    line-height: 1.0833333333;
  }
}
@media (min-width: 1450px) {
  .dlx-fullGalleryItem__head {
    font-size: 10rem;
    line-height: 1.1;
  }
}
.dlx-fullGalleryItem__subhead {
  padding-bottom: 4rem;
  display: flex;
  font-family: Roboto, sans-serif;
  font-size: 2.2rem;
  font-weight: 200;
  line-height: 1.1818181818;
  width: calc(70% + 1.4rem + 0px);
}
@media (min-width: 720px) {
  .dlx-fullGalleryItem__subhead {
    font-size: 2.8rem;
    line-height: 1.2857142857;
  }
}
@media (min-width: 1450px) {
  .dlx-fullGalleryItem__subhead {
    font-size: 4rem;
    line-height: 1.1;
  }
}
@media (min-width: 720px) {
  .dlx-fullGalleryItem__subhead {
    padding-bottom: 8rem;
  }
}
@media (min-width: 1450px) {
  .dlx-fullGalleryItem__subhead {
    padding-bottom: 10rem;
  }
}
.dlx-fullGalleryItem__image {
  display: block;
}
.dlx-fullGalleryItem__imageContainer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}
.dlx-fullGalleryItem__img {
  object-fit: cover;
  font-family: "object-fit: cover";
  display: block;
  height: 100%;
  width: 100%;
  position: absolute;
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  html.no-js .dlx-fullGalleryItem__img {
    box-sizing: border-box;
    padding-left: 100%;
    background-size: cover;
    background-position: 50% 50%;
  }
}

.dlx-blockContactForm {
  padding-left: 1rem;
  padding-right: 1rem;
  width: 100%;
  background-color: #ffffff;
  padding-top: 4rem;
  padding-bottom: 4rem;
  overflow: hidden;
  /*
  	&__image {
  		display: block;
  		position: relative;
  		width: 100%;
  		max-height: 100%;
  		height: auto;
  	}*/
}
@media (min-width: 720px) {
  .dlx-blockContactForm {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
@media (min-width: 1024px) {
  .dlx-blockContactForm {
    padding-left: 3.2rem;
    padding-right: 3.2rem;
  }
}
@media (min-width: 1450px) {
  .dlx-blockContactForm {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
.dlx-blockContactForm__content {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  flex-direction: column;
}
.dlx-blockContactForm__content > * {
  flex-grow: 0;
  flex-shrink: 0;
}
@media (min-width: 1024px) {
  .dlx-blockContactForm__content {
    flex-direction: row;
  }
}
@media (min-width: 1024px) {
  .dlx-blockContactForm__content {
    margin-left: calc(16.6666666667% + 0.3333333333rem + 0px);
  }
}
.dlx-blockContactForm__copytext {
  margin-bottom: 4rem;
  font-size: 1.8rem;
  line-height: 1.3333333333;
  font-weight: normal;
}
@media (min-width: 720px) {
  .dlx-blockContactForm__copytext {
    font-size: 2rem;
    line-height: 1.4;
  }
}
@media (min-width: 1450px) {
  .dlx-blockContactForm__copytext {
    font-size: 2.4rem;
    line-height: 1.4166666667;
  }
}
.dlx-blockContactForm__formContent {
  order: 2;
  width: 100%;
}
@media (min-width: 1024px) {
  .dlx-blockContactForm__formContent {
    order: 1;
    width: calc(50% + -1.6rem + 0px);
  }
}
@media (min-width: 1450px) {
  .dlx-blockContactForm__formContent {
    width: calc(50% + -1rem + 0px);
  }
}
.dlx-blockContactForm__header {
  margin-bottom: 4rem;
  font-family: Roboto, sans-serif;
  font-size: 2.2rem;
  font-weight: 200;
  line-height: 1.1818181818;
}
@media (min-width: 720px) {
  .dlx-blockContactForm__header {
    font-size: 2.8rem;
    line-height: 1.2857142857;
  }
}
@media (min-width: 1450px) {
  .dlx-blockContactForm__header {
    font-size: 4rem;
    line-height: 1.1;
  }
}
.dlx-blockContactForm__imageContent {
  order: 1;
  width: 100%;
  max-height: 100%;
  margin-bottom: 2rem;
  position: relative;
}
@media (min-width: 1024px) {
  .dlx-blockContactForm__imageContent {
    order: 2;
    width: calc(50% + -1.6rem + 0px);
  }
}
@media (min-width: 1450px) {
  .dlx-blockContactForm__imageContent {
    width: calc(50% + -1rem + 0px);
  }
}
.dlx-blockContactForm__image {
  display: block;
}
.dlx-blockContactForm__imageContainer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.dlx-blockContactForm__img {
  object-fit: cover;
  font-family: "object-fit: cover";
  display: block;
  height: 100%;
  width: 100%;
  position: absolute;
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  html.no-js .dlx-blockContactForm__img {
    box-sizing: border-box;
    padding-left: 100%;
    background-size: cover;
    background-position: 50% 50%;
  }
}

.dlx-anchorMenu {
  padding-left: 1rem;
  padding-right: 1rem;
  width: 100%;
  transition: opacity 0.2s  , visibility 0.2s  ;
  opacity: 0;
  visibility: hidden;
  background-color: rgba(255, 255, 255, 0.9);
  left: 0;
  max-width: 100%;
  position: absolute;
  top: 5rem;
  width: 100%;
}
@media (min-width: 720px) {
  .dlx-anchorMenu {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
@media (min-width: 1024px) {
  .dlx-anchorMenu {
    padding-left: 3.2rem;
    padding-right: 3.2rem;
  }
}
@media (min-width: 1450px) {
  .dlx-anchorMenu {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
.dlx-pageHeaderSpecial__container.dlx-js-active .dlx-anchorMenu {
  transition: opacity 0.2s  , visibility 0.2s  ;
  opacity: 1;
  visibility: inherit;
}
.dlx-body.dlx-js-fullyInvertedHeader .dlx-anchorMenu, .dlx-body.dlx-js-invertedHeader .dlx-pageHeader:not(.dlx-js-scrolledDown) .dlx-anchorMenu {
  background-color: transparent;
}
@media (min-width: 1024px) {
  .dlx-anchorMenu {
    background-color: transparent;
    margin-top: 0.5rem;
    opacity: 1;
    position: relative;
    top: auto;
    visibility: visible;
    width: auto;
  }
}
.dlx-anchorMenu__list {
  padding-top: 1.4rem;
  padding-bottom: 1.5rem;
  white-space: nowrap;
}
.dlx-anchorMenu__item {
  display: inline-block;
  margin-right: 4rem;
}
.dlx-anchorMenu__item:last-of-type {
  margin-right: 0;
}
.dlx-anchorMenu__anchor {
  font-family: Roboto, sans-serif;
  font-size: 1.4rem;
  font-weight: normal;
  line-height: 1;
  color: #6a665b;
}
@media (min-width: 720px) {
  .dlx-anchorMenu__anchor {
    font-size: 1.4rem;
    line-height: 1;
  }
}

.dlx-newsletterForm .button {
  transition: background-color 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94) ;
  font-family: Roboto, sans-serif;
  font-size: 1.3rem;
  font-weight: bold;
  letter-spacing: 0.1rem;
  line-height: 1.2307692308;
  background-color: #ece9e5;
  border: none;
  color: #6a665b;
  cursor: pointer;
  display: inline-block;
  height: 5.4rem;
  line-height: 5.4rem;
  margin-top: 3rem;
  min-width: 11rem;
  overflow: hidden;
  padding-left: 2rem;
  padding-right: 2rem;
  position: relative;
  text-align: center;
  text-transform: uppercase;
  vertical-align: middle;
  width: 100%;
  z-index: 1;
}
@media (min-width: 720px) {
  .dlx-newsletterForm .button {
    font-size: 1.4rem;
    line-height: 1.2857142857;
  }
}
@media (min-width: 1450px) {
  .dlx-newsletterForm .button {
    font-size: 1.8rem;
    line-height: 1.2222222222;
  }
}
@media (min-width: 1450px) {
  .dlx-newsletterForm .button {
    height: 6rem;
  }
}
@media (min-width: 1450px) {
  .dlx-newsletterForm .button {
    line-height: 6rem;
  }
}
.dlx-newsletterForm .button::after {
  transition: opacity 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94) , width 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94) , height 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94) ;
  background-color: #ffffff;
  border-radius: 50%;
  content: "";
  filter: blur(10px);
  height: 10px;
  left: 50%;
  opacity: 0.4;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 10px;
}
.dlx-newsletterForm .button:hover::after {
  opacity: 1;
  width: 100px;
  height: 100px;
}
.dlx-newsletterForm .button:hover {
  background-color: #ffffff;
}
.dlx-newsletterForm .button__label {
  vertical-align: middle;
  font-family: Roboto, sans-serif;
  font-size: 1.3rem;
  font-weight: bold;
  letter-spacing: 0.1rem;
  line-height: 1.2307692308;
  color: #6a665b;
  display: inline-block;
  width: auto;
  position: relative;
  text-transform: uppercase;
  margin-top: -1px;
  z-index: 1;
}
@media (min-width: 720px) {
  .dlx-newsletterForm .button__label {
    font-size: 1.4rem;
    line-height: 1.2857142857;
  }
}
@media (min-width: 1450px) {
  .dlx-newsletterForm .button__label {
    font-size: 1.8rem;
    line-height: 1.2222222222;
  }
}
.dlx-newsletterForm h2 {
  font-family: Roboto, sans-serif;
  font-size: 2.2rem;
  font-weight: normal;
  line-height: 1.1818181818;
}
@media (min-width: 720px) {
  .dlx-newsletterForm h2 {
    font-size: 2.8rem;
    line-height: 1.2857142857;
  }
}
@media (min-width: 1450px) {
  .dlx-newsletterForm h2 {
    font-size: 4rem;
    line-height: 1.1;
  }
}
.dlx-newsletterForm .indicates-required {
  font-family: Roboto, sans-serif;
  font-size: 1.5rem;
  font-weight: normal;
  line-height: 1.3333333333;
  margin-top: 2rem;
  margin-bottom: 2rem;
}
@media (min-width: 720px) {
  .dlx-newsletterForm .indicates-required {
    font-size: 1.6rem;
    line-height: 1.375;
  }
}
@media (min-width: 1450px) {
  .dlx-newsletterForm .indicates-required {
    font-size: 1.8rem;
    line-height: 1.3333333333;
  }
}
.dlx-newsletterForm .mc-field-group {
  margin-bottom: 1rem;
}
.dlx-newsletterForm .mc-field-group input {
  font-size: 1.8rem;
  line-height: 1.3333333333;
  font-weight: normal;
  border: 1px solid #ccc5b5;
  background-color: #ffffff;
  border: none;
  color: #323232;
  height: 5.4rem;
  padding-left: 2rem;
  padding-right: 2rem;
  vertical-align: top;
  width: 100%;
}
@media (min-width: 720px) {
  .dlx-newsletterForm .mc-field-group input {
    font-size: 2rem;
    line-height: 1.4;
  }
}
@media (min-width: 1450px) {
  .dlx-newsletterForm .mc-field-group input {
    font-size: 2.4rem;
    line-height: 1.4166666667;
  }
}
@media (min-width: 1450px) {
  .dlx-newsletterForm .mc-field-group input {
    height: 6rem;
  }
}
.dlx-newsletterForm .mc-field-group input:focus {
  border: 1px solid #ccc5b5;
  background-color: #ffffff;
}
.dlx-newsletterForm .mc-field-group input::placeholder {
  color: #6a665b;
  opacity: 0.5;
}
.dlx-newsletterForm label {
  color: #6a665b;
  font-family: Roboto, sans-serif;
  font-size: 1.3rem;
  font-weight: bold;
  letter-spacing: 0.1rem;
  line-height: 1.2307692308;
  margin-bottom: 1rem;
  text-transform: uppercase;
  display: block;
}
@media (min-width: 720px) {
  .dlx-newsletterForm label {
    font-size: 1.4rem;
    line-height: 1.2857142857;
  }
}
@media (min-width: 1450px) {
  .dlx-newsletterForm label {
    font-size: 1.8rem;
    line-height: 1.2222222222;
  }
}

.dlx-event {
  margin-bottom: 5rem;
  position: relative;
}
.dlx-event::after {
  content: "";
  position: absolute;
  bottom: -2rem;
  left: 0;
  right: -70vw;
  height: 1px;
  width: 200vw;
  background-color: #ccc5b5;
  z-index: 0;
}
@media (min-width: 1024px) {
  .dlx-event {
    margin-top: 2rem;
    margin-bottom: 7rem;
  }
}
.dlx-event__additional {
  margin-top: 1rem;
}
@media (min-width: 1024px) {
  .dlx-event__additional {
    margin-top: 1rem;
  }
}
.dlx-event__category {
  font-family: Roboto, sans-serif;
  font-size: 1.3rem;
  font-weight: bold;
  letter-spacing: 0.1rem;
  line-height: 1.2307692308;
  color: #6a665b;
  display: inline-block;
  text-transform: uppercase;
  margin-right: 2rem;
}
@media (min-width: 720px) {
  .dlx-event__category {
    font-size: 1.4rem;
    line-height: 1.2857142857;
  }
}
@media (min-width: 1450px) {
  .dlx-event__category {
    font-size: 1.8rem;
    line-height: 1.2222222222;
  }
}
.dlx-event__date {
  font-family: Roboto, sans-serif;
  font-size: 1.3rem;
  font-weight: bold;
  letter-spacing: 0.1rem;
  line-height: 1.2307692308;
  display: inline-block;
}
@media (min-width: 720px) {
  .dlx-event__date {
    font-size: 1.4rem;
    line-height: 1.2857142857;
  }
}
@media (min-width: 1450px) {
  .dlx-event__date {
    font-size: 1.8rem;
    line-height: 1.2222222222;
  }
}
.dlx-event__headline {
  margin-top: 1rem;
  margin-bottom: 2rem;
  font-family: Roboto, sans-serif;
  font-size: 2.2rem;
  font-weight: normal;
  line-height: 1.1818181818;
}
@media (min-width: 720px) {
  .dlx-event__headline {
    font-size: 2.8rem;
    line-height: 1.2857142857;
  }
}
@media (min-width: 1450px) {
  .dlx-event__headline {
    font-size: 4rem;
    line-height: 1.1;
  }
}
.dlx-event__image {
  width: 100%;
  background-color: lightgrey;
}
.dlx-event__img {
  width: 100%;
}
.dlx-event__readMore {
  font-family: Roboto, sans-serif;
  font-size: 1.3rem;
  font-weight: bold;
  letter-spacing: 0.1rem;
  line-height: 1.2307692308;
  color: #6a665b;
  display: inline-block;
  text-transform: uppercase;
  margin-top: 3rem;
}
@media (min-width: 720px) {
  .dlx-event__readMore {
    font-size: 1.4rem;
    line-height: 1.2857142857;
  }
}
@media (min-width: 1450px) {
  .dlx-event__readMore {
    font-size: 1.8rem;
    line-height: 1.2222222222;
  }
}
.dlx-event__time {
  font-family: Roboto, sans-serif;
  font-size: 1.3rem;
  font-weight: bold;
  letter-spacing: 0.1rem;
  line-height: 1.2307692308;
  display: inline-block;
}
@media (min-width: 720px) {
  .dlx-event__time {
    font-size: 1.4rem;
    line-height: 1.2857142857;
  }
}
@media (min-width: 1450px) {
  .dlx-event__time {
    font-size: 1.8rem;
    line-height: 1.2222222222;
  }
}
.dlx-event__time::before {
  content: "| ";
  display: inline-block;
}

.dlx-mediumHeader {
  width: 100%;
  font-family: Roboto, sans-serif;
  font-size: 1.3rem;
  font-weight: bold;
  letter-spacing: 0.1rem;
  line-height: 1.2307692308;
  display: block;
  position: relative;
  padding-top: 6rem;
  margin-bottom: 6rem;
  text-transform: uppercase;
}
@media (min-width: 1024px) {
  .dlx-mediumHeader {
    margin-left: calc(16.6666666667% + 0.5333333333rem + 0px);
    width: calc(75% + -0.8rem + 0px);
  }
}
@media (min-width: 1450px) {
  .dlx-mediumHeader {
    margin-left: calc(16.6666666667% + 0.3333333333rem + 0px);
    width: calc(75% + -0.5rem + 0px);
  }
}
@media (min-width: 720px) {
  .dlx-mediumHeader {
    font-size: 1.4rem;
    line-height: 1.2857142857;
  }
}
@media (min-width: 1450px) {
  .dlx-mediumHeader {
    font-size: 1.8rem;
    line-height: 1.2222222222;
  }
}
.dlx-mediumHeader::after {
  content: "";
  position: absolute;
  bottom: -3rem;
  left: 0;
  right: -100vw;
  height: 1px;
  width: 100vw;
  background-color: #ccc5b5;
  z-index: 0;
}
.dlx-mediumHeader--top {
  padding-top: 15rem;
}

.dlx-homepageSlideSpecial {
  padding-left: 1rem;
  padding-right: 1rem;
  width: 100%;
  background-color: #da7a61;
  height: calc(100vh - 1rem * 8);
  position: relative;
  width: 100vw;
  padding-top: 6rem;
}
@media (min-width: 720px) {
  .dlx-homepageSlideSpecial {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
@media (min-width: 1024px) {
  .dlx-homepageSlideSpecial {
    padding-left: 3.2rem;
    padding-right: 3.2rem;
  }
}
@media (min-width: 1450px) {
  .dlx-homepageSlideSpecial {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
.dlx-homepageSlideSpecial--color1 {
  background-color: #c9dfe2;
}
.dlx-homepageSlideSpecial--color2 {
  background-color: #e3e791;
}
.dlx-homepageSlideSpecial--color3 {
  background-color: #b1c077;
}
.dlx-homepageSlideSpecial--color4 {
  background-color: #f3d580;
}
.dlx-homepageSlideSpecial--color5 {
  background-color: #eab29d;
}
.dlx-homepageSlideSpecial--color6 {
  background-color: #cacac9;
}
.dlx-homepageSlideSpecial::after {
  transition: background-color 0.5s cubic-bezier(0.215, 0.61, 0.355, 1) ;
  background-color: rgba(0, 0, 0, 0.2);
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
.dlx-body.dlx-js-idle .dlx-homepageSlideSpecial::after {
  background-color: transparent;
}
.dlx-homepageSlideSpecial__additional {
  position: relative;
  margin-top: auto;
  display: flex;
}
.dlx-homepageSlideSpecial__additionalLeft {
  font-size: 1.7rem;
  line-height: 1.1764705882;
  font-weight: normal;
  align-self: flex-end;
  display: none;
  margin-right: 2rem;
  width: 70%;
}
@media (min-width: 720px) {
  .dlx-homepageSlideSpecial__additionalLeft {
    font-size: 1.8rem;
    line-height: 1.2222222222;
  }
}
@media (min-width: 1450px) {
  .dlx-homepageSlideSpecial__additionalLeft {
    font-size: 2rem;
    line-height: 1.2;
  }
}
@media (min-width: 720px) {
  .dlx-homepageSlideSpecial__additionalLeft {
    display: block;
  }
}
@media (min-width: 720px) {
  .dlx-homepageSlideSpecial__additionalRight {
    margin-left: auto;
    width: 25rem;
    align-self: flex-end;
    margin-bottom: 0;
  }
}
.dlx-homepageSlideSpecial__category {
  display: inline-block;
  text-transform: uppercase;
}
.dlx-homepageSlideSpecial__content {
  z-index: 2;
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;
  color: #ffffff;
  padding-top: 2rem;
  padding-bottom: 2rem;
}
@media (min-width: 1024px) {
  .dlx-homepageSlideSpecial__content {
    margin-left: calc(16.6666666667% + 0.5333333333rem + 0px);
    width: calc(75% + -0.8rem + 0px);
  }
}
@media (min-width: 1450px) {
  .dlx-homepageSlideSpecial__content {
    margin-left: calc(16.6666666667% + 0.3333333333rem + 0px);
    width: calc(75% + -0.5rem + 0px);
  }
}
@media (min-width: 720px) {
  .dlx-homepageSlideSpecial__content {
    padding-top: 7rem;
    padding-bottom: 7rem;
  }
}
@media (min-width: 1024px) {
  .dlx-homepageSlideSpecial__content {
    padding-top: 8rem;
    padding-bottom: 12rem;
  }
}
@media (min-width: 1450px) {
  .dlx-homepageSlideSpecial__content {
    padding-top: 10rem;
    padding-bottom: 14rem;
  }
}
.dlx-homepageSlideSpecial__date {
  font-family: Roboto, sans-serif;
  font-size: 1.3rem;
  font-weight: bold;
  letter-spacing: 0.1rem;
  line-height: 1.2307692308;
  text-transform: uppercase;
  width: 25rem;
}
@media (min-width: 720px) {
  .dlx-homepageSlideSpecial__date {
    font-size: 1.4rem;
    line-height: 1.2857142857;
  }
}
@media (min-width: 1450px) {
  .dlx-homepageSlideSpecial__date {
    font-size: 1.8rem;
    line-height: 1.2222222222;
  }
}
@media (min-width: 720px) {
  .dlx-homepageSlideSpecial__date {
    display: inline-block;
  }
}
.dlx-homepageSlideSpecial__headline {
  margin-top: 3rem;
  margin-bottom: 2rem;
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-size: 4rem;
  line-height: 1.125;
}
@media (min-width: 720px) {
  .dlx-homepageSlideSpecial__headline {
    font-size: 5rem;
    line-height: 1.2;
  }
}
@media (min-width: 1450px) {
  .dlx-homepageSlideSpecial__headline {
    font-size: 6rem;
    line-height: 1.1666666667;
  }
}
@media (min-width: 1024px) {
  .dlx-homepageSlideSpecial__headline {
    margin-top: 5rem;
    margin-bottom: 2rem;
  }
}
.dlx-homepageSlideSpecial__intro {
  font-size: 1.7rem;
  line-height: 1.1764705882;
  font-weight: normal;
  margin-right: 2rem;
}
@media (min-width: 720px) {
  .dlx-homepageSlideSpecial__intro {
    font-size: 1.8rem;
    line-height: 1.2222222222;
  }
}
@media (min-width: 1450px) {
  .dlx-homepageSlideSpecial__intro {
    font-size: 2rem;
    line-height: 1.2;
  }
}
.dlx-homepageSlideSpecial__intro a {
  text-decoration: underline;
}
.dlx-homepageSlideSpecial__linkAll {
  font-family: Roboto, sans-serif;
  font-size: 1.3rem;
  font-weight: bold;
  letter-spacing: 0.1rem;
  line-height: 1.2307692308;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 5;
  cursor: pointer;
  text-transform: uppercase;
  vertical-align: top;
}
@media (min-width: 720px) {
  .dlx-homepageSlideSpecial__linkAll {
    font-size: 1.4rem;
    line-height: 1.2857142857;
  }
}
@media (min-width: 1450px) {
  .dlx-homepageSlideSpecial__linkAll {
    font-size: 1.8rem;
    line-height: 1.2222222222;
  }
}
.dlx-homepageSlideSpecial__linkRegister {
  display: block;
}
@media (min-width: 720px) {
  .dlx-homepageSlideSpecial__linkRegister {
    display: inline-block;
  }
}
.dlx-homepageSlideSpecial__main {
  position: relative;
}
.dlx-homepageSlideSpecial__time {
  font-size: 1.8rem;
  line-height: 1.3333333333;
  font-weight: normal;
  display: inline-block;
  text-transform: uppercase;
  margin-bottom: 2rem;
}
@media (min-width: 720px) {
  .dlx-homepageSlideSpecial__time {
    font-size: 2rem;
    line-height: 1.4;
  }
}
@media (min-width: 1450px) {
  .dlx-homepageSlideSpecial__time {
    font-size: 2.4rem;
    line-height: 1.4166666667;
  }
}

.dlx-linkIcon {
  display: block;
}
.dlx-linkIcon__label {
  display: inline-block;
  vertical-align: top;
  margin-right: 1rem;
}
.dlx-linkIcon__icon {
  display: inline-block;
  vertical-align: top;
  height: 4rem;
  margin-top: -1rem;
}
.dlx-linkIcon__icon svg {
  height: 4rem;
}
.dlx-linkIcon__icon--small {
  margin-top: -1.3rem;
  margin-left: -1rem;
  width: 4rem;
  height: 4rem;
}
.dlx-linkIcon__icon--small svg {
  width: 4rem;
  height: 4rem;
}

.dlx-blockNewsletterForm {
  padding-left: 1rem;
  padding-right: 1rem;
  width: 100%;
  border-top: 1px solid #ccc5b5;
  border-bottom: 1px solid #ccc5b5;
  padding-top: 4rem;
  padding-bottom: 4rem;
}
@media (min-width: 720px) {
  .dlx-blockNewsletterForm {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
@media (min-width: 1024px) {
  .dlx-blockNewsletterForm {
    padding-left: 3.2rem;
    padding-right: 3.2rem;
  }
}
@media (min-width: 1450px) {
  .dlx-blockNewsletterForm {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
.dlx-block:last-of-type .dlx-blockNewsletterForm {
  margin-bottom: -8rem;
}
.dlx-blockNewsletterForm__content {
  width: 100%;
  margin-left: 0;
}
@media (min-width: 720px) {
  .dlx-blockNewsletterForm__content {
    margin-left: calc(16.6666666667% + -1.6666666667rem + 2rem);
    width: calc(75% + -0.5rem + 0px);
  }
}
@media (min-width: 1024px) {
  .dlx-blockNewsletterForm__content {
    margin-left: calc(41.6666666667% + -1.8666666667rem + 3.2rem);
    width: calc(50% + -1.6rem + 0px);
  }
}
@media (min-width: 1450px) {
  .dlx-blockNewsletterForm__content {
    margin-left: calc(41.6666666667% + -1.1666666667rem + 2rem);
    width: calc(50% + -1rem + 0px);
  }
}
.dlx-blockNewsletterForm__copytext {
  margin-bottom: 4rem;
  font-size: 1.8rem;
  line-height: 1.3333333333;
  font-weight: normal;
}
@media (min-width: 720px) {
  .dlx-blockNewsletterForm__copytext {
    font-size: 2rem;
    line-height: 1.4;
  }
}
@media (min-width: 1450px) {
  .dlx-blockNewsletterForm__copytext {
    font-size: 2.4rem;
    line-height: 1.4166666667;
  }
}
.dlx-blockNewsletterForm__formContent {
  width: 100%;
}
@media (min-width: 1024px) {
  .dlx-blockNewsletterForm__formContent {
    width: calc(75% + -0.8rem + 0px);
  }
}
@media (min-width: 1450px) {
  .dlx-blockNewsletterForm__formContent {
    width: calc(75% + -0.5rem + 0px);
  }
}
.dlx-blockNewsletterForm__header {
  margin-bottom: 4rem;
}

.dlx-newsHeader {
  margin-top: 2rem;
  margin-bottom: 2rem;
  width: 100%;
  margin-left: 0;
}
@media (min-width: 720px) {
  .dlx-newsHeader {
    margin-left: calc(16.6666666667% + -1.6666666667rem + 2rem);
    width: calc(75% + -0.5rem + 0px);
  }
}
@media (min-width: 1024px) {
  .dlx-newsHeader {
    margin-left: calc(41.6666666667% + -1.8666666667rem + 3.2rem);
    width: calc(50% + -1.6rem + 0px);
  }
}
@media (min-width: 1450px) {
  .dlx-newsHeader {
    margin-left: calc(41.6666666667% + -1.1666666667rem + 2rem);
    width: calc(50% + -1rem + 0px);
  }
}
@media (min-width: 1024px) {
  .dlx-newsHeader {
    margin-bottom: 4rem;
  }
}
.dlx-newsHeader__image {
  width: 100%;
  margin-bottom: 3rem;
}
.dlx-newsHeader__img {
  width: 100%;
}
.dlx-newsHeader__title {
  font-family: Roboto, sans-serif;
  font-size: 2.2rem;
  font-weight: normal;
  line-height: 1.1818181818;
  margin-top: 2rem;
  margin-bottom: 4rem;
}
@media (min-width: 720px) {
  .dlx-newsHeader__title {
    font-size: 2.8rem;
    line-height: 1.2857142857;
  }
}
@media (min-width: 1450px) {
  .dlx-newsHeader__title {
    font-size: 4rem;
    line-height: 1.1;
  }
}
.dlx-newsHeader__video {
  width: 100%;
  margin-bottom: 3rem;
}
.dlx-newsHeader__vid {
  width: 100%;
}

.dlx-nextPrevNavigation {
  width: 100%;
  margin-left: 0;
}
@media (min-width: 720px) {
  .dlx-nextPrevNavigation {
    margin-left: calc(16.6666666667% + -1.6666666667rem + 2rem);
    width: calc(75% + -0.5rem + 0px);
  }
}
@media (min-width: 1024px) {
  .dlx-nextPrevNavigation {
    margin-left: calc(41.6666666667% + -1.8666666667rem + 3.2rem);
    width: calc(50% + -1.6rem + 0px);
  }
}
@media (min-width: 1450px) {
  .dlx-nextPrevNavigation {
    margin-left: calc(41.6666666667% + -1.1666666667rem + 2rem);
    width: calc(50% + -1rem + 0px);
  }
}
.dlx-nextPrevNavigation__button {
  font-family: Roboto, sans-serif;
  font-size: 1.3rem;
  font-weight: bold;
  letter-spacing: 0.1rem;
  line-height: 1.2307692308;
  cursor: pointer;
  display: inline-block;
  margin-right: 2rem;
  text-transform: uppercase;
  vertical-align: top;
}
@media (min-width: 720px) {
  .dlx-nextPrevNavigation__button {
    font-size: 1.4rem;
    line-height: 1.2857142857;
  }
}
@media (min-width: 1450px) {
  .dlx-nextPrevNavigation__button {
    font-size: 1.8rem;
    line-height: 1.2222222222;
  }
}
.dlx-nextPrevNavigation__button--next {
  float: right;
}
.dlx-nextPrevNavigation__content {
  padding-top: 4rem;
  padding-bottom: 4rem;
  display: block;
  position: relative;
}
.dlx-nextPrevNavigation__content::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 1px;
  width: 100vw;
  background-color: #ccc5b5;
}

.dlx-registerButton {
  width: 100%;
  margin-left: 0;
  display: block;
  margin-bottom: 4rem;
}
@media (min-width: 720px) {
  .dlx-registerButton {
    margin-left: calc(16.6666666667% + -1.6666666667rem + 2rem);
    width: calc(75% + -0.5rem + 0px);
  }
}
@media (min-width: 1024px) {
  .dlx-registerButton {
    margin-left: calc(41.6666666667% + -1.8666666667rem + 3.2rem);
    width: calc(50% + -1.6rem + 0px);
  }
}
@media (min-width: 1450px) {
  .dlx-registerButton {
    margin-left: calc(41.6666666667% + -1.1666666667rem + 2rem);
    width: calc(50% + -1rem + 0px);
  }
}

.dlx-blockNewsItem::after {
  content: " ";
  display: table;
  clear: both;
}

.dlx-backgroundVideo__image {
  display: block;
}
.dlx-backgroundVideo__imageContainer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}
.dlx-backgroundVideo__img {
  object-fit: cover;
  font-family: "object-fit: cover";
  display: block;
  height: 100%;
  width: 100%;
  position: absolute;
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  html.no-js .dlx-backgroundVideo__img {
    box-sizing: border-box;
    padding-left: 100%;
    background-size: cover;
    background-position: 50% 50%;
  }
}
.dlx-backgroundVideo__video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  overflow: hidden;
}
.dlx-backgroundVideo__video iframe {
  width: 100vw;
  height: 56.25vw;
  /* Given a 16:9 aspect ratio, 9/16*100 = 56.25 */
  min-height: 100vh;
  min-width: 190vh;
  /* Given a 16:9 aspect ratio, 16/9*100 = 177.77 */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.dlx-blockInteractiveGraphic {
  padding-bottom: 10rem;
  padding-top: 10rem;
}
.dlx-blockInteractiveGraphic__container {
  position: relative;
  width: 100%;
}
@media (min-width: 1024px) {
  .dlx-blockInteractiveGraphic__container {
    width: 100%;
  }
}
@media (min-width: 1280px) {
  .dlx-blockInteractiveGraphic__container {
    margin-left: calc(16.6666666667% + 0.5333333333rem + 0px);
    width: calc(75% + -0.8rem + 0px);
  }
}
@media (min-width: 1450px) {
  .dlx-blockInteractiveGraphic__container {
    margin-left: calc(16.6666666667% + 0.3333333333rem + 0px);
    width: calc(75% + -0.5rem + 0px);
  }
}
.dlx-blockInteractiveGraphic__graphic {
  display: block;
  z-index: -1;
  transition: transform 1.2s cubic-bezier(0.25, 0.46, 0.45, 0.94) , opacity 1.2s cubic-bezier(0.25, 0.46, 0.45, 0.94) ;
  transform: translate(500px, 300px);
  opacity: 0;
}
.dlx-blockInteractiveGraphic.dlx-js-inside .dlx-blockInteractiveGraphic__graphic {
  transform: translate(0, 0);
  opacity: 1;
}
.dlx-blockInteractiveGraphic__graphicLottie {
  width: 171rem;
}
.dlx-blockInteractiveGraphic__graphicContentInner {
  position: relative;
}
.dlx-blockInteractiveGraphic__graphicImg {
  width: 171rem;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
}
.dlx-blockInteractiveGraphic__graphicImg #sls-1000 {
  opacity: 0;
  cursor: pointer;
  transition: opacity 0.1s cubic-bezier(0.25, 0.46, 0.45, 0.94) ;
}
.dlx-blockInteractiveGraphic[data-dlx-graphic-element=sls-1000] .dlx-blockInteractiveGraphic__graphicImg #sls-1000 {
  opacity: 1;
}
.dlx-blockInteractiveGraphic[data-dlx-current=sls-1000] .dlx-blockInteractiveGraphic__graphicImg #sls-1000 {
  opacity: 1;
}
.dlx-blockInteractiveGraphic__graphicImg #switch {
  opacity: 0;
  cursor: pointer;
  transition: opacity 0.1s cubic-bezier(0.25, 0.46, 0.45, 0.94) ;
}
.dlx-blockInteractiveGraphic[data-dlx-graphic-element=switch] .dlx-blockInteractiveGraphic__graphicImg #switch {
  opacity: 1;
}
.dlx-blockInteractiveGraphic[data-dlx-current=switch] .dlx-blockInteractiveGraphic__graphicImg #switch {
  opacity: 1;
}
.dlx-blockInteractiveGraphic__graphicImg #antenna {
  opacity: 0;
  cursor: pointer;
  transition: opacity 0.1s cubic-bezier(0.25, 0.46, 0.45, 0.94) ;
}
.dlx-blockInteractiveGraphic[data-dlx-graphic-element=antenna] .dlx-blockInteractiveGraphic__graphicImg #antenna {
  opacity: 1;
}
.dlx-blockInteractiveGraphic[data-dlx-current=antenna] .dlx-blockInteractiveGraphic__graphicImg #antenna {
  opacity: 1;
}
.dlx-blockInteractiveGraphic__graphicImg #apc-master {
  opacity: 0;
  cursor: pointer;
  transition: opacity 0.1s cubic-bezier(0.25, 0.46, 0.45, 0.94) ;
}
.dlx-blockInteractiveGraphic[data-dlx-graphic-element=apc-master] .dlx-blockInteractiveGraphic__graphicImg #apc-master {
  opacity: 1;
}
.dlx-blockInteractiveGraphic[data-dlx-current=apc-master] .dlx-blockInteractiveGraphic__graphicImg #apc-master {
  opacity: 1;
}
.dlx-blockInteractiveGraphic__graphicImg #ethernet-backbone {
  opacity: 0;
  cursor: pointer;
  transition: opacity 0.1s cubic-bezier(0.25, 0.46, 0.45, 0.94) ;
}
.dlx-blockInteractiveGraphic[data-dlx-graphic-element=ethernet-backbone] .dlx-blockInteractiveGraphic__graphicImg #ethernet-backbone {
  opacity: 1;
}
.dlx-blockInteractiveGraphic[data-dlx-current=ethernet-backbone] .dlx-blockInteractiveGraphic__graphicImg #ethernet-backbone {
  opacity: 1;
}
.dlx-blockInteractiveGraphic__graphicImg #seat-management {
  opacity: 0;
  cursor: pointer;
  transition: opacity 0.1s cubic-bezier(0.25, 0.46, 0.45, 0.94) ;
}
.dlx-blockInteractiveGraphic[data-dlx-graphic-element=seat-management] .dlx-blockInteractiveGraphic__graphicImg #seat-management {
  opacity: 1;
}
.dlx-blockInteractiveGraphic[data-dlx-current=seat-management] .dlx-blockInteractiveGraphic__graphicImg #seat-management {
  opacity: 1;
}
.dlx-blockInteractiveGraphic__graphicImg #wifi {
  opacity: 0;
  cursor: pointer;
  transition: opacity 0.1s cubic-bezier(0.25, 0.46, 0.45, 0.94) ;
}
.dlx-blockInteractiveGraphic[data-dlx-graphic-element=wifi] .dlx-blockInteractiveGraphic__graphicImg #wifi {
  opacity: 1;
}
.dlx-blockInteractiveGraphic[data-dlx-current=wifi] .dlx-blockInteractiveGraphic__graphicImg #wifi {
  opacity: 1;
}
.dlx-blockInteractiveGraphic__graphicImg #citysense {
  opacity: 0;
  cursor: pointer;
  transition: opacity 0.1s cubic-bezier(0.25, 0.46, 0.45, 0.94) ;
}
.dlx-blockInteractiveGraphic[data-dlx-graphic-element=citysense] .dlx-blockInteractiveGraphic__graphicImg #citysense {
  opacity: 1;
}
.dlx-blockInteractiveGraphic[data-dlx-current=citysense] .dlx-blockInteractiveGraphic__graphicImg #citysense {
  opacity: 1;
}
.dlx-blockInteractiveGraphic__graphicImg #board-computer {
  opacity: 0;
  cursor: pointer;
  transition: opacity 0.1s cubic-bezier(0.25, 0.46, 0.45, 0.94) ;
}
.dlx-blockInteractiveGraphic[data-dlx-graphic-element=board-computer] .dlx-blockInteractiveGraphic__graphicImg #board-computer {
  opacity: 1;
}
.dlx-blockInteractiveGraphic[data-dlx-current=board-computer] .dlx-blockInteractiveGraphic__graphicImg #board-computer {
  opacity: 1;
}
.dlx-blockInteractiveGraphic__graphicImg #server {
  opacity: 0;
  cursor: pointer;
  transition: opacity 0.1s cubic-bezier(0.25, 0.46, 0.45, 0.94) ;
}
.dlx-blockInteractiveGraphic[data-dlx-graphic-element=server] .dlx-blockInteractiveGraphic__graphicImg #server {
  opacity: 1;
}
.dlx-blockInteractiveGraphic[data-dlx-current=server] .dlx-blockInteractiveGraphic__graphicImg #server {
  opacity: 1;
}
.dlx-blockInteractiveGraphic__popup {
  background-color: grey;
  position: absolute;
  right: 0;
  width: 100%;
  max-width: 500px;
  height: auto;
  top: 10rem;
  display: block;
  z-index: 10;
}
.dlx-blockInteractiveGraphic__menu {
  display: block;
  position: relative;
  z-index: 10;
  margin-top: -50rem;
}

.dlx-interactiveGraphicInteractions {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
}
.dlx-interactiveGraphicInteractions__interaction {
  width: 100%;
  height: 100%;
  position: relative;
}
.dlx-interactiveGraphicInteractions__button {
  position: absolute;
  display: block;
  background-color: red;
}

.dlx-interactiveGraphicMenu {
  width: 32rem;
}
.dlx-interactiveGraphicMenu__head {
  font-family: Roboto, sans-serif;
  font-size: 1.5rem;
  font-weight: normal;
  line-height: 1.3333333333;
  font-weight: bold;
  text-transform: uppercase;
  margin-top: 2rem;
  margin-bottom: 1rem;
}
@media (min-width: 720px) {
  .dlx-interactiveGraphicMenu__head {
    font-size: 1.6rem;
    line-height: 1.375;
  }
}
@media (min-width: 1450px) {
  .dlx-interactiveGraphicMenu__head {
    font-size: 1.8rem;
    line-height: 1.3333333333;
  }
}
.dlx-interactiveGraphicMenu__menu {
  position: relative;
}
.dlx-interactiveGraphicMenu__menu::before {
  content: "";
  width: 1px;
  height: 100%;
  background-color: red;
  position: absolute;
  top: 0;
  left: -2rem;
}
.dlx-interactiveGraphicMenu__menu--cat1::before {
  background-color: #da7b5e;
}
.dlx-interactiveGraphicMenu__menu--cat2::before {
  background-color: #768c2f;
}
.dlx-interactiveGraphicMenu__menu--cat3::before {
  background-color: #6a665b;
}
.dlx-interactiveGraphicMenu__menuItem {
  margin-bottom: 2px;
  padding: 2px;
  margin-left: -1rem;
  width: 29rem;
  background-color: #ffffff;
}
.dlx-interactiveGraphicMenu__button {
  text-align: left;
  cursor: pointer;
  margin-left: 1rem;
  width: 29rem;
  font-family: Roboto, sans-serif;
  font-size: 1.5rem;
  font-weight: normal;
  line-height: 1.3333333333;
}
@media (min-width: 720px) {
  .dlx-interactiveGraphicMenu__button {
    font-size: 1.6rem;
    line-height: 1.375;
  }
}
@media (min-width: 1450px) {
  .dlx-interactiveGraphicMenu__button {
    font-size: 1.8rem;
    line-height: 1.3333333333;
  }
}

.dlx-interactiveGraphicPopup {
  background-color: #f8f8f7;
  border: 1px solid #ccc5b5;
  box-shadow: 0 5px 110px 20px rgba(210, 210, 210, 0.5);
  display: none;
  padding: 3rem 2rem 2rem 2rem;
  right: 0;
  z-index: 2;
}
.dlx-interactiveGraphicPopup.dlx-js-active {
  display: block;
}
@media (min-width: 720px) {
  .dlx-interactiveGraphicPopup {
    padding: 2rem;
  }
}
.dlx-interactiveGraphicPopup__close {
  position: absolute;
  font-family: Roboto, sans-serif;
  font-size: 1.4rem;
  font-weight: normal;
  line-height: 1;
  text-transform: uppercase;
  right: 2rem;
  top: 1rem;
  cursor: pointer;
}
@media (min-width: 720px) {
  .dlx-interactiveGraphicPopup__close {
    font-size: 1.4rem;
    line-height: 1;
  }
}
@media (min-width: 720px) {
  .dlx-interactiveGraphicPopup__close {
    top: 2rem;
  }
}
.dlx-interactiveGraphicPopup__copy {
  font-size: 1.7rem;
  line-height: 1.1764705882;
  font-weight: normal;
}
@media (min-width: 720px) {
  .dlx-interactiveGraphicPopup__copy {
    font-size: 1.8rem;
    line-height: 1.2222222222;
  }
}
@media (min-width: 1450px) {
  .dlx-interactiveGraphicPopup__copy {
    font-size: 2rem;
    line-height: 1.2;
  }
}
.dlx-interactiveGraphicPopup__head {
  margin-top: 2rem;
  margin-bottom: 3rem;
}
.dlx-interactiveGraphicPopup__item {
  display: none;
}
.dlx-interactiveGraphicPopup__item.dlx-js-active {
  display: block;
}
.dlx-interactiveGraphicPopup__link {
  font-family: Roboto, sans-serif;
  font-size: 1.3rem;
  font-weight: bold;
  letter-spacing: 0.1rem;
  line-height: 1.2307692308;
  color: #6a665b;
  display: inline-block;
  text-transform: uppercase;
  margin-top: 3rem;
}
@media (min-width: 720px) {
  .dlx-interactiveGraphicPopup__link {
    font-size: 1.4rem;
    line-height: 1.2857142857;
  }
}
@media (min-width: 1450px) {
  .dlx-interactiveGraphicPopup__link {
    font-size: 1.8rem;
    line-height: 1.2222222222;
  }
}