.dlx-formFieldLabel {
	@include font(subhead);
	margin-bottom: $base;
	text-transform: uppercase;
	display: block;

	&--invisible {
		opacity: 0;

		display: none;
		@include media($smallLayout) {
			display: block;
		}
	}
}
