.dlx-fullGalleryItem {
	height: 100vh;

	position: relative;
	overflow: hidden;

	&__textContainer {
		padding-top: $base4;
		padding-bottom: $base4;
		background-color: $fullGalleryBg;
		bottom: 0;
		color: $colorBaseInverted;
		left: 0;
		position: absolute;
		width: 100%;

		@include ease(transform, $durationVeryLong * 2);
		transform: translateY(100%);

		&.dlx-js-inside {
			transform: translateY(0%);
		}

		.dlx-fullGalleryItem--inverted & {
			background-color: $fullGalleryBgInverted;
			color: $colorBase;
		}
	}

	&__textInner {
		@include dlx-container();
		width: 100%;

		@include media($mediumLayout) {
			display: flex;
		}
	}

	&__title {
		@include font(headlight);
		display: flex;
		width: 100%;
		margin-bottom: $base2;

		@include media($mediumLayout) {
			width: staticColumnSize(6, 12, $gutterMedium);
		}

		@include media($xLargeLayout) {
			width: staticColumnSize(6, 12, $gutterXLarge);
		}
	}

	&__subtitle {
		width: 100%;

		@include media($mediumLayout) {
			width: staticColumnSize(6, 12, $gutterMedium);
		}

		@include media($xLargeLayout) {
			width: staticColumnSize(6, 12, $gutterXLarge);
		}
	}

	&__content {
		height: 100%;
		display: flex;
		flex-direction: column;

		@include media($smallLayout) {
			margin-left: staticColumnPush(2, 12, $gutterBase);
		}

		@include media($xLargeLayout) {
			margin-left: staticColumnPush(2, 12, $gutterXLarge);
		}
	}

	&__head {
		display: flex;
		flex-grow: 1;
		@include font(largeheadlight);
	}

	&__subhead {
		padding-bottom: $base4;
		display: flex;
		@include font(headerlight);
		width: staticColumnPush(7, 10, $gutterXLarge);

		@include media($smallLayout) {
			padding-bottom: $base8;
		}

		@include media($xLargeLayout) {
			padding-bottom: $base10;
		}
	}

	&__image {
		display: block;
	}

	&__imageContainer {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: -1;
	}

	&__img {
		@include objectFit(cover);
		display: block;
		height: 100%;
		width: 100%;
		position: absolute;
	}
}
