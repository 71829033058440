
@mixin dlx-marginContent() {
	width: 100%;

	@include media($smallLayout) {
		margin-left: staticColumnPush(2, 12, $gutterBase);
		width: staticColumnSize(6, 12, $gutterBase);
	}

	@include media($mediumLayout) {
		margin-left: staticColumnPush(2, 12, $gutterMedium);
		width: staticColumnSize(3, 12, $gutterMedium);
	}

	@include media($xLargeLayout) {
		margin-left: staticColumnPush(2, 12, $gutterXLarge);
		width: staticColumnSize(2, 12, $gutterXLarge);
	}
}
