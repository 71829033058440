.dlx-modalGallery {
	// @include storeValuesForJs((enableScrollMonitor: false))
	@include fadeOut($duration4);
	background-color: $colorModalGalleryBg;
	display: none;
	height: 100vh;
	left: 0;
	padding-left: $containerPaddingBase;
	padding-right: $containerPaddingBase;
	padding-top: $base;
	padding-bottom: $base;
	position: fixed;
	right: 0;
	top: 0;
	width: 100%;
	z-index: 100;

	@include media($smallLayout) {
		padding-left: $containerPaddingSmall;
		padding-right: $containerPaddingSmall;
	}

	@include media($mediumLayout) {
		padding-left: $containerPaddingMedium;
		padding-right: $containerPaddingMedium;
	}

	@include media($xLargeLayout) {
		padding-left: $containerPaddingXLarge;
		padding-right: $containerPaddingXLarge;
		padding-top: $base2;
		padding-bottom: $base2;
	}

	&.dlx-js-fixed {
		position: fixed;
	}


	&.dlx-js-beforeActive {
		display: block;
		will-change: opacity, visibility;
	}

	&.dlx-js-duringActive {
		@include fadeIn();
	}


	&__content {
		height: 100%;
		position: relative;
		z-index: 1;
	}


	&__nav {
		bottom: $base;
		left: $containerPaddingBase;
		position: absolute;
		right: $containerPaddingBase;
		top: $base;
		z-index: 2;
		@include media($smallLayout) {
			left: $containerPaddingSmall;
			right: $containerPaddingSmall;
		}

		@include media($mediumLayout) {
			left: $containerPaddingMedium;
			right: $containerPaddingMedium;
		}

		@include media($xLargeLayout) {
			left: $containerPaddingXLarge;
			right: $containerPaddingXLarge;
			padding-top: $base2;
			padding-bottom: $base2;
		}
	}
}
