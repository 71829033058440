.dlx-footer {
	@include dlx-container();
	background-color: $colorFooter;
	padding-bottom: $base6;
	padding-top: $base2 + $base05;
	z-index: 1;

	@include media($smallLayout) {
		padding-bottom: $base8;
		padding-top: $base4;
	}

	@include media($mediumLayout) {
		padding-bottom: $base10;
		padding-top: $base4;
	}

	@include media($xLargeLayout) {
		padding-bottom: $base * 12;
		padding-top: $base6;
	}

	.dlx-body.dlx-js-homePage & {
		visibility: hidden;
	}

	&__address {
		@include font(footermenu);
		vertical-align: top;

		margin-right: $gutterBase;
		width: staticColumnSize(1, 2, $gutterBase);

		@include media($smallLayout) {
			width: staticColumnSize(6, 9, $gutterBase);
		}

		@include media($mediumLayout) {
			margin-right: $gutterMedium;
			width: staticColumnSize(4, 6, $gutterMedium);
		}

		@include media($xLargeLayout) {
			margin-right: $gutterXLarge;
			width: staticColumnSize(4, 6, $gutterXLarge);
		}
	}

	&__content {
		width: 100%;
	}

	&__info {
		@include staticColumns();
	}

	&__impressum {
		@include font(footermenu);
		//margin-top: $base4 - $base025;
		align-self: flex-end;
		width: staticColumnSize(1, 2, $gutterBase);

		@include media($smallLayout) {
			width: staticColumnSize(3, 9, $gutterBase);
		}

		@include media($mediumLayout) {
			width: staticColumnSize(2, 6, $gutterMedium);
		}

		@include media($xLargeLayout) {
			width: staticColumnSize(2, 6, $gutterXLarge);
		}
	}

	&__mainContent {
		@include dlx-mainContent();
	}

	&__menu {
		width: 100%;
	}
}
