.dlx-search {
	@include dlx-container();
	@include fadeOut($durationShort, $easeBase);
	background-color: $colorBgBase;
	display: block;
	height: calc(100vh - #{$headerHeightSmall});
	left: 0;
	position: absolute;
	top: $headerHeightSmall;
	width: 100%;
	z-index: 30;
	pointer-events: none;

	@include media($mediumLayout) {
		background-color: transparent;
		position: fixed;
		top: $headerPaddingBig;
	}

	&--embedded {
		@include fadeIn();
		@include dlx-undoContainer();
		height: auto;
		z-index: auto;
		margin-bottom: $base2;
		pointer-events: auto;
		position: relative;
		top: auto;

		@include media($mediumLayout) {
			margin-bottom: $base4;
			position: relative;
			top: auto;
			// display: none;
		}
	}


	.dlx-body.dlx-js-searchOpen & {
		@include fadeIn($durationShort, $easeBase);
	}

	&__button {
		vertical-align: top;
		margin-top: $base2;
		width: 100%;

		.dlx-search--embedded & {
			@include media($smallLayout) {
				margin-left: 0;
			}
		}

		@include media($smallLayout) {
			margin-left: staticColumnPush(2, 12, $gutterBase);
			width: staticColumnSize(8, 12, $gutterBase);
		}

		@include media($mediumLayout) {
			margin-top: 0;
			margin-left: 0;
			width: auto;
		}
	}

	&__close {
		position: absolute;
		left: - $base05;
		cursor: pointer;
		top: - $headerHeightSmall + $base05;

		@include media($mediumLayout) {
			position: relative;
			top: 0;
			left: -$base05;
			display: inline-block;
			margin-top: $logoHeight * 0.5 - $base + 0.1rem;
			//transform: translateY(-50%);
		}

		.dlx-search--embedded & {
			display: none;
		}
	}

	&__closeIcon {
		[data-type="svg"] {
			color: $colorIcon;
			transform: scaleX(-1);
		}

		.dlx-body.dlx-js-fullyInvertedHeader &,
		.dlx-body.dlx-js-invertedHeader .dlx-pageHeader:not(.dlx-js-scrolledDown) & {
			@include media($mediumLayout) {
				[data-type="svg"] {
					color: $colorBaseInverted;
				}
			}
		}

		@include media($mediumLayout) {
			[data-type="svg"] {
				color: $colorSecond;
				transform: scaleX(1);
			}
		}

		&--normal {
			display: block;
			@include media($mediumLayout) {
				display: none;
			}
		}

		&--desktop {
			display: none;
			@include media($mediumLayout) {
				display: block;
			}
		}
	}

	&__content {
		position: relative;
		width: 100%;
		margin-left: 0;

		.dlx-search:not(.dlx-search--embedded) & {
			@include media($mediumLayout) {
				float: right;
				text-align: right;
				margin-left: staticColumnPush(5, 12, $gutterMedium);
				width: staticColumnSize(6, 12, $gutterMedium);
			}

			@include media($xLargeLayout) {
				margin-left: staticColumnPush(5, 12, $gutterXLarge);
				width: staticColumnSize(6, 12, $gutterXLarge);
			}
		}
	}



	&__form {
		.dlx-body.dlx-js-searchOpen & {
			pointer-events: all;
		}
	}

	&__input {
		//
	}

	&__inputContainer {
		width: 100%;
		padding-top: $base3;

		@include media($smallLayout) {
			margin-left: staticColumnPush(2, 12, $gutterBase);
			width: staticColumnSize(8, 12, $gutterBase);
		}

		.dlx-search--embedded & {
			@include media($smallLayout) {
				margin-left: 0;
			}
		}

		@include media($mediumLayout) {
			display: inline-block;
			margin-left: 0;
			margin-right: $gutterMedium - $base05;
			padding-top: 0;
			vertical-align: top;
			width: staticColumnSize(3, 6, $gutterMedium);
		}

		@include media($xLargeLayout) {
			width: staticColumnSize(3, 6, $gutterXLarge);
			margin-right: $gutterXLarge - $base05;
		}
	}
}
