.dlx-footnote {
	cursor: default;
	left: 0;
	opacity: 0;
	position: absolute;
	transform: translate(0, -100%);
	visibility: hidden;
	width: 100%;
	z-index: 1;
	margin-top: $base;

	@include media($smallLayout) {
		left: 50%;
		position: absolute;
		transform: translate(-50%, -100%);
		width: $footnoteWidthXL;
	}

	.dlx-footnoteLink.dlx-js-active & {
		@include ease(opacity visibility margin-top, $durationShort, $easeBase);
		margin-top: 0;
		opacity: 1;
		visibility: visible;
		z-index: 50;
	}

	&__content {
		background-color: $colorFootnote;
		display: block;
		margin: 0 $containerPaddingBase $base $containerPaddingBase;
		padding: $base;
		@include media($smallLayout) {
			margin: 0 $base2 $base $base2;
			padding: $base2;
		}
	}

	&__head {
		@include font(header);
		display: block;
		margin-bottom: $base;
		@include media($smallLayout) {
			margin-bottom: $base2;
		}
	}

	&__subhead {
		@include font(subhead);
		text-transform: uppercase;
		color: $colorSubhead;
		display: block;
		margin-bottom: $base3;
		@include media($smallLayout) {
			margin-bottom: $base5;
		}
		@include media($xLargeLayout) {
			margin-bottom: $base6;
		}
	}

	&__text {
		@include font(subheader);
		display: block;
	}
}
