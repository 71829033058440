.dlx-homepageSlide {
	background-color: $colorBase;
	width: 100vw;
	height: 100vh;
	position: relative;
	@include dlx-container();

	&::after {
		@include ease(background-color, $durationVeryLongHome, $easeOutCubic);
		background-color: $colorOverlayTransparent;
		content: '';
		height: 100%;
		left: 0;
		position: absolute;
		top: 0;
		width: 100%;

		.dlx-body.dlx-js-idle & {
			background-color: transparent;
		}
	}


	&__content {
		position: relative;
		height: 100%;
		color: $colorBaseInverted;
		padding-top: $base6;
		z-index: 1;

		@include media($smallLayout) {
			padding-top: $base7;
		}

		@include media($mediumLayout) {
			padding-top: $base * 12;
		}

		@include media($xLargeLayout) {
			padding-top: $base * 18;
		}
	}


	&__head {
		@include dlx-mainContent(true);
		@include ease(opacity, $durationVeryLongHome, $easeOutCubic);
		@include font(largehead);
		opacity: 0;

		.dlx-homepageSlide.dlx-js-active & {
			opacity: 0.01;
		}
	}

	&__image {
		display: block;
		height: 100%;
		left: 0;
		position: absolute;
		top: 0;
		width: 100%;
	}

	&__img {
		@include objectFit(cover);
		display: block;
		height: 100%;
		width: 100%;
	}

	&__links {
		position: absolute;
		width: 100%;
		bottom: $base * 3;

		@include media($smallLayout) {
			bottom: $base * 4;
		}

		@include media($xLargeLayout) {
			bottom: $base * 8;
		}
	}

	&__subhead {
		@include dlx-mainContent(true);
		@include ease(opacity, $durationVeryLongHome, $easeOutCubic);
		bottom: $base;
		opacity: 0;
		position: absolute;
		width: 100%;

		.dlx-homepageSlide.dlx-js-active & {
			@include ease(opacity, $durationVeryLongHome, $easeOutCubic, $durationVeryLongHome);
			opacity: 1;
		}

		@include media($smallLayout) {
			bottom: $base2;
			width: 100%;
		}

		@include media($xLargeLayout) {
			bottom: $base3;
		}
	}

	&__subheadText {
		@include font(subhead);
		color: $colorSecondInverted;
		display: block;
		margin-bottom: 0;
		position: relative;
		text-transform: uppercase;

		text-align: right;

		@include fadeIn($durationLong, $easeBase);

		.dlx-body.dlx-js-idle & {
			@include media($mediumLayout) {
				@include fadeOut($durationLong, $easeBase);
			}
		}

		@include media($mediumLayout) {
			text-align: left;
		}
	}

	&__subheadTextHighlight {
		@include colorSet($colorsSetActive, $colorsSetLength, color, true, false, '.dlx-homepageSlide');
	}


	&__video {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		pointer-events: none;
		overflow: hidden;

		iframe {
			width: 100vw;
			height: 56.25vw; /* Given a 16:9 aspect ratio, 9/16*100 = 56.25 */
			min-height: 100vh;
			min-width: 177.77vh; /* Given a 16:9 aspect ratio, 16/9*100 = 177.77 */
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
		}
	}
}
