.dlx-blockLinks {

	padding-bottom: $base2;
	padding-top: $base2;

	@include media($mediumLayout) {
		padding-bottom: $base4;
		padding-top: $base4;
	}

	&__head {
		@include font(base);
		font-weight: bold;

		margin-top: $base2;
		margin-bottom: $base;

		@include media($smallLayout) {
			margin-top: $base2;
			margin-bottom: $base2;
		}

		@include media($xLargeLayout) {
			margin-top: $base3;
			margin-bottom: $base3;
		}
	}


	&__item {
		@include dlx-mainContent();

		@for $i from 0 through 20 {
			&:nth-child(#{$i}) {
				@include dlx-fadeUp($durationDelayBetweenElements * $i, '.dlx-blockLinks__list');
			}
		}

		&--wide {
			@include dlx-mainContentWide();
		}

		&::before {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			height: 1px;
			width: 100vw;
			z-index: 2;
			background-color: $colorSeparateLine;
		}

		&:last-of-type::after {
			content: '';
			position: absolute;
			bottom: 0;
			left: 0;
			height: 1px;
			width: 100vw;
			z-index: 2;
			background-color: $colorSeparateLine;
		}

		&--highlight {
			&::after {
				content: '';
				position: absolute;
				bottom: 0;
				left: 0;
				height: 1px;
				width: 100vw;
				background-color: $colorBase;
				z-index: 1;
			}

			&::before {
				display: none;
			}
		}

		&--highlight:last-of-type {
			&::after {
				display: none;
			}
		}
	}

	&__list {
		//
	}
}
