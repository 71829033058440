$graphicElements: sls-1000, switch, antenna, apc-master, ethernet-backbone, seat-management, wifi, citysense, board-computer, server;

.dlx-blockInteractiveGraphic {
	//@include dlx-container();
	padding-bottom: $base10;
	padding-top: $base10;

	&__container {
		position: relative;
		width: 100%;

		@include media($mediumLayout) {
			width: staticColumnSize(6, 6, $gutterMedium);
		}

		@include media($largeLayout) {
			margin-left: staticColumnPush(2, 12, $gutterMedium);
			width: staticColumnSize(9, 12, $gutterMedium);
		}

		@include media($xLargeLayout) {
			margin-left: staticColumnPush(2, 12, $gutterXLarge);
			width: staticColumnSize(9, 12, $gutterXLarge);
		}
	}

	&__graphic {
		display: block;
		z-index: -1;

		@include ease(transform opacity, $durationVeryLong * 3, $easeBase);
		transform: translate(500px, 300px);
		opacity: 0;

		.dlx-blockInteractiveGraphic.dlx-js-inside & {
			transform: translate(0, 0);
			opacity: 1;
		}
	}

	&__graphicLottie {
		width: $base * 171;
	}

	&__graphicContentInner {
		position: relative;
	}

	&__graphicImg {
		width: $base * 171;
		position: absolute;
		top: 0;
		left: 0; //20px;
		z-index: 100;

		@each $graphicElement in $graphicElements {
			& ##{$graphicElement} {
				opacity: 0;
				cursor: pointer;
				@include ease(opacity, $durationShort, $easeBase);
			}
			.dlx-blockInteractiveGraphic[data-dlx-graphic-element="#{$graphicElement}"] & ##{$graphicElement} {
				opacity: 1;
			}

			.dlx-blockInteractiveGraphic[data-dlx-current="#{$graphicElement}"] & ##{$graphicElement} {
				opacity: 1;
			}
		}
	}

	&__popup {
		background-color: grey;
		position: absolute;
		right: 0;
		width: 100%;
		max-width: 500px;
		height: auto;
		top: $base10;
		display: block;
		z-index: 10;
	}

	&__menu {
		display: block;
		position: relative;
		z-index: 10;
		margin-top: -$base10 * 5;
	}
}
