.dlx-blockContactForm {
	@include dlx-container();
	background-color: $colorBaseInverted;
	padding-top: $base4;
	padding-bottom: $base4;
	overflow: hidden;

	&__content {
		@include staticColumns();
		flex-direction: column;

		@include media($mediumLayout) {
			flex-direction: row;
		}

		@include media($mediumLayout) {
			margin-left: staticColumnPush(2, 12, $gutterXLarge);
		}
	}

	&__copytext {
		margin-bottom: $base4;
		@include font(base);
	}

	&__formContent {
		order: 2;
		width: 100%;
		@include media($mediumLayout) {
			order: 1;
			width: staticColumnSize(6, 12, $gutterMedium);
		}

		@include media($xLargeLayout) {
			width: staticColumnSize(6, 12, $gutterXLarge);
		}
	}

	&__header {
		margin-bottom: $base4;
		@include font(headerlight);
	}

	&__imageContent {
		order: 1;
		width: 100%;
		max-height: 100%;
		margin-bottom: $base2;
		position: relative;

		@include media($mediumLayout) {
			order: 2;
			width: staticColumnSize(6, 12, $gutterMedium);
		}

		@include media($xLargeLayout) {
			width: staticColumnSize(6, 12, $gutterXLarge);
		}
	}
/*
	&__image {
		display: block;
		position: relative;
		width: 100%;
		max-height: 100%;
		height: auto;
	}*/

	&__image {
		display: block;
	}

	&__imageContainer {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}

	&__img {
		@include objectFit(cover);
		display: block;
		height: 100%;
		width: 100%;
		position: absolute;
	}
}
