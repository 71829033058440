.dlx-squareButton {
	@include ease(background-color, $durationBase, $easeBase);
	background-color: $colorSquareButton;
	cursor: pointer;
	display: inline-block;
	height: $formElementsHeight;
	line-height: $formElementsHeight;
	min-width: $base * 11;
	overflow: hidden;
	padding-left: $base2;
	padding-right: $base2;
	position: relative;
	text-align: center;

	@include media($xLargeLayout) {
		height: $formElementsHeightXL;
	}

	@include media($xLargeLayout) {
		line-height: $formElementsHeightXL;
	}

	&::after {
		@include ease(opacity width height, $durationBase, $easeBase);
		background-color: $colorBaseInverted;
		border-radius: 50%;
		content: '';
		filter: blur(10px);
		height: 10px;
		left: 50%;
		opacity: 0.4;
		position: absolute;
		top: 50%;
		transform: translate(-50%, -50%);
		width: 10px;
	}
  	&:disabled {
	  opacity: 0.3;
	  cursor: pointer;
	  pointer-events: none;
	}
	&:hover::after {
		opacity: 1;
		width: 100px;
		height: 100px;
	}

	&:hover {
		background-color: $colorBaseInverted;
	}

	&--colorSet {
		@include colorSet($colorsSet, $colorsSetLength, background-color, false, true, '.dlx-squareButton');

		&::after {
			@include colorSet($colorsSetActive, $colorsSetLength, background-color, false, true, '.dlx-squareButton');
		}

		&:hover {
			@include colorSet($colorsSetActive, $colorsSetLength, background-color, false, true, '.dlx-squareButton');
		}
	}

	&--lightBg {
		background-color: $colorSquareButtonLightBgHover;

		&::after {
			background-color: $colorSquareButton;
		}

		&:hover {
			background-color: $colorSquareButton;
		}
	}

	&--specialBg {
		background-color: $nameColorKhakiGreen;

		&::after {
			background-color: $nameColorTanGreen;
		}

		&:hover {
			background-color: $nameColorTanGreen;
		}
	}

	&--specialBg2 {
		background-color: $nameColorPaleSkyBlue;

		&::after {
			background-color: $nameColorBlueGrey;
		}

		&:hover {
			background-color: $nameColorBlueGrey;
		}
	}

	&--specialBg3 {
		background-color: $nameColorBlush;

		&::after {
			background-color: $nameColorDarkPeach;
		}

		&:hover {
			background-color: $nameColorDarkPeach;
		}
	}

	&--invertedBg {
		background-color: $colorBaseInverted;
		border: 1px solid $colorSeparateLine;

		&::after {
			background-color: $colorSquareButton;
		}

		&:hover {
			background-color: $colorSquareButton;
		}
	}

	&--invertedBgAlt {
		background-color: $colorBaseInverted;
		border: 1px solid $colorSeparateLine;

		&::after {
			background-color: $colorSquareButton;
		}

		&:hover {
			background-color: $colorSquareButton;
		}
	}

	&--borderBg {
		box-shadow: -3px -3px -3px -3px #{$colorBaseInverted} inset;
		//box-sizing: border-box;
		background-color: transparent;
		//border: 3px solid $colorBaseInverted;

		&::before {
			border: 3px solid $colorBaseInverted;
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			content: '';
		}

		&::after {
			background-color: $colorBaseInverted;
		}

		&:hover {
			background-color: $colorBaseInverted;
		}
	}

	&--alignToLabel {
		margin-top: getCalculatedLineHeight(subhead) + $base + 0.1rem;

		@include media($xLargeLayout) {
			margin-top: getCalculatedLineHeight(subhead, $xLargeLayout) + $base - 0.1rem;
		}
	}

	&--trackersOptions {
		text-align: center;
		width: staticColumnSize(1, 1, $gutterBase);
	}

	&--trackersSave {
		margin-top: $base3;
		//width: staticColumnSize(1, 1, $gutterBase);
	}

	&--trackersOk {
		width: staticColumnSize(1, 1, $gutterBase);

		.dlx-trackers:not(.js-confirmMode) & {
			display: none;
		}
	}

	&--trackersAcceptAll {
		margin-left: $gutterBase;

		@include media($mediumLayout) {
			margin-left: $gutterMedium;
		}

		@include media($xLargeLayout) {
			margin-left: $gutterXLarge;
		}
	}

	&--full {
		width: 100%;
	}

	&--wider {
		min-width: $base * 25;
	}

	&__label {
		vertical-align: middle;
		@include font(subhead);
		color: $colorSecond;
		display: inline-block;
		width: auto;
		position: relative;
		text-transform: uppercase;
		margin-top: -1px; //just to align nicer
		z-index: 1;

		.dlx-squareButton--specialBg & {
			color: $colorBaseInverted;
			@include font(base);
		}

		.dlx-squareButton--invertedBg & {
			@include font(base);
		}

		.dlx-squareButton--borderBg & {
			color: $colorBaseInverted;
		}

		.dlx-squareButton--borderBg:hover & {
			color: $colorSecond;
		}

		.dlx-squareButton--colorSet:hover & {
			color: $colorBaseInverted;
		}
	}
}
