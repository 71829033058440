.dlx-homepageSlideSpecial {
	@include dlx-container();
	background-color: #da7a61;
	@include colorSet($colorsSet, $colorsSetLength, background-color);
	height: calc(100vh - #{$base} * 8);
	position: relative;
	width: 100vw;
	padding-top: $base6;

	&::after {
		@include ease(background-color, $durationVeryLongHome, $easeOutCubic);
		background-color: $colorOverlayTransparent;
		content: '';
		height: 100%;
		left: 0;
		position: absolute;
		top: 0;
		width: 100%;

		.dlx-body.dlx-js-idle & {
			background-color: transparent;
		}
	}

	&__additional {
		position: relative;
		margin-top: auto;
		display: flex;
	}

	&__additionalLeft {
		@include font(intro);
		align-self: flex-end;
		display: none;
		margin-right: $base2;
		width: 70%;

		@include media($smallLayout) {
			display: block;
		}
	}

	&__additionalRight {
		@include media($smallLayout) {
			margin-left: auto;
			width: $base * 25;
			align-self: flex-end;
			margin-bottom: 0;
		}
	}

	&__category {
		display: inline-block;
		text-transform: uppercase;
		//margin-right: $base;
	}

	&__content {
		z-index: 2;
		@include dlx-mediumContent();
		display: flex;
		flex-direction: column;
		position: relative;
		height: 100%;
		color: $colorBaseInverted;
		padding-top: $base2;
		padding-bottom: $base2;

		@include media($smallLayout) {
			padding-top: $base7;
			padding-bottom: $base7;
		}

		@include media($mediumLayout) {
			padding-top: $base * 8;
			padding-bottom: $base * 12;
		}

		@include media($xLargeLayout) {
			padding-top: $base * 10;
			padding-bottom: $base * 14;
		}
	}

	&__date {
		@include font(subhead);
		text-transform: uppercase;
		width: $base * 25;

		@include media($smallLayout) {
			display: inline-block;
		}
	}

	&__headline {
		margin-top: $base3;
		margin-bottom: $base2;
		@include font(bannerHead);

		@include media($mediumLayout) {
			margin-top: $base5;
			margin-bottom: $base2;
		}
	}

	&__intro {
		@include font(intro);
		margin-right: $base2;

		a {
			text-decoration: underline;
		}
	}

	&__link {
		//
	}

	&__linkAll {
		@include font(subhead);
		position: absolute;
		right: 0;
		top: 0;
		z-index: 5;
		cursor: pointer;
		text-transform: uppercase;
		vertical-align: top;
	}

	&__linkRegister {
		display: block;

		@include media($smallLayout) {
			display: inline-block;
		}
	}

	&__main {
		position: relative;
	}

	&__time {
		@include font(base);
		display: inline-block;
		text-transform: uppercase;
		margin-bottom: $base2;
	}

}
