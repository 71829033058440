.dlx-blockHeadquarters {
	@include dlx-mainContent();
	@include dlx-fadeUp();
	position: relative;

	padding-top: $base2;

	@include media($mediumLayout) {
		padding-top: $base3;
	}

	@include media($xLargeLayout) {
		padding-top: $base5;
	}

	&::before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		height: 1px;
		width: 100vw;
		background-color: $colorSeparateLine;
	}

	&__items {
		@include staticColumns(0);

		margin-top: $base4;

		@include media($mediumLayout) {
			margin-top: $base6;
		}

		@include media($xLargeLayout) {
			margin-top: $base8;
		}
	}
}
