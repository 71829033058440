
$duration: 0.1s;
$duration2: $duration * 2;
$duration3: $duration * 3;
$duration4: $duration * 4;
$duration5: $duration * 5;
$duration6: $duration * 6;
$duration7: $duration * 7;
$duration8: $duration * 8;
$duration9: $duration * 9;
$duration10: $duration * 10;

$duration05: $duration * 0.5;
$duration025: $duration * 0.25;
$duration033: $duration * 0.33;


$easeLinear: linear;
$easeOutQuad: cubic-bezier(0.25, 0.46, 0.45, 0.94);
$easeOutCubic: cubic-bezier(0.215, 0.61, 0.355, 1);
$easeOutQuart: cubic-bezier(0.165, 0.84, 0.44, 1);
$easeOutQuint: cubic-bezier(0.23, 1, 0.32, 1);

$easeBase: $easeOutQuad;

$durationBase: $duration * 2;
$durationShort: $duration * 1;
$durationLong: $duration3;
$durationVeryLong: $duration4;

$durationVeryLongHome: $duration * 5;
$durationHomeHeadline: $duration * 5;
$durationShowAnim: $duration * 5;

$durationBlockquote: $duration * 10;

$durationAnim: $duration * 5;
$durationLoader: $duration * 5;

$durationOpacityShowAnim: $duration * 5;

$durationOpacityFast: $duration * 1;

$durationDelayBetweenElements: $duration * 0.5;

$durationCaseStudies: $duration * 14;
$durationCaseStudiesScale: $duration * 14;
$durationVeryLongCaseStudies: $duration * 5;

$inquiryButtonBusyOpacity: 0.5;

$baseYShift: $base * 2;

$videoOverlayOpacity: 0.3;


$opacityInputDisabled: 0.4;
