.dlx-featureListItem {
	background-color: $colorSquareButton;
	margin-bottom: $gutterBase;
	overflow: hidden;
	position: relative;
	height: auto;
	cursor: pointer;
	width: dynamicColumnSize(2, 2, $gutterBase);

	&::after {
		content: "";
		display: block;
		padding-bottom: 100%;
	}

	@include media($smallLayout) {
		width: dynamicColumnSize(1, 2, $gutterBase);
	}

	@include media($mediumLayout) {
		margin-bottom: $gutterMedium;
		width: dynamicColumnSize(1, 2, $gutterMedium);
	}

	@include media($xLargeLayout) {
		margin-bottom: $gutterXLarge;
		width: dynamicColumnSize(1, 3, $gutterXLarge);
	}

	&__additional {
		@include colorSet($colorsSetActive, $colorsSetLength, background-color, true, false, '.dlx-blockFeatureList');
		@include ease(transform, $durationLong);
		bottom: 0;
		left: 0;
		min-height: $base * 15;
		position: absolute;
		transform: translateY(calc(100% - 10px));
		width: 100%;

		.dlx-featureListItem:hover & {
			transform: translateY(0);
		}
	}

	&__additionalContent {
		@include dlx-container();
		padding-bottom: $base3;
		padding-top: $base2;
		padding-left: $base2;

		@include media($smallLayout) {
			padding-left: $base2;
			padding-top: $mediumMargin;
		}
	}

	&__copy {
		@include font(intro);
	}

	&__content {
		position: absolute;
		top: 0;
		width: 100%;
		@include dlx-container();
		padding-bottom: $base;
		padding-top: $base2;
		padding-left: $base2;

		@include media($smallLayout) {
			padding-left: $base2;
			padding-top: $mediumMargin;
		}
	}

	&__icon {
		position: absolute;
		width: $base3;
		height: $base3;
		bottom: $base3;
		right: $base2;

		@include media($mediumLayout) {
			display: none;
		}

		&::after {
			content: "";
			position: absolute;
			display: block;
			background-color: $colorSpecialHighlight;
			height: 3px;
			top: 50%;
			margin-top: -1px;
			left: 0;
			right: 0;
		}

		&::before {
			content: "";
			position: absolute;
			display: block;
			background-color: $colorSpecialHighlight;
			width: 3px;
			margin-left: -1px;
			left: 50%;
			top: 0;
			bottom: 0;
		}
	}


	&__title {
		@include font(headerlight);
	}
}
