.dlx-address {

	margin-bottom: $base5;
	width: 100%;

	@include media($smallLayout) {
		//TODO: special case? 4.5 out of 9 columns?
		width: staticColumnSize(4.5, 9, $gutterBase);
	}

	@include media($mediumLayout) {
		margin-bottom: $base7;
		width: staticColumnSize(3, 6, $gutterMedium);
	}

	@include media($xLargeLayout) {
		margin-bottom: $base9;
		width: staticColumnSize(3, 6, $gutterXLarge);
	}

	&--main {
		width: 100%;
		@include media($smallLayout) {
			width: staticColumnSize(8, 9, $gutterBase);
		}
		@include media($mediumLayout) {
			width: staticColumnSize(6, 6, $gutterMedium);
		}
		@include media($xLargeLayout) {
			width: staticColumnSize(6, 6, $gutterXLarge);
		}
	}

	&__link {
		@include font(subhead);
		display: block;
		position: relative;
		margin-top: $base2;

		margin-left: staticColumnPush(2, 12, $gutterBase);
		@include media($smallLayout) {
			margin-left: staticColumnPush(1, 4, $gutterBase);
		}
		@include media($mediumLayout) {
			margin-top: $base3;
			margin-left: staticColumnPush(1, 3, $gutterMedium);
		}
		@include media($xLargeLayout) {
			margin-left: staticColumnPush(1, 3, $gutterXLarge);
		}
	}

	&__linkIcon {
		left: -$base5;
		position: absolute;
		top: -$base05;
		width: $base * 3.5;
	}

	&__linkText {
		text-transform: uppercase;
		color: $colorSecond;
	}

	&__title {
		@include font(base);
		font-weight: bold;
		margin-bottom: $base2;
	}

	&__row {
		width: 100%;
		position: relative;
		@include staticColumns();
		margin-bottom: $base;
	}

	&__rowName {
		@include font(subhead);
		color: $colorLabel;
		margin-top: $base025;
		vertical-align: middle;
		text-transform: uppercase;
		width: staticColumnSize(2, 12, $gutterBase, $gutterBase);

		@include media($smallLayout) {
			margin-top: $base05;
			width: staticColumnSize(1, 4, $gutterBase, $gutterBase);
		}
		@include media($mediumLayout) {
			//margin-top: $base;
			width: staticColumnSize(1, 3, $gutterMedium);
		}
		@include media($xLargeLayout) {
			width: staticColumnSize(1, 3, $gutterXLarge);
		}

		.dlx-address--main & {
			@include media($smallLayout) {
				width: staticColumnSize(1, 8, $gutterBase);
			}
			@include media($mediumLayout) {
				width: staticColumnSize(1, 6, $gutterMedium);
			}
			@include media($xLargeLayout) {
				width: staticColumnSize(1, 6, $gutterXLarge);
			}
		}
	}

	&__rowValue {
		@include font(caption);
		vertical-align: middle;
		width: staticColumnSize(10, 12, $gutterBase);

		.dlx-address--main & {
			@include font(base);

			@include media($smallLayout) {
				width: staticColumnSize(3, 4, $gutterBase);
			}
			@include media($mediumLayout) {
				width: staticColumnSize(5, 6, $gutterMedium);
			}
			@include media($xLargeLayout) {
				width: staticColumnSize(5, 6, $gutterXLarge);
			}
		}

		@include media($smallLayout) {
			width: staticColumnSize(3, 4, $gutterBase);
		}
		@include media($mediumLayout) {
			width: staticColumnSize(2, 3, $gutterMedium);
		}
		@include media($xLargeLayout) {
			width: staticColumnSize(2, 3, $gutterXLarge);
		}
	}
}
