.dlx-blockText {
	@include dlx-mainContent();
	margin-bottom: $base3;
	margin-top: $base3;
	position: relative;

	@include media($xLargeLayout) {
		margin-top: $base6;
		margin-bottom: $base6;
	}
}
