.dlx-form {
	color: $colorSecond;

	&__button {
		@include ease(opacity, $duration2);
		min-width: 100%;

		@include media($smallLayout) {
			min-width: staticColumnSize(3, 4, $gutterBase);
		}

		@include media($mediumLayout) {
			min-width: staticColumnSize(2, 3, $gutterMedium);
		}

		@include media($xLargeLayout) {
			min-width: staticColumnSize(2, 3, $gutterXLarge);
		}

		.dlx-form.dlx-js-busy & {
			pointer-events: none;
			opacity: $inquiryButtonBusyOpacity;
		}
	}

	&__checkbox {
		margin-top: $base2;
	}

	&__file {

	}

	&__fileInput {
		display: none;
	}

	&__footnote {
		@include font(subheader);
		margin-top: $base2;
	}

	&__fullpart {
		display: block;
		width: 100%;
		margin-bottom: $base;
	}

	&__input {
		width: 100%;
	}

	&__parts {
		@include staticColumns(0);
	}

	&__part {
		margin-bottom: $base;
		width: 100%;

		@include media($smallLayout) {
			//TODO: special case? 4.5 out of 9 columns?
			width: staticColumnSize(4.5, 9, $gutterBase);
		}

		@include media($mediumLayout) {
			margin-bottom: $base2;
			width: staticColumnSize(3, 6, $gutterMedium);
		}

		@include media($xLargeLayout) {
			margin-bottom: $base3;
			width: staticColumnSize(3, 6, $gutterXLarge);
		}

		&--message {
			margin-bottom: 0;
			@include media($mediumLayout) {
				margin-bottom: 0;
			}

			@include media($xLargeLayout) {
				margin-bottom: 0;
			}
		}
	}

	&__largeField {
		padding-bottom: $base4;
		width: 100%;

		@include media($xLargeLayout) {
			padding-bottom: $base6;
		}
	}
}
