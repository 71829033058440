.dlx-blockNewsletterForm {
	@include dlx-container();
	border-top: 1px solid $colorSeparateLine;
	border-bottom: 1px solid $colorSeparateLine;
	padding-top: $base4;
	padding-bottom: $base4;

	.dlx-block:last-of-type & {
		margin-bottom: -$base8;
	}

	&__content {
		@include dlx-mainContent();
	}

	&__copytext {
		margin-bottom: $base4;
		@include font(base);
	}

	&__formContent {
		width: 100%;
		@include media($mediumLayout) {
			width: staticColumnSize(6, 8, $gutterMedium);
		}

		@include media($xLargeLayout) {
			width: staticColumnSize(6, 8, $gutterXLarge);
		}
	}

	&__header {
		margin-bottom: $base4;
	}
}
