// full width content, which changes to full width with left margin
// - $noSmallLeftMargin: special case for image header, which has no small left
// margin on tablet-size screens
@mixin dlx-wideContent($noSmallLeftMargin: false) {
	margin-left: -$containerPaddingBase;
	width: calc(100% + 2 * #{$containerPaddingBase});

	@include media($smallLayout) {
		margin-left: if($noSmallLeftMargin, -$containerPaddingSmall, 0);
		width: if($noSmallLeftMargin, calc(100% + 2 * #{$containerPaddingSmall}), calc(100% + #{$containerPaddingSmall}));
	}

	@include media($mediumLayout) {
		margin-left: staticColumnPush(2, 12, $gutterMedium);
		width: staticColumnSize(10, 12, $gutterMedium, $containerPaddingMedium);
	}

	@include media($xLargeLayout) {
		margin-left: staticColumnPush(2, 12, $gutterXLarge);
		width: staticColumnSize(10, 12, $gutterXLarge, $containerPaddingXLarge);
	}
}
