@mixin fadeInOnLoad($loadedClass, $duration: $duration3, $easing: '') {
	opacity: 0;
	overflow: hidden;
	height: 0;
	//filter: contrast(3);

	&.#{$loadedClass} {
		@include ease(opacity height, $duration, $easing);
		opacity: 1;
		height: 100%;
		//filter: contrast(1);
	}
}
