.dlx-relatedTopic {
	overflow: hidden;
	position: relative;
	display: inline-block;
	min-height: $base * 20;
	margin-bottom: $base;
	width: dynamicColumnSize(2, 2, $gutterBase);

	@include media($smallLayout) {
		min-height: $base * 45;
	}

	@include media($mediumLayout) {
		margin-bottom: $gutterMedium;
		width: dynamicColumnSize(1, 2, $gutterMedium);
	}

	@include media($xLargeLayout) {
		margin-bottom: $gutterXLarge;
		width: dynamicColumnSize(1, 2, $gutterXLarge);
	}

	&__anchor {
		width: 100%;
		height: 100%;
		position: absolute;
		left: 0;
		top: 0;
	}

	&__arrow {
		position: absolute;
		right: $base;
		bottom: $base;
	}

	&__container {
		@include dlx-container();
		color: $colorBaseInverted;
		padding-bottom: $base;
		padding-top: $base;

		@include media($smallLayout) {
			padding-left: $mediumMargin;
			padding-top: $mediumMargin;
		}

		&::after {
			content: '';
			background-color: $colorOverlayTransparentDark;
			position: absolute;
			bottom: 0;
			left: 0;
			width: 100%;
			height: 100%;
			right: 0;
			top: 0;
			z-index: -1;
			@include ease(background-color opacity, $durationLong, $easeBase);

			.dlx-relatedTopic:hover & {
				opacity: 0.7;
				//background-color: $colorOverlayTransparentDark;
			}
		}
	}

	&__image {
		display: block;
	}

	&__imageContainer {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: -1;
	}

	&__img {
		@include objectFit(cover);
		display: block;
		height: 100%;
		width: 100%;
		position: absolute;
	}

	&__title {
		@include font(header);
	}


	&__subtitle {
		@include font(headerlight);
	}
}
