.dlx-blockImage {
	@include dlx-fadeUp();

	margin-top: $base2;
	margin-bottom: $base2;

	@include media($mediumLayout) {
		margin-bottom: $base4;
	}

	&__caption {
		@include font(subheader);
		@include dlx-mainContent(false, true);
		margin-top: $base;
	}

	&__img {
		@include fadeInOnLoad(dlx-js-loaded, $durationOpacityShowAnim, $easeBase);
		@include dlx-wideContent();

		&--alternate {
			@include dlx-mainContent();
		}
	}
}
