.dlx-galleryCaptions {
	&__caption {
		@include font(caption);
	}


	&__item {
		@include ease(opacity visibility, $duration2);
		@include fadeOut();
		display: block;
		position: relative;
		width: 100%;
		flex-shrink: 0;

		@for $i from 2 through $galleryMaxItems {
			&:nth-child(#{$i}) {
				transform: translateX(-#{100% * ($i - 1)});
			}
		}

		&.dlx-js-current {
			@include fadeIn();
			z-index: 1;
		}
	}


	&__items {
		display: flex;
		flex-direction: row;
		flex-wrap: nowrap;
		position: relative;
	}
}
