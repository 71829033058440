.dlx-pageHeader {
	height: $headerHeightSmall;
	position: fixed;
	top: 0;
	width: 100%;
	z-index: 12;

	@include media($mediumLayout) {
		height: $headerHeightBig;
	}

	&--special {
		@include media($mediumLayout) {
			height: $headerHeightSpecialBig;
		}
	}

	&__content {
		position: relative;
	}

	&__head {
		top: 0;
		@include dlx-container();
		height: 100%;

		&::before {
			@include ease(opacity, $durationBase, $easeBase);
			background-color: $headerBackground;
			content: '';
			height: $headerPaddingSmall;
			left: 0;
			opacity: 0;
			position: absolute;
			top: 0;
			width: 100%;
		}

		&::after {
			@include ease(opacity, $durationBase, $easeBase);
			background-color: $headerBackground;
			bottom: 0;
			content: '';
			height: $headerPaddingSmall;
			left: 0;
			opacity: 0;
			position: absolute;
			width: 100%;
		}

		@include media($mediumLayout) {
			&::before {
				height: $headerPaddingBig;
			}

			&::after {
				height: $headerPaddingBig;
			}
		}

		.dlx-pageHeader.dlx-js-scrolledDown &::after,
		.dlx-pageHeader.dlx-js-scrolledDown &::before,
		.dlx-main.dlx-js-covered &::after,
		.dlx-main.dlx-js-covered &::before {
			opacity: 1;
		}
	}

	&__homeLink {
		@include ease(opacity, $durationBase, $easeBase);
		@include mediaMap($sizeVariants, logoSize);
		background-color: transparent;
		display: block;
		float: right;
		cursor: pointer;
		//z-index: 1;
		margin-top: $headerPaddingSmall;
		position: relative;

		.dlx-pageHeader--special & {
			@include mediaMap($sizeVariants, logoSizeSpecial);
		}

		&::before {
			@include ease(opacity, $durationBase, $easeBase);
			opacity: 0;
			background-color: $headerBackground;
			content: '';
			height: 100%;
			left: -100vw;
			pointer-events: none;
			position: absolute;
			top: 0;
			width: 100vw;
			z-index: -1;
		}

		&::after {
			@include ease(opacity, $durationBase, $easeBase);
			opacity: 0;
			background-color: $headerBackground;
			content: '';
			height: 100%;
			pointer-events: none;
			position: absolute;
			right: -100vw;
			top: 0;
			width: 100vw;
			//z-index: 0;
			z-index: -1;
		}

		.dlx-pageHeader.dlx-js-scrolledDown & {
			background-color: transparent;
		}

		.dlx-pageHeader.dlx-js-scrolledDown &::after,
		.dlx-pageHeader.dlx-js-scrolledDown &::before,
		.dlx-main.dlx-js-covered &::after,
		.dlx-main.dlx-js-covered &::before {
			opacity: 1;
		}

		.dlx-body.dlx-js-invertedHeader .dlx-pageHeader:not(.dlx-js-scrolledDown) &,
		.dlx-body.dlx-js-fullyInvertedHeader .dlx-pageHeader:not(.dlx-js-scrolledDown) & {
			//color: $colorBaseInverted;
		}
		//from medium breakpoint same margins as main content
		@include media($mediumLayout) {
			margin-top: $headerPaddingBig;
			float: none;
			margin-left: staticColumnPush(5, 12, $gutterMedium);
		}

		@include media($xLargeLayout) {
			float: none;
			margin-left: staticColumnPush(5, 12, $gutterXLarge);
		}

		&--special {
			float: left;
			@include media($mediumLayout) {
				margin-left: 0;
			}

			@include media($xLargeLayout) {
				margin-left: 0;
			}
		}
	}

	&__logo {
		@include ease(opacity, $durationBase, $easeBase);
		color: $colorSecond;
		display: block;
		opacity: 1;
		position: relative;

		svg {
			@include mediaMap($sizeVariants, logoSize);
		}

		.dlx-pageHeader--special & {
			svg {
				@include mediaMap($sizeVariants, logoSizeSpecial);
			}
		}

		.dlx-body.dlx-js-invertedHeader .dlx-pageHeader:not(.dlx-js-scrolledDown) &,
		.dlx-body.dlx-js-fullyInvertedHeader .dlx-pageHeader:not(.dlx-js-scrolledDown) & {
			color: $colorBaseInverted;
			display: block;
		}

		.dlx-pageHeader.dlx-js-scrolledDown &,
		.dlx-main.dlx-js-covered & {
			opacity: 0;
		}

		&--inverted {
			color: $headerBackground;
			position: absolute;
			top: 0;
			left: 0;
			opacity: 0;
			display: block;

			&::after {
				@include mediaMap($sizeVariants, logoSize);
				background-color: $colorLogoOverlayTransparent;
				content: '';
				left: 0;
				position: absolute;
				top: 0;
				z-index: -1;
			}

			.dlx-pageHeader--special &::after {
				@include mediaMap($sizeVariants, logoSizeSpecial);
			}

			.dlx-body.dlx-js-invertedHeader & {
				color: $headerBackground;
			}

			.dlx-main.dlx-js-covered & {
				color: $headerBackground;
				display: block;
				opacity: 1;
			}

			.dlx-pageHeader.dlx-js-scrolledDown & {
				display: block;
				opacity: 1;
			}
		}
	}

	&__logoSvg {
		&--small {
			display: block;
			width: $logoSmallWidth;
			height: $logoSmallHeight;

			.dlx-pageHeader--special & {
				@include media($mediumLayout) {
					display: block;
				}
			}

			@include media($mediumLayout) {
				display: none;
			}
		}

		&--large {
			display: none;
			width: $logoWidth;
			height: $logoHeight;

			.dlx-pageHeader--special & {
				@include media($mediumLayout) {
					display: none;
				}
			}

			@include media($mediumLayout) {
				display: block;
			}
		}
	}

	&__mainMenuToggler {
		//
	}


	&__menuContent {
		//
	}
}
