.dlx-blockTeam {
	@include dlx-tilesContent();
	@include dlx-fadeUp();

	&__items {
		@include dynamicColumns($gutterBase, 0);

		@include media($mediumLayout) {
			@include dynamicColumns($gutterMedium, 0);
		}

		@include media($xLargeLayout) {
			@include dynamicColumns($gutterXLarge, 0);
		}
	}

	&__item {
		//
	}
}
