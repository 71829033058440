.dlx-teamTile {
  margin-bottom: $gutterBase;
  margin-right: 0;
  position: relative;
  width: dynamicColumnSize(1, 2, $gutterBase);
  @include ease(z-index, $durationLong, $easeBase);
  z-index: 0;

  &--imageBackground {
	color: $colorBaseInverted;
  }

  &__personName {
	@include font(headermedium);


	&--mobileHidden {
	  .dlx-teamTile.dlx-teamTile--expandable & {
		opacity: 0;
		@include media($smallLayout) {
		  opacity: 1;
		}
	  }
	}
  }

  &__personPosition {
	@include font(personposition);

	&--mobileHidden {
	  .dlx-teamTile.dlx-teamTile--expandable & {
		opacity: 0;
		@include media($smallLayout) {
		  opacity: 1;
		}
	  }
	}
  }

  &__personDescription {
	padding-top: pxToRem(30);
	@include media($smallLayout) {
	  padding-top: pxToRem(98);
	}
	@include font(positiondescription);
  }

  &.dlx-teamTile--expandable {
	width: dynamicColumnSize(1, 2, $gutterBase);

	@include media($smallLayout) {
	  width: dynamicColumnSize(1, 4, $gutterBase);

	  @include media($mediumLayout) {
		margin-bottom: $gutterMedium;
		width: dynamicColumnSize(2, 8, $gutterMedium);
	  }

	  @include media($xLargeLayout) {
		margin-bottom: $gutterXLarge;
		width: dynamicColumnSize(2, 8, $gutterXLarge);
	  }
	}
  }

  &:hover {
	z-index: 3;
  }

  @include media($smallLayout) {
	width: dynamicColumnSize(1, 4, $gutterBase);
  }

  @include media($mediumLayout) {
	margin-bottom: $gutterMedium;
	width: dynamicColumnSize(2, 8, $gutterMedium);
  }

  @include media($xLargeLayout) {
	margin-bottom: $gutterXLarge;
	width: dynamicColumnSize(2, 8, $gutterXLarge);
  }

  &__container {
	padding-bottom: $portraitHeight;
	width: 100%;
  }

  &__icon {
	display: block;
	position: relative;
	margin-top: $base2;
	margin-left: $base;
	opacity: 0;

	.dlx-teamTile.dlx-teamTile--expandable & {
	  opacity: 1;
	  @include media($mediumLayout) {
		opacity: 0;
	  }
	}

	.dlx-teamTile:hover & {
	  @include ease(opacity, $durationLong, $easeBase, $durationLong);
	  opacity: 1;
	}
  }

  &__inner {
	@include colorSet($colorsSet, $colorsSetLength, background-color, true, false, '.dlx-teamTile');
	@include ease(width height, $durationLong, $easeBase);
	height: 100%;
	left: 0;
	position: absolute;
	top: 0;
	width: 100%;

	.dlx-teamTile.dlx-teamTile--expandable:hover & {
	  @include ease(width height, $durationLong, $easeBase);
	  height: calc(200% + #{$gutterBase});
	  width: calc(200% + #{$gutterBase});

	  @include media($mediumLayout) {
		width: calc(200% + #{$gutterMedium});
		height: calc(200% + #{$gutterMedium});
	  }

	  @include media($xLargeLayout) {
		width: calc(200% + #{$gutterXLarge});
		height: calc(200% + #{$gutterXLarge});
	  }
	}

	.dlx-teamTile.dlx-teamTile--expandable:nth-child(2n) & {
	  left: auto;
	  right: 0;
	  @include media($mediumLayout) {
		left: 0;
		right: auto;
	  }
	}

	.dlx-teamTile.dlx-teamTile--expandable:nth-child(4n) & {
	  left: auto;
	  right: 0;
	}

	.dlx-teamTile.dlx-teamTile--expandable:nth-last-child(-n + 2) & {
	  bottom: 0;
	  top: auto;
	}

	.dlx-teamTile.dlx-teamTile--expandable:nth-last-child(-n + 4) & {
	  @include media($mediumLayout) {
		bottom: 0;
		top: auto;
	  }
	}
  }

  &__content {
	@include font(subhead);
	bottom: $base;
	font-weight: bold;
	left: $base;
	position: absolute;
	opacity: 0;

	$colors: (
		color1: $nameColorBlueGrey,
		color2: $nameColorGolden,
		color3: $nameColorKhakiGreen,
		color4: $nameColorDarkPeach,
		color5: $nameColorLilia
	);

	@each $modifier, $color in $colors {
	  &--#{$modifier} {
		background: $color;
	  }

	  &--withImageUnderlay-#{$modifier} {
		background: rgba($color, 0.9);
	  }
	}

	&--hover {
	  top: $base0;
	  left: $base0;
	  padding: $mainPageDotSmall;
	  overflow: hidden;
	  text-overflow: ellipsis;
	  width: 100%;
	  height: 100%;
	}

	.dlx-teamTile.dlx-teamTile--expandable & {
	  opacity: 1;

	  @include media($mediumLayout) {
		opacity: 0;
	  }
	}

	.dlx-teamTile.dlx-teamTile--cardHover & {
	  opacity: 0;

	  @include media($mediumLayout) {
		opacity: 0;
	  }
	}


	.dlx-teamTile:hover & {
	  @include ease(opacity, $durationLong, $easeBase);
	  opacity: 1;
	}

	.dlx-teamTile.dlx-teamTile--expandable:hover & {
	  @include ease(opacity, $durationLong, $easeBase, $durationLong);
	}
  }

  &__image {
	display: block;
	height: 100%;
	left: 0;
	position: absolute;
	top: 0;
	width: 100%;
	z-index: 0;

	&::after {
	  content: '';
	  background-color: $colorOverlayTransparent;
	  position: absolute;
	  bottom: 0;
	  left: 0;
	  width: 100%;
	  height: 100%;
	  right: 0;
	  top: 0;
	  z-index: 0;
	  opacity: 0;

	  .dlx-teamTile.dlx-teamTile--expandable & {
		opacity: 1;
		@include media($mediumLayout) {
		  opacity: 0;
		}
	  }

	  .dlx-teamTile:hover & {
		@include ease(opacity, $durationLong, $easeBase);
		opacity: 1;
	  }
	}
  }

  &__img {
	@include objectFit(cover);
	display: block;
	height: 100%;
	width: 100%;
  }

  &__link {
	display: block;
	height: 100%;
	text-decoration: none;
	width: 100%;
	position: relative;
  }

  &__quote {
	position: relative;
	padding-left: $base;
	padding-top: $base;
	opacity: 0;

	.dlx-teamTile.dlx-teamTile--expandable & {
	  opacity: 1;
	  @include media($mediumLayout) {
		opacity: 0;
	  }
	}

	.dlx-teamTile:hover & {
	  @include ease(opacity, $durationLong, $easeBase, $durationLong);
	  opacity: 1;
	}
  }
}
