.dlx-caseStudy {
	@include dlx-container();

	&__header {
		padding-bottom: $base;
	}

	&__head {
		@include dlx-mainContent(true);
		@include font(head);
		padding-top: $base7;
		margin-bottom: $base5;

		@include media($mediumLayout) {
			padding-top: $base * 12;
			margin-bottom: $base8;
		}

		@include media($xLargeLayout) {
			margin-bottom: $base * 12;
		}
	}

	&__subhead {
		@include dlx-fadeUp(0s, '.dlx-caseStudy');
		@include dlx-mainContent();
		@include font(subhead);
		color: $colorSubhead;
		margin-bottom: $base;
		text-transform: uppercase;
	}

	&__lead {
		@include dlx-fadeUp(0s, '.dlx-caseStudy');
		@include dlx-mainContent();
		color: $colorBase;
	}
}
