//
// text links, solid background text links, downlload links
//
.dlx-blockLink {
	display: block;
	position: relative;

	&--configurator::after {
		content: '';
		position: absolute;
		bottom: 0;
		left: 0;
		height: 1px;
		width: 100vw;
		background-color: $colorBase;
	}

	&__anchor {
		@include ease(background-color, $durationBase, $easeBase);
		display: block;
		height: 100%;
		position: relative;
		text-decoration: none;
		width: 100%;

		&:hover {
			background-color: $colorSecondInverted;
		}

		&::before {
			content: '';
			position: absolute;
			top: 0;
			left: -$gutterBase;
			height: 100%;
			width: $gutterBase;
			background-color: inherit;

			@include media($mediumLayout) {
				left: -$gutterMedium;
				width: $gutterMedium;
			}

			@include media($xLargeLayout) {
				left: -$gutterXLarge;
				width: $gutterXLarge;
			}
		}

		&::after,
		&:last-of-type::after {
			content: '';
			position: absolute;
			top: 0;
			left: auto;
			right: -30vw;
			height: 100%;
			width: 30vw;
			background-color: inherit;
			z-index: -1;
		}

		.dlx-blockLink--configurator &:hover {
			background-color: transparent;
		}

		.dlx-blockLinks__item--highlight & {
			color: $colorBase;
			@include colorSet($colorsSet, $colorsSetLength, background-color, false, true, '.dlx-blockLink');

			&:hover {
				@include colorSet($colorsSetActive, $colorsSetLength, background-color, false, true, '.dlx-blockLink');
			}
		}
	}

	&__content {
		position: relative;
		min-height: $linkElementMinHeight;
		padding-top: $base2;
		padding-bottom: $base2;


		//margin-bottom: $base1;

		@include media($smallLayout) {
			//margin-bottom: $base2;
		}

		@include media($xLargeLayout) {
			//margin-bottom: $base3;
		}


		@include media($smallLayout) {
			padding-top: $base3;
			padding-bottom: $base3;
			min-height: $linkElementMinHeightM;
		}

		@include media($xLargeLayout) {
			padding-top: $base5;
			padding-bottom: $base5;
			min-height: $linkElementMinHeightXL;
		}

		.dlx-blockLink--image & {
			//min-height: 0;
			@include media($smallLayout) {
			//	min-height: 0;
			}
			@include media($xLargeLayout) {
			//	min-height: 0;
			}
		}
	}

	&__downloadIcon {
		@include ease(opacity, $durationBase, $easeBase);
		color: $colorIcon;
		height: $baseIconSize;
		width: $baseIconSize;
		position: absolute;
		left: 0;
		top: 50%;
		transform: translateY(-50%);

		.dlx-blockLink--highlight & {
			color: $colorBase;
		}
	}

	&__downloadTitle {
		@include font(subhead);
		position: absolute;
		text-transform: uppercase;
		left: $baseIconSize + $base2;
		top: 50%;
		transform: translateY(-50%);
		color: $colorSecond;

		.dlx-blockLink--highlight & {
			color: $colorBase;
		}
	}


	&__excerpt {
		padding-top: $base2;
		padding-bottom: $base2;

		@include media($smallLayout) {
			padding-bottom: $base3;
			padding-right: 0;
			width: staticColumnSize(4, 6, $gutterBase);
		}

		@include media($mediumLayout) {
			width: staticColumnSize(4, 6, $gutterMedium);
		}

		@include media($xLargeLayout) {
			padding-bottom: $base5;
			width: staticColumnSize(4, 6, $gutterXLarge);
		}
	}


	&__icon {
		@include ease(color, $durationBase, $easeBase);
		color: $colorIcon;
		height: $baseIconSize;
		position: absolute;
		right: 0;
		top: 50%;
		transform: translateY(-50%);
		width: $baseIconSize;

		.dlx-blockLinks__item--highlight & {
			color: $colorBase;
		}

		.dlx-blockLink--configurator & {
			color: $colorBase;
		}
	}

	&__imageContainer {
		position: relative;
		z-index: 1;
		height: auto;
		overflow: hidden;
		width: 100%;

		@include media($smallLayout) {
			width: calc(100% + #{staticColumnSize(1, 9, $gutterBase, $gutterBase)} + #{$containerPaddingSmall});
		}

		@include media($mediumLayout) {
			width: calc(100% + #{staticColumnSize(1, 6, $gutterMedium, $gutterMedium)} + #{$containerPaddingMedium});
		}

		@include media($xLargeLayout) {
			width: calc(100% + #{staticColumnSize(1, 6, $gutterXLarge, $gutterXLarge)} + #{$containerPaddingXLarge});
		}
	}


	&__image {
		position: relative;
		z-index: 1;
		height: auto;
		padding-bottom: 100%;
		width: 100%;

		@include media($smallLayout) {
			padding-bottom: staticColumnSize(7, 10, $gutterBase);
		}

		@include media($mediumLayout) {
			padding-bottom: staticColumnSize(3, 7, $gutterMedium);
		}

		@include media($xLargeLayout) {
			padding-bottom: staticColumnSize(3, 7, $gutterXLarge);
		}
	}

	&__img {
		@include objectFit(cover);
		display: block;
		height: 100%;
		width: 100%;
		position: absolute;
	}

	&__title {
		@include font(header);
		color: $colorSecond;
		//margin-bottom: $base1;
		padding-right: $base8;
		//margin-top: $base;
		position: relative;

		.dlx-blockLinks__item--highlight & {
			color: $colorBase;
		}

		.dlx-blockLink--configurator & {
			color: $colorBase;
		}

		@include media($smallLayout) {
			//margin-bottom: $base2;
		}

		@include media($xLargeLayout) {
			//margin-bottom: $base3;
		}
	}

	&__subtitle {
		@include font(subheader);
		//font-weight: bold;
		margin-bottom: $base;
	}
}
