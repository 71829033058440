.dlx-checkbox {
	@include font(subheader);
	cursor: pointer;
	display: block;

	&__checkbox {
		@include visuallyHidden();
	}

	&__label {
		margin-left: $base3;
		display: block;

		//TODO: @gerald, can we add a link inside $site->t('newsletter_t_and_c')?
		a {
			text-decoration: underline;
		}
	}

	&__off {
		display: inline-block;
		float: left;

		.dlx-checkbox__checkbox:checked ~ & {
			display: none;
		}
	}

	&__on {
		display: none;
		float: left;

		.dlx-checkbox__checkbox:checked ~ & {
			display: inline-block;
		}
	}
}
