.dlx-interactiveGraphicInteractions {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	height: 100%;

	&__interaction {
		width: 100%;
		height: 100%;
		position: relative;
	}

	&__button {
		position: absolute;
		display: block;
		background-color: red;
	}
}
