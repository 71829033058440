.dlx-interactiveGraphicMenu {
	width: $base * 32;

	&__head {
		@include font(subheader);
		font-weight: bold;
		text-transform: uppercase;
		margin-top: $base2;
		margin-bottom: $base;
	}

	&__menu {
		position: relative;

		&::before {
			content: '';
			width: 1px;
			height: 100%;
			background-color: red;
			position: absolute;
			top: 0;
			left: -$base2;
		}

		&--cat1::before {
			background-color: #da7b5e;
		}

		&--cat2::before {
			background-color: #768c2f;
		}

		&--cat3::before {
			background-color: #6a665b;
		}
	}

	&__menuItem {
		margin-bottom: 2px;
		padding: 2px;
		margin-left: -$base;
		width: $base * 29;
		background-color: $colorBaseInverted;
	}

	&__button {
		text-align: left;
		cursor: pointer;
		margin-left: $base;
		width: $base * 29;
		@include font(subheader);
	}
}
