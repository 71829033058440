.dlx-mainMenu {
	@include fadeOut($durationBase, $easeBase);
	background-color: $colorBgBase;
	display: block;
	height: calc(100vh - #{$headerHeightSmall});
	left: 0;
	position: absolute;
	top: $headerHeightSmall;
	width: 100%;
	z-index: 20;
	overflow-y: auto;

	.dlx-body.dlx-js-idle & {
		@include media($mediumLayout) {
			@include fadeOut($durationBase, $easeBase);
		}
	}


	&.dlx-js-beforeActive:not(.dlx-js-fixed) {
		will-change: opacity;
		@include fadeIn($durationBase, $easeBase);
	}

	&.dlx-js-duringActive {
		//@include fadeIn($durationBase, $easeBase);
	}

	@include media($mediumLayout) {
		@include fadeIn(0);
		background-color: transparent;
		height: calc(100vh - #{$headerHeightBig});
		padding-top: $headerHeightBig;
		position: fixed;
		top: 0;
		width: $marginMenuWidth;
		overflow-y: visible;
	}

	.dlx-pageHeader--special & {
		@include fadeIn(0);
		background-color: transparent;
	}


	&__container {
		@include dlx-container();
		display: flex;
		flex-direction: column;
		min-height: 100%;
		padding-bottom: $base1;
		padding-top: $base3;
		width: 100%;

		@include media($smallLayout) {
			padding-bottom: $base10;
			padding-top: $base10;
		}

		@include media($mediumLayout) {
			padding-right: 0;
			padding-top: $base3;
		}

		@include media($xLargeLayout) {
			padding-top: $base3;
		}
	}

	&__sectionMain {
		overflow: hidden;

		@include fadeIn($durationBase, $easeBase);

		.dlx-pageHeader.dlx-js-menuHidden & {
			@include media($mediumLayout) {
				@include fadeOut($durationBase, $easeBase);
			}
		}

		@include media($mediumLayout) {
			z-index: 5;
		}
	}

	&__sectionAdditional {
		left: 0;
		margin-top: auto;
		padding-top: $base4;
		padding-bottom: $base10;

		@include media($smallLayout) {
			right: $containerPaddingSmall;
			padding-bottom: 0;
		}

		@include media($mediumLayout) {
			display: block;
			left: auto;
			position: fixed;
			right: $containerPaddingMedium;
			top: $headerPaddingBig;
			z-index: 20;
			padding-top: 0;
		}

		@include media($xLargeLayout) {
			right: $containerPaddingXLarge;
		}

		.dlx-pageHeader--special & {
			display: block;
			left: auto;
			position: fixed;
			right: $containerPaddingSmall;
			top: $base2;// + $base05;
			z-index: 20;
			padding-top: 0;
			padding-bottom: 0;

			@include media($smallLayout) {
				right: $containerPaddingMedium;
				padding-bottom: 0;
			}

			@include media($mediumLayout) {
				top: $base * 2.8;
			}
		}

		&::before {
			.dlx-pageHeader--special & {
				@include ease(background-color, $durationBase, $easeBase);
				content: '';
				position: absolute;
				width: calc(100% + 2 * #{$base});
				height: calc(100% - 4 * #{$base});
				top: $base4;
				z-index: -1;
				left: -$base;
				background-color: $colorBgBase;
			}

			.dlx-body.dlx-js-invertedHeader .dlx-pageHeader--special:not(.dlx-js-scrolledDown) & {
				background-color: transparent;
			}

			@include media($mediumLayout) {
				@include ease(background-color, $durationBase, $easeBase);
				content: '';
				position: absolute;
				width: calc(100% + 2 * #{$base});
				height: calc(100% - 4 * #{$base});
				top: $base4;
				z-index: -1;
				left: -$base;
				background-color: $colorBgBase;
			}
		}

		.dlx-body.dlx-js-fullyInvertedHeader &::before,
		.dlx-body.dlx-js-invertedHeader .dlx-pageHeader:not(.dlx-js-scrolledDown) &::before {
			@include media($mediumLayout) {
				background-color: transparent;
			}
		}
	}


	&__icon {
		@include ease(opacity, $durationBase, $easeBase);
		display: inline-block;
		margin-left: $base025;
		margin-top: 2px;
		opacity: 1;
		height: $base2;
		width: $base3;
		vertical-align: top;

		@include media($mediumLayout) {
			opacity: 0;
		}

		@include media($xLargeLayout) {
			margin-top: 0;
		}

		.dlx-mainMenu__togglerLink:hover &,
		.dlx-mainMenu__togglerLink:focus & {
			opacity: 1;
		}

		svg {
			fill: $colorSecond;
			stroke: $colorSecond;
			@include ease(transform, $durationBase, $easeBase);
		}

		.dlx-body.dlx-js-fullyInvertedHeader &,
		.dlx-body.dlx-js-invertedHeader .dlx-pageHeader:not(.dlx-js-scrolledDown) .dlx-mainMenu__menuSections--additional & {
			svg {
				@include media($mediumLayout) {
					fill: $colorBaseInverted;
					stroke: $colorBaseInverted;
				}
			}
		}

		.dlx-body.dlx-js-fullyInvertedHeader .dlx-pageHeader--special &,
		.dlx-body.dlx-js-invertedHeader .dlx-pageHeader--special:not(.dlx-js-scrolledDown) .dlx-mainMenu__menuSections--additional & {
			svg {
				fill: $colorBaseInverted;
				stroke: $colorBaseInverted;
			}
		}

		.dlx-mainMenu__togglerLink.dlx-js-toggled & {
			svg {
				transform: scaleY(-1);
			}
		}

		.dlx-mainMenu__menuSections--additional & {
			margin-top: -$base025;

			@include media($smallLayout) {
				margin-top: -$base05;
			}

			@include media($mediumLayout) {
				margin-top: -$base05;
			}

			@include media($xLargeLayout) {
				margin-top: -$base05 - $base025;
			}
		}
	}

	&__menuItems {
		@include ease(opacity visibility, $durationBase, $easeBase);
		display: block;
		padding-bottom: $base;
		position: relative;

		&:focus {
			outline: 0;
		}

		&.dlx-js-collapsed {
			opacity: 0;
			position: absolute;
			visibility: hidden;
		}
	}

	&__menuItem {
		@include font(submenu);
		color: $colorSecond;
		height: auto;
		padding-top: $base1 + $base05;

		.dlx-body.dlx-js-fullyInvertedHeader &,
		.dlx-body.dlx-js-invertedHeader .dlx-pageHeader:not(.dlx-js-scrolledDown) .dlx-mainMenu__menuSections--additional & {
			@include media($mediumLayout) {
				color: $colorBaseInverted;
			}
		}

		.dlx-body.dlx-js-fullyInvertedHeader .dlx-pageHeader--special:not(.dlx-js-scrolledDown) &,
		.dlx-body.dlx-js-invertedHeader .dlx-pageHeader--special:not(.dlx-js-scrolledDown) .dlx-mainMenu__menuSections--additional & {
			color: $colorBaseInverted;
		}

		&:first-of-type {
			@include media($smallLayout) {
				padding-top: $base1;
			}
		}
	}

	&__menuLink {
		color: $colorSecond;
		position: relative;
		margin: 0;
		padding: 0;
		display: inline-block;
		vertical-align: top;

		&--active {
			font-weight: bold;
		}

		.dlx-body.dlx-js-fullyInvertedHeader &,
		.dlx-body.dlx-js-invertedHeader .dlx-pageHeader:not(.dlx-js-scrolledDown) .dlx-mainMenu__menuSections--additional & {
			@include media($mediumLayout) {
				color: $colorBaseInverted;
			}
		}

		.dlx-body.dlx-js-fullyInvertedHeader .dlx-pageHeader--special:not(.dlx-js-scrolledDown) &,
		.dlx-body.dlx-js-invertedHeader .dlx-pageHeader--special:not(.dlx-js-scrolledDown) .dlx-mainMenu__menuSections--additional & {
			color: $colorBaseInverted;
		}

		.dlx-mainMenu__menuSections--additional & {
			@include font(subhead);
			text-transform: uppercase;
			font-weight: normal;

			.dlx-pageHeader--special & {
				font-weight: normal;
			}
		}

		.dlx-mainMenu__menuItem--current & {
			font-weight: bold;
		}
	}


	&__menuSection {
		@include ease(height, $durationLong, $easeBase);
		overflow: hidden;
		padding-bottom: $base1 + $base05;
		position: relative;

		.dlx-mainMenu__menuSections--additional & {
			padding-bottom: $base;
			@include media($mediumLayout) {
				width: auto;
				display: inline-block;
				margin-left: $base + $base05;
			}
		}

		&:last-of-type {
			padding-bottom: 0;
		}
	}

	&__menuSections {
		@include ease(opacity, $durationBase, $easeBase);
		display: flex;
		flex-direction: column;
		overflow: hidden;

		&--additional {
			@include fadeIn($durationBase, $easeBase);

			.dlx-body.dlx-js-searchOpen & {
				@include fadeOut($durationBase, $easeBase);
			}

			@include media($mediumLayout) {
				flex-direction: row;
			}
		}

		@include media($smallLayout) {
			margin-left: staticColumnPush(2, 12, $gutterBase);
		}

		@include media($mediumLayout) {
			margin-left: 0;
		}
	}

	&__togglerLink {
		@include ease(color, $durationBase, $easeBase);
		white-space: nowrap;

		&:hover {
			color: $colorSecond;
		}

		.dlx-mainMenu__menuSections--additional & {
			text-transform: uppercase;
		}

		&--impressum {
			margin-right: $base2;
			display: none;

			.dlx-body.dlx-js-homePage & {
				display: block;
			}
		}
	}

	&__title {
		@include font(base);
		display: inline-block;
		font-weight: 500;

		.dlx-mainMenu__menuSections--additional & {
			@include font(subhead);
			color: $colorSecond;
			vertical-align: top;
		}

		.dlx-pageHeader--special & {
			font-weight: normal;
		}

		.dlx-body.dlx-js-fullyInvertedHeader &,
		.dlx-body.dlx-js-invertedHeader .dlx-pageHeader:not(.dlx-js-scrolledDown) .dlx-mainMenu__menuSections--additional & {
			@include media($mediumLayout) {
				color: $colorBaseInverted;
			}
		}

		.dlx-body.dlx-js-fullyInvertedHeader .dlx-pageHeader--special:not(.dlx-js-scrolledDown) &,
		.dlx-body.dlx-js-invertedHeader .dlx-pageHeader--special:not(.dlx-js-scrolledDown) .dlx-mainMenu__menuSections--additional & {
			color: $colorBaseInverted;
		}
	}
}
